<script>
import WithItemsMixin from "@/plugins/mixins/WithOrderToBuyItems";
import ResourceView from "@/plugins/mixins/ResourceView";
import TextInput from "@/components/Form/TextInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import WithVats from "@/plugins/mixins/WithVats";
import Btn from "@/components/Form/Btn.vue";
import OrderToBuyItemsFillingDialog from "@/components/Warehouse/Buy/NomenclatureFillingDialog.vue";

export default {
    name: "OrderToBuyItems",
    components: {OrderToBuyItemsFillingDialog, Btn, SelectInput, TextInput},

    mixins: [
        WithItemsMixin,
        WithVats,
        ResourceView
    ],

    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            entityName: 'order_to_buy_item',
            editingItemId: null,
            editingField: null,
            fillingDialog: false,
        }
    },

    computed: {
        internalItems() {
            return this.items.map(item => {
                item.discount = item.discount ? item.discount : 0;
                item.sum = item.quantity * item.price;
                item.discount_sum = item.sum * (item.discount ?? 0) / 100;
                item.sum_with_discount = item.sum - item.discount_sum;
                item.total = item.sum_with_discount;
                item.vat_sum = 0;
                item.sum_without_vat = 0;

                if (this.vats) {
                    let vat = this.vats.find((v) => {
                        return v.id === item.vat_id
                    });

                    if (vat) {
                        item.vat_sum = item.sum_with_discount * vat.value / 100;
                        item.sum_without_vat = item.sum_with_discount - item.vat_sum;
                    }
                }

                return item;
            });
        },

        sum() {
            let sum = 0;

            this.internalItems.map(item => {
                sum = sum + item.price * item.quantity;
            })

            return sum;
        },

        sumDiscount() {
            let result = 0;
            this.internalItems.forEach((item) => result += item.discount_sum);
            return result;
        },

        sumWithDiscount() {
            return this.internalItems.reduce((acc, item) => acc + item.sum_with_discount, 0)
        },

        vatSum() {
            return this.internalItems.reduce((acc, item) => acc + item.vat_sum, 0)
        },

        sumWithOutVat() {
            return this.internalItems.reduce((acc, item) => acc + item.sum_without_vat, 0)
        },

        total() {
            return this.internalItems.reduce((acc, item) => acc + item.total, 0)
        },
    },

    watch: {},

    methods: {
        onDeleteItemClick(item) {
            this.$emit('delete', item);
        },

        onValueChange(item, value, field) {
            item[field] = value

            this.$emit('change', item);
        },

        openFillDialog() {
            this.fillingDialog = true;
        },

        onFillingItems(items) {
            this.$emit('fill', items);
        },
    },
}
</script>

<template>
    <div>
        <v-row>
            <v-col>
                <Btn @click="openFillDialog">Заполнить по потребноcти</Btn>
            </v-col>
        </v-row>
        <v-row v-if="internalItems">
            <v-col>
                <OrderToBuyItemsFillingDialog :value="fillingDialog" @input="fillingDialog = $event" @fill="onFillingItems" :items-in-doc="internalItems"></OrderToBuyItemsFillingDialog>

                <ResizableTable
                    dense
                    :columns="[
                        ...tableColumns,
                        {
                            active:true,
                            default:true,
                            label: $t('sum'),
                            name: 'sum',
                            prop: 'sum',
                            sortable:false,
                        },
                        {
                            active:true,
                            default:true,
                            label: $t('discount_sum'),
                            name: 'discount_sum',
                            prop: 'discount_sum',
                            sortable:false,
                        },
                        {
                            active:true,
                            default:true,
                            label: $t('vat_sum'),
                            name: 'vat_sum',
                            prop: 'vat_sum',
                            sortable:false,
                        },
                        {
                            active:true,
                            default:true,
                            label: $t('total'),
                            name: 'total',
                            prop: 'total',
                            sortable:false,
                        }
                    ]"
                    :rows="internalItems"
                >
                    <template v-slot:nomenclature="{ item }">
                        <RouterLink v-if="item.nomenclature" :to="{
                            name: 'nomenclature.edit',
                            params: { uuid: item.nomenclature.uuid }
                        }">
                            {{ item.nomenclature.name }}
                            <span v-if="item.id == null">*</span>
                        </RouterLink>
                        <v-icon small @click="onDeleteItemClick(item)">
                            mdi-trash-can-outline
                        </v-icon>
                    </template>
                    <template v-slot:price="{ item }">
                        <TextInput
                            id="price"
                            label=""
                            :value="item.price"
                            dense
                            outlined
                            hide-label
                            @change="onValueChange(item, $event, 'price')"
                        ></TextInput>
<!--                        <v-chip v-for="p in item.nomenclature.prices" :key="p.id" class="ml-2 mt-2" small @click="onValueChange(item, p.value + '', 'price')">-->
<!--                            {{ p.price_type.name }}: {{ p.value }}-->
<!--                        </v-chip>-->
                    </template>
                    <template v-slot:discount="{ item }">
                        <TextInput
                            id="price"
                            label=""
                            :value="item.discount"
                            dense
                            outlined
                            hide-label
                            @change="onValueChange(item, $event, 'discount')"
                        ></TextInput>
                    </template>
                    <template v-slot:quantity="{ item }">
                        <TextInput
                            id="price"
                            label=""
                            :value="item.quantity"
                            dense
                            outlined
                            hide-label
                            @change="onValueChange(item, $event, 'quantity')"
                        ></TextInput>
                    </template>
                    <template v-slot:vat="{ item }">
                        <SelectInput
                            :value="item.vat_id"
                            :items="vats"
                            id="vat"
                            label=""
                            dense
                            outlined
                            hide-label
                            item-text="value"
                            item-value="id"
                            @input="onValueChange(item, $event, 'vat_id')"
                        ></SelectInput>
                    </template>
                    <template v-slot:sum="{ item }">
                        <span class="total__value">{{ item.sum }}</span>
                    </template>
                    <template v-slot:vat_sum="{ item }">
                        {{ item.vat_sum }}
                    </template>
                    <template v-slot:discount_sum="{ item }">
                        {{ item.discount_sum }}
                    </template>
                    <template v-slot:total="{ item }">
                        <div style="font-weight: bold;">
                            <span class="total__value">{{ item.total }}</span>
                        </div>
                    </template>
                </ResizableTable>
            </v-col>
        </v-row>
        <v-row v-if="internalItems && internalItems.length">
            <v-col class="d-flex justify-end">
                <table style="text-align: right;">
                    <tr>
                        <td>{{ $t('total_amount_discount') }}:</td>
                        <td>{{ sumDiscount }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('total_amount_without_discount') }}:</td>
                        <td>{{ sum }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('total_amount_without_vat') }}:</td>
                        <td>{{ sumWithOutVat }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('total_amount_vat') }}:</td>
                        <td>{{ vatSum }}</td>
                    </tr>
                    <tr style="font-weight: bold; font-size: 20px">
                        <td>{{ $t('total_amount') }}:</td>
                        <td>{{ sumWithDiscount }}</td>
                    </tr>
                </table>
            </v-col>
        </v-row>
    </div>
</template>
