<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('sale.report') }}</h1>
                </div>
            </div>
            <v-row>
                <v-col cols="12" md="6">
                    <BuyerOrderCount />
                </v-col>
                <v-col cols="12" md="6">
                    <BuyerOrderSum />
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import BuyerOrderCount from "@/components/Warehouse/BouerOrder/Chart/Count.vue";
import BuyerOrderSum from "@/components/Warehouse/BouerOrder/Chart/Sum.vue";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import NavMixin from "@/plugins/mixins/Nav";
import FilterView from "@/plugins/mixins/FilterView";
import Side from "@/components/Warehouse/Side.vue";

export default {
    name: "Sale",

    components: {
        Side,
        PageLayout,
        BuyerOrderCount,
        BuyerOrderSum
    },

    mixins: [
        NavMixin,
        FilterView
    ],

    data() {
        return {}
    },

    computed: {},

    mounted() {
    },

    methods: {}
}
</script>

<style lang="scss">
.process__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.process {
    border-collapse: collapse;
    color: #898989;

    svg {
        display: inline-block;
    }


    td {
        width: 80px;
        height: 80px;
        text-align: center;
        line-height: 1;

        align-items: center;
        justify-content: center;
    }

    a {
        text-decoration: none;
        color: #898989;
    }
}

.process__step {
    display: flex;
    align-items: center;
    flex-direction: column;

    svg {
        margin-bottom: $spacer * 2;
    }
}

.arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='47' height='14' viewBox='0 0 47 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M40 2L45 7L40 12' stroke='%23898989' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M45 7L2 7.00001' stroke='%23898989' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 47px;
    height: 14px;
    display: inline-block;

    &.down {
        transform: rotate(90deg);
    }

    &.right {
        transform: rotate(-180deg);
    }

    &.up {
        transform: rotate(270deg);
    }
}
</style>
