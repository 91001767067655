<script>
export default {
    name: "SelectOverflowInput",
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: {
            required: false,
            default: ''
        },
        items: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: false
        },

        placeholder: {
            type: String,
            required: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        color: {
            type: String,
            default: 'primary'
        },

        hideLabel: {
            type: Boolean,
            default: false
        },

        id: {
            type: String,
            required: true
        },

        error: {
            type: Boolean,
            required: false,
            default: false
        },

        multiple: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <div class="select2">
        <label class="input_label" v-if="!hideLabel" :for="id">{{ label }}</label>
        <v-overflow-btn
            :value="value"
            :id="id"
            :items="items"
            :label="placeholder ?? label"
            :disabled="disabled"
            :multiple="multiple"
            :error="error"
            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
            :color="color"
            hide-details
            editable
            outlined
            dense
            clearable
            v-bind="$attrs"
            @input="$emit('input', $event)"
        ></v-overflow-btn>
    </div>
</template>

<style lang="scss" scoped>
.select {
    .select__input {
        &.v-text-field--outlined {
            fieldset{
                border-width: 0;
            }
        }
    }
}

.select{
    .error--text {
        &.v-text-field--outlined {
            fieldset {
                border-width: 2px;
            }
        }
    }
}
.v-label, .theme--light{
    margin: 0 !important;
}
.v-application--is-ltr .v-overflow-btn .v-select__selection--comma:first-child{
    margin: 0 !important;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
 padding-left: 10px !important;
}
 input{
    padding-left: 0px !important;
}
.v-overflow-btn.v-input--is-focused .v-input__slot, .v-overflow-btn.v-select--is-menu-active .v-input__slot{
    box-shadow: none !important;
}
.v-overflow-btn .v-input__slot{
    border: 0 !important;
}


</style>
