<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-form @submit.prevent="getBusinessProcesses">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" @click.stop="addBusinessProcess()"
                           color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-card flat class="background_color_transparent">
                            <v-card-text class="py-0">
                                <v-row class="pt-5">
                                    <v-col cols="12" sm="8" class="title_menu mt-1">
                                        {{  $t('business_process') }}
                                    </v-col>
                                    <v-col v-if="!$vuetify.breakpoint.xsOnly" v-show="!filter_show" cols="12" sm="4" class="text-right">
                                        <v-btn :to="{name: 'business_process.create'}" class="infinity_button" color="primary">
                                            {{$t('create') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filter_show">

                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="business_process" rules=""
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="business_process"
                                                      :items="business_processItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('business_process')"

                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions v-if="filter_show" class="px-4 py-4">
                                <v-row>
                                    <v-col cols="12"
                                           class="d-flex justify-center justify-sm-end align-self-center">
                                        <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                               color="primary" class="infinity_button">{{ $t('search') }}
                                        </v-btn>
                                    </v-col>

                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-form>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-data-table item-key="id" ref="table" :headers="headers" :items="business_processItems"
                                      :options.sync="options" disable-pagination
                                      :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                                      :loading="loading" :locale="lang" :loading-text="$t('loading_please_wait')"
                                      hide-default-footer
                                      class="sortable-table elevation-0"
                        >
                            <template v-slot:item.sort>
                                <v-btn icon class="handle">
                                    <v-icon>mdi-drag-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <template v-slot:item.name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                    <span @click="dealTemplate(item)" class="cursor-pointer">{{  item.name }}</span>
                                    {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                    <v-icon  color="primary" class="mr-1" size="15" :title="$t('delete')" @click="editBusinessProcess(item)">mdi-file-document-edit-outline</v-icon>
                                    <v-icon v-if="!item.deleted" color="primary" class="mr-1" size="15" @click="deleteBusinessProcess(item)" small>mdi-delete-outline</v-icon>
                                    <v-icon v-if="item.next_name">mdi-arrow-right-thin</v-icon>
                                    {{item.next_name}}
                                    <v-icon v-if="item.next_name" small color="primary" @click="nextDeleteProcess(item)">mdi-minus-circle</v-icon>
                                    <v-icon v-else small color="primary" @click="dialogAddNextProcess(item)">mdi-plus-circle</v-icon>
                                </div>
                            </template>

                            <template v-slot:item.action="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="mr-2" @click="editBusinessProcess(item)">
                                            mdi-square-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ $t('edit') }}</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="(item.undeletable !== 1)">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="deleteBusinessProcess(item)" :title="$t('delete')">
                                            mdi-delete-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ $t('delete') }}</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:item.steps="{ item }">
                                <router-link :to="{
                                        name: 'deal_step_show',
                                        params: {
                                            business_process: item.id
                                        }
                                    }">{{$t('steps')}}</router-link>
                            </template>
                            <template v-slot:item.main_statuses="{ item }">
                                <router-link :to="{
                                        name: 'business_process.main_statuses',
                                        params: {
                                            businessProcessId: item.id
                                        }
                                    }">{{$t('statuses')}}</router-link>
                            </template>
                            <template v-slot:item.statuses="{ item }">
                                <router-link :to="{
                                        name: 'business_process.statuses',
                                        params: {
                                            businessProcessId: item.id
                                        }
                                    }">{{$t('sub_statuses')}}</router-link>
                            </template>
                            <template v-slot:item.documents="{ item }">
                                <router-link :to="{
                                        name: 'business_process.documents',
                                        params: {
                                            businessProcessId: item.id
                                        }
                                    }">{{ $t('documents') }}</router-link>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>

        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogNext"
        >

            <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('switching_to_another_business_process') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="closeNextProcess()" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-5">
                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="business_process" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="business_process"
                                              :items="business_processItems" :error-messages="errors"
                                              :error="!valid"
                                              item-text="name" item-value="id"
                                              :label="$t('business_process')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :disabled="loading"
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 pt-10 pb-7">
                        <v-btn @click="nextAddProcess()" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                            {{ $t('add') }}
                        </v-btn>
                        <v-btn
                            :disabled="loading"
                            class="ma-1 button_cancel"
                            plain
                            @click="closeNextProcess()"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>

                    </v-card-actions>

                </v-card>
            </ValidationObserver>

        </v-dialog>

    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Sortable from 'sortablejs'
import {mapGetters} from "vuex"
import SettingMenu from "../components/SettingMenu";

export default {
    name: 'BusinessProcesses',
    components: {
        ValidationProvider,
        ValidationObserver,
        SettingMenu
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: this.$t('business_process_creation'),
            progress: 0,
            language: null,
            loading: false,
            sortBy: "sort",
            sortDir: false,
            options: {},
            business_processItems: [],
            errors: {},
            id: null,
            name: null,
            business_process: null,
            headers: [
                {
                    text: "",
                    align: "left",
                    sortable: false,
                    value: "sort",
                },
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t('steps'),
                    align: "left",
                    sortable: false,
                    value: "steps"
                },
                {
                    text: this.$t('documents'),
                    align: "left",
                    sortable: false,
                    value: "documents"
                },
                {
                    text: this.$t('statuses'),
                    align: "left",
                    sortable: false,
                    value: "main_statuses"
                },
                {
                    text: this.$t('sub_statuses'),
                    align: "left",
                    sortable: false,
                    value: "statuses"
                },
            ],
            filter_show: false,
            dialogNext: false,
            current_business_process: false,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
        let table = this.$refs.table.$el.querySelector("tbody")
        Sortable.create(table, {
            handle: ".handle",
            onEnd: this.dragReorder
        })

    },
    watch: {
        options: {
            handler() {
                this.getBusinessProcesses()
            },
            deep: false
        }
    },
    methods: {
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
        },
        setLanguage(val) {
            this.language = this.languages[val]
        },
        checkCreate() {
            this.heading = this.$t('business_process_creation')

        },
        dragReorder({newIndex, oldIndex}) {
            const rowSelected = this.business_processItems.splice(oldIndex, 1)[0]
            this.business_processItems.splice(newIndex, 0, rowSelected)
            this.saveSortBusinessProcess()
        },
        async saveSortBusinessProcess() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.business_processItems && this.business_processItems.length > 0) {
                for (let i in this.business_processItems) {
                    if (this.business_processItems[i].id !== 'undefined' && this.business_processItems[i].id > 0) {
                        formData.append(`sort[${i}]`, this.business_processItems[i].id)
                    }
                }
            }
            await this.$http
                .post(`admin/business_process/sort`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('business_process_sorting_updated'))
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('business_process_sorting_not_updated'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getBusinessProcesses() {
            this.loading = true
            const {
                sortBy,
                sortDesc,
            } = this.options
            let params = {}

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'desc' : 'asc'
            }
            if (this.business_process) {
                params.name = this.business_process
            }

            await this.$http
                .get("admin/business_process", {
                    params: params,
                })
                .then(res => {
                    this.business_processItems = res.body.data
                })
                .catch(err => {
                    this.business_processItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteBusinessProcess(item) {
            if (confirm(this.$t('delete_business_process'))) {
                this.loading = true
                await this.$http
                    .delete(`admin/business_process/${item.id}`)
                    .then(res => {
                        this.getBusinessProcesses()
                    })
                    .catch(err => {
                        this.BusinessProcessStatusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        addBusinessProcess() {
            this.$router.push({
                name: 'business_process.create',
            })
        },
        editBusinessProcess(item) {
            this.$router.push({
                name: 'business_process.edit',
                params: {
                    id: item.id
                }
            })
        },
        dealTemplate(item) {
            this.$router.push({
                name: 'deal_template',
                params: {
                    business_process: item.id
                }
            })
        },
        dialogAddNextProcess(item){
            this.current_business_process = item.id;
            this.dialogNext = true;
        },
        async nextAddProcess() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            formData.append('business_process', this.current_business_process)
            formData.append('business_process_next', this.business_process)

            await this.$http
                .post(`admin/business_process/add_next`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('business_process_has_been_added'))
                    this.closeNextProcess()
                    this.getBusinessProcesses()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('business_process_has_not_been_added'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async nextDeleteProcess(item) {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            formData.append('business_process', item.id)


            await this.$http
                .post(`admin/business_process/delete_next`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('business_process_has_been_deleted'))
                    this.closeNextProcess()
                    this.getBusinessProcesses()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('business_process_not_been_deleted'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        closeNextProcess(){
            this.dialogNext=false;
            this.current_business_process=null;
            this.business_process=null;
        }
    }
}
</script>
