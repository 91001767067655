<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveDealMainStatus">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3" md="2" class="text-center">
                                    <MakePhotoPNG v-model="logo" aspect-ratio="1"/>
                                    {{ $t('photo_no_active') }}
                                </v-col>
                                <v-col cols="12" sm="3" md="2" class="text-center">
                                    <MakePhotoPNG v-model="logo_active" aspect-ratio="1"/>
                                    {{ $t('photo_active') }}
                                </v-col>
                                <v-col cols="12" sm="6" md="8"></v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-row>
                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="deal_main_status" rules="required|min:1|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('deal_main_status_name')}}</div>
                                                <v-text-field v-model="deal_main_status" type="text" :disabled="loading"
                                                              :error="!valid" :error-messages="errors"
                                                              :label="$t('deal_main_status_name')"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="business_process" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('business_process')}}</div>
                                                <v-select v-model="business_process"
                                                          :items="businessProcessItems" :error-messages="errors"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('business_process')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col >
                                    <v-color-picker v-model="color" mode="hexa" :disabled="loading" width="920"></v-color-picker>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="py-0 my-0 d-flex justify-start" cols="12" sm="4" >
                                    <v-switch v-model="step_stop"
                                              :disabled="loading"
                                              :label="$t('step_stop_process')"
                                              class="input_switch py-0"
                                              color="primary" hide-details></v-switch>
                                </v-col>


                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 py-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd()"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import MakePhotoPNG from '../components/MakePhotoPNG.vue'

    export default {
        name: 'DealMainStatusForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            MakePhotoPNG
        },
        inject: ['forceRerender'],
        data() {
            return {
                dialogForm: true,
                heading: null,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,
                color: '#ff0000',
                deal_main_status: null,
                exist_translations: {},
                all_translations: true,
                business_process:null,
                businessProcessItems:[],
                step_stop: false,
                logo: null,
                logo_active: null,
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.language = this.languages[this.tab];
            this.getBusinessProcesses();
            this.checkCreate()
        },
        methods: {
            checkCreate() {
                if (this.$route.name === "deal_main_status.create") {
                    this.heading = this.$t('deal_main_status_creation')
                } else {
                    this.heading = this.$t('deal_main_status_editing')
                    if (this.$route.params.id) {
                        this.getDealMainStatus()
                    }
                }
            },
            closeDialogAdd(){
                if(window.history.length > 2){
                    this.$router.back()
                }
                else{
                    this.$router.push({
                        name: 'deal_main_status',
                    })
                }
            },
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
            async getDealMainStatus() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/deal_main_status/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.deal_main_status = res.body.data.name
                        let color = res.body.data.color
                        this.business_process = res.body.data.business_process
                        this.logo = res.body.data.logo
                        this.logo_active = res.body.data.logo_active
                        this.color = color.match(/^#[0-9a-zA-Z]{6}$/) ? color : '#FF0000'
                        this.step_stop = res.body.data.step_stop
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_deal_main_status'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveDealMainStatus() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.deal_main_status) {
                    formData.append('deal_main_status', this.deal_main_status)
                }
                if (this.color) {
                    formData.append('color', this.color)
                }
                if (this.step_stop) {
                    formData.append('step_stop', 1)
                }
                if (this.business_process) {
                    if (this.business_process.id) {
                        formData.append('business_process', this.business_process.id)
                    } else {
                        formData.append('business_process', this.business_process)
                    }
                }
                if (this.logo) {
                    if (this.logo.length > 250) {
                        var mimeTypeLogo = this.getMimeType(this.logo)
                        var blobLogo = this.dataURL64toBlob(this.logo)
                        if (mimeTypeLogo && blobLogo) {
                            formData.append('logo', blobLogo, mimeTypeLogo)
                        }
                    } else {
                        formData.append('logo', this.logo)
                    }
                }
                if (this.logo_active) {
                    if (this.logo_active.length > 250) {
                        var mimeTypeLogo2 = this.getMimeType(this.logo_active)
                        var blobLogo2 = this.dataURL64toBlob(this.logo_active)
                        if (mimeTypeLogo2 && blobLogo2) {
                            formData.append('logo_active', blobLogo2, mimeTypeLogo2)
                        }
                    } else {
                        formData.append('logo_active', this.logo_active)
                    }
                }
                if (this.$route.params.id) {
                    await this.$http
                        .put(`admin/deal_main_status/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('deal_main_status_has_been_updated'))
                            if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                            }
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('deal_main_status_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/deal_main_status', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('deal_main_status_has_been_added'))
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                                this.$router.push({
                                    name: 'deal_main_status.edit',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: 'deal_main_status'
                                })
                            }
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('deal_main_status_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            async getBusinessProcesses() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/business_process", {
                        params: params,
                    })
                    .then(res => {
                        this.businessProcessItems = res.body.data
                    })
                    .catch(err => {
                        this.businessProcessItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
        }
    }
</script>

<style>
.v-color-picker {
    width: 100% !important;
}
</style>
