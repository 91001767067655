import Vue from "vue";

export default {
    namespaced: true,
    state: {
        admins: []
    },
    mutations: {
        setAdmins(state, admins) {
            state.admins = admins;
        }
    },
    actions: {
        setAdmins({ commit }, admins) {
            commit('setAdmins', admins);
        },

        addAdmin({ commit, state }, admin) {
            let admins = state.admins;
            admins.push(admin);
            commit('setAdmins', admins);
        },

        removeAdmin({ commit, state }, admin) {
            let admins = state.admins;
            let index = admins.findIndex(a => a.id === admin.id);
            admins.splice(index, 1);
            commit('setAdmins', admins);
        }
    },
    getters: {
        onlineAdmins(state) {
            return state.admins;
        },

        onlineAdminIds(state) {
            return state.admins.map(admin => admin.id);
        }
    }
}
