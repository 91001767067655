<script>
export default {
    name: "Doc",

    computed: {
        current() {
            return this.doc.uuid === this.currentDocUuid;
        }
    },

    props: {
        doc: {
            type: Object,
            required: true
        },
        currentDocUuid: {
            type: String,
            required: true
        }
    }
}
</script>

<template>
    <li class="doc" :class="{'current': current}">
        <RouterLink class="doc__name" :to="{ name: doc.type + '.edit', params: {uuid: doc.uuid} }">
            <v-icon v-if="doc.passed_at">mdi-text-box-check-outline</v-icon>
            <v-icon v-else>mdi-text-box-outline</v-icon>
            <span>{{ $t(`${doc.type}.self`) }} #{{ doc.id }}</span>
        </RouterLink>
        <ul v-if="doc.children">
            <Doc v-for="d in doc.children" :key="d.id" :doc="d" :currentDocUuid="currentDocUuid"></Doc>
        </ul>
    </li>
</template>

<style scoped lang="scss">
.doc__name {
    text-decoration: none;
    color: var(--body-text-color);
    display: flex;
    flex-direction: row;
    align-items: center;

    &.current > span.name{
        font-weight: bold;
    }
}
</style>
