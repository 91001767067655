<template>
    <PageLayout>
        <template v-slot:default>
            <TabsNav :items="breadcrumbs()[0]['items']"/>
            <div class="d-flex justify-space-between mb-10">
                <div class="d-flex align-center flex-grow-1">
                    <h1>
                        <template v-if="currentFilter">
                            {{ currentFilter.name }}
                        </template>
                        <template v-else>
                            {{ $t('activity_logs') }}
                        </template>
                    </h1>
                    <TableSettings
                        :fields="tableColumns"
                        :entityName="entityName"
                        className="ml-2"
                        @column-select="onColumnSelect"
                    />
                </div>
            </div>

            <div class="mobile-filter mb-5" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    :disabled="userBlock"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>

                <FilterMobileLinks
                    :links="[
                        ...Additional.map((item) => {
                            return {
                                ...item
                            }
                        }),

                        ...adminItems.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...system_filters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...userFilters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                />
            </div>

            <FilterComponent
                class="mb-10"
                v-if="filter_show"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                entity="document"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in activity_logs"
                    :item="item"
                    :key="item.id"
                    :columns="tableColumns"
                    :userBlock="userBlock"

                    @deleteItem="deleteDocument(item)"
                >


                    <template v-slot:id="{ item }">
                        <v-btn text plain :to="'/activity_log/' + item.id + '/show'" class="cursor-pointer font_weight_500 button_link ">
                            {{ item.id }}
                        </v-btn>
                    </template>

                    <template v-slot:subject_type="{ item }">
                        <div>
                            {{
                                subjectTypeItems.find((fine) => {
                                    return fine.id === item.subject_type
                                }).name
                            }}
                        </div>
                    </template>

                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                    </template>


                    <template v-slot:event="{ item }">

                            <div :style="'color:' + eventItems.find((fine) => {
                                        return fine.id === item.event
                                    }).color">
                                {{
                                    eventItems.find((fine) => {
                                        return fine.id === item.event
                                    }).name
                                }}
                            </div>

                    </template>

                    <template v-slot:causer_id="{ item }">
                        <div>
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.causer.photo === '/img/avatar.png'}">
                                        <img v-if="item.causer.photo !== '/img/avatar.png'" :src="item.causer.photo" :alt="item.causer.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.causer.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.causer.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>


                </ListItem>
            </template>
            <template v-else>
                <ResizableTable
                    class="main-data-table elevation-0 uveds-table"
                    :rows="activity_logs"
                    :columns="tableColumns"
                    :sort-by="options.sortBy[0]"
                    :sort-dir="options.sortDesc[0]"

                    @sort="sortItems"
                    @columnMoved="onColumnsMoved"
                    @columnResized="onColumnResized"
                >

                    <template v-slot:id="{ item }">
                        <v-btn text plain :to="'/activity_log/' + item.id + '/show'" class="cursor-pointer font_weight_500 button_link ">
                            {{ item.id }}
                        </v-btn>
                    </template>



                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                    </template>
                    <template v-slot:subject_type="{ item }">
                        <div>
                            {{
                                subjectTypeItems.find((fine) => {
                                    return fine.id === item.subject_type
                                }).name
                            }}
                        </div>
                    </template>

                    <template v-slot:event="{ item }">
                        <v-btn text plain :to="'/activity_log/' + item.id + '/show'" class="cursor_pointer font_weight_500 button_link ">
                            <div :style="'color:' + eventItems.find((fine) => {
                                        return fine.id === item.event
                                    }).color">
                                {{
                                    eventItems.find((fine) => {
                                        return fine.id === item.event
                                    }).name
                                }}
                            </div>
                        </v-btn>
                    </template>

                    <template v-slot:causer_id="{ item }">
                        <div>
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.causer.photo === '/img/avatar.png'}">
                                        <img v-if="item.causer.photo !== '/img/avatar.png'" :src="item.causer.photo" :alt="item.causer.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.causer.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.causer.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>



                </ResizableTable>
            </template>

            <div class="main-pagination d-flex flex-row justify-end">
                <SelectInput
                    class="main-pagination__per-page"
                    id="pagination-variants"
                    :value="options.itemsPerPage"
                    :items="perPageItems"
                    :label="$t('items_per_page')"
                    :disabled="loading"
                    @input="onPerPageChange($event)"
                    hide-label
                ></SelectInput>
                <v-pagination v-model="page" :total-visible="totalVisiblePag" class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>
        </template>

        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5" :disabled="userBlock" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>

            <v-list :disabled="userBlock" class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group1" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" :to="item.url" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}">
                        <v-list-item-title v-if="item.can" v-text="item.title" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-if="item.can" v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

        </template>
    </PageLayout>
</template>

<script>
import { mapGetters } from "vuex"
import FilterView from "@/plugins/mixins/FilterView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import ResourceView from "@/plugins/mixins/ResourceView";
import NavMixin from "@/plugins/mixins/Nav";
import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import ListItem from "@/components/ActivityLog/ActivityLogItem.vue";

export default {
    name: "ActivityLogs",
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    components: {
        FilterMobileLinks,
        ListItem,
        PageLayout,
        SelectInput,
        TabsNav
    },
    data() {
        return {
            entityName: 'activity_log',
            invalid: false,
            options: {
                ...FilterView.data().options,
                sortBy: ['id']
            },
            fab: false,
            activity_logs: [],
            sortBy: "created_at",
            sortDir: false,
            page: 1,
            pageCount: 0,
            totalVisiblePag: 7,
            loading: false,
            Additional: [],
            group1: true,
            userFiltersGroup: true,
            filter_show: false,
            adminItems: [],
            system_filters: [],
            subjectTypeItems: [],
            eventItems: [],
            subjectTypeFilter: [],
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'userBlock']),
    },
    watch: {
        // Следим за изменениями массива subjectTypeFilter
        subjectTypeFilter: {
            handler(newValue, oldValue) {
                this.updateData(); // Вызываем обновление данных
            },
            deep: true // Глубокое наблюдение для отслеживания изменений внутри массива
        }
    },
    mounted() {
        this.init();
    },

    methods: {
        async init() {
            await this.getItems();
            await this.getFilters();
        },
        sortItems(column) {
            if (this.$route.query.sortBy === column) {
                let sortDir = this.$route.query.sortDir === 'true';

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        sortDir: sortDir ? 'false' : 'true'
                    }
                });
            } else {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        sortBy: column,
                        sortDir: false
                    }
                });
            }
            // Здесь вы можете отсортировать элементы items
            // в соответствии с выбранным столбцом и порядком сортировки (asc/desc)
        },
        showDocument(item) {
            return {
                name: 'document.show',
                params: {
                    id: item.uuid
                }
            }
        },
        editDocument(item) {
            this.$router.push({
                name: 'document.edit',
                params: {
                    id: item.uuid
                }
            })
        },
        async deleteDocument(item) {
            if (confirm(this.$t('delete_document'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/document/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('document_has_been_deleted'))
                        this.getTasks()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('document_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        getItems() {
            this.getActivityLogs()
        },
        async getActivityLogs(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }

            params.sortDir = 'asc';
            if(sortDesc) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }

            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }

            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }


            await this.$http
                .get("admin/activity_log", {
                    params: params,
                })
                .then(res => {
                    this.filterData = res.body.filter
                    this.activity_logs = res.body.data
                    this.page = res.body.meta.current_page
                    this.pageCount = res.body.meta.last_page
                    this.subjectTypeItems = res.body.subject_type
                    this.subjectTypeFilter = res.body.subject_type
                    this.eventItems = res.body.events
                })
                .catch(err => {
                    this.activity_logs = []
                    this.$toastr.error(this.$t('failed_to_get_list_activity_logs'))
                })
                .finally(end => {
                    this.loading = false
                })
        },

        updateData() {
            this.Additional = this.subjectTypeFilter.map(filter => ({
                title: filter.name,
                count: '-',
                action: filter.id,
                can: 1,
                url: {
                    path: '/activity_log',
                    query: {
                        filter: {
                            subject_type: {
                                eq: filter.id
                            }
                        }
                    }
                }
            }));

            console.log('Обновляем данные...', this.subjectTypeFilter);
        },





    }
}
</script>
