<template>
    <div>
        <draggable v-if="widgetsList.length > 0"
                   v-model="widgetsList"
                   :group="{ name: 'row_' + dashboard }"
                   @change="changeDrag()"
                   class="row wrap fill-height align-center sortable-list"
                   :disabled="!can(['administrator'])"
        >

                <v-col cols="12" class="mx-0 px-0"  v-for="item in widgetsList" :key="item.id +'_' + dashboard" :sm="item.coll">
                        <v-card
                            class="mx-auto"
                            :style="'border: '+border+'px solid #ffffff;'"
                            style="background-color: #f9f9f9; margin-right: 10px !important; margin-left: 10px !important"
                        >
                        <v-app-bar flat color="rgba(0, 0, 0, 0)">
                            <v-toolbar-title class="text-h10 pl-0 text-center" style="cursor: all-scroll">
                                {{item.name}}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu
                                bottom
                                left
                                v-if="can(['administrator'])"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon

                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(menu_item, i) in menuItems"
                                        :key="i"
                                        link
                                    >
                                        <v-list-item-title @click="actionWidget(item, menu_item)">{{ menu_item.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>


                        </v-app-bar>

                        <v-card-text>
                            {{item.listItems}}
                        <pie-chart v-if="item.chart_type === 'pie'" :chartData="item.listItems" ></pie-chart>
                        <line-chart v-else-if="item.chart_type === 'line'" :chartData="item.listItems" ></line-chart>
                        <bar-chart v-else-if="item.chart_type === 'bar'" :chartData="item.listItems"></bar-chart>
                        <doughnut-chart v-else-if="item.chart_type === 'doughnut'" :chartData="item.listItems"></doughnut-chart>
                        <horizontal-bar-chart v-else-if="item.chart_type === 'horizontal_bar'" :chartData="item.listItems" ></horizontal-bar-chart>
                        <div v-else-if="item.chart_type === 'task_score'">
                            <horizontal-bar-chart :chartData="JSON.stringify(JSON.parse(item.listItems).chart)" ></horizontal-bar-chart>
                            <ReportBlockCount
                                :items="[JSON.parse(item.listItems).data_count]"
                            />
                        </div>


                            <ReportDataTable
                            v-else-if="item.chart_type === 'table'"
                            :headers="headers"
                            :items="JSON.parse(item.listItems)"
                        />

                        <ReportTableOneColumn
                            v-else-if="item.chart_type === 'table_one_column'"
                            :items="JSON.parse(item.listItems)"
                        />
                        <ReportBlockCount
                            v-else-if="item.chart_type === 'block_count'"
                            :title="item.name"
                            :items="JSON.parse(item.listItems)"
                        />
                        </v-card-text>
                        </v-card>

                </v-col>




        </draggable>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogRename"
        >
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('widget_editing') }}</span>
                        </div>
                        <div>
                            <v-icon @click="closeDialogWidget()">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="widget_name">
                                    <TextInput
                                        id="widget_name"
                                        :label="$t('widget_name')"
                                        :error="!valid"
                                        :error-messages="errors"
                                        v-model="widget_name"
                                        require
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-btn :disabled="invalid || loading" :loading="loading" color="primary"
                                       @click="renameWidget()"
                                     >
                                    {{ $t('edit') }}
                                </v-btn>
                                <v-btn
                                    :disabled="loading"
                                    plain
                                    @click="closeDialogWidget()"
                                >
                                    {{ $t('cancel') }}
                                </v-btn>
                                <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                            </v-col>
                        </v-row>

                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>
    </div>

</template>

<script>
import LineChart from '../plugins/charts/LineChart.js'
import BarChart from '../plugins/charts/BarChart.js'
import PieChart from '../plugins/charts/PieChart.js'
import DoughnutChart from "../plugins/charts/DoughnutChart";
import HorizontalBarChart from "../plugins/charts/HorizontalBarChart";
import draggable from "vuedraggable";
import ReportDataTable from "./ReportDataTable";
import ReportTableOneColumn from "./ReportTableOneColumn";
import ReportBlockCount from "./ReportBlockCount";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import TextInput from "@/components/Form/TextInput";



export default {
    name: "ChartDisplay",
    props: {
        widgets: {
            type: Array,
            required: true
        },
        dashboard: {
            type: String,
            default: '1'
        },
        border: {
            type: String,
            default: '0'
        },
    },
    components: {
        LineChart,
        BarChart,
        PieChart,
        DoughnutChart,
        HorizontalBarChart,
        draggable,
        ReportDataTable,
        ReportTableOneColumn,
        ReportBlockCount,
        ValidationObserver,
        ValidationProvider,
        TextInput,
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },

    },
    data() {
        return {
            progress: 0,
            loading: false,
            widgetsList:[],
            menuItems: [
                {
                    title: 'Переименовать',
                    action: 'rename'
                },
                {
                    title: this.$t('delete'),
                    action: 'delete'
                },
            ],
            dialogRename : false,
            widget_name : null,
            widget_uuid : null,

            headers:[
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "company_name"
                }, {
                    text: this.$t('phones'),
                    align: "left",
                    sortable: false,
                    value: "phones"
                },
            ],

        }
    },
    mounted() {
        this.widgetsList =  this.widgets
    },
    watch: {
        widgetsList: {
            handler() {
                this.updateWidgetsList();
            },
            deep: false
        }
    },
    methods: {
        updateWidgetsList() {
            this.$emit('update-widgets', {'widgets' : this.widgetsList, 'index': parseInt(this.dashboard)});
        },

        actionWidget(item,menu_item){
            if(menu_item.action === 'delete'){
                this.deleteWidget(item);
            }
            else if(menu_item.action === 'rename'){
                this.renameDialogWidget(item);
            }
        },
        async deleteWidget(item) {
            if (confirm(this.$t('delete_widget'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/widget/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('widget_has_been_deleted'))
                        this.widgetsList = this.widgetsList.filter(s_item => s_item.id !== item.id);
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('widget_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async saveSortWidget() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.widgetsList && this.widgetsList.length > 0) {
                for (let i in this.widgetsList) {
                    if (this.widgetsList[i].id !== 'undefined' && this.widgetsList[i].id > 0) {
                        formData.append(`sort[${i}]`, this.widgetsList[i].id)
                    }
                }
            }
            await this.$http
                .post(`admin/widget/sort`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('widget_sorting_updated'))
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('widget_sorting_not_updated'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        changeDrag() {
            this.saveSortWidget();
        },
        renameDialogWidget(item){
            this.dialogRename = true;
            this.widget_name = item.name;
            this.widget_uuid = item.uuid;
        },
        closeDialogWidget(){
            this.dialogRename = false;
            this.widget_name = null;
            this.widget_uuid = null;
        },
        async renameWidget() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()



            if (this.widget_uuid) {
                formData.append('widget_uuid', this.widget_uuid)
            }

            if (this.widget_name) {
                formData.append('widget_name', this.widget_name)
            }

            // Add
            await this.$http
                .put('admin/widget/rename', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('widget_has_been_updated'))
                    const widget = this.widgetsList.find(item => item.uuid === this.widget_uuid);
                    if (widget) {
                        widget.name = this.widget_name;
                    }
                    this.closeDialogWidget();

                })
                .catch(err => {
                    this.$toastr.error(this.$t('widget_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
    }

}
</script>

<style scoped>

</style>
