<template>
    <PageLayout
        class="nomenclature-detail"
    >
        <v-container fluid>
            <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-form @submit.prevent="saveItem">
                    <v-row>
                        <v-col>
                            <h1>{{ $t('buyer_order.self') }} #{{ id }}</h1>

                            <v-icon small @click="onDeleteBtnClick">mdi-trash-can-outline</v-icon>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card>
                                <v-card-title>
                                    {{ $t('general_info') }}
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider :ref="`organization_id`" rules="required|min:1" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    v-model="organization_id"
                                                    :items="organizations.map(item => ({ text: item.name, value: item.id }))"
                                                    :label="$t('columns.buyer_order.organization')"
                                                    :error-messages="errors"
                                                    id="organization_id"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12"  md="6">
                                            <ValidationProvider :ref="`warehouse_id`" rules="required" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    v-model="warehouse_id"
                                                    :items="warehouses.map(item => ({ text: item.name, value: item.id }))"
                                                    :label="$t('columns.buyer_order.warehouse')"
                                                    :error-messages="errors"
                                                    id="warehouse_id"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider :ref="`uved_id`" rules="required|min:1" v-slot="{ errors, valid }">
                                                <UvedSelection
                                                    id="uved_id"
                                                    :label="$t('columns.buyer_order.uved')"
                                                    v-model="uved"
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider :ref="`delivery_date`" rules="required" v-slot="{ errors, valid }">
                                                <label class="input_label">{{ $t('columns.buyer_order.delivery_date') }}</label>
                                                <DatetimePicker
                                                    format="24hr"
                                                    v-model="delivery_date"
                                                    :label="$t('columns.buyer_order.delivery_date')"
                                                    :text-field-props="{ 'error-messages': errors, error: !valid }"
                                                    validatorName="delivery_date"
                                                    validator-rules="required"
                                                    id="delivery_date"
                                                ></DatetimePicker>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider :ref="`delivery_address`" rules="" v-slot="{ errors, valid }">
                                                <TextInput
                                                    v-model="delivery_address"
                                                    :label="$t('columns.buyer_order.delivery_address')"
                                                    :error-messages="errors"
                                                    id="delivery_address"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="6">
                                            <ValidationProvider :ref="`comment`" rules="" v-slot="{ errors, valid }">
                                                <TextInput
                                                    v-model="comment"
                                                    :label="$t('columns.buyer_order.comment')"
                                                    :error-messages="errors"
                                                    id="comment"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="buttons-block">
                            <Btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="secondary">
                                <template v-if="saved">
                                    {{ $t('save') }}
                                </template>
                                <template v-else>
                                    {{ $t('add') }}
                                </template>
                            </Btn>
                            <template v-if="saved">
                                <Btn v-if="passed" @click="revoke" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="error">
                                    {{ $t('revoke') }}
                                </Btn>
                                <Btn v-else @click="pass" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('pass') }}
                                    <v-icon right>
                                        mdi-check-circle-outline
                                    </v-icon>
                                </Btn>
                            </template>
                        </v-col>
                        <v-col cols="12" v-if="loading">
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" indeterminate :active="loading" class="mx-2"></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>

            <v-row class="mt-4" v-if="saved && order">
                <v-col>
                    <BuyerOrderItems
                        :buyer-order="order"
                    />
                </v-col>
            </v-row>
        </v-container>
    </PageLayout>
</template>


<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import Btn from "@/components/Form/Btn.vue";
import TextInput from "@/components/Form/TextInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import WithOrganizations from "@/plugins/mixins/WithOrganizations";
import WithWarehouses from "@/plugins/mixins/WithWarehouses";
import WithBuyerOrderItems from "@/plugins/mixins/WithBuyerOrderItems";
import UvedSelection from "@/components/Form/UvedSelection.vue";
import DatetimePicker from "@/components/DatetimePicker.vue";
import BuyerOrderItems from "@/components/Warehouse/Sale/BuyerOrderItems.vue";

export default {
    name: 'WarehouseForm',

    mixins: [
        WithOrganizations,
        WithWarehouses,
        WithBuyerOrderItems
    ],

    components: {
        BuyerOrderItems,
        DatetimePicker,
        UvedSelection,
        SelectInput,
        Btn,
        ValidationProvider,
        ValidationObserver,
        TextInput
    },

    inject: ['forceRerender'],

    props: {
        uuid: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            dialogForm: true,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            items: [],

            /*  document fields */
            order: null, // все данные в том виде как пришли с серва
            organization_id: null,
            uved: null,
            warehouse_id: null,
            delivery_date: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            delivery_address: null,
            comment: null,
            passedAt: null,
        }
    },

    computed: {
        ...mapGetters(['listLanguages', 'lang']),

        languages() {
            return this.listLanguages
        },

        saved() {
            return this.id !== null
        },

        passed() {
            return this.passedAt !== null
        }
    },

    watch: {
        organizations: {
            handler: function (value) {
                if (this.organization_id === null && value.length > 0) {
                    this.organization_id = value[0].id;
                }
            },

            immediate: true
        },

        uuid: {
            handler: function (value) {
                if (value !== 'create') {
                    this.fetchItem()
                }
            },

            immediate: true
        },
    },

    async mounted() {
        this.language = this.languages[this.tab]
    },

    methods: {
        closeDialogAdd() {
            this.$router.push({
                name: 'warehouse.selling.buyer_order',
            })
        },

        async fetchItem() {
            let _this = this;
            let params = {}

            this.progress = 0
            this.loading = true

            if (this.language) {
                params.language = this.language
            }

            await this.$http
                .get(`admin/warehouse/buyer_order/${this.uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res && res.body && res.body.data) {
                        this.handleResponse(res.body.data)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('buyer_order.fetching-error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async saveItem() {
            let _this = this;

            this.progress = 0
            this.loading = true

            let data = {
                organization_id: this.organization_id,
                uved_id: this.uved.id,
                warehouse_id: this.warehouse_id,
                delivery_date: this.delivery_date,
                delivery_address: this.delivery_address,
                comment: this.comment
            }

            if (this.uuid === 'create') {
                await this.$http
                    .post('admin/warehouse/buyer_order', data)
                    .then(res => {
                        this.$toastr.success(this.$t('buyer_order.added'))
                        this.$router.push({
                            name: 'sale.buyer_order.edit',
                            params: {
                                uuid: res.body.data.uuid
                            }
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('buyer_order.add_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                await this.$http
                    .put(`admin/warehouse/buyer_order/${this.uuid}`, data, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('buyer_order.updat_success'));
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('buyer_order.update_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },

        async pass() {
            let _this = this;

            this.progress = 0
            this.loading = true

            await this.$http
                .post(`admin/warehouse/buyer_order/${this.uuid}/pass`)
                .then(res => {
                    this.$toastr.success(this.$t('buyer_order.passed'))
                    this.passedAt = res.body.data.passed_at
                })
                .catch(err => {
                    this.$toastr.error(this.$t('buyer_order.pass_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        revoke() {
            let _this = this;

            this.progress = 0
            this.loading = true

            this.$http
                .post(`admin/warehouse/buyer_order/${this.uuid}/revoke`)
                .then(res => {
                    this.$toastr.success(this.$t('buyer_order.revoked'))
                    this.passedAt = null
                })
                .catch(err => {
                    this.$toastr.error(this.$t('buyer_order.revoke_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        handleResponse(data) {
            this.id = data.id

            this.organization_id = data.organization_id
            this.uved = data.uved
            this.warehouse_id = data.warehouse_id
            this.delivery_date = data.delivery_date ?? this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.delivery_address = data.delivery_address
            this.comment = data.comment
            this.passedAt = data.passed_at
            this.order = data
        },

        onDeleteBtnClick() {
            if (confirm(this.$t('buyer_order.delete_confirm'))) {
                this.loading = true

                this.deleteDocument()
                    .then(() => {
                        this.$toastr.success(this.$t('buyer_order.deleted'))
                        this.$router.push({
                            name: 'warehouse.selling.buyer_order',
                        })
                    })
                    .catch(() => {
                        this.$toastr.error(this.$t('buyer_order.delete_error'))
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },

        deleteDocument() {
            let _this = this;

            return new Promise((resolve, reject) => {

                this.$http
                    .delete(`admin/warehouse/buyer_order/${this.uuid}`)
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('buyer_order.delete_error'))
                        reject(err)
                    })
                    .finally(end => {

                    })
            })
        }
    }
}
</script>
