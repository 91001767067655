<script>
import debounce from "lodash/debounce";
import EntitySelectionPopup from "@/components/Form/EntitySelectionPopup.vue";

export default {
    name: "TaskSelectionPopup",

    components: {
        EntitySelectionPopup,
    },

    props: {
        searchValue: {
            type: String,
            default: ''
        },

        focusItemIndex: {
            type: Number,
            default: null
        },

        value: {
            required: false,
        },

        multiple: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            items: [],
            loading: false,
            selected: [],
            innerValue: null,

            taskStatuses: null,
            clientTypes: null,
            filters: [],
            loaded: false,
            lastFilter: null
        }
    },

    watch: {
        searchValue: debounce(function (value) {
            if (value) {
                this.fetchItems({
                    name: {
                        contains: value
                    },
                    ...this.lastFilter
                }).then(items => {
                    this.items = items
                })
            } else {
                this.fetchItems(this.lastFilter).then(items => {
                    this.items = items
                })
            }
        }, 500),

        value: {
            handler: function (value) {
                this.innerValue = value;
                this.fetchItems(this.lastFilter).then(items => {
                    this.items = items
                });
            },
            deep: true,
            immediate: true
        },

        taskStatuses: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        clientTypes: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        focusItemIndex: function (value) {
            if (value !== null) {
                this.$nextTick(() => {
                    const item = this.$refs['users-list'].querySelectorAll('.entity-select__item').item(value);

                    if(item) {
                        item.focus()
                    }
                });
            }
        }
    },

    methods: {
        showPopup: function () {
            if(this.loaded) {
                return;
            }

            this.fetchItems({
                name: {
                    contains: this.searchValue
                }
            }).then((items) => {
                 this.items = items
            })

            this.fetchTaskStatuses().then((items) => {
                this.taskStatuses = items;
            })

            this.loaded = true;
        },

        updateValue: function (value) {
            this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        clear() {
            this.updateValue([])
            this.items = [];
        },

        input(value) {
            this.updateValue(value);
        },

        async fetchItems(filter) {
            let fltr = {};

            this.loading = true

            if(this.multiple) {
                if(this.innerValue && this.innerValue.length > 0) {
                    fltr.id = {
                        notin: this.innerValue.map(item => item.id)
                    }
                }
            } else {
                if(this.innerValue) {
                    fltr.id = {
                        notin: [this.innerValue.id]
                    }
                }
            }

            if(this.searchValue) {
                fltr.title = {
                    contains: this.searchValue
                }
            }

            fltr = {
                ...fltr,
                ...filter
            }

            let params = {
                sortBy: 'id',
                sortDir: 'desc',
                perPage: 20,
                fltr: fltr
            };


            let res = await this.$http.get('admin/task', {
                params: params
            })

            // on error
            if (res.status < 200 && res.status >= 300) {
                this.$toastr.error(this.$t('failed_to_get_administrator'))
                return [];
            }

            this.loading = false
            let deals = res.body.data;

            this.$emit('deal-fetched', deals);

            return deals;
        },

        async fetchTaskStatuses() {
            let self = this;
            let params = {};
            let res = await this.$http.get('admin/task_status', {
                params: params
            });

            return res.body.data.map(item => {
                return {
                    ...item,
                    name: item.name,
                    click: function (itm) {
                        self.onTaskStatusClick(itm)
                    }
                }
            });
        },

        onTaskStatusClick(itm) {
            this.lastFilter = {
                task_status_id: {
                    eq: itm.id
                }
            };

            this.fetchItems(this.lastFilter).then(items => {
                this.items = items;
            })
        },

        buildFilters() {
            let self = this;
            return [
                {
                    name: this.$t('all'),
                    click: function () {
                        self.lastFilter = {};
                        self.fetchItems(this.lastFilter).then(items => {
                            self.items = items
                        });
                    }
                },
                {
                    name: this.$t('task_statuses'),
                    children: this.taskStatuses
                },
                {
                    name: this.$t('system_filters'),
                    children: [
                        {
                            'name': this.$t('my_tasks'),
                            'click': () => {
                                this.lastFilter = {
                                    task_status_id: {
                                        notin: ['3', '5']
                                    }
                                };

                                this.fetchItems(this.lastFilter).then(items => {
                                    this.items = items
                                })
                            }
                        },
                        {
                            'name': this.$t('inbox'),
                            'click': () => {
                                this.lastFilter = {
                                    inbox: {
                                        custom: '1'
                                    },
                                    task_status_id: {
                                        notin: ['3', '5']
                                    }
                                };

                                this.fetchItems(this.lastFilter).then(items => {
                                    this.items = items
                                })
                            }
                        },
                        {
                            'name': this.$t('outbox'),
                            'click': () => {
                                this.lastFilter = {
                                    outbox: {
                                        custom: '1'
                                    },
                                    task_status_id: {
                                        notin: ['3', '5']
                                    }
                                };

                                this.fetchItems(this.lastFilter).then(items => {
                                    this.items = items
                                })
                            }
                        },
                        {
                            'name': this.$t('favorite'),
                            'click': () => {
                                this.lastFilter = {
                                    favorite: {
                                        custom: '1'
                                    }
                                };

                                this.fetchItems(this.lastFilter).then(items => {
                                    this.items = items
                                })
                            }
                        },
                        {
                            'name': this.$t('overdue'),
                            'click': () => {
                                this.lastFilter = {
                                    deadline: {
                                        lte: this.$moment().format('YYYY-MM-DD')
                                    },
                                    task_status_id: {
                                        in: ['1', '2']
                                    }
                                };

                                this.fetchItems(this.lastFilter).then(items => {
                                    this.items = items
                                })
                            }
                        },
                        {
                            'name': this.$t('urgent'),
                            'click': () => {
                                this.lastFilter = {
                                    is_urgent: {
                                        eq: '1'
                                    },
                                    task_status_id: {
                                        notin: ['3', '5']
                                    }
                                };

                                this.fetchItems(this.lastFilter).then(items => {
                                    this.items = items
                                })
                            }
                        },
                        {
                            'name': this.$t('subordinates'),
                            'click': () => {
                                this.lastFilter = {
                                    all_tasks: {
                                        eq: '1'
                                    },
                                    task_status_id: {
                                        notin: [
                                            '3',
                                            '5',
                                            '7'
                                        ]
                                    }
                                };

                                this.fetchItems(this.lastFilter).then(items => {
                                    this.items = items
                                })
                            }
                        },
                    ]
                }
            ]
        }
    },

    mounted() {
        this.showPopup();
    }
}
</script>

<template>
    <div>
        <EntitySelectionPopup
            :items="items ?? []"
            :filters="filters"
            :multiple="multiple"
            :value="value"
            :loading="loading"
            @input="input"
        >
            <template v-slot:item="{ item }">
                <div class="usr">
                    <div class="usr">
                        <div class="usr__info">
                            <div class="usr__name">
                                {{ item.title }}
                            </div>
                            <div class="usr__position">
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </EntitySelectionPopup>
    </div>
</template>
