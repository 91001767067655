<script>
import { h } from 'vue';
import Vue from 'vue'

let extend = Vue.extend.bind(Vue)

export default extend({
    data() {
        return {
            activeAccordion: null,
            tabs: [],
            innerValue: null
        };
    },

    props: {
        tabsPerRow: {
            type: Number|String,
            default: 0
        },
        value: {
            type: Number,
            default: null
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.innerValue = value;
            }
        },

        innerValue(value) {
            this.$emit('input', value);
        }
    },

    computed: {
        bvTabs() {
            return this.getBvTabs()
        }
    },

    inject: {
        getBvTabs: {
            default: () => () => ({})
        }
    },
    methods: {
        toggleAccordion(index) {
            this.activeAccordion = this.activeAccordion === index ? null : index;
        },

        registerTab(tab) {
            this.tabs.push(tab);
        },

        onResize() {

        },

        h: h,
    },

    mounted() {
        console.log('ResponsiveTabs mounted');
        window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
        console.log('ResponsiveTabs beforeDestroy');
        this.tabs = [];
        window.removeEventListener('resize', this.onResize);
    },

    beforeUpdate() {

    },

    provide() {
        return {
            getBvTabs: () => this
        }
    },

    render() {
        const self = this;
        let tabs = self.tabs

        if(self.$vuetify.breakpoint.smAndDown) {
            let $items = this.h('div', {
                class: {
                    'accordion': true
                }
            }, [tabs.map((tab, index) => {
                return this.h('div', {
                    key: index, // Добавляем ключи
                    class: {
                        'accordion-item': true,
                        'accordion-item--active': this.activeAccordion === index
                    },
                }, [
                    this.h('button', {
                        class: {
                            'tab': true
                        },
                        on: {
                            click: (e) => {
                                e.preventDefault();
                                this.$emit('input', index);
                                this.$emit('change', tab, index);
                                this.toggleAccordion(index);
                            }
                        }
                    }, [
                        tab.$scopedSlots['btn-before'] ? h('div', {
                            class: 'tab-before'
                        }, tab.$scopedSlots['btn-before']()) : null,
                        h('div', {
                            class: 'tab-text'
                        }, tab.$scopedSlots.btn ? tab.$scopedSlots.btn() : tab.$props.title),
                        tab.$scopedSlots['btn-after'] ? h('div', {
                            class: 'tab-after'
                        }, tab.$scopedSlots['btn-after']()) : null,
                    ]),

                    this.h('div', {
                        class: {
                            'accordion-content': true,
                            'd-none': this.activeAccordion !== index
                        }
                    }, [
                        tab.$scopedSlots.before ? tab.$scopedSlots.before() : '',
                        tab.$scopedSlots.default ? tab.$scopedSlots.default() : '',
                        tab.$scopedSlots.after ? tab.$scopedSlots.after() : '',
                    ]),
                ]);
            }), this.$slots.default]);
             return $items;
        } else {
            let $tabNav = this.h('ul', {
                class: 'tab-list'
            }, tabs.map((tab, index) => {
                return this.h('li', {
                    key: index, // Добавляем ключи
                    class: {
                        'tab': true,
                        'tab--active': this.innerValue === index
                    },
                    style: {
                        width: this.tabsPerRow > 0 ? `calc(${100 / this.tabsPerRow}% - 24px)` : 'auto'
                    },
                    on: {
                        click: () => {
                            if(index === self.innerValue) {
                                this.innerValue = null;
                                this.$emit('change', null, null);
                            } else {
                                this.innerValue = index;
                                this.$emit('change', tab, index);
                            }
                        }
                    }
                }, [
                    tab.$scopedSlots['btn-before'] ? h('div', {
                        class: 'tab-before'
                    }, tab.$scopedSlots['btn-before']()) : null,
                    h('div', {
                        class: 'tab-text'
                    }, tab.$scopedSlots.btn ? tab.$scopedSlots.btn() : tab.$props.title),
                    tab.$scopedSlots['btn-after'] ? h('div', {
                        class: 'tab-after'
                    }, tab.$scopedSlots['btn-after']()) : null,
                ]);
            }));

            /* content */
            let $tabContent = '';

            if(this.innerValue !== null) {
                $tabContent = tabs.map((tab, index) => {
                    if (this.innerValue === index) {

                        let className = tab.$vnode.data.class;

                        if(typeof className === 'string') {
                            className = className.split(' ').reduce((acc, item) => {
                                acc[item] = true;
                                return acc;
                            }, {});
                        }

                        return this.h('div', {
                            class: {
                                'tab-content': true,
                                ...className
                            },
                        }, [
                            tab.$scopedSlots.before ? tab.$scopedSlots.before() : '',
                            tab.componentInstance ? tab.componentInstance.normalizeSlot() : tab.$scopedSlots.default ? tab.$scopedSlots.default() : tab?.$slots?.default,
                            tab.$scopedSlots.after ? tab.$scopedSlots.after() : '',
                        ]);
                    }

                });
            }

            return this.h('div', {}, [$tabNav, $tabContent, this.$slots.default]);
        }
    }
})
</script>

<style scoped lang="scss">
.tabs {
    display: flex;
    flex-direction: column;
}

.tab-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    gap: $grid-gutter / 2;
    flex-wrap: wrap;

    &:has(.tab--active) {
        margin-bottom: $grid-gutter / 2;
    }
}

.tab {
    padding: $grid-gutter / 2 $grid-gutter;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid transparent;
    flex-grow: 1;
    display: flex;
    text-transform: uppercase;
    border-radius: $border-radius-root;

    &--active {
        border: 2px solid var(--primary);
        font-weight: bold;
        color: var(--primary);
    }
}

.tab-text {
    flex-grow: 1;
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: $grid-gutter / 2;
}

.accordion-item {
    background-color: #fff;
    border-radius: $border-radius-root;

    &--active {
        border: 2px solid var(--primary);
    }

    .tab {
        width: 100%;
        padding: $grid-gutter / 2 $grid-gutter;
        text-align: left;
        background: none;
        border: none;
        cursor: pointer;
    }
}

.accordion-content,
.tab-content {
    padding: $grid-gutter;
    background-color: #fff;
    //margin-bottom: $grid-gutter / 2;
    //margin-top: $grid-gutter / 2;
    border-radius: $border-radius-root;
}
</style>
