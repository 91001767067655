<script>
import Doc from "@/components/Doc.vue";

export default {
    name: "DocPath",

    props: {
        path: {
            type: Object,
            required: true
        },
        uuid: {
            type: String,
            required: true
        }
    },

    components: {
        Doc
    }
}
</script>

<template>
    <div class="doc-path">
        <ul>
            <Doc v-if="path" :doc="path" :current-doc-uuid="uuid"></Doc>
        </ul>
    </div>
</template>

<style lang="scss">
.doc-path {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        ul {
            margin-left: $spacer * 4;
        }
    }

    li {
        margin-top: $spacer * 2;
        margin-bottom: $spacer * 2;
    }
}
</style>
