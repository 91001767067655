<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-form @submit.prevent="getDealStatuses">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" @click.stop="addDealStatus()"
                           color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-card flat class="background_color_transparent">
                            <v-card-text class="py-0">
                                <v-row class="pt-5">
                                    <v-col cols="12" sm="8" class="title_menu mt-1">
                                        {{  $t('search_deal_status') }}
                                    </v-col>
                                    <v-col v-if="!$vuetify.breakpoint.xsOnly" v-show="!filter_show" cols="12" sm="4"  class="text-right">
                                        <v-btn :to="{name: 'deal_status.create'}" class="infinity_button" color="primary">
                                            {{$t('create') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filter_show">
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="deal_status" rules=""
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="deal_status"
                                                      :items="deal_statusItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('deal_status')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="business_process" rules=""
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="business_process"
                                                      :items="businessProcessItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('business_process')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions v-if="filter_show" class="px-4 py-4">
                                <v-row>

                                    <v-col cols="12"
                                           class="d-flex justify-center justify-sm-end align-self-center">
                                        <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                               color="primary" class="infinity_button">{{ $t('search') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-form>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-data-table item-key="id" ref="table" :headers="headers" :items="deal_statusItems"
                                      :options.sync="options" disable-pagination
                                      :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                                      :loading="loading" :locale="lang" :loading-text="$t('loading_please_wait')"
                                      hide-default-footer
                                      :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                      class="sortable-table elevation-0"
                                      :item-class="rowClass">
                            <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                <thead>
                                <tr class="rowHeadClass Table5Sort">
                                    <th v-for="h in headers" :key="h.value" class="table_header">
                                        <span>{{h.text}}</span>
                                    </th>
                                </tr>
                                </thead>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                    <span  class="cursor-pointer font_weight_600" @click="editDealStatus(item)" >
                                        {{  item.name }}
                                        {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                    </span>
                                    <v-icon v-if="!item.deleted" @click="deleteDealStatus(item)" small>mdi-delete-outline</v-icon>
                                </div>
                            </template>

                            <template v-slot:item.sort>
                                <v-btn icon class="handle">
                                    <v-icon>mdi-drag-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:item.color="{ item }">
                                <v-chip :color="item.color" small light dark></v-chip>
                            </template>
                            <template v-slot:item.action="{ item }">

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="mr-2" @click="editDealStatus(item)">
                                            mdi-square-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ $t('edit') }}</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="(item.undeletable !== 1)">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="deleteDealStatus(item)" :title="$t('delete')">
                                            mdi-delete-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ $t('delete') }}</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Sortable from 'sortablejs'
import {mapGetters} from "vuex"
import SettingMenu from "../components/SettingMenu";

export default {
    name: 'DealStatus',
    components: {
        ValidationProvider,
        ValidationObserver,
        SettingMenu
    },
    inject: ['forceRerender'],
    props: {
        businessProcessId: {
            default: null
        }
    },
    data() {
        return {
            heading: this.$t('deal_status_creation'),
            progress: 0,
            language: null,
            loading: false,
            sortBy: "sort",
            sortDir: false,
            options: {},
            deal_statusItems: [],
            errors: {},
            id: null,
            name: null,
            deal_status: null,
            headers: [
                {
                    text: "",
                    align: "left",
                    sortable: false,
                    value: "sort",
                },
                {
                    text: this.$t('deal_status'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t('deal_main_status'),
                    align: "left",
                    sortable: false,
                    value: "deal_main_status",
                },
                {
                    text: this.$t('business_process'),
                    align: "left",
                    sortable: false,
                    value: "business_process",
                },
                {
                    text: this.$t('deal_status_color'),
                    align: "left",
                    sortable: false,
                    value: "color",
                    width: 140
                },
            ],
            filter_show: false,
            business_process: 0,
            businessProcessItems: []
        }
    },
    computed: {
        ...mapGetters(['BusinessProcessId','listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    async mounted() {
        this.business_process = this.businessProcessId ?? this.BusinessProcessId;
        this.language = this.languages[this.tab];
        await this.getBusinessProcesses();
        this.checkCreate()
        let table = this.$refs.table.$el.querySelector("tbody")
        Sortable.create(table, {
            handle: ".handle",
            onEnd: this.dragReorder
        })

    },
    watch: {
        options: {
            handler() {
                this.getDealStatuses()
            },
            deep: false
        }
    },
    methods: {
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5Sort' : 'rowMobiClass';
        },
        setLanguage(val) {
            this.language = this.languages[val]
        },
        checkCreate() {
             this.heading = this.$t('deal_status_creation')

        },
        dragReorder({newIndex, oldIndex}) {
            const rowSelected = this.deal_statusItems.splice(oldIndex, 1)[0]
            this.deal_statusItems.splice(newIndex, 0, rowSelected)
            this.saveSortDealStatus()
        },
        async saveSortDealStatus() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.deal_statusItems && this.deal_statusItems.length > 0) {
                for (let i in this.deal_statusItems) {
                    if (this.deal_statusItems[i].id !== 'undefined' && this.deal_statusItems[i].id > 0) {
                        formData.append(`sort[${i}]`, this.deal_statusItems[i].id)
                    }
                }
            }
            await this.$http
                .post(`admin/deal_status/sort`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_status_sorting_updated'))
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_status_sorting_not_updated'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getDealStatuses() {
            this.loading = true
            const {
                sortBy,
                sortDesc,
            } = this.options
            let params = {}

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'desc' : 'asc'
            }
            if (this.deal_status) {
                params.name = this.deal_status
            }

            if (this.business_process) {
                params.business_process = this.business_process
            }

            await this.$http
                .get("admin/deal_status", {
                    params: params,
                })
                .then(res => {
                    this.deal_statusItems = res.body.data
                })
                .catch(err => {
                    this.deal_statusItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteDealStatus(item) {
            if (confirm(this.$t('delete_deal_status'))) {
                this.loading = true
                await this.$http
                    .delete(`admin/deal_status/${item.id}`)
                    .then(res => {
                        this.getDealStatuses()
                    })
                    .catch(err => {
                        this.DealStatusStatusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        addDealStatus() {
            this.$router.push({
                name: 'deal_status.create',
                params: {
                    businessProcessId: this.$route.params.business_process
                }
            })
        },
        editDealStatus(item) {
            this.$router.push({
                name: 'deal_status.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getBusinessProcesses() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/business_process", {
                    params: params,
                })
                .then(res => {
                    this.businessProcessItems = res.body.data
                })
                .catch(err => {
                    this.businessProcessItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
