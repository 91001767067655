<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveItem">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('columns.organization.editing') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <ValidationProvider :ref="`name`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.organization.name')"
                                            v-model="name"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider :ref="`full_name`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.organization.full_name')"
                                            v-model="full_name"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`country`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="country"
                                            :label="$t('columns.organization.country')"
                                            v-model="country"
                                            :items="countries.map((item) => ({'text': item.name, 'value': item.id}))"
                                            :error="!valid"
                                        >
                                        </SelectInput>
                                    </ValidationProvider>
                                </v-col>

                            </v-row>
                        </v-card-text>
                        <v-card-actions class="buttons-block">
                            <Btn type="submit" :disabled="invalid || loading" :loading="loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                {{ $t('save') }}
                            </Btn>
                            <Btn
                                :disabled="loading"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </Btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import Btn from "@/components/Form/Btn.vue";
    import TextInput from "@/components/Form/TextInput.vue";
    import SelectInput from "@/components/Form/SelectInput.vue";

    export default {
        name: 'VatForm',
        components: {
            SelectInput,
            Btn,
            ValidationProvider,
            ValidationObserver,
            TextInput
        },

        props: {
            uuid: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                dialogForm: true,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,

                countries: [],

                name: '',
                full_name: '',
                country: null,
            }
        },

        computed: {
            ...mapGetters(['listLanguages', 'lang']),

            languages() {
                return this.listLanguages
            },
        },

        watch: {
            uuid: {
                handler: function (value) {
                    this.init();

                    if (value !== 'create') {
                        this.fetchItem();
                    }
                },

                immediate: true
            }
        },

        async mounted() {
            this.language = this.languages[this.tab]
        },

        methods: {
            init() {
                this.fetchCountries();
            },

            fetchCountries() {
                let _this = this;
                this.$http.get('admin/country')
                    .then(res => {
                        if (res && res.body && res.body.data) {
                            this.countries = res.body.data
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('columns.organization.fetching-error'))

                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
            },

            closeDialogAdd(){
                this.$router.push({
                    name: 'organization',
                })
            },

            async fetchItem() {
                let _this = this;
                let params = {}

                this.progress = 0
                this.loading = true

                if (this.language) {
                    params.language = this.language
                }

                await this.$http
                    .get(`admin/organization/${this.uuid}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if (res && res.body && res.body.data) {
                            this.handleResponse(res.body.data)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$toastr.error(this.$t('columns.organization.fetching-error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },

            async saveItem() {
                let _this = this;

                this.progress = 0
                this.loading = true

                let formData = {};
                formData['name'] = this.name
                formData['full_name'] = this.full_name
                formData['country_id'] = this.country

                if (this.uuid === 'create') {
                    // Add
                    await this.$http
                        .post('admin/organization', formData, {
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('columns.organization.added'))
                            this.$router.push({
                                name: 'organization'
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('columns.organization.add_error'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    await this.$http
                        .put(`admin/organization/${this.uuid}`, formData, {
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('columns.organization.update_success'))

                            this.$router.push({
                                name: 'organization'
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('columns.organization.update_error'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },

            handleResponse(res) {
                this.name = res.name
                this.full_name = res.full_name
                this.country = res.country_id
            }
        }
    }
</script>
