<script>
export default {
    name: "Listitem",

    props: {
        item: {
            type: Object,
            required: true
        },

        columns: {
            type: Array,
            required: true
        },

        userBlock: {
            type: Boolean,
            required: true
        },

        id: {
            type: [String, Number],
            required: false
        },

        status: {
            type: String,
            required: false
        },

        statusColor: {
            type: String,
            required: false
        }
    },

    computed: {
        showDescription() {
            return this.id || this.$slots.name || this.$slots.description
        },

        showHeader() {
            return this.status && this.$slots.date
        },

        activeColumns() {
            return this.columns.filter(column => {
                return column.active
                    && column.prop !== 'id'
                    && column.prop !== 'admin'
                    && column.prop !== 'name'
                    && column.prop !== 'deal_status_id'
            });
        }
    },

    methods: {
        deleteItem(item) {
            this.$emit('deleteItem', item);
        }
    }
}
</script>

<template>
    <div class="item">
        <div class="item__header" v-if="showHeader">
            <template v-if="$slots.header">
                <slot name="header" />
            </template>
            <template v-else>
                <div class="item__status small-text" :style="{ backgroundColor: statusColor }">{{ status }}</div>
                <div class="item__created_at text-color-light small-text" v-if="$slots.date"><slot name="date" /></div>
            </template>
        </div>

        <div class="item__description" v-if="showDescription">
            <div class="item__id text-color-light small-text" v-if="id">#{{ id }}</div>

            <div class="item__name" v-if="$slots.name">
                <slot name="name" />
            </div>

            <div v-if="$slots.description" class="item__note text-color-light">
                <slot name="description" />
            </div>
        </div>

        <div class="item__data" v-if="activeColumns.length > 0">
            <template v-for="column in activeColumns">
                <div  class="text-info" v-if="item[column.prop]" :key="column.name">
                    <div class="text-info__label">
                        <div>{{ column.label }}</div>
                    </div>
                    <div class="text-info__value">
                        <slot :name="column.prop" :item="item">
                            <div>{{ item[column.prop] }}</div>
                        </slot>
                    </div>
                </div>
            </template>
        </div>

        <div class="item__footer" v-if="$slots.footer">
            <slot name="footer" />
        </div>
    </div>
</template>
