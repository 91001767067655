<template>
    <v-container fluid>
        <PageLayout>

            <template v-slot:default>
                <TabsNav />
                <div class="d-flex justify-space-between mb-10">
                    <div class="d-flex align-center flex-grow-1">
                        <h1>
                            <template>
                                {{ filter_show ? $t('search_tracking') : $t('menu_tracking') }}
                            </template>
                        </h1>
                        <TableSettings
                            :fields="tableColumns"
                            :entityName="entityName"
                            className="ml-2"
                            @column-select="onColumnSelect"
                        />
                    </div>
                    <div class="text-right">
                        <Btn large   v-if="$vuetify.breakpoint.smAndUp" :to="{name: 'tracking.create'}" color="secondary">
                            {{ $t('create') }}
                        </Btn>
                        <Teleport to="#app" v-else>
                            <div class="fab__wrapper">
                                <Btn
                                    fab
                                    :to="{name: 'tracking.create'}"
                                    :title="$t('create')"
                                    color="secondary"
                                ><v-icon>mdi-plus</v-icon></Btn>
                            </div>
                        </Teleport>
                    </div>
                </div>

                <div class="mobile-filter mb-5" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon
                        class="mobile-filter__btn mr-2"
                        color="secondary"
                        @click="filter_show === true ? filter_show = false : filter_show = true"
                        :title="$t['filter_add']"
                    >mdi-filter-plus</v-icon>

                    <FilterMobileLinks
                        :links="[
                        ...Additional.map((item) => {
                            return {
                                ...item
                            }
                        }),

                        ...adminItems.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...system_filters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...userFilters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                    />
                </div>

                <FilterComponent
                    class="mb-10"
                    v-if="filter_show"
                    :filterData="filterData"
                    :currentFilter="currentFilter"
                    :value="filterValues"
                    :loading="loading"
                    :invalid="invalid"
                    entity="document"
                    @input="filterValues = $event"
                    @submit="submitForm"
                    @filterSaved="filterSaved"
                    @filterDeleted="filterDeleted"
                />

                <template  v-if="$vuetify.breakpoint.smAndDown">
                    <ListItem
                        v-for="item in trackings"
                        :item="item"
                        :key="item.id"
                        @showDialogTracking="showDialogTracking(item)"
                        @showDetailsApplication="showDetailsApplication(item)"
                    >
                    </ListItem>
                </template>
                <template v-else>
                    <div>
                        <v-row>
                            <v-col cols="12" >

                                <v-row v-if="trackings.length > 0">
                                    <v-col cols="2" class="my-0 py-0 pl-4">
                                        <div class="color_grey_header">
                                            {{$t('date_created')}}
                                        </div>
                                    </v-col>
                                    <v-col cols="3" class="my-0 py-0 pl-6">
                                        <div class="color_grey_header">
                                            {{$t('deal.self')}}
                                        </div>
                                    </v-col>
                                    <v-col cols="2" class="my-0 py-0 pl-4">
                                        <div class="color_grey_header">
                                            {{$t('importer')}}
                                        </div>
                                    </v-col>
                                    <v-col cols="2" class="my-0 py-0">
                                        <div class="color_grey_header">
                                            {{$t('working_on_application')}}
                                        </div>
                                    </v-col>
                                    <v-col cols="2" class="my-0 py-0 pl-6">
                                        <div class="color_grey_header">
                                            {{$t('container')}}
                                        </div>
                                    </v-col>
                                    <v-col cols="1" class="my-0 py-0">

                                    </v-col>
                                    <v-col cols="12" class="my-0 py-0 pt-1">

                                        <v-card
                                            elevation="0"
                                            :hover="true"
                                            v-for="(item, i) in trackings"
                                            :key="'tracking_'+i"
                                            class="mb-7 rounded-md"

                                            color="#FFFFFF"

                                            :class="item.step_stop === false ? 'no_color' : 'border_card'"


                                        >

                                            <v-card-text class="px-0 pt-3 pb-6"   @click="showDetailsApplication(item)">

                                                <v-row class="px-5 pb-2">

                                                    <v-col cols="2">
                                                        <div class="deal_bold"  @click="showDetailsApplication(item)">
                                                            {{ item.date_operation ? $moment(item.date_operation).format('DD.MM.YYYY') : '' }}
                                                            <v-icon small color="primary">mdi-chevron-down</v-icon>
                                                        </div>
                                                        <div v-if="item.deal_topic && item.deal_topic.uuid">
                                                            <v-btn text plain :to="'/topic/' + item.deal_topic.uuid"  class="cursor-pointer tracking_link button_link">
                                                                <v-icon color="primary" small>icon-messages</v-icon>
                                                            </v-btn>

                                                        </div>
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <div class="deal_bold" @click.stop="showDeal(item.deal_uuid)">
                                                            <v-btn text plain :to="'/deal/' + item.deal_uuid + '/show'"  class="cursor-pointer tracking_link button_link" :class="item.step_stop === false ? 'no_color' : ''">
                                                                {{item.number_deal}}
                                                            </v-btn>
                                                        </div>

                                                        <div class="color_grey" v-if="item.type_transport">
                                                            {{item.type_transport}}
                                                        </div>
                                                        <div class="color_grey" v-if="item.loading_type && item.loading_type.name">{{item.loading_type.name}}</div>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <div class="deal_bold" @click.stop="true" v-if="item.uved_name">
                                                            <v-btn text plain :to="'/uved/' + item.uved.uuid + '/show'"  class="cursor-pointer tracking_link button_link">
                                                                {{item.uved_name}}
                                                            </v-btn>
                                                        </div>
                                                        <div class="color_grey" v-if="item.uved_phones && item.uved_phones.phone">
                                                            {{item.uved_phones.phone}}

                                                        </div>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <div v-if="item.responsible && item.responsible.length > 0">
                                                            <div  v-for="(responsible, i) in item.responsible.slice(0, 3)" :key="'responsible_'+i" 	>
                                                                <v-icon small>icon-user</v-icon>
                                                                {{ (responsible) ? responsible.name : '' }}
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="2" class="pl-8">
                                                        <div>
                                                            <v-icon class="pb-2">icon-shipment</v-icon>
                                                            <span class="deal_bold mx-2">{{ item.container_number ? item.container_number : $t('no_data') }}</span>
                                                        </div>

                                                    </v-col>
                                                    <v-col cols="1" style="text-align: right">
                                                        <div class="deal_bold">
                                                            {{item.documents.length}}
                                                            <v-icon small color="primary" class="pl-1">icon-file-text</v-icon>
                                                        </div>
                                                        <div class="deal_bold">
                                                            {{item.deal_documents.length }}
                                                            <v-icon small color="primary" class="pl-1">icon-file-text-check</v-icon>
                                                        </div>
                                                        <div v-if="false" class="deal_bold">
                                                            Не подана
                                                            <v-icon small color="primary" class="pl-1">icon-doc</v-icon>
                                                        </div>
                                                    </v-col>


                                                </v-row>
                                                <v-row class="px-5 pb-2">
                                                    <v-col cols="2">
                                                        <div v-if="!item.arrival_date_at_destination">
                                                            <span style="color: #2b95eb">{{ $t('in_operation_for') }}:</span>
                                                            <span class="color_grey"> {{ $t('for_day', {"day": item.day}) }}</span>
                                                        </div>
                                                        <div v-else>
                                                            <span style="color: #2b95eb">{{ $t('arrived') }}: </span>
                                                            <span class="color_grey">{{ $moment(item.arrival_date_at_destination).format('DD.MM.YYYY') }} </span>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <div v-if="item.finish === true || item.step_stop === false">
                                                            <span class="color_grey">{{ $t('current_station') }}:</span>
                                                            <span class="deal_bold">
                                                                {{ item.station ? item.station : item.departure }}
                                                            </span>
                                                        </div>
                                                        <div v-else>
                                                            <span class="color_grey">{{ $t('current_station') }}:</span>
                                                            <span class="deal_bold cursor-pointer" @click.stop="showDialogTracking(item)">
                                                                {{ item.station ? item.station : item.departure }}
                                                                <v-icon small color="primary" class="ml-1">icon-shipment-add</v-icon>
                                                            </span>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="7" v-if="item.sub_status_name">
                                                        <div >
                                                            <span class="color_grey">{{ $t('delivery_status') }}:</span>
                                                            <span class="deal_bold">
                                                                {{ item.sub_status_name ? item.sub_status_name : ''}}
                                                            </span>
                                                        </div>
                                                    </v-col>
                                                </v-row>

                                                <v-row  class="px-5 pb-2">
                                                    <v-col cols="12" v-if="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)">
                                                        <v-row v-if="false">
                                                            <v-col cols="12">
                                                                <ul class="pod_status d-flex">
                                                                    <template v-for="(status_item, i) in item.trackings">
                                                                        <li :key="'status'+status_item.id" class="li1">
                                                                            <div class="node " :class="(item.cargo_arrived === 0 && (i + 1) === item.trackings.length)   ? 'grey_point' : 'blue_point'"></div>
                                                                            <p

                                                                                :class="(i+1) === item.trackings.length ? 'li_last' : ''"><span :class="(item.cargo_arrived === 0 && (i + 1) === item.trackings.length)   ? 'grey_color' : 'blue_color'">{{ status_item.client_name }}</span>
                                                                            </p>
                                                                        </li>
                                                                        <li :key="'status'+status_item.id + '-' + status_item.id" v-if="(i+1) !== item.trackings.length" class="my_divider "

                                                                            :style="'width:' + (100 / (item.trackings.length-1)) + '% !important'"

                                                                            :class="(item.cargo_arrived === 0 && (i + 2) === item.trackings.length)   ? 'grey_line' : 'blue_line'">
                                                                        </li>
                                                                    </template>
                                                                </ul>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row >
                                                            <v-col cols="12">
                                                                <ul class="pod_status d-flex">
                                                                    <template v-for="(status_item, i) in item.deal_statuses">
                                                                        <li :key="'status'+status_item.id" class="li1">
                                                                            <div class="node " :class="parseInt(item.deal_status_sort) >= parseInt(status_item.sort) ? (item.step_stop === false ? 'grey_point' : 'blue_point') : 'grey_point'"></div>
                                                                            <p :class="(i+1) === item.deal_statuses.length ? 'li_last' : ''"><span :class="parseInt(item.deal_status_sort, 10)  >= parseInt(status_item.sort , 10) ? (item.step_stop === false ? 'black_color' : 'blue_color') : 'grey_color'" >{{ status_item.client_name }}</span>
                                                                            </p></li>
                                                                        <li :key="'status'+status_item.id + '-' + status_item.id" v-if="(i+1) !== item.deal_statuses.length" class="my_divider "
                                                                            :class="item.deal_status_sort > status_item.sort ? (item.step_stop === false ? 'black_point' : 'blue_line') : 'grey_line'">
                                                                        </li>
                                                                    </template>
                                                                </ul>
                                                            </v-col>
                                                        </v-row>

                                                    </v-col>
                                                </v-row>

                                            </v-card-text>
                                        </v-card>

                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-row>
                    </div>
                </template>
                <v-row>
                    <v-col cols="12">
                        <div class="main-pagination d-flex flex-row justify-end">
                            <SelectInput
                                class="main-pagination__per-page"
                                id="pagination-variants"
                                :value="options.itemsPerPage"
                                :items="perPageItems"
                                :label="$t('items_per_page')"
                                :disabled="loading"
                                @input="onPerPageChange($event)"
                                hide-label
                            ></SelectInput>
                            <v-pagination v-model="page" :total-visible="totalVisiblePag" class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
                        </div>
                    </v-col>
                </v-row>


                <v-dialog
                    v-model="dialogDetailTracking"
                    :width="!$vuetify.breakpoint.xsOnly ? 700 : '100%'"
                    overlay-color="#edebeb"

                    transition="dialog-bottom-transition"

                    :fullscreen="$vuetify.breakpoint.xsOnly"
                    :hide-overlay="$vuetify.breakpoint.xsOnly"
                    :scrollable="$vuetify.breakpoint.xsOnly"

                >
                    <v-card class="px-5 py-5">
                        <v-toolbar  elevation="0" style="background-color: #FFFFFF">
                            <div class="ma-auto ml-1">
                                <span>{{ $t('tracking_data') }}</span>
                            </div>
                            <div>
                                <v-icon @click="close_info()">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-title class="distance_0">
                            <v-row>
                                <v-col cols="12" class="page-layout__main">
                                    <div class="tabs-nav">
                                        <div class="tabs-nav__inner">
                                            <div
                                                v-for="tab in data_info"
                                                :key="tab.type"
                                                class="tabs-nav__item cursor_pointer"
                                                :class="{ active: tab_type === tab.type }"
                                            >
                                                <button  @click="show_info(tab.type)">
                                                    <v-icon class="tabs-nav__item-icon" small>{{ tab.icon }}</v-icon>
                                                    {{ tab.name }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" v-if="tab_type==='data'">
                                    <v-row>
                                        <v-col cols="12" class="mb-1 pb-0"><b>{{ $t('application_number') }}:</b> {{ detail_tracking.number ? detail_tracking.number : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-0 py-0"><b>{{ $t('container_number') }}:</b> {{ detail_tracking.container_number ? detail_tracking.container_number : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-1 py-0"><b>{{ $t('departures') }}:</b> {{ detail_tracking.departure ? detail_tracking.departure : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-0 py-0"><b>{{ $t('destination') }}:</b> {{ detail_tracking.destination ? detail_tracking.destination : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-0 py-0"><b>{{ $t('delivery_status') }}:</b> {{detail_tracking.sub_status_name ? detail_tracking.sub_status_name : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-1 py-0"><b>{{ $t('type_container') }}:</b> {{ detail_tracking.type_container ? detail_tracking.type_container : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-0 py-0"><b>{{ $t('type_transportation') }}:</b> {{ detail_tracking.type_transport ? detail_tracking.type_transport : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-1 py-0"><b>{{ $t('download_type') }}:</b>{{ (detail_tracking.loading_type && detail_tracking.loading_type.name) ? detail_tracking.loading_type.code + ' ' + detail_tracking.loading_type.name : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-0 py-0"><b>{{ $t('application_date') }}:</b> {{ detail_tracking.created_at ? $moment(detail_tracking.created_at).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-1 py-0"><b>{{ $t('platform_number') }}:</b> {{ detail_tracking.platform_number ? detail_tracking.platform_number : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-0 py-0"><b>{{ $t('platform_number_knr') }}:</b> {{ detail_tracking.platform_number_knr ? detail_tracking.platform_number_knr : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-1 py-0"><b>{{ $t('date_operation') }}:</b> {{ detail_tracking.date_operation ? $moment(detail_tracking.date_operation).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-0 py-0"><b>{{ $t('date_of_arrival_at_the_border') }}:</b>{{ detail_tracking.date_of_arrival_at_border ? $moment(detail_tracking.date_of_arrival_at_border).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-1 py-0"><b>{{ $t('arrival_date_at_destination') }}:</b>{{ detail_tracking.arrival_date_at_destination ? $moment(detail_tracking.arrival_date_at_destination).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-0 py-0"><b>{{ $t('distance_to_destination') }}:</b> {{detail_tracking.distance_to_destination ? detail_tracking.distance_to_destination : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="my-1 py-0"><b>{{ $t('delivered') }}:</b> {{ detail_tracking.paid == true ? $t('yes') : $t('no') }}</v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" v-if="tab_type==='tracking'">

                                    <v-row>
                                        <v-col cols="12" md="12" class="mb-1 pb-0">
                                            <div class="roadmap_wrapper">

                                                <ul class="roadmap">


                                                    <li class="li1_2" v-if="tracking_geo.from">
                                                        <div class="node_2 blue_point_2"></div>
                                                        <p>
                                                            <span class="li_last_bold">{{ tracking_geo.from.name }}</span>
                                                            <br/>
                                                            <span class="grey_date_2">{{ tracking_geo.from.date_operation }}</span>
                                                        </p>
                                                    </li>


                                                    <template v-for="(item, t) in tracking_geo.tracking" >
                                                        <li v-if="tracking_geo.to.name !== item.name" class="my_divider_2 blue_line_2" :key="'geo2_'+t"></li>
                                                        <li v-if="tracking_geo.to.name !== item.name" class="li1_2" :key="'geo21_'+t">
                                                            <div class="node_2 blue_point_2"></div>
                                                            <p class="li_last_center_2">
                                                                <span class="li_last_bold">{{item.name}}</span>
                                                                <br/>
                                                                <span class="grey_date_2">{{ item.date_operation }}</span>
                                                            </p>
                                                        </li>
                                                    </template>

                                                    <li v-if="tracking_geo.to" :class="(tracking_geo.tracking && tracking_geo.tracking.length > 0 ? 'my_divider_2' : 'my_divider_3') + ' ' + (!detail_tracking.arrival_date_at_destination ? 'grey_line_2' : 'blue_line_2')"></li>
                                                    <li v-if="tracking_geo.to" class="li1_2">
                                                        <div class="node_2" :class="!detail_tracking.arrival_date_at_destination ? 'grey_point_2' : 'blue_point_2'"></div>
                                                        <p class="li_last_finish_2">
                                                            <span class="li_last_bold">{{ tracking_geo.to.name }}</span>
                                                            <br/>
                                                            <span class="grey_date_2">{{ tracking_geo.to.date_operation }}</span>
                                                        </p>
                                                    </li>

                                                </ul>


                                            <ul v-if="false" class="roadmap">
                                                <!-- Станция 1 -->
                                                <li class="li1_2">
                                                    <div class="node_2 blue_point_2"></div>
                                                    <p>
                                                        <span class="li_last_bold">Almaty 1</span>
                                                        <br/>
                                                        <span class="grey_date_2">2024-10-10</span>
                                                    </p>
                                                </li>

                                                <!-- Линия между станциями -->
                                                <li class="my_divider_3 grey_line_2"></li>
                                                <!-- Станция 4 -->
                                                <li class="li1_2">
                                                    <div class="node_2 grey_point_2"></div>
                                                    <p class="li_last_finish_2">
                                                        <span class="li_last_bold">Altyn Kol</span>
                                                        <br/>
                                                        <span class="grey_date_2">2024-10-10</span>
                                                    </p>
                                                </li>
                                            </ul>
                                            </div>


                                        </v-col>
                                        <v-col cols="12" md="12" style="min-height: 370px" class="rounded-lg">
                                            <div >
                                                <yandex-map class="py-0 my-0 py-0" v-if="coords && showMap" ref="map"
                                                            :coords="coords"
                                                            :controls="[]"
                                                            :show-all-markers="showAllMarkers"
                                                            :zoom="zoom"
                                                            @map-was-initialized="initHandler"
                                                            style="background-color: #ffffff">
                                                </yandex-map>
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-col>
                                <v-col cols="12" v-if="tab_type==='event'" style="min-height: 390px">
                                    <v-row>
                                        <v-col cols="12" class="mb-1 pb-0">

                                                <template v-for="(item, t) in trackingLogItems">
                                                    <v-row class="py-2">
                                                        <v-col cols="9" sm="9" color="primary" class="my-0 py-0" >
                                                            <v-icon small color="primary" class="mr-2" v-if="!$vuetify.breakpoint.xsOnly">icon-check-circle-1</v-icon>
                                                            {{item.message}}
                                                        </v-col>
                                                        <v-col cols="3" sm="3" class="grey_date_2 text-right my-0 py-0">
                                                            {{item.created_at}}
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                        </v-col>

                                        </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="dialogTracking"
                    :width="!$vuetify.breakpoint.xsOnly ? 600 : '100%'"
                    overlay-color="#edebeb"

                    transition="dialog-bottom-transition"

                    :fullscreen="$vuetify.breakpoint.xsOnly"
                    :hide-overlay="$vuetify.breakpoint.xsOnly"
                    :scrollable="$vuetify.breakpoint.xsOnly"

                >
                        <v-card >
                            <ValidationObserver ref="observerDialogTracking" v-slot="{ invalid, validated, passes, validate }">

                            <v-toolbar class="dialog_toolbar" elevation="0">
                                <div class="ma-auto ml-0">
                                    <span>{{ $t('tracking') }}</span>
                                </div>
                                <div>
                                    <v-icon @click="closeDialogTracking()" :title="$t('close')">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-card-title class="distance_0"></v-card-title>
                            <v-card-text class="py-5">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="departure" rules="min:1" v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('station')}}</div>
                                            <v-autocomplete v-model="station" :items="stationItems"
                                                            :error="!valid" :error-messages="errors"
                                                            :search-input.sync="stationSearching"
                                                            item-text="geocoder_name" item-value="id"
                                                            :loading="loadingDepartures"
                                                            @click="clearStation"
                                                            :no-data-text="stationSearching ? $t('nothing_found_by',{'search': stationSearching}) : $t('nothing_found_departures')"
                                                            :label="$t('station')" @click:clear="stationItems=[]"
                                                            return-object  autocomplete="off"
                                                            append-outer-icon="mdi-plus"
                                                            @click:append-outer="openDialogStation()"

                                                            outlined
                                                            solo
                                                            flat
                                                            hide-details
                                                            dense
                                                            :disabled="loadingStation"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="distance" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('distance')}}</div>
                                            <v-text-field v-model="distance" type="text"
                                                          :error="!valid"
                                                          :label="$t('distance')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="type_container" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('type_container')}}</div>
                                            <v-autocomplete v-model="type_container" :items="typeContainerItems"
                                                            item-text="name" item-value="id"
                                                            :label="$t('type_container')"
                                                            :error="!valid"
                                                            hide-details
                                                            :error-messages="errors"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            :disabled="loadingTypeContainer"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable></v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="container_number" rules="min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('container_number')}}</div>
                                            <v-text-field v-model="container_number" type="text"
                                                          :error="!valid"
                                                          :label="$t('container_number')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="status" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('status')}}</div>
                                            <v-autocomplete v-model="status" :items="statusItems"
                                                            item-text="name" item-value="id"
                                                            :label="$t('status')"
                                                            :error="!valid"
                                                            hide-details
                                                            :error-messages="errors"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            :disabled="loading"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable
                                                            @change="getSubStatus()"
                                                            @click:clear="status=null;sub_status=null;subStatusItems=[];"
                                            ></v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="sub_status" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('sub_status')}}</div>
                                            <v-autocomplete v-model="sub_status" :items="subStatusItems"
                                                            item-text="name" item-value="id"
                                                            :label="$t('sub_status')"
                                                            :error="!valid"
                                                            hide-details
                                                            :error-messages="errors"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            :disabled="loading"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable
                                            ></v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="menuDateSending" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="date_sending" name="date_sending" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <div class="input_label">{{$t('date_sending')}}</div>

                                                    <v-text-field v-model="date_sending" :error-messages="errors"
                                                                  :label="$t('date_sending')"
                                                                  readonly v-on="on"
                                                                  @click:clear="date_sending = null"
                                                                  outlined
                                                                  hide-details
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="date_sending"
                                                           :min="$moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="menuDateSending = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuPr" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <div class="input_label">{{$t('arrival_date_at_destination')}}</div>
                                                <ValidationProvider ref="date_pr" name="date__pr" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="date_pr" :error-messages="errors"
                                                                  :label="$t('arrival_date_at_destination')"
                                                                  readonly v-on="on"
                                                                  @click:clear="date_pr = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  hide-details
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="date_pr"
                                                           :min="$moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuPr = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuGr" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="date_gr" name="date_gr" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <div class="input_label">{{$t('date_of_arrival_at_the_border')}}</div>

                                                    <v-text-field v-model="date_gr" :error-messages="errors"
                                                                  :label="$t('date_of_arrival_at_the_border')"
                                                                  readonly v-on="on"
                                                                  @click:clear="date_gr = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  hide-details
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="date_gr"
                                                           :min="$moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuGr = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuBorder" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <div class="input_label">{{$t('date_of_exit_from_border')}}</div>

                                                <ValidationProvider ref="date_border" name="date_from" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="date_border" :error-messages="errors"
                                                                  :label="$t('date_of_exit_from_border')"
                                                                  readonly v-on="on"
                                                                  @click:clear="date_border = null"
                                                                  outlined
                                                                  hide-details
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="date_border"
                                                           :min="$moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuBorder = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>



                                </v-row>
                                <v-row v-if="$vuetify.breakpoint.xsOnly">
                                    <v-col cols="12" class="pt-8 text-right">
                                        <v-btn
                                            block
                                            color="primary"
                                            @click="saveTracking()"
                                        >{{ $t('save') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="saveTracking()"
                                >{{ $t('save') }}</v-btn>
                            </v-card-actions>
                            </ValidationObserver>
                        </v-card>

                </v-dialog>


            </template>
            <template v-slot:side>
                <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5"  color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>

                <v-list  class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                    <v-list-group :ripple="false" v-model="group1" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                        </template>
                        <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" :to="item.url" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}">
                            <v-list-item-title v-if="item.can" v-text="item.title" class="cursor-pointer"></v-list-item-title>
                            <v-list-item-icon v-if="item.can" v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>

            </template>
        </PageLayout>

        <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelDetails()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text class="pa-0">
                    <v-row>
                        <v-col cols="12" sm="6" >
                            <v-col cols="12" class="py-1 text-right" v-if="(detailsItems.client !== null && typeof detailsItems.client.name !== null)"><b> Клиент:</b> {{ detailsItems.client.name ? detailsItems.client.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1 text-right"><b> {{$t('application_number')}}:</b> {{ detailsItems.deal.number ? detailsItems.deal.number :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1 text-right"><b> {{$t('container_number')}}:</b> {{ detailsItems.container_number ? detailsItems.container_number :  $t('no_data')  }}</v-col>

                            <v-col cols="12" class="py-1 text-right"><b> {{$t('departures')}}:</b> {{ detailsItems.departure.name ? detailsItems.departure.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1 text-right"><b> {{$t('destination')}}:</b> {{ detailsItems.destination.name ? detailsItems.destination.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1 text-right"><b> {{$t('type_container')}}:</b> {{ detailsItems.type_container.name ? detailsItems.type_container.name :  $t('no_data')  }}</v-col>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-col cols="12" class="py-1"><b> {{$t('status')}}:</b> {{ detailsItems.status.name ? detailsItems.status.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('application_date')}}:</b> {{ detailsItems.created_at ? $moment(detailsItems.created_at).format('YYYY-MM-DD HH:MM') : $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('date_operation')}}:</b> {{ detailsItems.date_operation  ? $moment(detailsItems.date_operation).format('YYYY-MM-DD HH:MM') : $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('date_of_arrival_at_the_border')}}:</b> {{ detailsItems.date_of_arrival_at_border ? $moment(detailsItems.date_of_arrival_at_border).format('YYYY-MM-DD HH:MM') :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('arrival_date_at_destination')}}:</b> {{ detailsItems.arrival_date_at_destination ? $moment(detailsItems.arrival_date_at_destination).format('YYYY-MM-DD HH:MM') :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('distance_to_destination')}}:</b> {{ detailsItems.distance_to_destination ? detailsItems.distance_to_destination :  $t('no_data')  }}</v-col>
                        </v-col>

                        <v-col class="py-0" cols="12" sm="2">

                        </v-col>
                        <v-col class="py-0" cols="12" sm="8">
                            <template>
                                <v-timeline
                                    align-top
                                    :dense="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-timeline-item
                                        v-for="(item, i) in detailsItems.trackings"
                                        :key="i"
                                        color="primary"
                                        icon="mdi-chevron-down-circle-outline"
                                        fill-dot
                                        flat
                                    >
                                        <v-card
                                            color="primary"
                                            dark
                                        >
                                            <v-card-title class="title">
                                                {{$t('updated_at')}}  : {{  item.date_operation ? $moment(item.date_operation).format('YYYY-MM-DD HH:MM') : $t('no_data')}}
                                            </v-card-title>
                                            <v-card-text>
                                                <p style="line-height:25px">
                                                    <b>{{$t('distance_to_destination')}}:</b>  {{ item.distance_to_destination ? item.distance_to_destination :  $t('no_data')  }} <br/>
                                                    <b>{{$t('status')}}:</b> {{ item.status ? item.status :  $t('no_data')  }} <br/>
                                                    <b>{{$t('operations')}}:</b>  {{ item.station ? item.station :  $t('no_data')  }} <br/>
                                                    {{$t('date_operation')}}: {{  item.date_operation ? $moment(item.date_operation).format('YYYY-MM-DD HH:MM') : $t('no_data')}}
                                                </p>

                                            </v-card-text>
                                        </v-card>
                                    </v-timeline-item>
                                </v-timeline>
                            </template>
                        </v-col>
                        <v-col cols="12" sm="2">

                        </v-col>

                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-if="dialogFile" v-model="dialogFile"  width="500">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{ $t('upload_tracking') }}
                </v-card-title>

                <v-card-text class="my-2 py-2">
                    <div class="my-1">{{ $t('upload_title') }}</div>
                    <div class="my-1">{{ $t('upload_text_1') }}</div>
                    <div class="my-1">{{ $t('upload_text_2') }}</div>
                    <div class="my-1">{{ $t('upload_text_3') }}</div>
                    <div class="my-1">{{ $t('upload_text_4') }}</div>
                    <div class="my-2" v-html="$t('upload_text_attention')"></div>
                    <v-row class="my-2" align="center" justify="space-around">
                        <v-btn
                            class="my-3"
                            outlined
                            :href="fileDownload"
                            download>
                            {{ $t('download_example')}}
                        </v-btn>
                    </v-row>

                    <v-file-input class="my-2" v-model="uploadFile"
                                  accept=".xls,.xlsx"
                                  :label="$t('select_file')"
                                  truncate-length="11"
                    ></v-file-input>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closeDialogFile()"
                    >
                        {{ $t('cancel')}}
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="saveFile()"
                    >
                        {{ $t('upload')}}
                    </v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogCompanyToTracking"
        >
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card>
                    <v-toolbar
                        color="primary"
                        dark
                    >{{ $t('add_company') }}</v-toolbar>
                    <v-card-text>
                        <v-col cols="12">
                            <ValidationProvider ref="company" rules="min:1"
                                                v-slot="{ errors, valid }">
                                <v-autocomplete v-model="company" :items="companyItems"
                                                :error-messages="errors" :error="!valid"
                                                :search-input.sync="companySearching"
                                                item-text="name" item-value="id"
                                                prepend-icon="mdi-account"
                                                append-icon="mdi-location-enter"
                                                :loading="loadingCompanies" :disabled="loading"
                                                :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_name')"
                                                :label="$t('company')"
                                                @click:clear="companyItems=[]"
                                                @click="clearCompanies"
                                                @click:append="redirectToCompany(company)"
                                                color="primary" clearable autocomplete="off">
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                <span class="search-list">
                                                    <span v-html="item.name"></span>
                                                    <span class="ml-2 hidden-is-empty"
                                                          v-text="item.bin_iin"></span>
                                                </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <span class="search-list">
                                                    <span v-html="item.name"></span>
                                                    <span class="ml-2 hidden-is-empty"
                                                          v-text="item.bin_iin"></span>
                                                </span>
                                    </template>
                                </v-autocomplete>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            text
                            @click="closeClientToTracking()"
                        >{{ $t('close') }}</v-btn>
                        <v-btn
                            text
                            @click="addCompanyToTracking()"
                        >{{ $t('add') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogDeal"
            class="dialog_body"
        >
            <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('add_deal') }}</span>
                        </div>
                        <div>
                            <v-icon @click="closeDealToApplication()" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-2 pb-0">
                        <v-col cols="12">
                            <ValidationProvider ref="company" rules="min:1"
                                                v-slot="{ errors, valid }">
                                <v-autocomplete v-model="deal" :items="dealItems"
                                                :error-messages="errors" :error="!valid"
                                                :search-input.sync="dealSearching"
                                                item-text="number" item-value="id"
                                                append-icon="mdi-location-enter"
                                                :loading="loadingDeals" :disabled="loading"
                                                :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                                :label="$t('deal.self')"
                                                @click:clear="dealItems=[]"
                                                @click="clearDeals"
                                                clearable autocomplete="off"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                hide-details
                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                >
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                <span class="search-list">
                                                    <span v-html="item.number"></span>
                                                    <span class="ml-2 hidden-is-empty"
                                                          v-text="item.admin.name"></span>
                                                </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <span class="search-list">
                                                    <span v-html="item.number"></span>
                                                    <span class="ml-2 hidden-is-empty"
                                                          v-text="item.admin.name"></span>
                                                </span>
                                    </template>
                                </v-autocomplete>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="addDealApplication()"
                        >{{ $t('add') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>




        <v-dialog
            persistent
            max-width="1200px"
            transition="dialog-bottom-transition"
            v-model="dialogFormStation"
        >
            <v-form @submit.prevent="saveStation">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                    <v-card flat >
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{$t('station_creation')}}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogStation" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12" v-show="false">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>

                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="4">
                                    <ValidationProvider ref="country" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="country" :error="!valid" :error-messages="errors"
                                                  :disabled="loading" :items="countryItems"
                                                  item-text="name" item-value="id"
                                                  :label="$t('country')"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable>
                                        </v-select>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="2">
                                    <ValidationProvider ref="code" rules="min:1|max:30"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="code" type="text" :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('code')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="station_name" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="station_name" type="text" :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('station_name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row style="background-color: #F5F5F5" class="pa-2">
                                <v-col cols="12"> {{ $t('station_center') }}</v-col>
                                <v-col cols="12">
                                    <yandex-map v-if="coords && showMap" ref="map"
                                                :coords="coords"
                                                :show-all-markers="showAllMarkers"
                                                :zoom="zoom"
                                                @map-was-initialized="initHandler2"
                                                style="height: 300px"
                                                @click="onClickMap">
                                        <ymap-marker ref="mapMarker"
                                                     marker-id="123"
                                                     marker-type="placemark"
                                                     :coords="coords"
                                                     @dragend="ondDagendMap"/>
                                    </yandex-map>
                                </v-col>
                                <v-col cols="12" v-if="(coords[0] === 51 && coords[1] === 71)"
                                       class="mt-2" style="color: red">
                                    {{ $t('coordinates_of_point_not_set') }}
                                </v-col>
                            </v-row>

                        </v-card-text>

                        <v-card-actions class="px-4 py-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogStation()"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>


    </v-container>
</template>
<script>

    import {mapGetters} from "vuex"
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import debounce from "lodash/debounce";
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import TabsNav from "@/components/Leentech/TabsNav.vue";
    import FilterView from "@/plugins/mixins/FilterView";
    import {loadYmap, yandexMap, ymapMarker} from 'vue-yandex-maps'
    import Btn from "@/components/Form/Btn.vue";
    import NavMixin from "@/plugins/mixins/Nav";
    import ResourceView from "@/plugins/mixins/ResourceView";
    import SelectInput from "@/components/Form/SelectInput.vue";
    import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
    import ListItem from "@/components/Tracking/ListItem.vue";
    import Teleport from "vue2-teleport"

    export default {
        name: "Tracking",
        mixins: [
            FilterView,
            ResourceView,
            NavMixin
        ],
        components: {
            TabsNav,
            ValidationProvider,
            ValidationObserver,
            PageLayout,
            yandexMap,
            ymapMarker,
            Btn,
            SelectInput,
            FilterMobileLinks,
            ListItem,
            Teleport
        },
        data() {
            return {
                entityName: 'application',
                invalid: false,
                adminItems:[],
                system_filters:[],
                page: 1,
                pageCount: 0,
                totalVisiblePag: 7,
                options: {
                    ...FilterView.data().options,
                    sortBy: ['id']
                },
                disabledCount: 0,
                deal: null,
                dealItems: [],
                dealSearching: null,
                loadingDeals: false,
                dialogDeal: false,

                uploadFile: null,
                fileDownload: null,
                dialogFile: false,
                textFileError: null,
                dialogFileError: false,
                dialogDetails: false,
                company: null,
                companyItems: [],
                companySearching: null,
                loadingCompanies: false,


                tracking: null,
                trackings: [],
                trackingItems: [],
                sortBy: "id",
                sortDir: false,
                perPage: 5,

                totalTrackings: 0,

                loading: false,
                singleSelect: false,
                dialogCompanyToTracking: false,
                selected: [],
                headers: [
                    {
                        text: this.$t('date_of_deployment'),
                        align: "left",
                        value: "date_operation"
                    },
                    {
                        text: this.$t('number_deal'),
                        align: "left",
                        value: "number_deal"
                    },
                    {
                        text: this.$t('container_number'),
                        align: "left",
                        value: "container_number"
                    },
                    {
                        text: this.$t('station'),
                        align: "left",
                        value: "station"
                    },

                    {
                        text: this.$t('departures'),
                        align: "left",
                        value: "departure"
                    },
                    {
                        text: this.$t('destination'),
                        align: "left",
                        value: "destination"
                    },
                ],
                number: null,
                platform_number: null,
                platform_number_knr: null,
                filterShow: false,
                detailsItems:[],
                dateFrom: null,
                dateMenuFrom: false,
                dateTo: null,
                dateMenuTo: false,
                dateFromGr: null,
                dateMenuFromGr: false,
                dateToGr: null,
                dateMenuToGr: false,
                dateFromPr: null,
                dateMenuFromPr: false,
                dateToPr: null,
                dateMenuToPr: false,
                application_number: null,
                container_number: null,
                destination: null,
                destinationItems:[],
                destinationSearching: null,
                loadingDestination: false,
                loadingDelivered: false,
                delivered: null,
                departure: null,
                departureItems:[],
                departureSearching: null,
                loadingDepartures: false,
                station: null,
                stationItems:[],
                stationSearching: null,
                loadingStation: false,
                type_container: null,
                typeContainerItems:[],
                loadingTypeContainer: null,
                status: null,
                statusItems:[],
                loadingStatuses: false,
                dateOperationFrom:null,
                menuDateOfDeploymentFrom: false,
                dateOperationTo:null,
                menuDateOfDeploymentTo: false,
                deliveredItems:[
                    {
                        'id': -1,
                        'name': this.$t('no')
                    },
                    {
                        'id': 1,
                        'name': this.$t('yes')
                    },
                ],
                Additional: [
                    {
                        'title': this.$t('all_tracking'),
                        'count': '-',
                        'action': 'all',
                        'can': 1,
                        'url': {
                            path: '/tracking',
                        }
                    },
                    {
                        'title': this.$t('my_tracking'),
                        'count': '-',
                        'action': 'my_tracking',
                        'can': 1,
                        'url': {
                            path: '/tracking',
                            query: {
                                filter: {
                                    my_tracking: {
                                        custom: '1'
                                    }
                                }
                            }
                        }
                    }
                ],
                group1: true,
                group2: true,
                filter_show: false,
                dialogTracking: false,
                date_border: null,
                dateMenuBorder: false,
                date_gr: null,
                dateMenuGr: false,
                date_pr: null,
                dateMenuPr: false,
                date_sending:null,
                menuDateSending: false,
                distance: null,
                sub_status: null,
                subStatusItems:[],
                tracking_uuid:null,
                dialogDetailTracking: false,
                detail_tracking: {},
                tab_type: "data",
                data_info: [
                    {
                        "type": "data",
                        "name" : this.$t('data'),
                        "class": "mr-2",
                        "color": "primary",
                        "icon": 'icon-doc',
                    },
                    {
                        "type": "tracking",
                        "name": this.$t('tracking'),
                        "class": "border_0",
                        "icon": 'icon-binoculars',
                        "color": "color_B3B3B3"
                    },
                    {
                        "type": "event",
                        "name": this.$t('event'),
                        "class": "border_0",
                        "icon": 'icon-check-circle-1',
                        "color": "color_B3B3B3"
                    }
                ],
                tracking_geo: [],
                referencePoints: [],
                showAllMarkers: true,
                mapMarker: null,
                zoom: 12,
                ymaps: null,
                coordinates: [],
                showMap: false,
                daysDifference: 0,
                trackingLogItems: [],
                deal_statusItems: [],
                deal_status_sort: 0,
                dialogFormStation: false,
                tab: 0,
                exist_translations: {},
                all_translations: true,
                country: null,
                countryItems: [],
                code: null,
                station_name: null,
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'language',  'listLanguages', 'lang', 'perPageItems']),
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
            coords: {
                get: function () {
                    return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [51, 71]
                },
                set: function (val) {
                    this.coordinates = (val && val.length === 2) ? val : [51, 71]
                }
            },
            mapSettings() {
                this.$yandex.lang = 'ru_RU'
                return this.$yandex
            },
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.init();
        },
        watch: {
            options: {
                handler() {
                    this.getTrackings()
                },
                deep: false
            },
            companySearching: debounce(function (val) {
                if (val && !this.company) {
                    this.getCompanies(val)
                }
            }, 500),
            departureSearching: debounce(function (val) {
                if (val && !this.departure) {
                    this.getDepartures(val)
                }
            }, 500),
            stationSearching: debounce(function (val) {
                if (val && !this.station) {
                    this.getStations(val)
                }
            }, 500),
            destinationSearching: debounce(function (val) {
                if (val && !this.destination) {
                    this.getDestination(val)
                }
            }, 500),
            dealSearching: debounce(function (val) {
                if (val) {
                    this.getDeals(val)
                }
            }, 500),
        },
        methods: {
            async init() {
                await this.getStatus();
                await this.getTypeContainer();
                await this.getFilters();
                await this.getTrackings();
            },
            rowClass(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table6' : 'rowMobiClass';
            },
            showDeal(uuid) {
                this.$router.push({
                    name: 'deal.show',
                    params: {
                        id: uuid
                    }
                })
            },
            addTracking(uuid) {
                this.$router.push({
                    name: 'tracking.create',
                })
            },

            async getTracking(uuid) {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/tracking/${uuid}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.tracking_uuid = res.body.data.uuid
                        this.container_number = res.body.data.container_number
                        this.platform_number = res.body.data.platform_number
                        this.platform_number_knr = res.body.data.platform_number_knr
                        this.date_sending = res.body.data.date_sending ? this.$moment(res.body.data.date_sending).format('YYYY-MM-DD') : null;
                        this.date_border = res.body.data.date_border ? this.$moment(res.body.data.date_border).format('YYYY-MM-DD') : null;
                        this.date_gr = res.body.data.date_gr ? this.$moment(res.body.data.date_gr).format('YYYY-MM-DD') : null;
                        this.date_pr = res.body.data.date_pr ? this.$moment(res.body.data.date_pr).format('YYYY-MM-DD') : null;
                        this.distance = res.body.data.distance
                        this.station = (res.body.data.station && res.body.data.station.id) ? res.body.data.station.id : null;
                        this.stationItems = [res.body.data.station]
                        this.type_container = (res.body.data.type_container && res.body.data.type_container.id) ? res.body.data.type_container.id : null
                        this.loading_type = (res.body.data.loading_type && res.body.data.loading_type.id) ? res.body.data.loading_type.id : null;
                        this.type_transport = (res.body.data.type_transport && res.body.data.type_transport.id) ? res.body.data.type_transport.id : null
                        this.status = (res.body.data.status && res.body.data.status.id) ? res.body.data.status.id : null;
                        this.sub_status = (res.body.data.sub_status && res.body.data.sub_status.id) ? res.body.data.sub_status.id : null
                        this.subStatusItems = [res.body.data.sub_status]
                        this.deal_statusItems = res.body.data.deal_statuses
                        this.deal_status_sort = res.body.data.deal_status_sort
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_tracking'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveTracking() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.container_number) {
                    formData.append('container_number', this.container_number)
                }
                if (this.platform_number) {
                    formData.append('platform_number', this.platform_number)
                }
                if (this.platform_number_knr) {
                    formData.append('platform_number_knr', this.platform_number_knr)
                }
                if (this.date_sending) {
                    formData.append('date_sending', this.date_sending)
                }
                if (this.date_border) {
                    formData.append('date_border', this.date_border)
                }
                if (this.date_gr) {
                    formData.append('date_gr', this.date_gr)
                }
                if (this.date_pr) {
                    formData.append('date_pr', this.date_pr)
                }
                if (this.distance) {
                    formData.append('distance', this.distance)
                }

                if (this.station) {
                    if (this.station.id) {
                        formData.append('station', this.station.id)
                    } else {
                        formData.append('station', this.station)
                    }
                }
                if (this.type_container) {
                    if (this.type_container.id) {
                        formData.append('type_container', this.type_container.id)
                    } else {
                        formData.append('type_container', this.type_container)
                    }
                }
                if (this.loading_type) {
                    if (this.loading_type.id) {
                        formData.append('loading_type', this.loading_type.id)
                    } else {
                        formData.append('loading_type', this.loading_type)
                    }
                }
                if (this.type_transport) {
                    if (this.type_transport.id) {
                        formData.append('type_transport', this.type_transport.id)
                    } else {
                        formData.append('type_transport', this.type_transport)
                    }
                }
                if (this.status) {
                    if (this.status.id) {
                        formData.append('status', this.status.id)
                    } else {
                        formData.append('status', this.status)
                    }
                }
                if (this.sub_status) {
                    if (this.sub_status.id) {
                        formData.append('sub_status', this.sub_status.id)
                    } else {
                        formData.append('sub_status', this.sub_status)
                    }
                }

                if (this.tracking_uuid ) {
                    await this.$http
                        .put(`admin/tracking_station/${this.tracking_uuid}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('tracking_has_been_updated'))
                            this.closeDialogTracking();
                            this.getTrackings();

                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('tracking_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            showDialogTracking(item){
                this.getTracking(item.uuid)
                this.dialogTracking = true;
            },
            closeDialogTracking(){
                this.dialogTracking = false;
                this.tracking_uuid = null;
                this.container_number = null;
                this.platform_number = null;
                this.platform_number_knr = null;
                this.date_sending = null;
                this.date_border = null;
                this.date_gr = null;
                this.date_pr = null;
                this.distance = null;
                this.station = null;
                this.stationItems = [];
                this.type_container = null;
                this.loading_type = null;
                this.type_transport = null;
                this.status =  null;
                this.sub_status =  null;
                this.subStatusItems = []

            },
            editApplication(item){
                this.$router.push({
                    name: 'tracking.edit',
                    params: {
                        uuid: item.uuid
                    }
                })
            },
            selectAllToggle(props) {
                if(this.selected.length !== (this.topics.length - this.disabledCount)) {
                    this.selected = [];
                    const self = this;
                    props.items.forEach(item => {
                        if(!item.number_deal) {
                            self.selected.push(item);
                        }
                    });
                } else this.selected = [];
            },
            async getDeals(str) {
                this.loadingDeal = true
                let params = {};
                params.filter = 'not_tracking'
                if (str !== 'undefined') {
                    params.number = str;
                }

                await this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.dealItems = res.body.data;
                    })
                    .catch(err => {
                        this.dealItems = []
                        this.$toastr.error(this.$t('failed_to_get_deals'))
                    })
                    .finally(end => {
                        this.loadingDeal = false
                    })
            },
            clearDeals() {
                if (!this.deal) {
                    this.dealItems = []
                }
            },
            closeDealToApplication(){
                this.dialogDeal  = false
                this.deal = null
                this.dealItems = []
                this.dealSearching = null

            },
            async addDealApplication(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                this.selected.forEach(function callback(item, key) {
                    formData.append(`applications_uuid[${key}]`, item.uuid)
                });

                if (this.deal ) {
                    formData.append('deal', this.deal )
                }
                // Add
                await this.$http
                    .post('admin/tracking/deal', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.closeDealToApplication();
                        this.selected = [];
                        this.getTrackings()
                    })
                    .catch(err => {
                        this.closeDealToApplication();
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            openDialogDealApplication(){
                this.deal = null
                this.dealItems =  []
                this.dealSearching = null
                this.dialogDeal   = true
            },

            cancelDetails(){
                this.dialogDetails = false;
            },

            showDetailsApplication(item) {
                this.detail_tracking = {};
                this.detail_tracking = item;
                this.dialogDetailTracking = true;
            },
            close_info() {
                this.detail_tracking = {};
                this.dialogDetailTracking = false;
                this.tab_type = 'data';
            },
            show_info(type) {
                this.tab_type = type;
                if (type === 'tracking') {
                    this.getTrackingDeal()
                }
                else if (type === 'event') {
                    this.getTrackingLog()
                }

            },
            async getTrackingDeal() {
                var _this = this;
                this.loading = true;

                let params = {};


                await this.$http
                    .get("admin/tracking/deal/" + this.detail_tracking.deal_uuid, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.referencePoints = []
                        this.tracking_geo = res.body.data.tracking[0];
                        this.coordinates = this.tracking_geo.from.point

                        this.referencePoints.push(this.tracking_geo.from.point);
                        if (this.tracking_geo && this.tracking_geo.tracking) {
                            for (var i = 0; i < this.tracking_geo.tracking.length; i++) {
                                this.referencePoints.push(this.tracking_geo.tracking[i].point)
                            }
                        }


                        this.ymapInit()
                    })
                    .catch(err => {
                        this.tracking_geo = []
                        this.$toastr.error(this.$t('failed_to_get_list_tracking'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async getTrackingLog() {
                var _this = this;
                this.loading = true;

                let params = {};

                await this.$http
                    .get("admin/tracking_log/" + this.detail_tracking.uuid, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.trackingLogItems = res.body.data;
                    })
                    .catch(err => {
                        this.trackingLogItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_tracking_logs'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async ymapInit() {
                var _this = this
                if (window.ymaps) {
                    this.showMap = false
                    if (this.$refs.map && this.$refs.map.myMap) {
                        this.$refs.map.myMap.destroy()
                    }
                    document.getElementById("vue-yandex-maps").remove()
                    delete window.ymaps
                }
                await loadYmap({...this.mapSettings, debug: true})
                if (window.ymaps) {
                    window.ymaps.ready(function () {
                        _this.showMap = true
                    })
                }
            },
            initHandler(e) {
                var _this = this;
                if (this.coords && this.coords.length !== 2 && window.ymaps) {
                    var location = window.ymaps.geolocation.get({
                        provider: 'yandex',
                        mapStateAutoApply: true,
                        autoReverseGeocode: true
                    })
                    location.then(
                        function (result) {
                            //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                            e.geoObjects.add(result.geoObjects)
                        },
                        function (err) {
                            console.log('Error: ' + err)
                        }
                    )
                }
                e.geoObjects.options.set('draggable', true)
                _this.myMap = e;
                var multiRoute2 = new window.ymaps.multiRouter.MultiRoute({
                    // Точки маршрута. Точки могут быть заданы как координатами, так и адресом.
                    referencePoints: this.referencePoints,
                }, {
                    // Внешний вид путевых точек.
                    wayPointStartIconColor: "#FFFFFF",
                    wayPointStartIconFillColor: "#B3B3B3",
                    // Внешний вид линии активного маршрута.
                    routeActiveStrokeWidth: 4,
                    routeActiveStrokeStyle: 'solid',
                    routeActiveStrokeColor: "#007EC9",
                    // Внешний вид линий альтернативных маршрутов.
                    routeStrokeStyle: 'dot',
                    routeStrokeWidth: 3,
                    // Автоматически устанавливать границы карты так,
                    // чтобы маршрут был виден целиком.
                    boundsAutoApply: true
                });


                _this.myMap.geoObjects.add(multiRoute2);
                //e.geoObjects.add(multiRoute);

                var fullscreenControl = new window.ymaps.control.FullscreenControl();
                _this.myMap.controls.add(fullscreenControl);
                //  e.controls.add('FullscreenControl');
            },
            addOperationTracking(uuid) {
                this.$router.push({
                    name: 'tracking_operation.create',
                    params: {
                        uuid: uuid
                    }
                })
            },
            async getStatus() {
                this.loadingStatuses = true
                let params = {};
                params.filter = 'search'

                await this.$http
                    .get("admin/status", {
                        params: params,
                    })
                    .then(res => {
                        this.statusItems = res.body.data;
                    })
                    .catch(err => {
                        this.statusItems = []
                        this.$toastr.error(this.$t('failed_to_get_status'))
                    })
                    .finally(end => {
                        this.loadingStatuses = false
                    })
            },
            async getTypeContainer() {
                this.loadingTypeContainer = true
                let params = {};
                params.filter = 'search'

                await this.$http
                    .get("admin/type_container", {
                        params: params,
                    })
                    .then(res => {
                        this.typeContainerItems = res.body.data;
                    })
                    .catch(err => {
                        this.typeContainerItems = []
                        this.$toastr.error(this.$t('failed_to_get_type_container'))
                    })
                    .finally(end => {
                        this.loadingTypeContainer = false
                    })
            },
            async getDepartures(str) {
                this.loadingDepartures = true
                let params = {};
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.station_name = str;
                }

                await this.$http
                    .get("admin/station", {
                        params: params,
                    })
                    .then(res => {
                        this.departureItems = res.body.data;
                    })
                    .catch(err => {
                        this.departureItems = []
                        this.$toastr.error(this.$t('failed_to_get_station'))
                    })
                    .finally(end => {
                        this.loadingDepartures = false
                    })
            },

            clearDepartures() {
                if (!this.departure) {
                    this.departuresItems = []
                }
            },
            async getDestination(str) {
                this.loadingDestination = true
                let params = {};
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.station_name = str;
                }

                await this.$http
                    .get("admin/station", {
                        params: params,
                    })
                    .then(res => {
                        this.destinationItems = res.body.data;
                    })
                    .catch(err => {
                        this.destinationItems = []
                        this.$toastr.error(this.$t('failed_to_get_station'))
                    })
                    .finally(end => {
                        this.loadingDestination = false
                    })
            },
            clearDestination() {
                if (!this.destination) {
                    this.destinationItems = []
                }
            },

            async getStations(str) {
                this.loadingStations = true
                let params = {};
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.station_name = str;
                }

                await this.$http
                    .get("admin/station", {
                        params: params,
                    })
                    .then(res => {
                        this.stationItems = res.body.data;
                    })
                    .catch(err => {
                        this.stationItems = []
                        this.$toastr.error(this.$t('failed_to_get_station'))
                    })
                    .finally(end => {
                        this.loadingStations = false
                    })
            },
            clearStation() {
                if (!this.station) {
                    this.stationItems = []
                }
            },
            async getSubStatus() {
                if(this.status && this.status > 0){
                    this.loadingSubStatuses = true
                    let params = {};
                    params.filter = 'search'
                    params.status_id = this.status

                    await this.$http
                        .get("admin/sub_status", {
                            params: params,
                        })
                        .then(res => {
                            this.subStatusItems = res.body.data;
                        })
                        .catch(err => {
                            this.subStatusItems = []
                            this.$toastr.error(this.$t('failed_to_get_sub_status'))
                        })
                        .finally(end => {
                            this.loadingSubStatuses = false
                        })
                }

            },

            async getTrackings() {

                this.loading = true;

                let params = {};

                params.sortBy = 'id';


                params.sortDir = 'desc';

                params.upload = -1

                params.perPage = this.itemsPerPage;
                params.page = this.page

                if (this.filterValues) {
                    params.fltr = this.filterValues;
                }

                await this.$http
                    .get("admin/tracking", {
                        params: params,
                    })
                    .then(res => {
                        this.filterData = res.body.filter
                        this.trackings = res.body.data
                        this.page = res.body.meta.current_page
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.trackings = []
                        this.$toastr.error(this.$t('failed_to_get_list_tracking'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getCompanies(str) {
                if (str) {
                    this.loadingCompanies = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.company = str
                    }
                    await this.$http
                        .get("admin/company", {
                            params: params,
                        })
                        .then(res => {
                            this.companyItems = res.body.data
                        })
                        .catch(err => {
                            this.companyItems = []
                        })
                        .finally(end => {
                            this.loadingCompanies = false
                        })
                }
            },
            clearCompanies() {
                if (!this.company) {
                    this.companyItems = []
                }
            },
            redirectToCompany(val) {
                if (val) {
                    this.$router.push({
                        name: 'company.profile',
                        params: {
                            id: val
                        }
                    })
                }
            },
            async UploadApplications() {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }

                params.upload = 1

                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.tracking) {
                    params.tracking = this.tracking;
                }

                if (this.client) {
                    if (this.client.id) {
                        params.client = this.client.id
                    } else {
                        params.client = this.client
                    }
                }

                if (
                    this.dateFrom !== undefined &&
                    this.dateFrom != null &&
                    this.dateFrom !== ''
                ) {
                    params.dateFrom = this.dateFrom;
                }
                if (
                    this.dateTo !== undefined &&
                    this.dateTo != null &&
                    this.dateTo !== ''
                ) {
                    params.dateTo = this.dateTo;
                }

                if (
                    this.dateFromGr !== undefined &&
                    this.dateFromGr != null &&
                    this.dateFromGr !== ''
                ) {
                    params.date_of_arrival_at_border_from = this.dateFromGr;
                }
                if (
                    this.dateToGr !== undefined &&
                    this.dateToGr != null &&
                    this.dateToGr !== ''
                ) {
                    params.date_of_arrival_at_border_to = this.dateToGr;
                }

                if (
                    this.dateFromPr !== undefined &&
                    this.dateFromPr != null &&
                    this.dateFromPr !== ''
                ) {
                    params.arrival_date_at_destination_from = this.dateFromPr;
                }
                if (
                    this.dateToPr !== undefined &&
                    this.dateToPr != null &&
                    this.dateToPr !== ''
                ) {
                    params.arrival_date_at_destination_to = this.dateToPr;
                }


                if (
                    this.dateOperationFrom !== undefined &&
                    this.dateOperationFrom != null &&
                    this.dateOperationFrom !== ''
                ) {
                    params.date_operation_from = this.dateOperationFrom;
                }
                if (
                    this.dateOperationTo !== undefined &&
                    this.dateOperationTo != null &&
                    this.dateOperationTo !== ''
                ) {
                    params.date_operation_to = this.dateOperationTo;
                }


                if (this.delivered) {
                    if (this.delivered.id) {
                        params.delivered = this.delivered.id;
                    } else {
                        params.delivered = this.delivered;
                    }
                }
                if (this.status) {
                    if (this.status.id) {
                        params.status = this.status.id;
                    } else {
                        params.status = this.status;
                    }
                }

                if (this.type_container) {
                    if (this.type_container.id) {
                        params.type_container = this.type_container.id;
                    } else {
                        params.type_container = this.type_container;
                    }
                }

                if (this.number) {
                    params.container_number = this.number;
                }

                if (this.destination) {
                    if (this.destination.id) {
                        params.destination =  this.destination.id
                    } else {
                        params.destination =  this.destination
                    }
                }

                if (this.departure) {
                    if (this.departure.id) {
                        params.departure =  this.departure.id
                    } else {
                        params.departure =  this.departure
                    }
                }

                if (this.application_number) {
                    params.application_number = this.application_number;
                }

                if (this.platform_number_knr) {
                    params.platform_number_knr = this.platform_number_knr;
                }

                if (this.platform_number) {
                    params.platform_number = this.platform_number;
                }

                await this.$http
                    .get("admin/tracking", {
                        params: params,
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('file_was_uploaded'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_upload_file'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            openDialogFile() {
                this.dialogFile = true;
            },
            closeDialogFile() {
                this.uploadFile = null;
                this.dialogFile = false;
            },
            async saveFile() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                if (this.uploadFile) {
                    formData.append('file', this.uploadFile)
                }

                // Add
                await this.$http
                    .post('admin/tracking/import', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('file_was_uploaded'))
                        this.dialogFile = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_upload_file'))
                        this.dialogFileError = true
                        this.textFileError = err.body.message;
                        /*
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                        */
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            openDialogClientToTracking(){
                this.client = null
                this.companyItems =  []
                this.companySearching = null
                this.dialogCompanyToTracking  = true
            },
            closeClientToTracking(){
                this.client = null
                this.companyItems = []
                this.companySearching = null
                this.dialogCompanyToTracking  = false
            },
            async addCompanyToTracking(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                const itemsUuidTracking = [];
                this.selected.forEach(function callback(item, key) {
                     formData.append(`tracking_uuid[${key}]`, item.uuid)
                });

                if (this.company) {
                    formData.append('company', this.company)
                }
                // Add
                await this.$http
                    .post('admin/tracking/company', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('client_has_been_updated'))
                        this.closeClientToTracking();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('client_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            async saveStation() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }
                if (this.station_name) {
                    formData.append('station_name', this.station_name)
                }
                if (this.code) {
                    formData.append('code', this.code)
                }
                if (this.coords && this.coords.length > 0) {
                    for (let i in this.coords) {
                        formData.append(`location[${i}]`, this.coords[i])
                    }
                }
                if (this.country) {
                    formData.append('country', this.country)
                }

                // Add
                await this.$http
                    .post('admin/station', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('station_has_been_added'))
                        this.closeDialogStation();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('station_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            async getCountries() {
                var _this = this
                let params = {}
                params.list = 'all'
                await this.$http.get(`admin/country`, {
                        params: params,
                    }
                )
                    .then(res => {
                        this.countryItems = res.body.data
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_countries'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
            },
            openDialogStation(){
                this.dialogFormStation = true;
                this.getCountries();
                this.ymapInit()
            },
            closeDialogStation(){
                this.dialogFormStation = false;
                this.code = null;
                this.station_name =  null;
                this.exist_translations = {};
                this.all_translations = true;
                this.showAllMarkers = false;
                this.mapMarker = null;
                this.coordinates = [];
                this.zoom = 12;
                this.country = null;
                this.add_type_station = null;
            },

            initHandler2(e) {
                var _this = this;
                if (this.coords && this.coords.length !== 2 && window.ymaps) {
                    var location = window.ymaps.geolocation.get({
                        provider: 'yandex',
                        mapStateAutoApply: true,
                        autoReverseGeocode: true
                    })
                    location.then(
                        function (result) {
                            //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                            e.geoObjects.add(result.geoObjects)
                        },
                        function (err) {
                            console.log('Error: ' + err)
                        }
                    )
                }
                e.geoObjects.options.set('draggable', true)
                _this.myMap = e;

            },
            onClickMap(e) {
                var _this = this
                this.coords = e.get('coords')
                // var myGeocoder = window.ymaps.geocode(this.coords)
                // myGeocoder.then(
                //     function (res) {
                //         let GeocoderMetaData = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
                //         if (GeocoderMetaData.Address.Components) {
                //             let Components = GeocoderMetaData.Address.Components
                //             let address_full = []
                //             let street_full = []
                //             let district = Components.filter(item => item.kind === 'district')
                //             if (district.length > 0) {
                //                 address_full.push(district[0].name)
                //                 street_full.push(district[0].name)
                //             }
                //             let street = Components.filter(item => item.kind === 'street')
                //             if (street.length > 0) {
                //                 address_full.push(street[0].name)
                //                 street_full.push(street[0].name)
                //             }
                //             let house = Components.filter(item => item.kind === 'house')
                //             if (house.length > 0) {
                //                 address_full.push(house[0].name)
                //                 _this.house = house[0].name
                //             }
                //             _this.street = street_full.join(', ')
                //             _this.address = address_full.join(', ')
                //         }
                //
                //     },
                //     function (err) {
                //         console.log('Error Load GeocoderMetaData')
                //     }
                // )
                //this.zoom = 16
            },
            ondDagendMap(e) {
                var _this = this
                this.coords = e.get('target').geometry.getCoordinates()
                // var myGeocoder = window.ymaps.geocode(this.coords)
                // myGeocoder.then(
                //     function (res) {
                //         let GeocoderMetaData = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
                //         if (GeocoderMetaData.Address.Components) {
                //             let Components = GeocoderMetaData.Address.Components
                //             let address_full = []
                //             let street_full = []
                //             let district = Components.filter(item => item.kind === 'district')
                //             if (district.length > 0) {
                //                 address_full.push(district[0].name)
                //                 street_full.push(district[0].name)
                //             }
                //             let street = Components.filter(item => item.kind === 'street')
                //             if (street.length > 0) {
                //                 address_full.push(street[0].name)
                //                 street_full.push(street[0].name)
                //             }
                //             let house = Components.filter(item => item.kind === 'house')
                //             if (house.length > 0) {
                //                 address_full.push(house[0].name)
                //                 _this.house = house[0].name
                //             }
                //             _this.street = street_full.join(', ')
                //             _this.address = address_full.join(', ')
                //         }
                //
                //     },
                //     function (err) {
                //         console.log('Error Load GeocoderMetaData')
                //     }
                // )
            },
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
        }
    }
</script>

<style scoped>


.v-card__text{
    font-size: inherit;
}
.deal_col_50{
    width: 50% !important;
    max-width: 50% !important;
}
.deal_bold{
    font-weight: bold;
}
.color_grey{
    color: rgba(64, 64, 64, 0.6) !important
}
.color_grey_header{
    color: rgba(77, 77, 77, 0.38) !important
}
.tracking_link{
    font-weight: bold;
}




.roadmap_wrapper {
    overflow-x: auto;
    white-space: nowrap;
}


.roadmap {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 60px 0 0 0;
    height: 50px;

}
.li1_2 {
    text-align: center;
    position: relative;

}
.li1_2 p{
    position: absolute;
    top: -60px;
    min-width: 200px;
    left: 0;
    text-align: left;
}
.node_2 {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 5px;
}
.blue_point_2 {
    background-color: #3498db;
}
.grey_point_2 {
    background-color: #bdc3c7;
}
.my_divider_2 {
    height: 1px;
    position: relative;
    top: 0;
    min-width: 287px !important;
}
.my_divider_3 {
    height: 1px;
    position: relative;
    top: 0;
    min-width: 590px !important;
}
.blue_line_2 {
    background-color: #3498db;
}
.grey_line_2 {
    background-color: #bdc3c7;
}
.li_last_bold {
    font-weight: bold;
}
.li_last_finish_2 {
    left: -190px !important;
    text-align: right !important;
}
.blue_color_2 {
    color: #3498db;
}
.grey_color_2 {
    color: #bdc3c7;
}

.grey_date_2{
    color: #B3B3B3 !important;
}

.li_last_center_2{
    left: -98px !important;
    text-align: center !important;
}

.li_last{
    left: -95px !important;
}

li.my_divider{
    margin-top: 3px !important;
    height: 1px !important;
}
.node{
    height: 8px !important;
    width: 8px !important;
}
.no_color{
    color: #b3b3b3 !important;
}

.no_color .deal_bold, .no_color i, .no_color span, .no_color ul li.my_divider, .no_color a, .no_color div{
    color: #b3b3b3 !important;
}
.border_card{
    border: solid 1px #007EC9 !important;
}

ul li span.black_color{
    color: #000000 !important;
}
.black_point{
    background-color: black;
}
@media (max-width: 960px) {
    .v-toolbar{
        flex: none;
    }
    .tabs-nav{
        margin-bottom: 8px;
    }

    .my_divider_3{
        min-width: 93% !important
    }

    .tabs-nav__item{
        margin-right: 0;
    }
    .distance_0{
        padding: 10px 0 20px 0 !important;
    }


}
</style>
