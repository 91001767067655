export default {
    data() {
        return {
            organizations: [],
        }
    },

    methods: {
        initOrganizations() {
            this.fetchOrganizations()
                .then((organizations) => {
                    this.organizations = organizations;
                });
        },

        fetchOrganizations(params = {}) {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/organization', params)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
}
