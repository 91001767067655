<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveItem">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('warehouse.editing') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="10">
                                    <ValidationProvider :ref="`data[name]`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            :id="`name`"
                                            :label="$t('warehouse.name')"
                                            v-model="data.name"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="2">
                                    <ValidationProvider :ref="`data[sort]`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            :id="`sort`"
                                            :label="$t('warehouse.sort')"
                                            v-model="data.sort"
                                            type="number"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider :ref="`data[description]`" rules="min:1" v-slot="{ errors, valid }">
                                        <Textarea
                                            :id="`description`"
                                            :label="$t('warehouse.description')"
                                            v-model="data.description"
                                            :error="!valid"
                                        >
                                        </Textarea>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider :ref="`data[address]`" rules="min:1" v-slot="{ errors, valid }">
                                        <Textarea
                                            :id="`address`"
                                            :label="$t('warehouse.address')"
                                            v-model="data.address"
                                            :error="!valid"
                                        >
                                        </Textarea>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <yandex-map
                                        :coords="[data.location.lat, data.location.lon]"
                                        :zoom="4"
                                        @click="onMapClick"
                                        :controls="['searchControl', 'zoomControl']"
                                    >
                                        <ymap-marker
                                            ref="mapMarker"
                                            marker-id="warehouse"
                                            marker-type="placemark"
                                            :coords="[data.location.lat, data.location.lon]"
                                            :options="{
                                                draggable: true
                                            }"
                                            @dragend="onMarkerDrugEnd"
                                        />
                                    </yandex-map>
                                </v-col>
                                <v-col>
                                    <ValidationProvider :ref="`data[location][lat]`" rules="min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            :id="`location.lat`"
                                            :label="$t('warehouse.location.lat')"
                                            v-model="data.location.lat"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider :ref="`data[location][lon]`" rules="min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            :id="`location.lon`"
                                            :label="$t('warehouse.location.lon')"
                                            v-model="data.location.lon"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="buttons-block">
                            <Btn type="submit" :disabled="invalid || loading" :loading="loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                {{ $t('save') }}
                            </Btn>
                            <Btn
                                :disabled="loading"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </Btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import Btn from "@/components/Form/Btn.vue";
import TextInput from "@/components/Form/TextInput.vue";
import Textarea from "@/components/Form/Textarea.vue";
import {loadYmap, yandexMap, ymapMarker} from 'vue-yandex-maps'

export default {
    name: 'WarehouseForm',
    components: {
        Textarea,
        Btn,
        ValidationProvider,
        ValidationObserver,
        TextInput,
        yandexMap,
        ymapMarker
    },

    inject: ['forceRerender'],

    props: {
        uuid: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,

            data: {
                name: '',
                address: '',
                description: '',
                sort: '500',
                location: {
                    lat: '46.8701',
                    lon: '67.5794'
                }
            }
        }
    },

    computed: {
        ...mapGetters(['listLanguages', 'lang']),

        languages() {
            return this.listLanguages
        },
    },

    watch: {
        uuid: {
            handler: function (value) {
                if (value === 'create') {
                    this.heading = this.$t('warehouse.add')
                } else {
                    this.heading = this.$t('warehouse.edit')
                    this.fetchItem()
                }
            },

            immediate: true
        }
    },

    async mounted() {
        this.language = this.languages[this.tab]
    },

    methods: {
        async checkCreate() {

        },

        closeDialogAdd(){
            this.$router.push({
                name: 'warehouse',
            })
        },

        async fetchItem() {
            let _this = this;
            let params = {}

            this.progress = 0
            this.loading = true

            if (this.language) {
                params.language = this.language
            }

            await this.$http
                .get(`admin/warehouse/warehouse/${this.uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res && res.body && res.body.data) {
                        this.handleResponse(res.body.data)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('warehouse.fetching-error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async saveItem() {
            let _this = this;


            this.progress = 0
            this.loading = true

            if (this.uuid === 'create') {
                // Add
                let formData = new FormData()
                for(let field in this.data) {
                    let value = this.data[field]

                    if(field === 'location') {
                        formData.append(`${field}[lat]`, value.lat);
                        formData.append(`${field}[lon]`, value.lon);
                        continue;
                    }

                    formData.append(`${field}`, value);
                }

                await this.$http
                    .post('admin/warehouse/warehouse', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('warehouse.added'))
                        this.$router.push({
                            name: 'warehouse'
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('warehouse.add_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                let data = {};
                for(let field in this.data) {
                    let value = this.data[field]

                    if(field === 'location') {
                        data[`${field}`] = {
                            lat: value.lat,
                            lon: value.lon
                        };

                        continue;
                    }

                    data[`${field}`] = value;
                }

                await this.$http
                    .put(`admin/warehouse/warehouse/${this.uuid}`, data, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('warehouse.updat_success'));
                        this.$router.push({
                            name: 'warehouse'
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('warehouse.update_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },

        handleResponse(res) {
            this.data.name = res.name
            this.data.sort = res.sort + ''
            this.data.address = res.address
            this.data.description = res.description
            this.data.location = {
                lat: res.location.coordinates[1] + '',
                lon: res.location.coordinates[0] + '',

            }
        },

        onMapClick(e,a,b,c) {
            console.log(e,a,b,c);
        },

        onMarkerDrugEnd(e) {
            let coords = e.originalEvent.target.geometry.getCoordinates();
            this.data.location = {
                lat: coords[0].toFixed(4) + '',
                lon: coords[1].toFixed(4) + ''
            };
        }
    }
}
</script>
