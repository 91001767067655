<script>
import debounce from "lodash/debounce";

export default {
    name: "DealSelect",

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        errors: {
            type: Array,
            default: () => []
        },
        hideLabel: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: false
        },
        id: {
            type: String,
            required: true
        },
        value: {
            required: false,
            default: ''
        },
    },

    data() {
        return {
            items: [],
            searchingText: null,
            loading: false,
            valid: true,
            innerValue: null,
        };
    },

    watch: {
        value(val) {
            this.innerValue = val;
        },
        innerValue(val) {
            this.$emit('input', val);
        },
        searchingText: debounce(function (val) {
            if (val && !this.value) {
                this.getItems(val)
            }
        }, 500),
    },

    methods: {
        clearUveds() {
            this.items = [];
            this.value = null;
        },

        getItems(str) {
            if (str) {
                let params = {}

                params.filter = 'search'

                if (str !== 'undefined') {
                    params.deal = str
                }

                this.loading = true
                this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.items = res.body.data
                    })
                    .catch(err => {
                        this.items = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        selectValue(value) {
            this.innerValue = value
            this.items = [value]
        },

        clear() {
            this.searchingText = null;
            this.innerValue = null;
            this.items=[];
        }
    }
}
</script>

<template>
    <div>
        <label class="input_label" v-if="!hideLabel" :for="id">{{ label }}</label>
        <v-autocomplete
            v-model="innerValue"
            :items="items"
            :error="!valid"
            :search-input.sync="searchingText"
            item-text="name"
            item-value="id"
            :loading="loading"
            :no-data-text="searchingText ? $t('nothing_found_by',{'search': searchingText}) : $t('nothing_found_name')"
            :label="$t('uved.self')"
            @click:clear="clear"
            @click="clearUveds"
            @click:append="redirectToUved(value)"
            autocomplete="off"
            hide-details
            :error-messages="errors"
            :disabled="loading"
            outlined
            solo
            flat
            dense
            color="primary"
            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
            clearable
            return-object
        >
            <template v-slot:item="{ attrs, item, parent, selected }">
                <span class="search-list" @click="selectValue(item)">
                    <span v-html="item.name"></span>
                    <span
                        class="ml-2 hidden-is-empty"
                        v-text="item.bin_iin"
                    ></span>
                </span>
            </template>
            <template
                v-slot:selection="{ attrs, item, parent, selected }"
            >
                <span class="search-list">
                    <span v-html="item.name"></span>
                    <span
                        class="ml-2 hidden-is-empty"
                        v-text="item.bin_iin"
                    ></span>
                </span>
            </template>
        </v-autocomplete>
    </div>
</template>

<style scoped lang="scss">

</style>
