<script>
import TextInput from "@/components/Form/TextInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import NomenclatureSelection from "@/components/Warehouse/NomenclatureSelection.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import WithVats from "@/plugins/mixins/WithVats";

export default {
    name: "BuyerOrderItemEdit",

    components: {ValidationProvider, ValidationObserver, NomenclatureSelection, SelectInput, TextInput},

    mixins: [
        WithVats
    ],

    model: {
        prop: 'value',
        event: 'input',
    },

    props: {
        value: {
            required: true,
        },
        buyerOrder: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            order: null,

            vat: null,
            nomenclaturePrices: [],
            stock: null,

            nomenclature: null,
            quantity: null,
            price: null,
            vat_id: null,
            priceSearch: null,
            discount: null,
        }
    },

    computed: {
        sum() {
            return this.quantity * this.price;
        },

        sumDiscount() {
            let r = this.sum * this.discount / 100
            return r.toFixed(2);
        },

        sumWithDiscount() {
            return this.sum - this.sumDiscount;
        },

        vatSum() {
            let r = this.total * this.vat.value / (100 + this.vat.value);
            return r.toFixed(2);
        },

        sumWithoutVat() {
            let r = this.sum - this.vatSum
            return r.toFixed(2);
        },

        total() {
            return this.sumWithDiscount;
        },
    },

    watch: {
        nomenclature: function (nomenclature) {
            if (nomenclature) {
                this.onNomenclatureSelect(nomenclature)
            }
        },

        vat_id: function (vatId) {
            this.vat = this.vats.find(vat => vat.id === vatId)
        },

        buyer_order: {
            handler: function (uuid) {

            },
            immediate: true
        }
    },

    methods: {
        onNomenclatureSelect: function (nomenclature) {
            this.fetchNomenclaturePrice(nomenclature.uuid)
                .then(price => {
                    this.nomenclaturePrices = price;
                    this.vat_id = this.nomenclature.vat_id
                })
                .catch(() => {

                });


            this.fetchNomenclatureStockForWarehouse(nomenclature.uuid, this.buyerOrder.warehouse_id)
                .then(stock => {
                    if (stock && stock[0]) {
                        this.stock = stock[0].quantity;
                    }
                })
                .catch(() => {

                });
        },

        onSaveDocumentItemBtnClick() {
            let data = {
                nomenclature_id: this.nomenclature.id,
                vat_id: this.vat_id,
                quantity: this.quantity,
                price: this.price,
                discount: this.discount
            };

            this.saveDocumentItem(this.buyerOrder.uuid, data)
                .then((data) => {
                    this.$emit('saved', data);
                })
                .catch(() => {

                });
        },

        calcSum({
            quantity,
            price,
            discount
        }) {
            return quantity * price - (price * discount / 100);
        },

        calcVat({
            quantity,
            price,
            discount,
            vat
        }) {
            let result = this.calcSum({
                quantity,
                price,
                discount
            }) * vat / 100;
            return Math.round(result);
        },

        saveDocumentItem(uuid, data) {
            return new Promise((resolve, reject) => {
                this.$http
                    .post(`admin/warehouse/buyer_order/${uuid}/item`, data)
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('buyer_order_item.save_error'))
                        reject(err)
                    });
            });
        },

        fetchNomenclaturePrice(nomenclatureUuid) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/nomenclature/${nomenclatureUuid}/price`)
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('nomenclature.price_fetch_error'))
                        reject(err)
                    });
            });
        },

        fetchNomenclatureStockForWarehouse(nomenclatureUuid, warehouseId) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/nomenclature/${nomenclatureUuid}/stock`, {
                        params: {
                            warehouse_id: warehouseId
                        }
                    })
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('nomenclature.price_fetch_error'))
                        reject(err)
                    });
            });
        },
    }
}
</script>

<template>
    <v-dialog :value="value" @input="$emit('input', $event)" max-width="992">
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <v-card>
                <v-card-title>
                    {{ $t('buyer_order.select_nomenclature') }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <NomenclatureSelection
                                v-model="nomenclature"
                                :label="$t('nomenclature.self')"
                                id="nomenclature"
                            />
                        </v-col>
                        <template v-if="nomenclature">
                            <v-col cols="12" md="4">
                                <ValidationProvider ref="quantity" rules="required|integer|min_value:1" v-slot="{ errors, valid }">
                                    <TextInput
                                        id="quantity"
                                        :label="$t('quantity')"
                                        v-model="quantity"
                                        :error="!valid"
                                        :disabled="!nomenclature"
                                    />
                                    <v-chip v-if="stock !== null" class="ml-2 mt-2" small @click="quantity = stock">
                                        {{ $t('nomenclature.free_stock') }}: {{ stock }}
                                    </v-chip>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="4">
                                <ValidationProvider ref="price" rules="required|integer|min:1" v-slot="{ errors, valid }">
                                    <TextInput
                                        id="price"
                                        :label="$t('nomenclature.price')"
                                        v-model="price"
                                        :error="!valid"
                                        :disabled="!nomenclature"
                                    />
                                    <template v-for="p in nomenclaturePrices">
                                        <v-chip class="ml-2 mt-2" small :key="p.id" @click="price = p.value" v-if="p.value">
                                            {{ p.name }}: {{ p.value }}
                                        </v-chip>
                                    </template>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="4">
                                <ValidationProvider ref="discount" rules="integer|min_value:0" v-slot="{ errors, valid }">
                                    <TextInput
                                        id="discount"
                                        :label="`${$t('discount')} %`"
                                        v-model="discount"
                                        :error="!valid"
                                    />
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <SelectInput
                                    :label="$t('vat.self')"
                                    id="vat_id"
                                    v-model="vat_id"
                                    :items="vats.map(item => ({ value: item.id, text: item.value }))"
                                    :disabled="!nomenclature"
                                />
                            </v-col>
                            <v-col cols="12" class="text-right d-flex justify-end">
                                <table v-if="quantity && price">
                                    <tr>
                                        <td>{{ $t('total_amount_discount') }}:</td>
                                        <td>{{ sumDiscount }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('total_amount_without_discount') }}:</td>
                                        <td>{{ sum }}</td>
                                    </tr>
                                    <tr style="font-weight: bold;">
                                        <td>{{ $t('total_amount') }}:</td>
                                        <td>{{ total }}</td>
                                    </tr>

                                    <tr>
                                        <td>{{ $t('total_amount_without_vat') }}:</td>
                                        <td>{{ sumWithoutVat }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('total_amount_vat') }}:</td>
                                        <td>{{ vatSum }}</td>
                                    </tr>
                                </table>
                            </v-col>
                        </template>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="$emit('input', false)"
                    >
                        {{ $t('close') }}
                    </v-btn>
                    <v-btn
                        color="secondary"
                        @click="onSaveDocumentItemBtnClick"
                        :disabled="invalid"
                    >
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>
