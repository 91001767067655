<template>
    <div>
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <v-dialog
                persistent
                max-width="960px"
                transition="dialog-bottom-transition"
                v-model="localDialogForm"

            >
                <v-form @submit.prevent="saveDocument">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('document_creation') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary">
                                                mdi-close
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col class="py-0" cols="12">
                                    <form-dialog :ref="'form_'+form.sort" v-for="form in forms" :key="form.sort"
                                                 :form="form"/>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="recipients" rules="min:1" v-slot="{ errors, valid }">
                                        <SelectOverflowInput
                                            v-model="uved"
                                            id="uved"
                                            :label="$t('columns.document.our_company')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            :items="uvedItems"
                                            item-value="id"
                                            item-text="name"
                                        ></SelectOverflowInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="recipients" rules="min:1" v-slot="{ errors, valid }">
                                        <SelectOverflowInput
                                            v-model="provider"
                                            id="provider"
                                            :label="$t('columns.document.provider')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            :items="providerItems"
                                            item-value="id"
                                            item-text="name"
                                        ></SelectOverflowInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="recipients" rules="required|min:1"
                                                        v-slot="{ errors, valid }">
                                        <UserSelection
                                            multiple
                                            v-model="recipients"
                                            id="recipients"
                                            :label="$t('recipients')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            clearable
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <FileUpload
                                        :documents="documents_flows"
                                        :loading="loading"
                                        @file-dropped="handleFileDropped"
                                        @preview-files="accompanyingDocumentsPreviewFiles"
                                    />
                                </v-col>
                                <v-col v-if="document_flow_urls.length > 0">
                                    <v-list subheader>
                                        <v-list-item class="mt-5" v-for="(file, index) in document_flow_urls"
                                                     :key="'document_flow_'+index">
                                            <v-list-item-icon>
                                                <v-img
                                                    max-width="45"
                                                    :alt="`${file.name}`"
                                                    :src="file.url"
                                                    max-height="30"
                                                ></v-img>
                                                <v-icon
                                                    x-large
                                                    v-if="!mimeTypeImage(file.type)">mdi-file-document-outline mdi-24px
                                                </v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                                <v-list-item-title
                                                    v-text="file.name +' ('+(file.size / 1000).toFixed(1) + ' KB)'"></v-list-item-title>
                                                <v-list-item-subtitle
                                                    v-text="file.document.type_name"></v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="file.comment !== ''">
                                                    <span class="cursor-pointer"
                                                          @click="editComment(file)"> {{ file.comment }}</span>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-else>
                                                    <v-row v-if="!file.comment_show">
                                                        <v-col cols="12">
                                                            <div class="cursor-pointer" @click="addComment(file)">
                                                                {{ $t('add_comment') }}
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row v-if="file.comment_show">
                                                        <v-col cols="12">
                                                            <ValidationProvider :ref="file.comment_new"
                                                                                rules="required|min:1|max:65535"
                                                                                v-slot="{ errors, valid }">
                                                                <div class="input_label">{{ $t('comment') }}</div>
                                                                <v-textarea v-model="file.comment_new"
                                                                            type="text"
                                                                            :error="!valid"
                                                                            :error-messages="errors"
                                                                            :disabled="loading"
                                                                            :label="$t('comment')" rows="5"
                                                                            color="primary"
                                                                            auto-grow
                                                                            clearable outlined
                                                                            hide-details
                                                                            full-width
                                                                            background-color="white lighten-2"
                                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                                </v-textarea>
                                                            </ValidationProvider>
                                                        </v-col>
                                                        <v-col>
                                                            <Btn large @click="saveComment(file)"
                                                                 :disabled="invalid || loading" :loading="loading"
                                                                 color="primary">
                                                                {{ $t('save') }}
                                                            </Btn>
                                                            <Btn plain :disabled="loading" @click="addComment(file)"
                                                                 color="primary">
                                                                {{ $t('cancel') }}
                                                            </Btn>
                                                        </v-col>
                                                    </v-row>

                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-icon class="d-flex mt-2"
                                                              :class="!file.comment_show ? ' align-self-center' : ''">
                                                <v-btn
                                                    icon
                                                    @click="deleteAccompanyingDocumentFile(file)"
                                                >
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>

                                            </v-list-item-icon>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>


                        </v-card-text>
                        <v-card-actions class="px-5 pt-10 pb-7">
                            <Btn large type="submit" :disabled="invalid || loading || document_flow_urls.length === 0"
                                 :loading="loading" color="primary">
                                {{ $t('save') }}
                            </Btn>
                            <Btn plain :disabled="loading" @click="closeDialogAdd" color="primary">
                                {{ $t('cancel') }}
                            </Btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>


                    </v-card>
                </v-form>

            </v-dialog>
        </ValidationObserver>

        <ValidationObserver ref="observerAdd" v-slot="{ invalid, validated, passes, validate }">
            <v-dialog
                v-model="documents_flows_dialog"
                scrollable
                persistent
                max-width="500px"
                transition="dialog-bottom-transition"
                style="z-index:600 !important;"
            >

                <v-card z-index="600">
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('send_files', {'count': documents_flows_preview.length}) }}</span>
                        </div>
                        <div>
                            <v-icon
                                @click="documents_flows_dialog = false;documents_flows_preview=[];documents_flows=[]">
                                mdi-close
                            </v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text style="height: 300px;" class="pa-0">
                        <v-list subheader>
                            <v-list-item>
                                <v-row>
                                    <v-col cols="12">
                                        <ValidationProvider ref="document_flow_type" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <SelectInput
                                                id="document_flow_type"
                                                :value="document_flow_type"
                                                v-model="document_flow_type"
                                                item-text="name"
                                                item-value="id"
                                                return-object
                                                :error="!valid"
                                                :error-messages="errors"
                                                :label="$t('document_flow_type')"
                                                :disabled="loading"
                                                :items="documentFlowTypeItems"
                                                @input="parent_id = $event"
                                                clearable
                                            />
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </v-list>
                        <v-list subheader>
                            <v-list-item v-for="(document, index) in documents_flows_preview" :key="'document_'+index">
                                <v-list-item-icon>
                                    <v-img
                                        max-width="90"
                                        :alt="`${document.name}`"
                                        :src="document.url"
                                        max-height="60"
                                    ></v-img>
                                    <v-icon
                                        x-large
                                        v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="document.name"></v-list-item-title>
                                    <v-list-item-subtitle
                                        v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-icon class="d-flex align-self-center mt-2">
                                    <v-btn
                                        icon
                                        @click="deleteDocumentFile(document)"
                                    >
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>

                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <Btn plain :disabled="invalid || loading" :loading="loading" color="primary" text
                             @click="addFile">
                            {{ $t('add') }}
                        </Btn>
                    </v-card-actions>

                </v-card>

            </v-dialog>
        </ValidationObserver>
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import FormDialog from '@/components/Eav/FormDialog.vue';
import UserSelection from "@/components/Form/UserSelection.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import Btn from "@/components/Form/Btn.vue";
import FileUpload from '@/components/FileUpload.vue';
import SelectOverflowInput from "../components/Form/SelectOverflowInput";

export default {
    name: "DocumentFormDialog",
    props: {
        dialogForm: {
            type: Boolean,
            required: true
        },
        document_flow_urls_form: {
            type: Array,
            default: () => []
        },

        closeForm: {
            type: Boolean,
            required: false
        },

    },
    components: {
        ValidationProvider,
        ValidationObserver,
        UserSelection,
        FormDialog,
        SelectInput,
        Btn,
        FileUpload,
        SelectOverflowInput
    },
    inject: ['forceRerender'],
    data() {
        return {
            localDialogForm: this.dialogForm,
            progress: 0,
            loading: false,
            id: null,
            recipients: null,
            documents_flows: [],
            documents_flows_preview: [],
            documents_flows_dialog: false,
            forms: [],
            document_flow_type: null,
            document_flow_urls: [],
            documentFlowTypeItems: [],
            document,
            provider: null,
            providerItems: [],
            uved: null,
            uvedItems: [],
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang', 'language']),
        languages() {
            return this.listLanguages
        },
    },
    watch: {
        dialogForm(newValue) {
            this.localDialogForm = newValue;
        },
        document_flow_urls_form(newValue) {
            if (this.document_flow_urls !== undefined || this.document_flow_urls !== null) {
                this.document_flow_urls = newValue;
                if (this.document_flow_urls[0].name === undefined || this.document_flow_urls[0].name === null || this.document_flow_urls[0].name === '') {
                    this.document_flow_urls[0].name = newValue[0].file_name;
                }
                if (this.document_flow_urls[0].url === undefined || this.document_flow_urls[0].url === null || this.document_flow_urls[0].url === '') {
                    this.document_flow_urls[0].url = newValue[0].value;
                }
            }
        },
    },
    async mounted() {
        if (this.document_flow_urls_form.length > 0) {
            this.document_flow_urls = this.document_flow_urls_form;
        }
        await this.grtDocumentFlowTypes()
        await this.grtProviders()
        await this.grtUveds()
    },
    methods: {
        handleFileDropped(files) {
            this.documents_flows = files;
            this.accompanyingDocumentsPreviewFiles();
        },
        editComment(file) {
            file.comment = '';
            file.comment_show = true
        },
        addComment(file) {
            if (file.comment_show === true) {
                file.comment_show = false;
            } else {
                file.comment_show = true
            }
        },
        saveComment(file) {
            file.comment = file.comment_new;
            this.addComment(file);
        },
        closeDialogAdd() {
            if (this.closeForm === true) {
                this.document_flow_urls = [];
                this.documents_flows_dialog = false;
                this.document_flow_urls = [];
                this.documents_flows = [];
                this.$emit("dialog-form-close", false);
            } else {
                if (window.history.length > 2) {
                    this.$router.back()
                } else {
                    this.$router.push({
                        name: 'document',
                    })
                }
            }


        },
        deleteAccompanyingDocumentFile(item) {
            let index = this.document_flow_urls.indexOf(item)
            this.document_flow_urls.splice(index, 1)
        },
        deleteDocumentFile(item) {
            let index = this.documents_flows_preview.indexOf(item)
            this.documents_flows_preview.splice(index, 1)
            if (this.documents_flows_preview.length === 0) {
                this.documents_flows_dialog = false;
                this.document_flow_urls = [];
                this.documents_flows = []
            }
        },
        async addFile() {
            if (this.documents_flows_preview && this.documents_flows_preview.length > 0) {
                for (let i in this.documents_flows_preview) {
                    this.documents_flows_preview[i].document = {
                        type_name: this.document_flow_type.name,
                        id: this.document_flow_type.id
                    }
                    this.document_flow_urls.push(this.documents_flows_preview[i])
                }
                this.documents_flows_dialog = false;
                this.documents_flows_preview = [];
                this.documents_flows = []
            }


        },
        accompanyingDocumentsPreviewFiles() {
            let i = 0;
            let data = {}
            this.documents_flows.forEach((file) => {
                data = {}
                data.id = i
                data.document = {}
                data.comment = ''
                data.comment_new = ''
                data.comment_show = false
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.documents_flows_preview.push(data)
                i++
            })
            this.documents_flows_dialog = true
        },
        dropFile: function (event) {
            event.stopPropagation();
            event.preventDefault();
            this.documents_flows = Array.from(event.dataTransfer.files);
            this.accompanyingDocumentsPreviewFiles();
        },
        async saveDocument() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            this.forms.reduce((acc, item) => {
                const fields = item.fields;
                const group_id = item.id
                for (let fieldName in fields) {
                    if (Object.hasOwn(fields, fieldName)) {
                        formData.append(`fields[${group_id}][${fieldName}]`, fields[fieldName].default)
                    }
                }
            }, []);


            if (this.provider) {
                formData.append('provider', this.provider)
            }
            if (this.uved) {
                formData.append('uved', this.uved)
            }
            if (this.language) {
                formData.append('language', this.language)
            }


            if (this.recipients && this.recipients.length > 0) {
                for (let i in this.recipients) {
                    if (this.recipients[i].id !== undefined && this.recipients[i].id > 0) {
                        formData.append(`recipients[${i}]`, this.recipients[i].id)
                    }
                }
            }


            if (this.document_flow_urls && this.document_flow_urls.length > 0) {
                let is_image = false;
                for (let i in this.document_flow_urls) {
                    is_image = this.mimeTypeImage(this.document_flow_urls[i].type);
                    formData.append(`comment[${i}]`, this.document_flow_urls[i].comment)
                    formData.append(`document_flow_type[${i}]`, this.document_flow_urls[i].document.id)
                    formData.append(`type_mime[${i}]`, this.document_flow_urls[i].type)
                    formData.append(`size[${i}]`, this.document_flow_urls[i].size)
                    formData.append(`file_name[${i}]`, this.document_flow_urls[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)

                    if (this.document_flow_urls[i].url) {
                        try {
                            // Загружаем файл по URL и получаем Blob
                            const response = await fetch(this.document_flow_urls[i].url);
                            const blob = await response.blob();
                            // Сохраняем blob в объекте для дальнейшего использования
                            this.document_flow_urls[i].blob = blob;
                            // Добавляем blob в formData
                            formData.append(`upfiles[${i}]`, blob, this.document_flow_urls[i].type);
                        } catch (error) {
                            console.error('Ошибка при конвертации URL в Blob:', error);
                        }
                    }

                    if (this.document_flow_urls[i].blob) {
                        this.document_flow_urls[i].blob = await this.convertBlobToBase64(this.document_flow_urls[i].blob)
                        let blob = this.dataURL64toBlob(this.document_flow_urls[i].blob)
                        if (blob) {
                            formData.append(`upfiles[${i}]`, blob, this.document_flow_urls[i].type)
                        }
                    }
                }
            }


            // Add
            await this.$http
                .post('admin/document_flow_author', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('document_flow_has_been_added'))
                    this.$router.push({
                        name: 'document',
                    })
                })
                .catch(err => {
                    this.$toastr.error(this.$t('document_flow_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        async grtDocumentFlowTypes() {

            this.loading = true
            let params = {}
            await this.$http
                .get("admin/document_flow_type", {
                    params: params,
                })
                .then(res => {
                    this.documentFlowTypeItems = res.body.data
                })
                .catch(err => {
                    this.documentFlowTypeItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },

        async grtProviders() {

            this.loading = true
            let params = {}
            await this.$http
                .get("admin/uved/client_type/provider", {
                    params: params,
                })
                .then(res => {
                    this.providerItems = res.body.data
                })
                .catch(err => {
                    this.providerItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async grtUveds() {

            this.loading = true
            let params = {}
            await this.$http
                .get("admin/uved/client_type/uved", {
                    params: params,
                })
                .then(res => {
                    this.uvedItems = res.body.data
                })
                .catch(err => {
                    this.uvedItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },

    }
}
</script>
