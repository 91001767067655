<script>
import {mapGetters} from "vuex";

export default {
    name: "UserAvatar",

    props: {
        img: {
            type: String,
            required: false,
            default: null
        },
        alt: {
            type: String,
            required: false,
            default: ''
        },
        online: {
            type: Boolean,
            required: false,
            default: null
        },
        id: {
            type: Number,
            required: false,
            default: null
        }
    },

    computed: {
        ...mapGetters('admins', ['onlineAdminIds']),

        hasPhoto() {
            return this.img !== null && this.img !== '/img/avatar.png';
        },

        isOnline() {
            if(this.online === null && this.id) {
                return this.onlineAdminIds.includes(this.id);
            }

            return this.online;
        }
    },


}
</script>

<template>
    <v-avatar class="avatar" :class="{'border_blue': !hasPhoto}" :size="$vuetify.breakpoint.smAndUp ? 40 : 30">
        <template v-if="hasPhoto">
            <img :src="img" :alt="alt">
        </template>
        <template v-else>
            <v-icon color="primary" size="20">
                ${{'settingsIcon'}}
            </v-icon>
        </template>
        <div class="avatar__online" v-if="isOnline"></div>
    </v-avatar>
</template>

<style lang="scss">
.v-avatar {
    border-radius: 8px;
    background-color: #fff;
}

.avatar__online {
    position: absolute;
    right: 2px;
    bottom: 2px;
    width: 10px;
    height: 10px;
    background-color: #2AB930;
    border-radius: 100%;
    border: 1px solid #fff;
}
</style>
