<template>
    <PageLayout>
        <template v-slot:default>
            <TabsNav :items="breadcrumbs()[0]['items']"/>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>
                        <template v-if="select_folder.name">
                             {{ select_folder.name }}
                        </template>
                        <template v-else>
                            {{ $t('menu_cloud') }}
                        </template>
                    </h1>
                    <TableSettings
                        :fields="tableColumns"
                        :entityName="entityName"
                        className="ml-2"
                        @column-select="onColumnSelect"
                    />
                </div>
                <div class="text-right">
                    <v-menu
                        bottom
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :disabled="userBlock"

                                color="secondary"
                            >
                                {{ $t('upload') }}
                            </v-btn>
                        </template>
                        <v-list min-width="180">
                            <v-list-item
                                v-for="(item, i) in buttonItems"
                                :key="i"
                                @click="addFileOrFolder(item.action)"
                            >
                                <v-list-item-icon class="mr-2">
                                    <v-icon color="primary" v-text="item.icon" ></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>

            <div class="mobile-filter mb-6" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    :disabled="userBlock"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus
                </v-icon>

                <FilterMobileLinks
                    :links="[
                        ...Additional.map((item) => {
                            return {
                                ...item
                            }
                        }),

                        ...adminItems.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...system_filters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...userFilters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                />
            </div>

            <FilterComponent
                class="mb-10"
                v-if="filter_show"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                entity="file_storage"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in storages"
                    :item="item"
                    :key="item.id"
                    :columns="tableColumns"
                    :userBlock="userBlock"
                    @deleteItem="deleteFileStorage(item)"
                >


                    <template v-slot:files="{ item }">
                        <div class="cursor_pointer">
                            <div v-if="item.type === 'folder'" style="display: flex; align-items: center;"
                                 @click=" item.name === '...' ? prevFiles(item) : showFiles(item)"
                            >
                                <v-icon size="40px"  class="mr-2" color="primary">
                                    icon-folder-empty-1
                                </v-icon>
                                {{ item.name }}
                            </div>
                            <div v-else>
                                <span @click="downloadFile(item.url, item.name)" class="cursor-pointer">{{item.name }}</span>
                                <v-btn icon @click="dialogFoldersOpen(item)">
                                    <v-icon small color="primary">icon-window-next</v-icon>
                                </v-btn>
                                <v-btn x-small icon @click="deleteFileStorage(item)" v-if="item.my_file">
                                    <v-icon color="primary" small>icon-bin</v-icon>
                                </v-btn>
                            </div>
                        </div>


                    </template>


                    <template v-slot:folder="{ item }">
                        <div>{{ item.folder }}</div>
                    </template>


                    <template v-slot:admin_id="{ item }">
                        <div v-if="item.author.name">
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0"
                                                        :class="{'border_blue': item.author.photo === '/img/avatar.png'}">
                                        <img v-if="item.author.photo !== '/img/avatar.png'" :src="item.author.photo"
                                             :alt="item.author.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.author.name"></v-list-item-title>
                                        <v-list-item-subtitle
                                            v-text="formatPhoneNumber(item.author.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>

                    <template v-slot:created_at="{ item }">
                        <div v-if="item.created_at">{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                    </template>


                </ListItem>
            </template>
            <template v-else>
                <ResizableTable
                    class="main-data-table elevation-0 uveds-table"
                    :rows="storages"
                    :columns="tableColumns"
                    :sort-by="options.sortBy[0]"
                    :sort-dir="options.sortDesc[0]"

                    @sort="sortItems"
                    @columnMoved="onColumnsMoved"
                    @columnResized="onColumnResized"
                >


                    <template v-slot:files="{ item }">
                        <div class="cursor_pointer">
                            <div v-if="item.type === 'folder'" style="display: flex; align-items: center;"
                                 @click=" item.name === '...' ? prevFiles(item) : showFiles(item)"
                            >
                                <v-icon size="40px"  class="mr-2" color="primary">
                                    icon-folder-empty-1
                                </v-icon>
                                {{ item.name }}
                            </div>
                            <div v-else class="d-flex">
                                <ContextMenuFile :file="item" @add-document-form ="addDocumentForm"  @open-file="openFileContext" class=" mr-auto"></ContextMenuFile>
                                <div>
                                <v-btn icon @click="dialogFoldersOpen(item)">
                                    <v-icon small color="primary">icon-file-text-refresh</v-icon>
                                </v-btn>
                                <v-btn x-small icon @click="deleteFileStorage(item)" v-if="item.my_file">
                                    <v-icon color="primary" small>icon-bin</v-icon>
                                </v-btn>
                                </div>
                            </div>
                        </div>


                    </template>


                    <template v-slot:folder="{ item }">
                        <div>{{ item.folder }}</div>
                    </template>


                    <template v-slot:admin_id="{ item }">
                        <div v-if="item.author.name">
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0"
                                                        :class="{'border_blue': item.author.photo === '/img/avatar.png'}">
                                        <img v-if="item.author.photo !== '/img/avatar.png'" :src="item.author.photo"
                                             :alt="item.author.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.author.name"></v-list-item-title>
                                        <v-list-item-subtitle
                                            v-text="formatPhoneNumber(item.author.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>

                    <template v-slot:created_at="{ item }">
                        <div v-if="item.created_at">{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                    </template>


                </ResizableTable>
            </template>

            <div class="main-pagination d-flex flex-row justify-end">
                <SelectInput
                    class="main-pagination__per-page"
                    id="pagination-variants"
                    :value="options.itemsPerPage"
                    :items="perPageItems"
                    :label="$t('items_per_page')"
                    :disabled="loading"
                    @input="onPerPageChange($event)"
                    hide-label
                ></SelectInput>
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              class="main-pagination__pagination pagination_table" :length="pageCount"
                              :disabled="loading" @input="onPageChange"></v-pagination>
            </div>


            <v-file-input hide-input prepend-icon=""
                          v-model="files"
                          id="file"
                          multiple
                          @change="previewFiles"
            >
            </v-file-input>


            <v-dialog
                v-model="dialog_file"
                scrollable
                persistent
                transition="dialog-bottom-transition"
                max-width="500px"


            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('send_files', {'count': document_urls.length}) }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialog_file = false;document_urls=[];documents=[]">mdi-close</v-icon>
                        </div>
                    </v-toolbar>

                    <v-card-text style="height: 300px;" class="pa-0">
                        <v-list subheader>
                            <v-list-item v-for="document in document_urls" :key="document.id">
                                <v-list-item-icon>
                                    <v-img
                                        max-width="90"
                                        :alt="`${document.name}`"
                                        :src="document.url"
                                        max-height="60"
                                    ></v-img>
                                    <v-icon
                                        x-large
                                        v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="document.name"></v-list-item-title>
                                    <v-list-item-subtitle
                                        v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-icon class="d-flex align-self-center mt-2">
                                    <v-btn
                                        icon
                                        @click="deleteFile(document)"
                                    >
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>

                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>


                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="SaveFile">
                            {{ $t('sent') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog

                persistent
                max-width="960px"
                transition="dialog-bottom-transition"

                v-if="dialogSharedFolder" v-model="dialogSharedFolder"
            >

                <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('share_access_to_folder') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogFolder()" :title="$t('close')" color="primary">
                                                mdi-close
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="12">
                                    <ValidationProvider ref="shared" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <SelectInput
                                            clearable
                                            item-text="name"
                                            item-value="id"
                                            hide-details
                                            hide-label
                                            :items="sharedItems"
                                            v-model="shared"
                                            name="shared"
                                            id="shared"
                                            :hideLabel="false"
                                            :label="$t('shared_type')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            @input="shared_admin=[]"
                                        ></SelectInput>
                                    </ValidationProvider>

                                </v-col>


                                <v-col cols="12" v-if="shared === 0">
                                    <ValidationProvider ref="shared_admin" rules="required|min:1"
                                                        v-slot="{ errors, valid }">
                                        <UserSelection
                                            multiple
                                            v-model="shared_admin"
                                            id="shared_admin"
                                            :label="$t('staff')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            clearable
                                        />
                                    </ValidationProvider>
                                </v-col>

                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 pt-10 pb-7">
                            <v-btn :disabled="invalid || loading" :loading="loading"
                                   @click="actionSharedFolder(folderData)"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ shared === -1 ? $t('close_access') : $t('open') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogFolder()"
                            >
                                {{ $t('cancel') }}
                            </v-btn>

                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>

            <v-dialog

                persistent
                max-width="960px"
                transition="dialog-bottom-transition"

                v-if="folderData" v-model="dialogFolder"
            >

                <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{
                                                    folderData.id ? $t('folder_editing') : $t('folder_creation')
                                                }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogFolder()" :title="$t('close')" color="primary">
                                                mdi-close
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                    <span :class="exist_translations[language] ? '' : 'red--text '">
                                        {{ language }}
                                    </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="12">
                                    <ValidationProvider ref="folder" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="folderData.name" type="text" :disabled="loading"
                                                      :error="!valid" :error-messages="errors"
                                                      :label="$t('folder_name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 pt-10 pb-7">
                            <v-btn :disabled="invalid || loading" :loading="loading"
                                   @click="saveFolder(folderData)"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogFolder()"
                            >
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>

            <DocumentFormDialog
                :dialogForm="dialogForm"
                :document_flow_urls_form="document_flow_urls_form"
                :closeForm = "true"
                @dialog-form-close = "dialogFormClose"
            ></DocumentFormDialog>

            <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen"/>

            <TreeFolder
                :dialogFolder="dialogFolders"
                :treeRef="'folderTreeRef'"
                :showCheckbox="true"
                :defaultExpandAll="true"
                @getFolder="getFolderSelected"
                @update:dialogFolder="updateDialogFolders"
            />

        </template>

        <template v-slot:side>

            <v-col cols="12" class="pa-0 pt-5">
                <v-card class="side-block">
                    <div>
                        <div class="d-flex">
                            <div class="pa-4 participants mr-auto">
                                {{ $t('my_folders') }}
                            </div>
                            <div class="pa-4">
                                <v-icon
                                    size="18"
                                    color="secondary"
                                    class="add_icon"
                                    @click="addFolder(null, [])"
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                            </div>
                        </div>
                    </div>
                    <v-card-text class="pt-1 px-2" v-if="showTree">
                        <el-tree ref="tree"
                                 :data="folderItems"
                                 node-key="id"
                                 draggable
                                 icon-class="mdi mdi-chevron-right"
                                 :filter-node-method="filterNode"
                                 :props="defaultProps" class="folder-tree elevation-0 p-0 m-0"
                                 :empty-text="$t('loading_please_wait')"
                                 :expand-on-click-node="false"
                                 @node-drop="handleDrop">
                        <span slot-scope="{ node, data }" class="custom-tree-node container_tree align-center">
                            <div class="d-flex text-info">
                                <div @click="showFiles(data)" class="pa-2 mr-auto text-info__value">

                                    {{ data.name }}

                                </div>
                                <div class="d-flex pr-2">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                             <v-icon small color="primary">
                                                 {{ data.folder_shared === 1 ? 'icon-folder-open' : 'icon-folder-empty-1' }}
                                             </v-icon>
                                            <v-btn icon
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   x-small
                                                   class="align-self-center"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="addFolder(node, data)">
                                                <v-list-item-icon  class="mr-2">
                                                    <v-icon small color="primary">icon-folder-add</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>{{ $t('add') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="editFolder(node, data)">
                                                 <v-list-item-icon  class="mr-2">
                                                    <v-icon small color="primary">icon-folder-edit</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-show="data.hide!==1" @click="getSharedFolder(node, data)"
                                                         v-if="data.folder_shared === 1">
                                                 <v-list-item-icon  class="mr-2">
                                                    <v-icon small color="primary">icon-folder-share</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>{{ $t('shared_access') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-show="data.hide!==1" @click="sharedFolder(node, data)"
                                                         v-else>
                                                 <v-list-item-icon  class="mr-2">
                                                    <v-icon small color="primary">icon-folder-share</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>{{ $t('shared_access') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="deleteFolder(node, data)">
                                                 <v-list-item-icon  class="mr-2">
                                                    <v-icon small color="primary">icon-folder-remove</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>

                                </div>
                            </div>
                        </span>
                        </el-tree>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" class="pa-0 pt-5" v-if="showSharedTree">
                <v-card class="side-block">
                    <div>
                        <div class="d-flex">
                            <div class="pa-4 participants mr-auto">
                                {{ $t('shared_folders') }}
                            </div>
                        </div>
                    </div>

                    <v-card-text class="pt-1 pl-2">
                        <el-tree v-if="showSharedTree" ref="tree_shared" :data="folderSharedItems" node-key="id"
                                 draggable
                                 icon-class="mdi mdi-chevron-right" :filter-node-method="filterNode"
                                 :props="defaultProps" class="folder-tree elevation-0 pt-0 mt-0"
                                 :empty-text="$t('loading_please_wait')" :expand-on-click-node="false"
                                 @node-drop="handleDrop">
                            <div slot-scope="{ node, data }" class="custom-tree-node container_tree align-center">
                                <div class="d-flex text-info">
                                    <div @click="showFiles(data)" class="pa-2 mr-auto text-info__value">
                                        {{ data.name }}
                                    </div>


                                </div>
                            </div>
                        </el-tree>


                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </PageLayout>
</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import UserSelection from "@/components/Form/UserSelection.vue";
import TreeFolder from "@/components/TreeFolder.vue";
import FilterView from "@/plugins/mixins/FilterView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import ResourceView from "@/plugins/mixins/ResourceView";
import NavMixin from "@/plugins/mixins/Nav";
import ListItem from "@/components/FileStorage/FileStorageItem.vue";
import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import ContextMenuFile from "@/components/ContextMenuFile.vue";
import DocumentFormDialog from '@/components/DocumentFormDialog.vue';
import OpenFile from '@/components/OpenFile.vue';

export default {
    name: "Storages",
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    components: {
        ValidationProvider,
        ValidationObserver,
        UserSelection,
        SelectInput,
        TreeFolder,
        FilterMobileLinks,
        ListItem,
        PageLayout,
        TabsNav,
        ContextMenuFile,
        DocumentFormDialog,
        OpenFile
    },
    data() {
        return {
            dialogForm: false,
            document_flow_urls_form: [],
            dialogOpenFile: false,
            fileOpen: {},
            storage: null,
            email: null,
            storages: [],
            storageItems: [],
            sortBy: "id",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalStorages: 0,
            entityName: 'file_storage',
            invalid: false,
            options: {
                ...FilterView.data().options,
                sortBy: ['id']
            },
            loading: false,
            headers: [
                {
                    text: this.$t('review'),
                    align: "left",
                    sortable: true,
                    value: "url"
                },
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    sortable: true,
                    value: "author"
                },
                {
                    text: this.$t('folder'),
                    align: "left",
                    sortable: true,
                    value: "folder"
                },
                {
                    text: this.$t('date_created'),
                    align: "left",
                    sortable: true,
                    value: "created_at"
                },
            ],
            filter_show: false,
            showTree: false,
            folderItems: [],
            addFolderData: {},
            defaultProps: {
                children: 'children',
                label: 'name',
                isLeaf: 'leaf'
            },
            node: {},
            nodedata: {},
            dialogFolder: false,
            exist_translations: {},
            all_translations: true,
            tab: 0,
            language: null,
            progress: 0,

            tooltipShowMobile: false,
            tooltipShow: false,
            folder: null,
            loadingSaveFolder: false,

            folderData: [],
            select_folder: {
                id: null,
                name: null,
            },
            document,
            files: [],
            document_urls: [],
            dialog_file: false,


            showSharedTree: false,
            folderSharedItems: [],


            dialogSharedFolder: false,
            shared: null,
            sharedItems: [
                {
                    id: 1,
                    name: this.$t('everyone')
                },
                {
                    id: 0,
                    name: this.$t('for_individual_users')
                },
                {
                    id: -1,
                    name: this.$t('close')
                }
            ],
            shared_admin: [],
            dialogFolders: false,
            file_id: null,
            Additional: [],
            adminItems: [],
            system_filters: [],
            shared_click: false,
            buttonItems: [
                {
                    title: this.$t('folder'),
                    action: 'folder',
                    icon: 'icon-folder-add'
                },
                {
                    title: this.$t('files'),
                    action: 'file',
                    icon: 'icon-file-text-add'
                }
            ],
            prev_folder: null,
            breadcrumbItems:[],
            breadcrumbFolderItems:[],
            itemPrevFolder : {
                id: null,
                uuid: null,
                folder: '...',
                author: {
                    id: null,
                    uuid: null,
                    photo: null,
                    name: null,
                    phone: null,
                    active: null,
                },
                type: 'folder',
                url: null,
                size: null,
                type_mime: null,
                file_type: null,
                name: '...',
                created_at: null,
            }


        };
    },
    computed: {
        ...mapGetters(['lang', 'listLanguages', 'itemsPerPage', 'perPageItems', 'userBlock']),
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.language = this.languages[this.tab]
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            await this.getStorages()
            await this.getFolders(false)
            await this.getFolders(true)
        },


        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
        },
        async getStorages() {
            this.loading = true;
            const {
                sortBy,
                sortDir: sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc !== undefined) {
                params.sortDir = sortDesc ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.storage) {
                params.storage = this.storage;
            }
            if (this.select_folder.id) {
                params.folder_id = this.select_folder.id;
                this.prev_folder = this.select_folder.id;
                this.breadcrumbFolderItems.push(
                    {
                        id: this.select_folder.id,
                        name: this.select_folder.name,
                    }
                )
            }
            else {
                this.prev_folder = null;
                this.breadcrumbItems = [];
                this.breadcrumbFolderItems = [];
            }
            await this.$http
                .get("admin/file_storage", {
                    params: params,
                })
                .then(res => {
                    this.storages = res.body.data
                    this.page = res.body.meta.current_page
                    this.itemPrevFolder.id = this.prev_folder;
                    if(this.prev_folder  && this.prev_folder !== ''){
                        this.storages.unshift(this.itemPrevFolder);
                        this.breadcrumbItems.push(this.prev_folder);
                    }
                    this.totalStorages = res.body.meta.total
                    this.pageCount = res.body.meta.last_page




                })
                .catch(err => {
                    this.storages = []
                    this.totalStorages = 0
                    this.$toastr.error(this.$t('failed_to_get_list_storages'))
                })
                .finally(end => {
                    this.loading = false
                })
        },


        setLanguage(val) {
            this.language = this.languages[val]
        },
        async getFolders(shared) {
            this.loading = true
            let params = {}
            params.filter = 'all'

            if (this.folder) {
                params.folder = this.folder
            }
            if (shared) {
                params.shared = 1
            }
            await this.$http
                .get("admin/folder", {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.length > 0) {
                        if (shared) {
                            this.folderSharedItems = res.body.data
                            this.showSharedTree = this.folderSharedItems.length > 0;
                        } else {
                            this.folderItems = res.body.data
                            this.showTree = true;
                        }

                    }
                })
                .catch(err => {
                    this.folderItems = []
                    this.folderSharedItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_folders'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        prevFiles(item){

            this.breadcrumbItems = this.breadcrumbItems.filter(breadcrumbId => breadcrumbId !== item.id);
            this.breadcrumbFolderItems = this.breadcrumbFolderItems.filter(breadcrumbFolder => breadcrumbFolder.id !== item.id);
            this.select_folder.id = this.breadcrumbItems.length > 0 ? this.breadcrumbItems[this.breadcrumbItems.length - 1] : null;
            if(this.select_folder.id){
                const foundItem = this.breadcrumbFolderItems.find(item => item.id === this.select_folder.id);
                if(foundItem.id){
                    this.select_folder.name  = foundItem.name
                }
            }
            else {
                this.select_folder.name = null
            }
            this.getStorages();

        },
        showFiles(item) {
            this.select_folder.id = item.id;
            this.select_folder.name = item.name;
            this.getStorages();
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        async handleDrop(draggingNode, dropNode, dropType, ev) {
            if (dropType === 'inner') {
                let notesInner = []
                draggingNode.data.parent_id = dropNode.data.id
                draggingNode.data.sort = (Number((dropNode.data.children.length - 1)) + 1)
                notesInner.push(draggingNode.data)
                await this.sortingFolders(dropNode.data.id, notesInner)
            }
            let parent = 0
            if (dropNode.parent.data.id !== undefined) {
                parent = dropNode.parent.data.id
            }
            let nodesPatrent = dropNode.parent.getChildren()
            for (let i in nodesPatrent) {
                nodesPatrent[i].parent_id = parent
                nodesPatrent[i].sort = (Number(i) + 1)
            }
            await this.sortingFolders(parent, nodesPatrent)
        },
        async reload() {
            await this.getCategories()
        },

        sharedFolder(node, data) {
            this.node = node
            this.nodedata = data
            this.dialogSharedFolder = true
            this.folderData = Object.assign({}, data)
        },
        editFolder(node, data) {
            this.node = node
            this.nodedata = data
            this.dialogFolder = true
            this.folderData = Object.assign({}, data)
        },
        closeDialogFolder() {
            this.dialogFolder = false
            this.dialogSharedFolder = false
            this.$nextTick(() => {
                this.folderData = {}
            })
            this.shared = null;
            this.shared_admin = [];
            this.shared_click = false;
        },
        addFolder(node, data) {
            this.dialogFolder = true
            this.node = node
            this.nodedata = data
            this.folderData = {
                id: 0,
                parent_id: (data && data.id) ? data.id : 0,
                name: null,
                sort: (data && data.children && data.children !== null) ? (data.children.length + 1) : 1,
                leaf: true,
                children: false
            }
        },
        async deleteFolder(node, data) {
            if (confirm(this.$t('delete_folder'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/folder/${data.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('folder_has_been_deleted'))
                        this.$refs.tree.remove(data);
                        this.getStorages();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async saveFolder(item) {
            var _this = this
            this.progress = 0
            this.loading = true
            this.loadingSaveFolder = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (item.name) {
                formData.append('folder', item.name)
            }
            if (item.parent_id) {
                formData.append('parent', item.parent_id)
            }
            if (item.sort >= 0) {
                if (this.node === null) {
                    item.sort = (Number(this.folderItems.length) + 1)
                }
                formData.append('sort', item.sort)
            }
            if (item.id) {
                // Save
                await this.$http
                    .put(`admin/folder/${item.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('folder_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations

                            this.nodedata.name = item.name
                            this.getStorages();
                        }

                        this.dialogFolder = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                        this.loadingSaveFolder = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/folder', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('folder_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations

                            if (!this.nodedata.children) {
                                this.$set(this.nodedata, 'children', []);
                            }

                            item.id = res.body.data.id

                            if (this.node === null) {
                                this.folderItems.push(item)
                            } else {
                                this.nodedata.children.push(item)
                            }
                        }
                        this.getStorages();
                        this.getFolders(false)
                        this.dialogFolder = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                        this.loadingSaveFolder = false
                    })
            }
        },
        actionSharedFolder(item) {
            if (this.shared === -1) {
                this.closeSharedFolder(item)
            } else {
                this.saveSharedFolder(item)
            }
        },
        async saveSharedFolder(item) {
            var _this = this
            this.progress = 0
            this.loading = true
            this.loadingSaveFolder = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }

            if (this.shared) {
                formData.append('shared', 1)
            }


            if (this.shared_admin && this.shared_admin.length > 0) {
                for (let i in this.shared_admin) {
                    if (this.shared_admin[i].id !== undefined && this.shared_admin[i].id > 0) {
                        formData.append(`shared_admins[${i}]`, this.shared_admin[i].id)
                    }
                }
            }


            // Save
            await this.$http
                .put(`admin/folder_shared/${item.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('folder_has_been_updated'))
                    this.closeDialogFolder();
                    this.getFolders(false)
                    this.getFolders(true)
                })
                .catch(err => {
                    this.$toastr.error(this.$t('folder_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                    this.loadingSaveFolder = false
                })

        },
        async closeSharedFolder(item) {
            var _this = this
            this.progress = 0
            this.loading = true
            this.loadingSaveFolder = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            // Save
            await this.$http
                .delete(`admin/folder_shared/${item.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('folder_has_been_updated'))
                    this.closeDialogFolder();
                    this.getFolders(false)
                    this.getFolders(true)
                })
                .catch(err => {
                    this.$toastr.error(this.$t('folder_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                    this.loadingSaveFolder = false
                })

        },
        async sortingFolders(parent, nodes) {

            if (parent >= 0 && nodes.length > 0) {

                // this.loading = true
                // var formData = new FormData()
                //
                // if (parent > 0) {
                //     formData.append('parent', parent)
                // }
                //
                // for (let i in nodes) {
                //     formData.append(`nodes[${i}][id]`, nodes[i].id)
                //     formData.append(`nodes[${i}][sort]`, nodes[i].sort)
                // }
                //
                // await this.$http
                //     .post('admin/folder/sort', formData)
                //     .then(res => {
                //         this.$toastr.success(this.$t('folder_sorting_updated'))
                //     })
                //     .catch(err => {
                //         this.$toastr.error(this.$t('folder_sorting_not_updated'))
                //         if (err && err.body && err.body.message) {
                //             if (!err.body.errors) {
                //                 this.$toastr.error(err.body.message)
                //             }
                //         }
                //     })
                //     .finally(end => {
                //         this.loading = false
                //     })
            }
        },
        async getSharedFolder(node, data) {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/folder/${data.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.node = node
                    this.nodedata = data
                    this.dialogSharedFolder = true
                    this.folderData = Object.assign({}, data)
                    this.shared = res.body.data.shared_everyone;
                    this.shared_admin = res.body.data.admins;
                    this.shared_click = true;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_folder'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        previewFiles() {
            let i = this.document_urls.length
            let data = {}
            this.files.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.document_urls.push(data)
                i++
            })
            this.dialog_file = true
        },
        deleteFile(item) {
            let index = this.document_urls.indexOf(item)
            this.document_urls.splice(index, 1)
            if (this.document_urls.length === 0) {
                this.dialog_file = false;
                this.document_urls = [];
                this.documents = []
            }
        },
        async SaveFile() {
            var _this = this;
            let formData, blob;
            this.dialog_file = false;
            this.documents = []
            let is_image = false;

            for (const file of this.document_urls) {


                is_image = this.mimeTypeImage(file.type);

                formData = new FormData();
                if (this.select_folder.id) {
                    formData.append('folder_id', this.select_folder.id)
                }
                formData.append('type_mime', file.type)
                formData.append('size', file.size)
                formData.append('file_name', file.name)
                formData.append('is_image', is_image ? 1 : null)
                file.blob = await this.convertBlobToBase64(file.blob)
                if (file.blob) {
                    blob = this.dataURL64toBlob(file.blob)
                    if (blob) {
                        formData.append('file', blob, file.type)
                    }
                }

                // Add
                this.$http
                    .post('admin/file_storage', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('file_storage_has_been_added'))
                        this.getStorages()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_storage_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.document_urls = [];
                    })


            }
            await this.getStorages();

        },
        downloadFile(url, file_name) {
            fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },

        dialogFoldersOpen(item) {
            this.dialogFolders = true;
            this.file_id = item.id
        },
        getFolderSelected(folder_id) {
            this.moveFile(folder_id)
        },
        updateDialogFolders(value) {
            this.file_id = null;
            this.dialogFolders = value;
        },
        async moveFile(folder_id) {
            var _this = this
            this.progress = 0
            this.loading = true
            this.loadingSaveFolder = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            formData.append('folder_id', folder_id)


            // Save
            await this.$http
                .put(`admin/file_storage/${this.file_id}/move`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('file_storage_has_been_updated'))
                    this.getStorages();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('file_storage_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                    this.loadingSaveFolder = false
                })

        },
        async deleteFileStorage(data) {
            if (confirm(this.$t('delete_file_storage'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/file_storage/${data.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('file_storage_has_been_deleted'))
                        this.getStorages();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_storage_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        addFileOrFolder(action){
            if(action === 'file'){
                document.getElementById('file').click()
            }
            else if(action === 'folder'){
                this.folderData = {
                    id: 0,
                    parent_id: this.select_folder.id,
                    name: null,
                    sort: 1,
                    leaf: true,
                    children: false
                };
                this.dialogFolder = true
            }
        }

    }
}
</script>


<style>
.custom-tree-node {
    width: 100%;
    min-height: 25px;
}

.folder-tree .el-tree-node__content {
    min-height: 50px;
    margin-top: 5px;
}
</style>
