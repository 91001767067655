import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";

export default {
    data() {
        return {
            nomenclatureGroups: []
        }
    },



    methods: {
        initNomenclatureGroups() {
            this.fetchNomenclatureGroups({
                perPage: 9999999
            })
            .then((body) => {
                let groups = body.data;

                groups = buildTree(groups, null, 'id', 'parent_id', 'children');
                groups = buildListArrayFromTreeArray(groups);

                for (let i in groups) {
                    groups[i].name = (' . '.repeat((groups[i].depth ?? 1))) + groups[i].name
                }

                groups.unshift({
                    id: null,
                    name: this.$t('nomenclature_group.top-level')
                })

                this.nomenclatureGroups = groups;
            });
        },

        fetchNomenclatureGroups(params) {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/nomenclature_group', { params })
                    .then((({ body }) => {
                        resolve(body);
                    })).catch((error) => {
                        reject(error);
                    })
                });
        }
    }
}
