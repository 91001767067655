<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('warehouse.report.stock.self') }}</h1>
                </div>
            </div>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-menu
                                        v-model="fromMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <TextInput
                                                v-model="date"
                                                :label="$t('warehouse.report.stock.date')"
                                                v-bind="attrs"
                                                v-on="on"
                                                id="date"
                                                hide-label
                                            ></TextInput>
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            @input="fromMenu = false"

                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="flex-grow-0">
                                    <Btn @click="fetchReport">{{ $t('get-report') }}</Btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="text-left">{{ $t('warehouse.report.stock.columns.nomenclature') }}</th>
                                                <th class="text-left">{{ $t('warehouse.report.stock.columns.quantity') }}</th>
                                                <th class="text-left">{{ $t('warehouse.report.stock.columns.reserve') }}</th>
                                                <th class="text-left">{{ $t('warehouse.report.stock.columns.warehouse') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="movement in reportData" :key="movement.id">
                                                <td><RouterLink :to="{name: 'nomenclature.edit', params: {uuid: movement.nomenclature.uuid}}">{{ movement.nomenclature.name }}</RouterLink></td>
                                                <td>{{ movement.quantity }}</td>
                                                <td>{{ movement.reserve }}</td>
                                                <td>
                                                    <RouterLink :to="{name: 'warehouse_cell.edit', params: {uuid: movement.cell.uuid}}">{{ movement.cell.name }}</RouterLink>
                                                    -
                                                    <RouterLink :to="{name: 'warehouse_shelf.edit', params: {uuid: movement.cell.shelf.uuid}}">{{ movement.cell.shelf.name }}</RouterLink>
                                                    -
                                                    <RouterLink :to="{name: 'warehouse_zone.edit', params: {uuid: movement.cell.shelf.zone.uuid}}">{{ movement.cell.shelf.zone.name }}</RouterLink>
                                                    -
                                                    <RouterLink :to="{name: 'warehouse.edit', params: {uuid: movement.cell.shelf.zone.warehouse.uuid}}">{{ movement.cell.shelf.zone.warehouse.name }}</RouterLink>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import NavMixin from "@/plugins/mixins/Nav";
import FilterView from "@/plugins/mixins/FilterView";
import Side from "@/components/Warehouse/Side.vue";
import TextInput from "@/components/Form/TextInput.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "Sale",

    components: {
        Btn,
        TextInput,
        Side,
        PageLayout,
    },

    mixins: [
        NavMixin,
        FilterView
    ],

    data() {
        return {
            reportData: [],
            fromMenu: false,
            date: this.$moment().format('YYYY-MM-DD'),
        }
    },

    computed: {},

    mounted() {
        this.fetchReport();
    },

    methods: {
        fetchReport() {
            this.$http.get('admin/warehouse/report/stock', {
                params: {
                    date: this.date
                }
            }).then((resp) => {
                this.reportData = resp.body.data
            })
        },
    }
}
</script>
