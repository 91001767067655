<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveItem">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('columns.bank.editing') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="my-0">
                                <v-col cols="12">
                                    <ValidationProvider :ref="`name`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.bank.name')"
                                            v-model="name"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`bik`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.bank.bik')"
                                            v-model="bik"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`kpp`" rules="" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.bank.kpp')"
                                            v-model="kpp"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`swift`" rules="" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.bank.swift')"
                                            v-model="swift"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`address`" rules="" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.bank.address')"
                                            v-model="address"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`correspondent_account`" rules="" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.bank.correspondent_account')"
                                            v-model="correspondent_account"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`inn`" rules="" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.bank.inn')"
                                            v-model="inn"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="buttons-block">
                            <Btn type="submit" :disabled="invalid || loading" :loading="loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                {{ $t('save') }}
                            </Btn>
                            <Btn
                                :disabled="loading"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </Btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import Btn from "@/components/Form/Btn.vue";
    import TextInput from "@/components/Form/TextInput.vue";

    export default {
        name: 'BankForm',
        components: {
            Btn,
            ValidationProvider,
            ValidationObserver,
            TextInput
        },

        props: {
            uuid: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                dialogForm: true,
                heading: null,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,

                name: null,
                bik: null,
                kpp: null,
                swift: null,
                address: null,
                correspondent_account: null,
                inn: null
            }
        },

        computed: {
            ...mapGetters(['listLanguages', 'lang']),

            languages() {
                return this.listLanguages
            },
        },

        watch: {
            uuid: {
                handler: function (value) {
                    if (value === 'create') {
                        this.heading = this.$t('columns.bank.add')
                    } else {
                        this.heading = this.$t('columns.bank.edit')
                        this.fetchItem()
                    }
                },

                immediate: true
            }
        },

        async mounted() {
            this.language = this.languages[this.tab]
        },

        methods: {
            closeDialogAdd(){
                this.$router.push({
                    name: 'bank',
                })
            },

            async fetchItem() {
                let _this = this;
                let params = {}

                this.progress = 0
                this.loading = true

                if (this.language) {
                    params.language = this.language
                }

                await this.$http
                    .get(`admin/bank/${this.uuid}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if (res && res.body && res.body) {
                            this.handleResponse(res.body)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$toastr.error(this.$t('columns.bank.fetching-error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },

            async saveItem() {
                let _this = this;

                this.progress = 0
                this.loading = true

                let formData = {};
                formData['name'] = this.name
                formData['bik'] = this.bik
                formData['kpp'] = this.kpp
                formData['swift'] = this.swift
                formData['address'] = this.address
                formData['correspondent_account'] = this.correspondent_account
                formData['inn'] = this.inn

                if (this.uuid === 'create') {
                    // Add
                    await this.$http
                        .post('admin/bank', formData, {
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('columns.bank.added'))
                            this.$router.push({
                                name: 'bank'
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('columns.bank.add_error'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    await this.$http
                        .put(`admin/bank/${this.uuid}`, formData, {
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('columns.bank.update_success'))

                            this.$router.push({
                                name: 'bank'
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('columns.bank.update_error'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },

            handleResponse(res) {
                this.id = res.id
                this.uuid = res.uuid
                this.name = res.name
                this.bik = res.bik
                this.kpp = res.kpp
                this.swift = res.swift
                this.address = res.address
                this.correspondent_account = res.correspondent_account
                this.inn = res.inn
            }
        }
    }
</script>
