export default {
    methods: {
        fetchItems(uuid) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/warehouse/order_to_buy/${uuid}/item`)
                    .then(res => {
                        let data = res.body;

                        data.data.forEach(item => {
                            item.price = parseFloat(item.price);
                            item.sum = parseFloat(item.sum);
                        });

                        console.log(data);

                        resolve(data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('order_to_buy_item.fetch_error'))
                        reject(err)
                    });
            });
        },

        deleteItem(uuid, itemUuid) {
            return new Promise((resolve, reject) => {
                this.$http
                    .delete(`admin/warehouse/order_to_buy/${uuid}/item/${itemUuid}`)
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        updateItem(buyerOrderUuid, itemUuid, data) {
            return new Promise((resolve, reject) => {
                this.$http
                    .put(`admin/warehouse/order_to_buy/${buyerOrderUuid}/item/${itemUuid}`, data)
                    .then(res => {
                        let data = res.body.data;

                        data.price = parseFloat(data.price);
                        data.sum = parseFloat(data.sum);

                        resolve(data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}
