export default {
    data() {
        return {
            warehouseCells: [],
        }
    },

    methods: {
        initWarehouseCells() {
            this.fetchWarehouseCells()
                .then((items) => {
                    this.warehouseCells = items;
                });
        },

        fetchWarehouseCells({
            warehouse_id = null
        } = {}) {
            let params = {
                fltr: {},
            }

            if(warehouse_id) {
                params.fltr.warehouse_id = {
                    eq: warehouse_id
                };
            }

            return new Promise((resolve, reject) => {
                this.$http.get('admin/warehouse/warehouse_cell', {
                    params
                })
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },
}
