export default [
    {
        id: 'clients',
        title: 'menu_clients',
        icon: 'icon icon-clients',
        route: {
            name: 'uved'
        },
        permissions: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
        items: [
            {
                title: 'menu_clients',
                route: {name: "uved"},
                icon: 'icon icon-clients',
                permissions: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
                notification_group: 'uved.show',
            },
            {
                title: 'menu_users',
                route: {name: 'client'},
                icon: 'icon icon-client',
                permissions: ['client_module'],
            },
            {
                title: 'menu_invitations',
                route: {name: 'invite'},
                icon: 'icon icon-invitations',
                permissions: ['client_module'],
            },
            {
                title: 'menu_permissions',
                icon: 'icon icon-permissions',
                route: {
                    name: 'uved_permissions'
                },
                permissions: ['uved-edit-permission'],
            },
        ]
    },
    {
        title: 'menu_calendar',
        icon: 'icon-calendar',
        route: {name: "calendar"},
        permissions: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'lawyer', 'hr_manager'],
    },
    {
        id: 'tasks',
        title: 'menu_tasks',
        icon: "icon-doc",
        route: {
            name: "task",
            query: {
                filter: {
                    task_status_id: {
                        notin: ['3', '5']
                    }
                }
            }
        },
        permissions: ['administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
        notification_group: 'task.show'
    },
    {
        title: 'menu_deals',
        icon: "icon-taks",
        route: {
            name: "deal",
            query: {
                filter: {
                    everything_except_completed_canceled: { eq: 1 },
                    ...(process.env.VUE_APP_LOGO_DIR === 'logo-inf' ? { outbox: { eq: '1' } } : {}),
                }
            }
        },
        permissions: ['administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
        exact: false,
        notification_group: 'deal.show',
    },
    {
        title: 'menu_cloud',
        icon: "icon-storage",
        route: {name: "file_storage"},
        permissions: ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
        items: [
            {
                title: 'menu_cloud',
                route: {name: "file_storage"},
                icon: 'icon-storage',
                permissions: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
                notification_group: 'uved.show',
            },
            {
                title: 'menu_documents',
                route: {name: 'document'},
                icon: 'icon-folder-file-1',
                permissions:  ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
            },
            {
                title: 'menu_basket',
                route: {name: 'basket'},
                icon: 'icon-bin',
                permissions:  ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
            },
        ]
    },
    {
        title: 'menu_dashboard',
        icon: "icon-chart",
        route: {name: "dashboard"},
        permissions:  ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
    },
    {
        title: 'menu_topics',
        icon: 'icon-comments',
        route: {name: 'my_topic'},
        permissions: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'lawyer'],
        items: [
            {
                title: 'menu_my_topics',
                route: {name: "my_topic"},
                permissions: ['client_module'],
                notification_group: 'my_topics',
            },
            {
                title: 'menu_topics',
                route: {name: "topics"},
                permissions: ['client_module'],
                notification_group: 'topics',
            }
        ],
    },
    {
        title: 'menu_tracking',
        icon: 'icon-binoculars',
        route: {name: 'tracking'},
        permissions: ['client_module'],
        items: [
            {
                title: 'menu_tracking',
                route: {name: "tracking"},
                permissions: ['client_module'],
            },
            {
                title: 'menu_stations',
                route: {name: "station"},
                permissions: ['client_module'],
            },
        ]
    },

    {
        title: 'buy.menu',
        route: { name: "buy" },
        icon: 'icon-buying',
        permissions: ['warehouse_accounting'],
        items: [
            {
                title: 'order_to_buy.menu',
                route: {name: "order_to_buy"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'incoming.menu',
                route: {name: "incoming"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'accounting_invoice.menu',
                route: {name: "accounting_invoice"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'supplier_return.menu',
                route: {name: "buy.supplier_return"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'buying.menu',
                route: {name: "buying"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'buy.dashboard',
                route: {name: "buy.report"},
                permissions: ['warehouse_accounting'],
                items: [
                    {
                        title: 'buy.dashboard',
                        route: {name: "buy.report"},
                        permissions: ['warehouse_accounting']
                    },
                    // {
                    //     title: 'warehouse.report.stock.self',
                    //     route: {name: "warehouse.stock"},
                    //     permissions: ['warehouse_accounting']
                    // },

                ]
            }
        ]
    },

    {
        title: 'sale.menu',
        route: {name: "sale"},
        icon: 'icon-salling',
        permissions: ['warehouse_accounting'],
        items: [
            {
                title: 'buyer_order.menu',
                route: {name: "sale.buyer_order"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'invoice_outgoing.menu',
                route: {name: "sale.invoice"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'realization.menu',
                route: {name: "sale.realization"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'buyer_return.menu',
                route: {name: "sale.buyer_return"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'sale.dashboard',
                route: {name: "sale.report"},
                permissions: ['warehouse_accounting']
            }
        ]
    },

    {
        title: 'warehouse.menu',
        icon: 'icon-warehouse',
        route: {name: 'warehouse_main'},
        permissions: ['warehouse_accounting'],
        items: [
            {
                title: 'nomenclature.menu',
                route: {name: "nomenclature"},
                permissions: ['nomenclature_accounting']
            },

            {
                title: 'nomenclature_type.menu',
                route: {name: "nomenclature_types"},
                permissions: ['nomenclature_accounting']
            },

            {
                title: 'warehouse.menu',
                route: {name: 'warehouse'},
                permissions: ['warehouse_accounting'],
                items: [
                    {
                        title: 'warehouse.menu',
                        route: {name: 'warehouse'},
                        permissions: ['warehouse_accounting'],
                    },
                    {
                        title: 'warehouse_zone.menu',
                        route: {name: 'warehouse_zone'},
                        permissions: ['warehouse_accounting'],
                    },
                    {
                        title: 'warehouse_shelf.menu',
                        route: {name: 'warehouse_shelf'},
                        permissions: ['warehouse_accounting'],
                    },
                    {
                        title: 'warehouse_cell.menu',
                        route: {name: 'warehouse_cell'},
                        permissions: ['warehouse_accounting'],
                    }
                ]
            },
            {
                title: 'unit.menu',
                route: {name: "units"},
                permissions: ['units']
            },
            {
                title: 'price_type.menu',
                route: {name: "price_types"},
                permissions: ['price_types']
            },
            {
                title: 'warehouse.dashboard',
                route: {name: "warehouse.dashboard"},
                permissions: ['warehouse_accounting']
            },
            {
                title: 'warehouse.report.stock.self',
                route: {name: "warehouse.stock"},
                permissions: ['warehouse_accounting']
            },
        ]
    },

    {
        title: 'accounting.menu',
        icon: 'icon-finance',
        route: {name: 'accounting'},
        permissions: ['warehouse_accounting'],
        items: [
            {
                title: 'incoming_payment.menu',
                route: {name: 'account_payment', query: {filter: {account_payment_type_id: {eq: ['1']}}}},
                permissions: ['warehouse_accounting'],
            },
            {
                title: 'outgoing_payment.menu',
                route: {name: 'account_payment', query: {filter: {account_payment_type_id: {eq: ['2']}}}},
                permissions: ['warehouse_accounting'],
            },
            {
                title: 'chart_of_account.menu',
                route: {name: 'chart_of_account'},
                permissions: ['warehouse_accounting'],
            },
            {
                title: 'transaction_log.menu',
                route: {name: 'transaction_log'},
                permissions: ['warehouse_accounting'],
            },
            {
                title: 'accounting.dashboard',
                route: {name: 'accounting.report'},
                permissions: ['warehouse_accounting'],
                items: [
                    {
                        title: 'accounting.dashboard',
                        route: {name: 'accounting.report'},
                        permissions: ['warehouse_accounting'],
                    },
                    {
                        title: 'accounting.report.movement.menu',
                        route: {name: 'accounting.report.movement'},
                        permissions: ['warehouse_accounting'],
                    },
                ]
            },
        ]
    },

    {
        title: 'menu_payments',
        icon: "icon-money",
        route: {name: "invoice"},
        permissions: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'lawyer'],
    },
    {
        title: 'menu_staff',
        icon: 'icon-user-settings',
        route: {name: 'administrator'},
        permissions: ['administrator', 'director', 'hr_manager'],
        items: [
            {
                title: 'menu_staff',
                icon: 'icon-user',
                route: {name: 'administrator'},
                permissions: ['administrator', 'director', 'hr_manager'],
            },
            {
                title: 'menu_department',
                icon: 'icon-team',
                route: {
                    name: 'department.tree'
                },
                permissions: ['administrator', 'director', 'hr_manager'],
            },
            {
                title: 'menu_vacation',
                icon: 'mdi-umbrella-beach-outline',
                route: {name: 'vacation'},
                permissions: ['administrator', 'director', 'hr_manager'],
            },
            {
                title: 'menu_employee_position',
                icon: 'icon-position',
                route: {name: 'employee_position'},
                permissions: ['administrator', 'director', 'hr_manager'],
            },
            {
                title: 'permissions',
                icon: 'icon-key',
                route: {name: 'permissions'},
                permissions: ['administrator'],
            },
            {
                title: 'menu_integration',
                icon: 'icon-team',
                route: {name: 'email_connect'},
                permissions: ['administrator', 'director', 'hr_manager'],
            },
        ]
    },
    {
        title: 'menu_settings',
        icon: 'icon-gear',
        route: {name: "currency"},
        permissions: ['administrator', 'director'],
    },
    {
        title: 'menu_reports',
        icon: 'icon-analytics-graph',
        route: {name: "report_call"},
        permissions: ['administrator', 'director'],
    },
    {
        title: 'menu_activity_logs',
        icon: 'icon-newspaper',
        route: {name: "activity_log"},
        permissions: ['administrator', 'director'],
    },
    // {
    //     title: 'menu_error_correction',
    //     icon: 'icon-circle-attansion',
    //     route: {name: 'invoice_correction'},
    //     permissions: ['administrator'],
    //     items: [
    //         {
    //             title: 'menu_payments_correction',
    //             route: {name: "invoice_correction"},
    //             permissions: ['administrator'],
    //         },
    //         {
    //             title: 'menu_topics_correction',
    //             route: {name: "topics_correction"},
    //             permissions: ['administrator'],
    //         },
    //         {
    //             title: 'menu_tracking_correction',
    //             route: {name: "tracking_correction"},
    //             permissions: ['administrator'],
    //         },
    //     ]
    // },
]
