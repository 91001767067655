import { mapActions } from "vuex";

let PaginationView = {
    data() {
        return {
            page: 1,
            totalVisiblePag: 5
        }
    },

    beforeMount() {
        this.processQuery();
    },

    methods: {
        ...mapActions(['setSettings']),

        processQuery() {
            let itemsPerPage = parseInt(this.$route.query.itemsPerPage);
            if (itemsPerPage) {
                this.options.itemsPerPage = itemsPerPage;
                this.setSettings({
                    ...this.$auth.user().settings,
                    itemsPerPage: itemsPerPage
                })
            } else {
                this.options.itemsPerPage  = this.itemsPerPage;
            }

            let page = parseInt(this.$route.query.page);
            if (page) {
                this.options.page = page;
            }

            if(!!this.onProcessQueryDone && this.onProcessQueryDone instanceof 'function') {
                this.onProcessQueryDone();
            }
        },

        onPageChange(page) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page: page
                }
            });
        },

        onPerPageChange(value) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    itemsPerPage: value
                }
            });
        },
    }
}

export default PaginationView;
