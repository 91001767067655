<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveItem">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('nomenclature_type.editing') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="my-0">
                                <v-col cols="12">
                                    <ValidationProvider :ref="`data[kind]`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            :id="`kind`"
                                            :label="$t('columns.nomenclature_type.kind')"
                                            v-model="kind"
                                            :error="!valid"
                                            :items="kinds.map((item) => ({ text: item.name, value: item.id }))"
                                        >
                                        </SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <v-tabs
                                        grow
                                        v-model="tab"
                                        background-color="transparent"
                                    >
                                        <v-tab
                                            v-for="(lng, index) in listLanguages"
                                            :key="lng"
                                        >
                                            {{ lng }}
                                        </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="tab">
                                        <v-tab-item
                                            v-for="(lng, index) in listLanguages"
                                            :key="lng"
                                        >
                                            <ValidationProvider :ref="`data[name][${lng}]`" rules="required|min:1" v-slot="{ errors, valid }">
                                                <TextInput
                                                    :id="`name_${lng}`"
                                                    :label="`${$t('nomenclature_type.name')} ${lng}`"
                                                    v-model="data.name[lng]"
                                                    :error="!valid"
                                                >
                                                </TextInput>
                                            </ValidationProvider>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="buttons-block">
                            <Btn type="submit" :disabled="invalid || loading" :loading="loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                {{ $t('save') }}
                            </Btn>
                            <Btn
                                :disabled="loading"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </Btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import Btn from "@/components/Form/Btn.vue";
    import TextInput from "@/components/Form/TextInput.vue";
    import SelectInput from "@/components/Form/SelectInput.vue";

    export default {
        name: 'UnitForm',
        components: {
            SelectInput,
            Btn,
            ValidationProvider,
            ValidationObserver,
            TextInput
        },

        inject: ['forceRerender'],

        props: {
            uuid: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                dialogForm: true,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,
                kinds: [],

                kind: null,

                data: {
                    name: {},
                }
            }
        },

        computed: {
            ...mapGetters(['listLanguages', 'lang']),

            languages() {
                return this.listLanguages
            },
        },

        watch: {
            uuid: {
                handler: function (value) {
                    if (value !== 'create') {
                        this.fetchItem()
                    }

                    this.init();
                },

                immediate: true
            }
        },

        async mounted() {
            this.language = this.languages[this.tab]
        },

        methods: {
            init() {
                this.fetchKinds().then((data) => {
                    this.kinds = data;
                })
            },

            closeDialogAdd(){
                this.$router.push({
                    name: 'nomenclature_types',
                })
            },

            async fetchItem() {
                let _this = this;
                let params = {}

                this.progress = 0
                this.loading = true

                if (this.language) {
                    params.language = this.language
                }

                await this.$http
                    .get(`admin/nomenclature_type/${this.uuid}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if (res && res.body && res.body.data) {
                            this.handleResponse(res.body.data)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$toastr.error(this.$t('nomenclature_type.fetching-error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },

            fetchKinds() {
                let _this = this;

                this.progress = 0
                this.loading = true

                return new Promise((resolve, reject) => {
                    this.$http
                        .get('admin/nomenclature_kind')
                        .then(res => {
                            if (res && res.body && res.body.data) {
                                resolve(res.body.data)
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('nomenclature_kind.fetching-error'))

                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                })
            },

            async saveItem() {
                let _this = this;

                this.progress = 0
                this.loading = true

                let formData = {
                    'nomenclature_kind_id': this.kind,
                };

                for(let field in this.data) {
                    let langs = this.data[field]

                    for(let lng in langs) {
                        let value = langs[lng];

                        if (!formData[lng]) {
                            formData[lng] = {}
                        }

                        formData[lng][field] = value
                    }
                }

                if (this.uuid === 'create') {
                    // Add

                    await this.$http
                        .post('admin/nomenclature_type', formData, {
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('nomenclature_type.added'))
                            this.$router.push({
                                name: 'nomenclature_types'
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('nomenclature_type.add_error'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    await this.$http
                        .put(`admin/nomenclature_type/${this.uuid}`, formData, {
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('nomenclature_type.updat_success'))

                            this.$router.push({
                                name: 'nomenclature_types'
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('nomenclature_type.update_error'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },

            handleResponse(res) {
                this.kind = res.kind_id

                res.translations.forEach((item) => {
                    this.data.name[item.locale] = item.name;
                })

                console.log(res, this.data)
            }
        }
    }
</script>
