<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveDealStep">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary">
                                                mdi-close
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="12">
                                    <ValidationProvider ref="deal_step" rules="required|min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <TextInput
                                            id="deal_step"
                                            :label="$t('deal_step_name')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            v-model="deal_step"
                                            require
                                        ></TextInput>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="12">
                                    <ValidationProvider ref="text" rules="min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <TextInput
                                            id="text"
                                            :label="$t('text_buttons')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            v-model="text"
                                            require
                                        ></TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" v-if="false">
                                    <v-autocomplete
                                        v-model="where_move"
                                        :disabled="isUpdating"
                                        :items="dealStepItems"
                                        chips
                                        :label="$t('deal_step')"
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        outlined
                                        solo
                                        flat
                                        dense
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                close
                                                @click="data.select"
                                                @click:close="remove(data.item)"
                                                class="input_chip"
                                            >
                                                <v-avatar left>
                                                    <v-icon :color="data.item.color">mdi-circle</v-icon>
                                                </v-avatar>
                                                {{ data.item.name }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="typeof data.item !== 'object'">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-avatar>
                                                    <v-icon :color="data.item.color">mdi-circle</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="text" rules="min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <SelectInput
                                            clearable
                                            item-text="name"
                                            item-value="id"
                                            hide-details
                                            hide-label
                                            :items="departmentItems"
                                            v-model="department"
                                            name="department"
                                            id="department"
                                            :hideLabel="false"
                                            :label="$t('departments.head')"
                                            :multiple="true"
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" v-if="no_admins">
                                    <ValidationProvider ref="admin" rules="min:1" v-slot="{ errors, valid }">
                                        <UserSelection
                                            v-model="admin"
                                            :valuel="admin"
                                            id="responsible"
                                            :label="$t('responsible')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            clearable
                                            multiple
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="!no_admins">
                                    <ValidationProvider ref="admin" rules="min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            clearable
                                            item-text="name"
                                            item-value="id"
                                            hide-details
                                            hide-label
                                            :items="adminItems"
                                            v-model="admin"
                                            name="coll"
                                            id="coll"
                                            :hideLabel="false"
                                            :label="$t('responsible')"
                                            return-object
                                            :multiple="true"
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" v-if="no_admins">
                                    <ValidationProvider ref="executors" rules="min:1" v-slot="{ errors, valid }">
                                        <UserSelection
                                            v-model="executors"
                                            :valuel="executors"
                                            id="executor"
                                            :label="$t('co-executors')"
                                            :error="!valid"
                                            :valid="valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            clearable
                                            multiple
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="!no_admins">
                                    <ValidationProvider ref="executors" rules="min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            clearable
                                            item-text="name"
                                            item-value="id"
                                            hide-details
                                            hide-label
                                            :items="adminItems"
                                            v-model="executors"
                                            name="executors"
                                            id="executors"
                                            :hideLabel="false"
                                            :label="$t('co-executors')"
                                            return-object
                                            :multiple="true"
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider ref="deal_status" rules="min:1"
                                                        v-slot="{ errors, valid }">
                                        <SelectInput
                                            v-model="deal_status"
                                            name="deal_status"
                                            id="deal_status"
                                            clearable
                                            item-text="name"
                                            item-value="id"
                                            hide-details
                                            hide-label
                                            :items="deal_statusItems"

                                            :hideLabel="false"
                                            :label="$t('deal_status')"
                                        ></SelectInput>

                                    </ValidationProvider>
                                </v-col>

                            </v-row>
                            <v-row class="pt-4">
                                <v-col cols="12" sm="6">
                                    <v-color-picker v-model="color" :disabled="loading" mode="hexa"></v-color-picker>
                                </v-col>
                                <v-col class="py-0 my-0" cols="12" sm="6">
                                    <v-switch v-model="finish"
                                              class="py-0 my-0"
                                              :label="$t('finish')"
                                              color="primary" hide-details></v-switch>

                                    <v-switch v-model="required_filled"
                                              :label="$t('required_fields')"
                                              color="primary" hide-details></v-switch>
                                    <v-switch v-model="accompany_comment"
                                              :label="$t('accompany_comment')"
                                              color="primary" hide-details></v-switch>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <SelectInput
                                        v-model="required_document"
                                        name="required_document"
                                        id="required_document"
                                        clearable
                                        item-text="name"
                                        item-value="id"
                                        hide-details
                                        hide-label
                                        :items="documentTypeItems"
                                        :hideLabel="false"
                                        :label="$t('document_type')"
                                        :multiple="true"
                                    ></SelectInput>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <SelectInput
                                        v-model="required_tabs"
                                        name="required_tabs"
                                        id="required_tabs"
                                        clearable
                                        item-text="name"
                                        item-value="id"
                                        hide-details
                                        hide-label
                                        :items="requiredTabItems"
                                        :hideLabel="false"
                                        :label="$t('required_tabs')"
                                        :multiple="true"
                                    ></SelectInput>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <SelectInput
                                        v-model="required_fields"
                                        name="required_fields"
                                        id="required_fields"
                                        clearable
                                        item-text="name"
                                        item-value="id"
                                        hide-details
                                        hide-label
                                        :items="requiredFieldItems"
                                        :hideLabel="false"
                                        :label="$t('required_field')"
                                        :multiple="true"
                                    ></SelectInput>
                                </v-col>

                                <v-col cols="12">
                                    <ValidationProvider ref="next_business_process" rules="min:1"
                                                        v-slot="{ errors, valid }">
                                        <SelectInput
                                            clearable
                                            item-text="name"
                                            item-value="id"
                                            hide-details
                                            hide-label
                                            :items="businessProcessItems"
                                            v-model="next_business_process"
                                            name="next_business_process"
                                            id="next_business_process"
                                            :hideLabel="false"
                                            :label="$t('switching_to_another_business_process')"

                                        ></SelectInput>


                                    </ValidationProvider>
                                </v-col>

                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 pt-10 pb-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";
import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";
import UserSelection from "@/components/Form/UserSelection.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import TextInput from "@/components/Form/TextInput.vue";

export default {
    name: 'DealStepForm',
    components: {
        ValidationProvider,
        ValidationObserver,
        UserSelection,
        SelectInput,
        TextInput
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            deal_step: null,
            text: null,
            where_move: [],
            isUpdating: false,
            dealStepItems: [],
            color: '#ff0000',
            finish: null,
            business_process: null,
            next_business_process: null,
            businessProcessItems: [],
            no_admins: true,
            admin: null,
            adminItems: [],
            no_executors: false,
            executors: null,
            executorItems: [],
            loadingAdmins: false,
            exist_translations: {},
            all_translations: true,
            isDocumentUpdating: false,
            required_document: [],
            documentTypeItems: [],
            required_filled: false,
            accompany_comment: false,
            deal_statusItems: [],
            deal_status: null,
            required_field: null,
            required_fields: [],
            requiredFieldItems: [],
            isRequiredFieldUpdating: false,
            isRequiredTabUpdating: false,
            required_tabs: [],
            requiredTabItems: [
                {
                    id: 'deal',
                    name: this.$t('deal.self')
                },
                {
                    id: 'tracking',
                    name: this.$t('tracking')
                },
                {
                    id: 'order',
                    name: this.$t('order')
                },
                {
                    id: 'application',
                    name: this.$t('application')
                },
                {
                    id: 'invoice',
                    name: this.$t('invoice')
                }
            ],
            department: [],
            departmentItems: [],
            only_author: {
                id: 0,
                name: this.$t('author'),
            }
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    async mounted() {
        this.language = this.languages[this.tab]
        await this.getBusinessProcesses();
        //  await this.getDealSteps()
        await this.getDealStatuses()
        await this.getRequiredFields()
        await this.getDocumentType();
        await this.checkCreate();
        await this.initAdmins()
        await this.initDepartments()

    },
    methods: {
        async checkCreate() {
            if (this.$route.name === "deal_step.create") {
                this.heading = this.$t('deal_step_creation')
            } else {
                this.heading = this.$t('deal_step_editing')
                if (this.$route.params.id) {
                    await this.getDealStep()
                }
            }
        },
        closeDialogAdd() {
            if (window.history.length > 2) {
                this.$router.back()
            } else {
                this.$router.push({
                    name: 'deal_step',
                })
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]

            this.checkCreate()
        },

        async initDepartments() {
            let params = {};
            let business_process = 0;
            if (this.business_process && this.business_process.id) {
                business_process = this.business_process.id
            } else {
                business_process = this.$route.params.business_process
            }
            params = {
                fltr: {
                    business_process_id: {
                        eq: business_process
                    },
                    department_type: {
                        eq: 'participant'
                    }
                }
            }
            await this.getDepartments(params);
            if (this.departmentItems.length === 0) {
                params = {}
                await this.getDepartments(params);
            }
        },
        async getDepartments(params) {
            await this.$http
                .get(`admin/department`, {
                    params: params,
                })
                .then(res => {
                    if (params.fltr && params.fltr.business_process_id) {
                        this.departmentItems = res.body.data;
                        this.departmentItems.forEach((item) => {
                            item.members.forEach((item2) => {
                                this.adminItems.push(item2);
                            });

                        });
                        console.log(this.departmentItems.length);
                        console.log(this.adminItems.length );
                        console.log(this.departmentItems);
                        console.log(this.adminItems );
                        if (this.departmentItems.length > 0 || this.adminItems.length > 1) {
                            this.no_admins = false;
                        }


                    } else {
                        let deps = res.body.data;
                        deps = buildTree(deps, 0, 'id', 'parent_id', 'children');
                        deps = buildListArrayFromTreeArray(deps);
                        for (let i in deps) {
                            let department = deps[i];
                            department.name = (' . '.repeat((department.depth ?? 1))) + department.name
                        }
                        this.departmentItems = deps;
                    }


                })
                .catch(() => {
                    this.departmentItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_departments'))
                });
        },

        async initAdmins() {
            let params = {};
            let business_process = 0;
            if (this.business_process && this.business_process.id) {
                business_process = this.business_process.id
            } else {
                business_process = this.$route.params.business_process
            }
            params = {
                fltr: {
                    business_process_id: {
                        eq: business_process
                    },
                    department_type: {
                        eq: 'participant'
                    }
                }
            }
            await this.getAdmins(params);
            if (this.adminItems.length > 1) {
                this.no_admins = false;
                this.no_executors = false;
            }
        },
        async getAdmins(params) {
            this.loadingAdmins = true

            params.filter = 'search'

            await this.$http
                .get("admin/admin", {
                    params: params,
                })
                .then(res => {
                    this.adminItems = res.body.data
                    this.executorItems = res.body.data
                    this.adminItems.unshift(this.only_author)
                })
                .catch(err => {
                    this.adminItems = []
                    this.executorItems = []
                })
                .finally(end => {
                    this.loadingAdmins = false
                })
        },
        async getDealStep() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/deal_step/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.deal_step = res.body.data.name
                    this.text = res.body.data.text
                    this.business_process = res.body.data.business_process
                    this.next_business_process = res.body.data.next_business_process
                    this.deal_status = res.body.data.deal_status
                    this.admin = res.body.data.admins
                    this.executors = res.body.data.executors
                    this.finish = res.body.data.finish
                    this.required_filled = res.body.data.required_filled
                    this.accompany_comment = res.body.data.accompany_comment
                    this.color = res.body.data.color
                    this.where_move = res.body.data.where_move
                    this.department = res.body.data.department
                    this.required_tabs = res.body.data.required_tabs
                    this.required_fields = res.body.data.required_fields
                    this.required_document = res.body.data.required_document
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                    this.all_translations = res.body.data.all_translations
                    if(res.body && res.body.data && res.body.data.only_author && res.body.data.only_author === 1){
                        if(this.admin){
                            this.admin.unshift(this.only_author)
                        }
                        else {
                            this.admin = [this.only_author]
                        }
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_deal_step'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveDealStep() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.deal_step) {
                formData.append('deal_step', this.deal_step)
            }

            if (this.next_business_process) {
                if (this.next_business_process.id) {
                    formData.append('next_business_process', this.next_business_process.id)
                } else {
                    formData.append('next_business_process', this.next_business_process)
                }
            }
            if (this.text) {
                formData.append('text', this.text)
            }
            if (this.finish) {
                formData.append('finish', 1)
            }
            if (this.required_filled) {
                formData.append('required_filled', 1)
            }
            if (this.accompany_comment) {
                formData.append('accompany_comment', 1)
            }
            if (this.department.length > 0) {
                for (let i = 0; i < this.department.length; i++) {
                    formData.append(`department[${i}]`, this.department[i]);
                }
            }

            if (this.$route.params.business_process) {
                formData.append('business_process', this.$route.params.business_process)
            }

            if (this.deal_status) {
                if (this.deal_status.id) {
                    formData.append('deal_status', this.deal_status.id)
                } else {
                    formData.append('deal_status', this.deal_status)
                }
            }

            if (this.color) {
                formData.append('color', this.color)
            }
            // if (this.where_move.length > 0) {
            //     for (let i = 0; i < this.where_move.length; i++) {
            //         formData.append(`where_move[${i}]`, this.where_move[i]);
            //     }
            // }
            if (this.required_tabs.length > 0) {
                for (let i = 0; i < this.required_tabs.length; i++) {
                    formData.append(`required_tabs[${i}]`, this.required_tabs[i]);
                }
            }
            if (this.required_fields.length > 0) {
                for (let i = 0; i < this.required_fields.length; i++) {
                    formData.append(`required_fields[${i}]`, this.required_fields[i]);
                }
            }
            if (this.required_document.length > 0) {
                for (let i = 0; i < this.required_document.length; i++) {
                    formData.append(`required_document[${i}]`, this.required_document[i]);
                }
            }

            if (this.admin && this.admin.length > 0) {
                for (let i in this.admin) {
                    if (this.admin[i].id !== undefined && this.admin[i].id > -1) {
                        formData.append(`admins[${i}]`, this.admin[i].id)
                    }
                }
            }
            if (this.executors && this.executors.length > 0) {
                for (let i in this.executors) {
                    if (this.executors[i].id !== undefined && this.executors[i].id > 0) {
                        formData.append(`executors[${i}]`, this.executors[i].id)
                    }
                }
            }

            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/deal_step/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_step_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                        this.closeDialogAdd()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_step_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/deal_step', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_step_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                        this.closeDialogAdd()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_step_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async getBusinessProcesses() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/business_process", {
                    params: params,
                })
                .then(res => {
                    this.businessProcessItems = res.body.data
                })
                .catch(err => {
                    this.businessProcessItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getDealSteps() {
            this.loading = true
            let params = {}
            // if (this.business_process) {
            //     if (this.business_process.id) {
            //         params.business_process = this.business_process.id
            //     } else {
            //         params.business_process = this.business_process
            //     }
            // }
            params.business_process = this.$route.params.business_process

            await this.$http
                .get("admin/deal_step", {
                    params: params,
                })
                .then(res => {
                    this.dealStepItems = res.body.data
                })
                .catch(err => {
                    this.dealStepItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getDocumentType() {
            this.loading = true
            let params = {}
            // if (this.business_process) {
            //     if (this.business_process.id) {
            //         params.business_process = this.business_process.id
            //     } else {
            //         params.business_process = this.business_process
            //     }
            // }
            params.business_process = this.$route.params.business_process
            await this.$http
                .get("admin/document_type", {
                    params: params,
                })
                .then(res => {
                    this.documentTypeItems = res.body.data
                })
                .catch(err => {
                    this.documentTypeItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        remove(item) {
            const index = this.where_move.indexOf(item.id)
            if (index >= 0) this.where_move.splice(index, 1)
        },
        removeDepartment(item) {
            const index = this.department.indexOf(item.id)
            if (index >= 0) this.department.splice(index, 1)
        },
        removeDocumentType(item) {
            const index = this.required_document.indexOf(item.id)
            if (index >= 0) this.required_document.splice(index, 1)
        },
        removeRequiredTabs(item) {
            const index = this.required_tabs.indexOf(item.id)
            if (index >= 0) this.required_tabs.splice(index, 1)
        },
        removeRequiredFields(item) {
            const index = this.required_fields.indexOf(item.id)
            if (index >= 0) this.required_fields.splice(index, 1)
        },

        async getDealStatuses() {
            this.loading = true
            let params = {}
            // if (this.business_process) {
            //     if (this.business_process.id) {
            //         params.business_process = this.business_process.id
            //     } else {
            //         params.business_process = this.business_process
            //     }
            // }
            params.business_process = this.$route.params.business_process
            await this.$http
                .get("admin/deal_status", {
                    params: params,
                })
                .then(res => {
                    this.deal_statusItems = res.body.data
                })
                .catch(err => {
                    this.deal_statusItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getRequiredFields() {
            this.loading = true
            let params = {}
            // if (this.business_process) {
            //     if (this.business_process.id) {
            //         params.business_process = this.business_process.id
            //     } else {
            //         params.business_process = this.business_process
            //     }
            // }
            params.business_process = this.$route.params.business_process
            await this.$http
                .get("admin/required_field", {
                    params: params,
                })
                .then(res => {
                    this.requiredFieldItems = res.body.data
                })
                .catch(err => {
                    this.requiredFieldItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
