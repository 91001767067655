<template>
    <PageLayout>
        <template v-slot:side>
            <v-icon color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
            <v-list class="filter_left mt-5">
                <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{$t('menu_integration')}}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link :class="item.id === type_email_connect ? 'active' : ''">
                        <v-list-item-title v-text="item.name" class="cursor-pointer" @click="sendRoute(item)"></v-list-item-title>
                        <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
        <template>
            <v-form @submit.prevent="getEmailConnects('search')">
                <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'email_connect.create'}" color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <TabsNav />
                    <v-row class="pt-3">
                        <v-col cols="12" sm="10" class="mt-1">
                            <h1>{{ filter_show ? $t('search_email_connect') : $t('email_connect') }}</h1>
                        </v-col>
                        <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" v-show="!filter_show" class="text-right">
                            <v-btn :to="{name: 'email_connect.create'}"  class="infinity_button" color="secondary">
                                {{$t('create') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="filter_show">
                        <v-col class="py-0" cols="12" >
                            <ValidationProvider ref="admin" rules="min:1"
                                                v-slot="{ errors, valid }">
                                <v-autocomplete v-model="admin" :items="adminItems"
                                                :error-messages="errors" :error="!valid"
                                                :search-input.sync="adminSearching"
                                                item-text="name" item-value="id"
                                                :loading="loadingAdmins"
                                                :no-data-text="adminSearching ? $t('nothing_found_by',{'search': adminSearching}) : $t('nothing_found_name')"
                                                :label="$t('user_name')"
                                                @click:clear="adminItems=[]"
                                                @click="clearAdmins"

                                                autocomplete="off"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                :disabled="loading"
                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                clearable>
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.phone"></span>
                                        </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.address_legal"></span>
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <div v-if="filter_show" class="px-4 py-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems"
                                          :label="$t('items_per_page')"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details
                                          outlined
                                          solo
                                          flat
                                          dense
                                          :disabled="loading"
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" class="infinity_button"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </ValidationObserver>
            </v-form>
            <div>
                <div>
                    <ResizableTable
                        class="main-data-table"
                        :rows="email_connects"
                        :columns="filteredHeaders.map((item) => {
                            return {
                                prop: item.value,
                                label: item.text,
                                width: 'auto',
                                active: true
                            }
                        })"
                    >
                        <template v-slot:username	="{ item }">
                            <div>
                                <span  class="cursor-pointer font_weight_600" @click="editEmailConnect(item)" >
                                    {{ item.username }}
                                </span>
                                <v-icon @click="deleteEmailConnect(item)" small>mdi-delete-outline</v-icon>

                            </div>
                        </template>
                        <template v-slot:email_connector_status_id="{ item }">
                            <div >{{ emailConnectorStatusItems[item.email_connector_status_id] }}</div>
                        </template>
                        <template v-slot:created_at="{ item }">
                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                        </template>


                    </ResizableTable>
                </div>
                <v-pagination v-model="page" v-if="email_connects.length > 0" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
            </div>
        </template>
    </PageLayout>
</template>
<script>

import { mapGetters } from "vuex"
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import debounce from "lodash/debounce";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";

export default {
    name: "EmailConnects",
    components: {
        PageLayout,
        ValidationProvider,
        ValidationObserver,
        TabsNav
    },
    data() {
        return {
            admin: null,
            adminItems: [],
            adminSearching: null,
            loadingAdmins: false,
            email_connects: [],
            sortBy: "id",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalEmailConnects: 0,
            options: {},
            loading: false,
            headers: [
                {
                    text: this.$t('email'),
                    align: "left",
                    sortable: true,
                    value: "username"
                },
                {
                    text: this.$t('email_connector_status'),
                    align: "left",
                    sortable: true,
                    value: "email_connector_status_id"
                },
                {
                    text: this.$t('date_created'),
                    align: "left",
                    sortable: true,
                    value: "created_at"
                }
            ],
            type_email_connectItems: [],
            type_email_connect: null,
            system_filters: [
                {'name': this.$t('emails'), 'count': '-' , 'route_name': 'email_connect'},
                {'name': 'Telegram', 'count': '-', 'route_name': 'telegram_connect'},
            ],
            group1: true,
            group2: true,
            filter_show: false,
            emailConnectorStatusItems: [
                '',
                this.$t('no_connection'),
                this.$t('connected'),
                this.$t('connection_is_outdated'),
            ]
        };

    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getEmailConnects()
    },
    watch: {
        options: {
            handler() {
                this.getEmailConnects()
            },
            deep: false
        },
        adminSearching: debounce(function (val) {
            if (val && !this.admin) {
                this.getAdmins(val)
            }
        }, 500),
    },
    methods: {
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
        },
        sendRoute(item){
            this.$router.push({
                name: item.route_name,
            })
        },
        async getEmailConnects(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};

            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.type_email_connect) {
                params.type_email_connect = this.type_email_connect
            }


            if (this.admin) {
                if (this.admin.id) {
                    params.admin =  this.admin.id
                } else {
                    params.admin =  this.admin
                }
            }
            await this.$http
                .get("admin/email_reader/connector", {
                    params: params,
                })
                .then(res => {
                    this.email_connects = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalEmailConnects = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.email_connects = []
                    this.totalEmailConnects = 0
                    this.$toastr.error(this.$t('failed_to_get_list_email_connects'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        editEmailConnect(item) {
            this.$router.push({
                name: 'email_connect.edit',
                params: {
                    id: item.id
                }
            });
        },
        async deleteEmailConnect(item) {
            const index = this.email_connects.indexOf(item)
            if (confirm(this.$t('delete_email_connect'))) {
                this.loading = true;
                await this.$http
                    .delete(`admin/email_reader/connector/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('email_connect_has_been_deleted'));
                        this.getEmailConnects();
                    })
                    .catch(err => {
                        //this.sub_statusItems = []
                        //this.totalSubStatuses = 0
                        this.$toastr.error(this.$t('email_connect_has_not_been_deleted'));
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    /*
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                     */
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message);
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    });
            }
        },
        async getAdmins(str) {
            if (str) {
                this.loadingAdmins = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.admin = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.adminItems = res.body.data
                    })
                    .catch(err => {
                        this.adminItems = []
                    })
                    .finally(end => {
                        this.loadingAdmins = false
                    })
            }
        },
        clearAdmins() {
            if (!this.admin) {
                this.adminItems = []
            }
        },
        redirectToAdmin(val) {
            if (val) {
                this.$router.push({
                    name: 'admin.profile',
                    params: {
                        id: val
                    }
                })
            }
        },
    }
}
</script>
