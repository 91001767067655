<script>
import debounce from "lodash/debounce";
import EntitySelectionPopup from "@/components/Form/EntitySelectionPopup.vue";

export default {
    name: "UvedSelectionPopup",

    components: {
        EntitySelectionPopup,
    },

    props: {
        searchValue: {
            type: String,
            default: ''
        },

        focusItemIndex: {
            type: Number,
            default: null
        },

        value: {
            required: false,
        },

        multiple: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            items: [],
            loading: false,
            selected: [],
            innerValue: null,

            bases: null,
            clientTypes: null,
            filters: [],
            loaded: false,
            lastFilter: null
        }
    },

    watch: {
        searchValue: debounce(function (value) {
            if (value) {
                this.fetchUveds({
                    name: {
                        contains: value
                    },
                    ...this.lastFilter
                }).then(items => {
                    this.items = items
                })
            } else {
                this.fetchUveds(this.lastFilter).then(items => {
                    this.items = items
                })
            }
        }, 500),

        value: {
            handler: function (value) {
                this.innerValue = value;
                this.fetchUveds(this.lastFilter).then(items => {
                    this.items = items
                });
            },
            deep: true,
            immediate: true
        },

        bases: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        clientTypes: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        focusItemIndex: function (value) {
            if (value !== null) {
                this.$nextTick(() => {
                    const item = this.$refs['users-list'].querySelectorAll('.entity-select__item').item(value);

                    if(item) {
                        item.focus()
                    }
                });
            }
        }
    },

    methods: {
        showPopup: function () {
            if(this.loaded) {
                return;
            }

            this.fetchUveds({
                name: {
                    contains: this.searchValue
                }
            }).then((items) => {
                 this.items = items
            })

            this.fetchBases().then((items) => {
                this.bases = items;
            })

            this.fetchClientTypes().then((items) => {
                this.clientTypes = items
            })

            this.loaded = true;
        },

        updateValue: function (value) {
            this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        clear() {
            this.updateValue([])
            this.items = [];
        },

        input(value) {
            this.updateValue(value);
        },

        async fetchUveds(filter) {
            let fltr = {
                active: {
                    eq: '1'
                },
            };

            this.loading = true

            if(this.multiple) {
                if(this.innerValue && this.innerValue.length > 0) {
                    fltr.id = {
                        notin: this.innerValue.map(item => item.id)
                    }
                }
            } else {
                if(this.innerValue) {
                    fltr.id = {
                        notin: [this.innerValue.id]
                    }
                }
            }

            if(this.searchValue) {
                fltr.name = {
                    contains: this.searchValue
                }
            }

            fltr = {
                ...fltr,
                ...filter
            }

            let params = {
                perPage: 20,
                fltr: fltr
            };

            let res = await this.$http.get('admin/uved', {
                params: params
            })

            // on error
            if (res.status < 200 && res.status >= 300) {
                this.$toastr.error(this.$t('failed_to_get_administrator'))
                return [];
            }

            this.loading = false
            let user = res.body.data;

            this.$emit('uveds-fetched', user);

            return user;
        },

        async fetchBases() {
            let self = this;
            let params = {};
            let res = await this.$http.get('admin/base', {
                params: params
            });

            return res.body.data.map(item => {
                return {
                    ...item,
                    name: item.name,
                    click: function (itm) {
                        self.onBaseClick(itm)
                    }
                }
            });
        },

        async fetchClientTypes() {
            let self = this;
            let params = {
                perPage: 0
            };
            let res = await this.$http.get('admin/type_client', {
                params: params
            });

            return res.body.data.map(item => {
                return {
                    ...item,
                    click: function (itm) {
                        self.onClientTypeClick(itm)
                    }
                }
            });
        },

        onBaseClick(itm) {
            this.lastFilter = {
                base: {
                    eq: itm.id
                }
            };

            this.fetchUveds(this.lastFilter).then(items => {
                this.items = items;
            })
        },

        onClientTypeClick(itm) {
            this.lastFilter = {
                type_client: {
                    eq: itm.id
                }
            }
            this.fetchUveds(this.lastFilter).then(items => {
                this.items = items;
            })
        },

        buildFilters() {
            let self = this;
            return [
                {
                    name: this.$t('all'),
                    click: function () {
                        self.lastFilter = {};
                        self.fetchUveds(this.lastFilter).then(items => {
                            self.items = items
                        });
                    }
                },
                {
                    name: this.$t('base'),
                    children: this.bases
                },
                {
                    name: this.$t('type_client'),
                    children: this.clientTypes
                },
            ]
        }
    },

    mounted() {
        this.showPopup();
    }
}
</script>

<template>
    <EntitySelectionPopup
        :items="items"
        :filters="filters"
        :multiple="multiple"
        :value="value"
        :loading="loading"
        @input="input"
    >
        <template v-slot:item="{ item }">
            <div class="usr">
                <div class="usr">
                    <div class="usr__info">
                        <div class="usr__name">
                            {{item.name}}
                        </div>
                        <div class="usr__position">
                            <slot name="position">
                                {{formatPhoneNumber(item.phone.phone)}}
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </EntitySelectionPopup>
</template>
