var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.value,"max-width":"992"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('nomenclature.filling.title'))+" ")]),_c('v-card-text',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-row',[_c('v-col',[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('SelectInput',{attrs:{"id":"nomenclature-group","label":_vm.$t('nomenclature_group.self'),"items":_vm.nomenclatureGroups,"item-text":"name","item-value":"id","error":!valid},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('TextInput',{attrs:{"label":_vm.$t('nomenclature.filling.min-stock'),"id":"min-stock","error":!valid},model:{value:(_vm.minStock),callback:function ($$v) {_vm.minStock=$$v},expression:"minStock"}})]}}],null,true)})],1)],1)]}}])})],1),_c('v-card-actions',[_c('Btn',{attrs:{"color":"secondary","loading":_vm.loading},on:{"click":_vm.onPreviewBtnClick}},[_vm._v(_vm._s(_vm.$t('nomenclature.filling.preview')))])],1),(_vm.items.length > 0)?_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-1"},[_c('TextInput',{attrs:{"label":_vm.$t('nomenclature.filling.set_quantity_value'),"id":"min-stock"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})],1),_c('v-col',{staticClass:"d-flex align-end flex-grow-0"},[_c('Btn',{on:{"click":_vm.onSetQuantityClick}},[_vm._v(" "+_vm._s(_vm.$t('nomenclature.filling.set_quantity'))+" ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                                {
                                    text: _vm.$t('columns.nomenclature.name'),
                                    align: 'start',
                                    sortable: false,
                                    value: 'name'
                                },
                                {
                                    text: _vm.$t('columns.nomenclature.stock'),
                                    align: 'start',
                                    sortable: false,
                                    value: 'stock'
                                },
                                {
                                    text: _vm.$t('nomenclature.filling.quantity'),
                                    align: 'quantity',
                                    sortable: false,
                                    value: 'quantity'
                                }
                            ],"items":_vm.items,"item-key":"id","show-select":"","dense":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function({ item }){return [_c('TextInput',{attrs:{"label":_vm.$t('quantity'),"type":"number","min":"0","dens":"","step":"1","id":`item-quantity-${item.id}`,"hide-details":"","hide-label":""},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}}],null,false,2840826943),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1):_vm._e(),_c('v-card-actions',[_c('Btn',{on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),(_vm.selectedItems.length > 0)?_c('Btn',{attrs:{"color":"primary"},on:{"click":_vm.onFillBtnClick}},[_vm._v(_vm._s(_vm.$t('nomenclature.filling.fill')))]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }