export default {
    data() {
        return {
            warehouses: [],
        }
    },

    methods: {
        initWarehouses() {
            this.fetchWarehouses()
                .then((warehouses) => {
                    this.warehouses = warehouses;
                });
        },

        fetchWarehouses(params = {}) {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/warehouse/warehouse', params)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },
}
