<script>
import FileDialog from "@/components/FileDialog.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "FileUploader",
    components: {Btn, FileDialog},

    props: {
        entityType: {
            type: String,
            required: true
        },

        entityUuid: {
            type: String,
            required: true
        },

        accept: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            uploadingFiles: [],
            uploadingFilesPreview: [],
            uploadingFilesPreviewDialog: false
        }
    },

    methods: {
        init() {

        },

        onFileSend(previewFiles) {
            this.loading = true;
            this.$emit('files-uploading-start');

            this.uploadFiles(previewFiles)
                .then((body) => {
                    this.$emit('files-uploaded', body.data);
                }).catch((error) => {
                    this.loading = false;
                }).finally(() => {
                    this.uploadingFilesPreviewDialog = false;
                });
        },

        onUploadBtnClick() {
            this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
        },

        uploadFiles(previewFiles) {
            let formData = new FormData();
            let i = 0;

            previewFiles.forEach((file) => {
                formData.append(`files[${i}][file]`, file.blob);
                formData.append(`files[${i}][property]`, 'images');

                if(file.description) {
                    formData.append(`files[${i}][description]`, file.description);
                }

                i++;
            });

            return new Promise((resolve, reject) => {
                this.$http.post(`admin/${this.entityType}/${this.entityUuid}/file`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    resolve(response.body);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        onFileChange() {
            this.uploadingFilesPreviewDialog = true;
        },
    }
}
</script>

<template>
    <div>
        <slot name="activator">
            <Btn @click="onUploadBtnClick" color="secondary">{{ $t('upload_file') }}</Btn>
        </slot>

        <v-file-input
            ref="fileInput"
            hide-input
            prepend-icon=""
            v-model="uploadingFiles"
            id="accompanying_documents"
            multiple
            @change="onFileChange"
            style="display:none;"
            :accept="accept"
        >
        </v-file-input>

        <FileDialog
            v-model="uploadingFiles"
            :show-dialog="uploadingFilesPreviewDialog"
            @send="onFileSend"
            @closeDialog="uploadingFilesPreviewDialog=false"
        ></FileDialog>
    </div>
</template>

<style scoped lang="scss">

</style>
