import nav from '@/plugins/nav';
import {mapGetters, mapState} from "vuex";

const getItems = (Vue) => {
    let items = nav;

    items = getVisibility(items, Vue)
    items = getActivity(items, Vue)
    items = getHasNew(items, Vue)

    return items;
}

const getVisibility = (items, Vue) => {
    items.forEach((item, index) => {
        items[index].visible = Vue.can(item.permissions);

        if(item.items) {
            items[index].items = getVisibility(item.items, Vue);
        }
    });

    return items;
}

const getActivity = (items, Vue) => {
    items.forEach((item, index) => {
        items[index].active = Vue.$route.path === Vue.$router.resolve(item.route).resolved.path;
        let hasActiveChild = false;

        if(item.items) {
            items[index].items = getActivity(item.items, Vue);
            hasActiveChild = item.items.some((child) => child.active);
        }

        items[index].active = items[index].active || hasActiveChild;
    });

    return items;
}

const getHasNew = (items, Vue) => {
    if(!Vue.notificationGroups || Vue.notificationGroups.length <= 0) {
        return items;
    }

    items.forEach((item, index) => {
        if(items[index].items) {
            items[index].items = getHasNew(item.items, Vue);
            items[index].new = 0
            items[index].items.forEach((child) => {
                items[index].new += child.new ?? 0;
            });
        } else {
            items[index].new = Vue.notificationGroups[item.notification_group] ?? 0;
        }
    });

    return items;
}

const breadcrumbs = (items) => {
    let breadCrumbs = [];
    let breadcrumb = items.find((item) => item.active);

    if(breadcrumb) {
        breadCrumbs.push(breadcrumb);

        if(breadcrumb.items) {
            breadCrumbs = breadCrumbs.concat(breadcrumbs(breadcrumb.items));
        }
    }

    return breadCrumbs;
}

const NavMixin = {
    data() {
        return {
            navItems: getItems(this)
        }
    },

    created() {},

    computed: {
        ...mapGetters(['notificationGroups']),
    },

    watch: {
        '$route'() {
            // this.navItems = getItems(this);
        },

        notificationGroups() {
            this.$set(this, 'navItems', getItems(this));
            this.$forceUpdate();
        }
    },

    methods: {
        breadcrumbs() {
            return breadcrumbs(this.navItems);
        }
    }
};

export default NavMixin;
