<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>
                        <template v-if="currentFilter">
                            {{ currentFilter.name }}
                        </template>
                        <template v-else>
                            {{ $t('warehouse.dashboard') }}
                        </template>
                    </h1>
                </div>
            </div>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import FilterView from "@/plugins/mixins/FilterView";
import ResourceView from "@/plugins/mixins/ResourceView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Side from "@/components/Warehouse/Side.vue";
import NavMixin from "@/plugins/mixins/Nav";

export default {
    name: "Warehouses",
    directives: {
        mask,
    },
    components: {
        PageLayout,
        Side
    },
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    data() {
        return {
            entityName: 'warehouse',
            invalid: false,
            items: [],
            sortBy: "id",
            sortDir: false,
            page: 1,
            pageCount: 0,
            options: {
                ...FilterView.data().options,
                itemsPerPage: 10,
                sortBy: [
                    "id"
                ],
            },
            loading: false
        };
    },

    computed: {
        ...mapGetters(['userBlock', 'lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.getItems();
            this.getFilters();
        },

        updateKey(oldVal, head) {
            if (oldVal) {
                const oldKey = oldVal;
                const newKey = head.field;
                if (oldKey === newKey) {
                    return
                }
                for (let i = 0; i < this.tableData.length; i++) {
                    Object.entries(this.tableData[i]).forEach(([key, item]) => {
                        if (key === oldKey) {
                            this.tableData[i][newKey] = this.tableData[i][key]
                            delete this.tableData[i][oldKey];
                            head.value = newKey;
                            head.show = newKey;
                        }
                    });
                }
                const foundObject = this.headerOptions.find(obj => obj.value === newKey);
                if (foundObject) {
                    foundObject.disabled = true
                }
            }
        },

        handleSuccess({results, header}) {
            this.tableData = results
            for (let i = 0; i < header.length; i++) {
                this.tableHeader.push(
                    {
                        text: header[i],
                        align: "left",
                        sortable: false,
                        value: header[i],
                        field: 'column_' + i,
                        field_clear: 'column_' + i,

                    }
                );
            }
        },

        async deleteItem(item) {
            if (confirm(this.$t('warehouse.delete'))) {
                var _this = this
                this.loading = true;

                await this.$http
                    .delete(`admin/warehouse/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('warehouse.has_been_deleted'))
                        this.getItems()
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('warehouse.has_not_been_deleted'))
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async restoreItem(item) {
            if (confirm(this.$t('warehouse.restore'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .post(`admin/warehouse/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('warehouse.has_been_restored'))
                        this.getItems()
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('warehouse.has_not_been_restored'))
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async getItems() {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;

            let params = {};

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            if (sortBy !== undefined && sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            } else {
                params.sortBy = 'id';
            }

            if (sortDesc !== undefined && sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            } else {
                params.sortDir = 'asc';
            }

            if (page !== undefined) {
                params.page = page
            }

            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            } else {
                params.perPage = this.itemsPerPage;
            }

            await this.$http
                .get("admin/warehouse/warehouse", {
                    params: params,
                })
                .then(res => {
                    this.items = res.body.data
                    if (this.page > res.body.meta.last_page) {
                        this.onPageChange(res.body.meta.last_page);
                    }
                    this.totalUveds = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.items = []
                    this.totalUveds = 0
                    this.$toastr.error(this.$t('failed_to_get_list_uveds'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
