<script>
import TextInput from "@/components/Form/TextInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import WithNomenclatureGroups from "@/plugins/mixins/WithNomenclatureGroups";
import WithNomenclature from "@/plugins/mixins/WithNomenclature";
import Btn from "@/components/Form/Btn.vue";


export default {
    name: "OrderToBuyItemsFillingDialog",

    components: {Btn, ValidationProvider, ValidationObserver, SelectInput, TextInput},

    mixins: [
        WithNomenclatureGroups,
        WithNomenclature
    ],

    props: {
        value: {
            type: Boolean,
            required: true
        },

        itemsInDoc: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            loading: false,
            items: [],

            quantity: 0,
            nomenclatureSections: [],
            group: null,
            mode: null,
            minStock: 0,
            selectedItems: []
        }
    },

    computed: {},

    watch: {},

    mounted() {
        this.initNomenclatureGroups();
    },

    methods: {
        onPreviewBtnClick() {
            this.loading = true;
            let filter = {
                stock: {
                    lte: this.minStock
                }
            };

            if (this.itemsInDoc.length > 0) {
                filter['id'] = {
                    notin: [
                        ...this.itemsInDoc.map((item) => item.nomenclature_id + '')
                    ]
                };
            }

            if (this.group) {
                filter['nomenclature_group_id'] = {eq: this.group};
            }

            this.fetchNomenclature({
                fltr: filter,
                perPage: 999999
            })
                .then((body) => {
                    this.items = body.data;
                    this.items.forEach((item) => {
                        item.quantity = item.stock;
                    });
                    this.selectedItems = [...this.items];
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        onFillBtnClick() {
            this.$emit('fill', this.selectedItems.map((item) => {
                return {
                    quantity: this.quantity,
                    nomenclature: item,
                    nomenclature_id: item.id,
                    vat_id: 0,
                    price: 0
                }
            }));
        },

        onSetQuantityClick() {
            this.items.forEach((item) => {
                item.quantity = this.quantity;
            });

            this.$set(this, 'items', [...this.items]);
        },
    }
}
</script>

<template>
    <v-dialog :value="value" @input="$emit('input', $event)" max-width="992">
        <v-card>
            <v-card-title>
                {{ $t('nomenclature.filling.title') }}
            </v-card-title>
            <v-card-text>
                <ValidationObserver v-slot="{ errors }">
                    <v-row>
                        <v-col>
                            <ValidationProvider v-slot="{ errors, valid }">
                                <SelectInput
                                    id="nomenclature-group"
                                    v-model="group"
                                    :label="$t('nomenclature_group.self')"
                                    :items="nomenclatureGroups"
                                    item-text="name"
                                    item-value="id"
                                    :error="!valid"
                                ></SelectInput>
                            </ValidationProvider>
                        </v-col>
                        <v-col>
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <TextInput
                                    v-model="minStock"
                                    :label="$t('nomenclature.filling.min-stock')"
                                    id="min-stock"
                                    :error="!valid"
                                ></TextInput>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>
            <v-card-actions>
                <Btn @click="onPreviewBtnClick" color="secondary" :loading="loading">{{ $t('nomenclature.filling.preview') }}</Btn>
            </v-card-actions>
            <v-card-text class="pt-0" v-if="items.length > 0">
                <v-row>
                    <v-col class="flex-grow-1">
                        <TextInput
                            v-model="quantity"
                            :label="$t('nomenclature.filling.set_quantity_value')"
                            id="min-stock"
                        ></TextInput>
                    </v-col>
                    <v-col class="d-flex align-end flex-grow-0">
                        <Btn @click="onSetQuantityClick">
                            {{ $t('nomenclature.filling.set_quantity') }}
                        </Btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table
                            v-model="selectedItems"
                            :headers="[
                                    {
                                        text: $t('columns.nomenclature.name'),
                                        align: 'start',
                                        sortable: false,
                                        value: 'name'
                                    },
                                    {
                                        text: $t('columns.nomenclature.stock'),
                                        align: 'start',
                                        sortable: false,
                                        value: 'stock'
                                    },
                                    {
                                        text: $t('nomenclature.filling.quantity'),
                                        align: 'quantity',
                                        sortable: false,
                                        value: 'quantity'
                                    }
                                ]"
                            :items="items"
                            item-key="id"
                            show-select
                            dense
                            disable-pagination
                            hide-default-footer
                            class="elevation-1"
                        >
                            <template
                                v-slot:item.quantity="{ item }"
                            >
                                <TextInput
                                    v-model="item.quantity"
                                    :label="$t('quantity')"
                                    type="number"
                                    min="0"
                                    dens
                                    step="1"
                                    :id="`item-quantity-${item.id}`"
                                    hide-details
                                    hide-label
                                ></TextInput>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <Btn @click="$emit('input', false)">{{ $t('cancel') }}</Btn>
                <Btn v-if="selectedItems.length > 0" @click="onFillBtnClick" color="primary">{{ $t('nomenclature.filling.fill') }}</Btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
