<template>
    <v-container fluid>
        <v-form @submit.prevent="saveDealType">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'deal_type'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" md="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="deal_type" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="deal_type" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-home-deal_type-usd"
                                                  :label="$t('deal_type_name')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="business_process" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="business_process"
                                              :items="businessProcessItems" :error-messages="errors"
                                              :error="!valid"
                                              item-text="name" item-value="id"
                                              :label="$t('business_process')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :disabled="loading"
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="unit" rules="required|min:1|max:50"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="unit" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-home-deal_type-usd"
                                                  :label="$t('unit.self')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="price" rules="required|numeric|min_value:0|max_value:1000000"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="price" type="number"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-home-deal_type-usd"
                                                  :label="$t('default_price')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="count" rules="required|numeric|min_value:1|max_value:1000000"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="count" type="number"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-home-deal_type-usd"
                                                  :label="$t('default_count')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="discount" rules="required|min_value:0|max_value:1000000"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="discount" type="number"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-home-deal_type-usd"
                                                  :label="$t('default_discount')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="extra_charge" rules="required|min_value:0|max_value:1000000"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="extra_charge" type="number"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-home-deal_type-usd"
                                                  :label="$t('default_extra_charge')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="nds" rules="required|min_value:0|max_value:1000000"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="nds" type="number"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-home-deal_type-usd"
                                                  :label="$t('nds')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-6">
                        <v-row>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :loading="loading" color="primary">
                                    {{ $t('save') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";

export default {
    name: 'DealTypeForm',
    components: {
        ValidationProvider,
        ValidationObserver
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            deal_type: null,
            unit: null,
            count: 1,
            price: 0,
            discount: 0,
            nds: 0,
            extra_charge: 0,
            exist_translations: {},
            all_translations: true,
            business_process:null,
            businessProcessItems:[],
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.getBusinessProcesses();
        this.checkCreate()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "deal_type.create") {
                this.heading = this.$t('deal_type_creation')
            } else {
                this.heading = this.$t('deal_type_editing')
                if (this.$route.params.id) {
                    this.getDealType()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        async getDealType() {
            this.loading = true
            let params = {};
            if (this.language) {
                params.language = this.language;
            }
            await this.$http
                .get(`admin/deal_type/${this.$route.params.id}`, {params: params})
                .then(res => {
                    this.deal_type = res.body.data.name
                    this.unit = res.body.data.unit
                    this.discount = res.body.data.discount
                    this.nds = res.body.data.nds
                    this.extra_charge = res.body.data.extra_charge
                    this.count = res.body.data.count
                    this.price = res.body.data.price
                    this.business_process = res.body.data.business_process
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_deal_type'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async saveDealType() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.deal_type) {
                formData.append('deal_type', this.deal_type)
            }
            if (this.unit) {
                formData.append('unit', this.unit)
            }
            if (this.count) {
                formData.append('count', this.count)
            }

            formData.append('price', this.price)

            if (this.discount >= 0) {
                formData.append('discount', this.discount)
            }

            if (this.nds >= 0) {
                formData.append('nds', this.nds)
            }
            if (this.extra_charge >= 0) {
                formData.append('extra_charge', this.extra_charge)
            }

            if (this.business_process) {
                if (this.business_process.id) {
                    formData.append('business_process', this.business_process.id)
                } else {
                    formData.append('business_process', this.business_process)
                }
            }

            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/deal_type/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_type_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_type_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/deal_type', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_type_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'deal_type.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                            //this.forceRerender()
                        } else {
                            this.$router.push({
                                name: 'deal_type'
                            })
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_type_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async getBusinessProcesses() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/business_process", {
                    params: params,
                })
                .then(res => {
                    this.businessProcessItems = res.body.data
                })
                .catch(err => {
                    this.businessProcessItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
