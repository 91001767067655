<script>
import WithBuyerOrderItems from "@/plugins/mixins/WithBuyerOrderItems";
import BuyerOrderItemEditDialog from "@/components/Warehouse/Sale/BuyerOrderItemEditDialog.vue";
import ResourceView from "@/plugins/mixins/ResourceView";
import TextInput from "@/components/Form/TextInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import WithVats from "@/plugins/mixins/WithVats";

export default {
    name: "BuyerOrderItems",
    components: {SelectInput, TextInput, BuyerOrderItemEditDialog},

    mixins: [
        WithBuyerOrderItems,
        WithVats,
        ResourceView
    ],

    props: {
        buyerOrder: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            entityName: 'buyer_order_item',

            editingItemId: null,
            editingField: null,

            items: [],
            addItemDialogShown: false,
        }
    },

    computed: {
        sum() {
            return this.items.reduce((acc, item) => acc + parseFloat(item.sum), 0)
        },

        sumDiscount() {
            return this.items.reduce((acc, item) => acc + parseFloat(item.discount_sum), 0).toFixed(2)
        },

        sumWithDiscount() {
            return this.items.reduce((acc, item) => acc + parseFloat(item.sum_with_discount), 0)
        },

        vatSum() {
            return this.items.reduce((acc, item) => acc + parseFloat(item.vat_sum), 0)
        },

        sumWithoutVat() {
            return this.items.reduce((acc, item) => acc + parseFloat(item.sum_without_vat), 0).toFixed(2)
        },

        total() {
            return this.items.reduce((acc, item) => acc + parseFloat(item.total), 0)
        },
    },

    watch: {
        buyerOrder: {
            handler() {
                this.init()
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        init() {
            this.fetchItems(this.buyerOrder.uuid).then((body) => {
                this.items = body.data
            })
        },

        onAddItemBtnClick() {
            this.addItemDialogShown = true;
        },

        onItemSaved(data) {
            this.fetchItems(this.buyerOrder.uuid).then((body) => {
                this.items = body.data
            })

            this.addItemDialogShown = false;
        },

        onDeleteItemClick(item) {
            this.deleteItem(this.buyerOrder.uuid, item.uuid).then(() => {
                this.$toastr.success(this.$t('buyer_order_item.deleted'))
                this.fetchItems(this.buyerOrder.uuid).then((body) => {
                    this.items = body.data
                })
            }).catch(() => {
                this.$toastr.error(this.$t('buyer_order_item.delete_error'))
            })
        },

        onValueChange(item, value, field) {
            if (item[field] === value) {
                return
            }

            let data = {
                nomenclature_id: item.nomenclature_id,
                price: item.price,
                vat_id: item.vat_id,
                quantity: item.quantity,
                discount: item.discount,
            }

            data[field] = value
            this.$set(item, field, value)

            this.updateItem(this.buyerOrder.uuid, item.uuid, data).then((data) => {
                this.$toastr.success(this.$t('order_to_buy_item.updated'))
                let item = this.items.findIndex((item) => item.uuid === data.uuid);
                this.$set(this.items, item, data)
            }).catch((error) => {
                this.$toastr.error(this.$t('order_to_buy_item.update_error'))
                this.$toastr.error(error)
            })
        },
    },
}
</script>

<template>
    <v-card>
        <v-card-title>
            {{ $t('buyer_order_item.plural') }}
            <v-icon class="ml-2" color="secondary" dense @click="onAddItemBtnClick">mdi-plus-circle-outline</v-icon>
        </v-card-title>
        <v-card-text>
            <BuyerOrderItemEditDialog
                v-model="addItemDialogShown"
                :buyer-order="buyerOrder"
                @saved="onItemSaved"
                @input="addItemDialogShown = $event"
            ></BuyerOrderItemEditDialog>

            <v-row>
                <v-col>
                    <ResizableTable :columns="[
                        ...tableColumns,
                        {
                            active:true,
                            default:true,
                            label: $t('total'),
                            name: 'total',
                            prop: 'total',
                            sortable:false,
                        }
                    ]" :rows="items">
                        <template v-slot:nomenclature="{ item }">
                            <RouterLink :to="{
                                name: 'nomenclature.edit',
                                params: { uuid: item.nomenclature.uuid }
                            }">{{ item.nomenclature.name }}</RouterLink>
                            <v-icon small @click="onDeleteItemClick(item)">
                                mdi-trash-can-outline
                            </v-icon>
                        </template>
                        <template v-slot:price="{ item }">
                            <TextInput
                                id="price"
                                label=""
                                :value="item.price"
                                dense
                                outlined
                                hide-label
                                @change="onValueChange(item, $event, 'price')"
                            ></TextInput>
                            <v-chip v-for="p in item.nomenclature.prices" :key="p.id" class="ml-2 mt-2" small @click="onValueChange(item, p.value + '', 'price')">
                                {{ p.price_type.name }}: {{ p.value }}
                            </v-chip>
                        </template>
                        <template v-slot:discount="{ item }">
                            <TextInput
                                id="price"
                                label=""
                                :value="item.discount"
                                dense
                                outlined
                                hide-label
                                @change="onValueChange(item, $event, 'discount')"
                            ></TextInput>
                        </template>
                        <template v-slot:quantity="{ item }">
                            <TextInput
                                id="price"
                                label=""
                                :value="item.quantity"
                                dense
                                outlined
                                hide-label
                                @change="onValueChange(item, $event, 'quantity')"
                            ></TextInput>
                        </template>
                        <template v-slot:vat="{ item }">
                            <SelectInput
                                id="vat"
                                label=""
                                :value="item.vat_id"
                                dense
                                outlined
                                hide-label
                                :items="vats.map(vat => ({ text: vat.value, value: vat.id }))"
                                @input="onValueChange(item, $event, 'vat_id')"
                            ></SelectInput>
                        </template>
                        <template v-slot:total="{ item }">
                            <div style="font-weight: bold;">
                                <span class="total__value">{{ item.sum_with_discount }}</span>
                            </div>
                        </template>
                    </ResizableTable>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex justify-end">
                    <table v-if="items" style="text-align: right;">
                        <tr>
                            <td>{{ $t('total_amount_discount') }}:</td>
                            <td>{{ sumDiscount }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('total_amount_without_discount') }}:</td>
                            <td>{{ sum }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('total_amount_without_vat') }}:</td>
                            <td>{{ sumWithoutVat }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('total_amount_vat') }}:</td>
                            <td>{{ vatSum }}</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: 20px">
                            <td>{{ $t('total_amount') }}:</td>
                            <td>{{ sumWithDiscount }}</td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<style scoped lang="scss">

</style>
