<script>
import debounce from "lodash/debounce";
import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";
import TreeMenu from "@/components/Leentech/TreeMenu.vue";
import User from "@/components/User.vue";
import EntitySelectionPopup from "@/components/Form/EntitySelectionPopup.vue";

export default {
    name: "UserSelectionPopup",
    components: {
        EntitySelectionPopup,
        User
    },

    props: {
        searchValue: {
            type: String,
            default: ''
        },

        focusItemIndex: {
            type: Number,
            default: null
        },

        value: {
            required: false,
        },

        multiple: {
            type: Boolean,
            default: false
        },

        filter: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            items: [],
            loading: false,
            selected: [],
            innerValue: null,

            departments: null,
            roles: null,
            positions: null,
            filters: [],
            loaded: false,
            lastFilter: null,
            page: 1,
            maxPage: null
        }
    },

    watch: {
        searchValue: debounce(function (value) {
            this.page = 1;
            if (value) {
                this.fetchUsers({
                    name: {
                        contains: value
                    },
                    ...this.lastFilter
                }).then(items => {
                    this.items = items
                })
            } else {
                this.fetchUsers(this.lastFilter).then(items => {
                    this.items = items
                })
            }
        }, 500),

        value: {
            handler: function (value) {
                this.innerValue = value;
                this.fetchUsers(this.lastFilter).then(items => {
                    this.items = items
                });
            },
            deep: true,
            immediate: true
        },

        departments: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        roles: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        positions: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        focusItemIndex: function (value) {
            if (value !== null) {
                this.$nextTick(() => {
                    const item = this.$refs['users-list'].querySelectorAll('.entity-select__item').item(value);

                    if(item) {
                        item.focus()
                    }
                });
            }
        }
    },

    methods: {
        showPopup: function () {
            if(this.loaded) {
                return;
            }

            this.page = 1;

            this.fetchUsers({
                name: {
                    contains: this.searchValue
                }
            }).then((items) => {
                 this.items = items
            })

            this.fetchDepartments().then((items) => {
                this.departments = items;
            })

            this.fetchPositions().then((items) => {
                this.positions = items
            })

            this.fetchRoles().then(items => {
                this.roles = items;
            })

            this.loaded = true;
        },

        updateValue: function (value) {
            this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        clear() {
            this.updateValue([])
            this.items = [];
        },

        input(value) {
            this.updateValue(value);
        },

        async fetchUsers(filter) {
            let fltr = {
                active: {
                    eq: '1'
                },

                ...this.filter
            };

            if(this.multiple) {
                if(this.innerValue && this.innerValue.length > 0) {
                    fltr.id = {
                        notin: this.innerValue.map(item => item.id)
                    }
                }
            } else {
                if(this.innerValue) {
                    fltr.id = {
                        notin: [this.innerValue.id]
                    }
                }
            }

            if(this.searchValue) {
                fltr.name = {
                    contains: this.searchValue
                }
            }

            fltr = {
                ...fltr,
                ...filter
            }

            let params = {
                perPage: 20,
                page: this.page,
                fltr: fltr
            };

            this.loading = true

            let res = await this.$http.get('admin/admin', {
                params: params
            })

            // on error
            if (res.status < 200 && res.status >= 300) {
                this.$toastr.error(this.$t('failed_to_get_administrator'))
                return [];
            }

            this.loading = false
            let user = res.body.data;
            this.maxPage = res.body.meta.last_page;

            this.$emit('users-fetch', user);

            return user;
        },

        async fetchDepartments() {
            let self = this;
            let params = {};
            let res = await this.$http.get('admin/department', {
                params: params
            });

            let deps = res.body.data;

            deps.forEach((item) => {
                let repeatCount = (item.depth ?? 1) - 1;
                item.name = ('. '.repeat(repeatCount)) + item.name;
                item.click = function (itm) {
                    self.onDepartmentClick(itm)
                }
                return item;
            })

            deps = buildTree(deps, 0, 'id', 'parent_id', 'children');
            deps = buildListArrayFromTreeArray(deps);

            return deps;
        },

        async fetchRoles() {
            let self = this;
            let params = {};
            let res = await this.$http.get('admin/role', {
                params: params
            });

            return res.body.data.map(item => {
                return {
                    ...item,
                    name: item.display_name,
                    slug: item.name,
                    click: function (itm) {
                        self.onRoleClick(itm)
                    }
                }
            });
        },

        async fetchPositions() {
            let self = this;
            let params = {
                perPage: 0
            };
            let res = await this.$http.get('admin/employee_position', {
                params: params
            });

            return res.body.data.map(item => {
                return {
                    ...item,
                    click: function (itm) {
                        self.onPositionClick(itm)
                    }
                }
            });
        },

        onDepartmentClick(itm) {
            this.page = 1;
            this.lastFilter = {
                department_id: {
                    eq: itm.id
                }
            };

            this.fetchUsers(this.lastFilter).then(items => {
                this.items = items;
            })
        },

        onRoleClick(itm) {
            this.page = 1;
            this.lastFilter = {
                role_id: {
                    eq: itm.id
                }
            };

            this.fetchUsers(this.lastFilter).then(items => {
                this.items = items;
            })
        },

        onPositionClick(itm) {
            this.page = 1;
            this.lastFilter = {
                employee_position_id: {
                    eq: itm.id
                }
            }
            this.fetchUsers(this.lastFilter).then(items => {
                this.items = items;
            })
        },

        buildFilters() {
            let self = this;
            return [
                {
                    name: this.$t('all'),
                    click: function () {
                        self.page = 1;
                        self.lastFilter = {};
                        self.fetchUsers(this.lastFilter).then(items => {
                            self.items = items
                        });
                    }
                },
                {
                    name: this.$t('roles'),
                    children: this.roles
                },
                {
                    name: this.$t('positions'),
                    children: this.positions
                },
                {
                    name: this.$t('departments.plural_form'),
                    children: this.departments
                },
            ]
        },

        scrollEnd() {
            if(this.maxPage && this.page >= this.maxPage) {
                return;
            }

            this.page++;

            this.fetchUsers({
                ...this.lastFilter
            }).then(items => {
                this.items = this.items.concat(items)
            })
        }
    },

    mounted() {
        this.showPopup();
    }
}
</script>

<template>
    <EntitySelectionPopup
        :items="items"
        :filters="filters"
        :multiple="multiple"
        :loading="loading"
        :value="value"
        @input="input"
        @scroll-end="scrollEnd"
    >
        <template v-slot:item="{ item }">
            <User :item="item"></User>
        </template>
    </EntitySelectionPopup>
</template>
