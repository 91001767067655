<script>
import TextInput from "@/components/Form/TextInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import NomenclatureSelection from "@/components/Warehouse/NomenclatureSelection.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import WithVats from "@/plugins/mixins/WithVats";
import WithNomenclature from "@/plugins/mixins/WithNomenclature";

export default {
    name: "BuyingItemEditDialog",

    components: {ValidationProvider, ValidationObserver, NomenclatureSelection, SelectInput, TextInput},

    mixins: [
        WithVats,
        WithNomenclature
    ],

    model: {
        prop: 'value',
        event: 'input',
    },

    props: {
        value: {
            required: true,
        },
        warehouseId: {
            type: Number,
            required: false
        }
    },

    data() {
        return {
            order: null,

            vat_id: null,
            nomenclaturePrices: [],
            stock: null,

            nomenclature: null,
            quantity: null,
            price: null,
            priceSearch: null,
            discount: null,
        }
    },

    computed: {
        vat() {
            return this.vats.find(v => v.id === this.vat_id);
        },

        sum() {
            return this.quantity * this.price;
        },

        sumDiscount() {
            let r = this.sum * this.discount / 100
            return r.toFixed(2);
        },

        sumWithDiscount() {
            return this.sum - this.sumDiscount;
        },

        vatSum() {
            let r = 0;

            if (this.vat) {
                r = this.total * this.vat.value / (100 + this.vat.value);
            }

            return r.toFixed(2);
        },

        sumWithoutVat() {
            let r = this.sum - this.vatSum
            return r.toFixed(2);
        },

        total() {
            return this.sumWithDiscount;
        },
    },

    watch: {
        nomenclature: function (nomenclature) {
            if (nomenclature) {
                this.onNomenclatureSelect(nomenclature)
            }
        },
    },

    methods: {
        onNomenclatureSelect: function (nomenclature) {
            this.fetchNomenclaturePrice(nomenclature.uuid)
                .then(price => {
                    this.nomenclaturePrices = price;
                    this.vat_id = this.nomenclature.vat_id
                })
                .catch(() => {

                });

            if (this.warehouseId) {
                this.fetchNomenclatureStockForWarehouse(nomenclature.uuid, this.warehouseId)
                    .then(stock => {
                        if (stock && stock[0]) {
                            this.stock = stock[0].quantity;
                        }
                    })
                    .catch(() => {

                    });
            }
        },

        onSaveDocumentItemBtnClick() {
            let data = {
                nomenclature: this.nomenclature,
                vat_id: this.vat_id,
                quantity: parseInt(this.quantity),
                price: parseFloat(this.price),
                discount: parseInt(this.discount)
            };

            this.$emit('add', data);
        }
    }
}
</script>

<template>
    <v-dialog :value="value" @input="$emit('input', $event)" max-width="992">
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <v-card>
                <v-card-title>
                    {{ $t('nomenclature.select') }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <NomenclatureSelection
                                v-model="nomenclature"
                                :label="$t('nomenclature.self')"
                                id="nomenclature"
                            />
                        </v-col>
                        <template v-if="nomenclature">
                            <v-col cols="12" md="4">
                                <ValidationProvider ref="quantity" rules="required|integer|min_value:1" v-slot="{ errors, valid }">
                                    <TextInput
                                        id="quantity"
                                        :label="$t('quantity')"
                                        v-model="quantity"
                                        :error="!valid"
                                        :disabled="!nomenclature"
                                    />
                                    <v-chip v-if="stock !== null" class="ml-2 mt-2" small @click="quantity = stock">
                                        {{ $t('nomenclature.free_stock') }}: {{ stock }}
                                    </v-chip>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="4">
                                <ValidationProvider ref="price" rules="required|integer|min:1" v-slot="{ errors, valid }">
                                    <TextInput
                                        id="price"
                                        :label="$t('nomenclature.price')"
                                        v-model="price"
                                        :error="!valid"
                                        :disabled="!nomenclature"
                                    />
                                    <template v-for="p in nomenclaturePrices">
                                        <v-chip class="ml-2 mt-2" small :key="p.id" @click="price = p.value" v-if="p.value">
                                            {{ p.name }}: {{ p.value }}
                                        </v-chip>
                                    </template>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="4">
                                <ValidationProvider ref="discount" rules="integer|min_value:0" v-slot="{ errors, valid }">
                                    <TextInput
                                        id="discount"
                                        :label="`${$t('discount')} %`"
                                        v-model="discount"
                                        :error="!valid"
                                    />
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider ref="vat" rules="required|min:1" v-slot="{ errors, valid }">
                                    <SelectInput
                                        :label="$t('vat.self')"
                                        id="vat_id"
                                        v-model="vat_id"
                                        item-text="value"
                                        item-value="id"
                                        :items="vats"
                                        :disabled="!nomenclature"
                                        :error="!valid"
                                    />
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" class="text-right d-flex justify-end">
                                <table v-if="quantity && price">
                                    <tr>
                                        <td>{{ $t('total_amount_discount') }}:</td>
                                        <td>{{ sumDiscount }}</td>
                                    </tr>

                                    <tr>
                                        <td>{{ $t('total_amount_without_discount') }}:</td>
                                        <td>{{ sum }}</td>
                                    </tr>

                                    <tr style="font-weight: bold;">
                                        <td>{{ $t('total_amount') }}:</td>
                                        <td>{{ total }}</td>
                                    </tr>

                                    <tr>
                                        <td>{{ $t('total_amount_without_vat') }}:</td>
                                        <td>{{ sumWithoutVat }}</td>
                                    </tr>

                                    <tr>
                                        <td>{{ $t('total_amount_vat') }}:</td>
                                        <td>{{ vatSum }}</td>
                                    </tr>
                                </table>
                            </v-col>
                        </template>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="$emit('input', false)"
                    >
                        {{ $t('close') }}
                    </v-btn>
                    <v-btn
                        color="secondary"
                        @click="onSaveDocumentItemBtnClick"
                        :disabled="invalid"
                    >
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>
