export default {
    methods: {
        fetchChatOfAccounts() {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/accounting/chart-of-account')
                    .then(({body}) => {
                        resolve(body)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        }
    }
}
