export default {
    methods: {
        fetchUvedAccounts(uvedUuid, params = {}) {
            return new Promise((resolve, reject) => {
                this.$http.get(`admin/uved/${uvedUuid}/bank`, params)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
}
