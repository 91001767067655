<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveItem">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('account_payment.edit') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary">mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <ValidationProvider ref="organization" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="organization"
                                            :label="$t('columns.payment.organization')"
                                            v-model="organization"
                                            :error="!valid"
                                            item-value="uuid"
                                            item-text="name"
                                            :items="organizations"
                                        >
                                        </SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" v-if="organization">
                                    <ValidationProvider ref="organization_account" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="organization_account"
                                            :label="$t('columns.payment.organization_account')"
                                            v-model="organization_account"
                                            :error="!valid"
                                            item-value="id"
                                            item-text="name"
                                            :items="organization_accounts.map(item => {
                                                return {
                                                    ...item,
                                                    name: `${item.bank.name} [${item.currency.letter_code}] - ${item.account_number}`
                                                }
                                            })"
                                        >
                                        </SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider ref="account_payment_type" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="account_payment_type_id"
                                            :label="$t('columns.payment.account_payment_type_id')"
                                            v-model="account_payment_type"
                                            :error="!valid"
                                            item-value="id"
                                            item-text="name"
                                            :items="account_payment_types"
                                        >
                                        </SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <ValidationProvider ref="uved_id" rules="required|min:1" v-slot="{ errors, valid }">
                                        <UvedSelection
                                            id="uved_id"
                                            :label="$t('columns.payment.uved')"
                                            v-model="uved"
                                            :error-messages="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider ref="sum" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sum"
                                            :label="$t('columns.payment.sum')"
                                            v-model="sum"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12">
                                    <ValidationProvider ref="payment_date" rules="required" v-slot="{ errors, valid }">
                                        <label class="input_label">{{ $t('columns.payment.payment_date') }}</label>
                                        <DatetimePicker
                                            format="24hr"
                                            v-model="payment_date"
                                            :label="$t('columns.payment.payment_date')"
                                            :text-field-props="{ 'error-messages': errors, error: !valid }"
                                            validatorName="payment_date"
                                            validator-rules="required"
                                            id="payment_date"
                                        ></DatetimePicker>
                                    </ValidationProvider>
                                </v-col>

                                <v-col>
                                    <ValidationProvider ref="number" rules="required" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sum"
                                            :label="$t('columns.payment.number')"
                                            v-model="number"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider ref="description" rules="" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sum"
                                            :label="$t('columns.payment.description')"
                                            v-model="description"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="buttons-block">
                            <Btn type="submit" :disabled="invalid || loading" :loading="loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                {{ $t('save') }}
                            </Btn>
                            <template v-if="saved">
                                <Btn v-if="passed" @click="revoke" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="error">
                                    {{ $t('revoke') }}
                                </Btn>
                                <Btn v-else @click="pass" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('pass') }}
                                    <v-icon right>
                                        mdi-check-circle-outline
                                    </v-icon>
                                </Btn>
                            </template>
                            <Btn
                                :disabled="loading"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{ $t('cancel') }}
                            </Btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import WithOrganizations from "@/plugins/mixins/WithOrganizations";
import WithBuying from "@/plugins/mixins/WithBuying";
import WithOrganizationAccount from "@/plugins/mixins/WithOrganizationAccounts";
import WithAccountPaymentTypes from "@/plugins/mixins/WithAccountPaymentTypes";
import WithAccountingInvoice from "@/plugins/mixins/WithAccountingInvoice";
import {mapGetters} from "vuex";
import Btn from "@/components/Form/Btn.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import TextInput from "@/components/Form/TextInput.vue";
import DatetimePicker from "@/components/DatetimePicker.vue";
import UvedSelection from "@/components/Form/UvedSelection.vue";

export default {
    name: 'PaymentForm',

    components: {
        UvedSelection,
        DatetimePicker,
        Btn,
        ValidationProvider,
        ValidationObserver,
        TextInput,
        SelectInput
    },

    mixins: [
        WithOrganizations,
        WithOrganizationAccount,
        WithAccountPaymentTypes,
        WithAccountingInvoice,
        WithBuying
    ],

    props: {
        uuid: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            passedAt: null,

            payment_date: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            organization: null,
            organization_account: null,
            account_payment_type: null,
            sum: null,
            description: null,
            number: null,
            uved: null,
            based_on_id: null
        }
    },

    computed: {
        ...mapGetters(['listLanguages', 'lang']),

        languages() {
            return this.listLanguages
        },

        passed() {
            return this.passedAt !== null
        },

        saved() {
            return this.uuid !== 'create'
        },
    },

    watch: {
        uuid: {
            handler: function (value) {
                if (value !== 'create') {
                    this.fetchItem()
                } else {
                    if(this.$route.query.buying_uuid) {
                        this.fetchBuying(this.$route.query.buying_uuid).then((data) => {
                            this.organization = data.organization.uuid;
                            this.based_on_id = data.id
                            this.uved = data.uved
                            this.warehouse_id = data.warehouse_id
                            this.delivery_date = data.delivery_date ?? this.$moment().format('YYYY-MM-DD HH:mm:ss')
                            this.delivery_address = data.delivery_address
                            this.comment = data.comment
                            this.based_on_id = data.id
                            this.items = data.items.map((item) => {
                                item.id = null;
                                return item;
                            })
                            this.sum = data.sum;

                            this.buying = data
                        })
                    }

                    if(this.$route.query.accounting_invoice) {
                        this.fetchInvoice(this.$route.query.accounting_invoice).then((data) => {
                            this.organization = data.organization.uuid;
                            this.organization_account = data.organization_account_id;
                            this.based_on_id = data.id
                            this.account_payment_type = data.account_payment_type_id
                            this.uved = data.uved
                            this.uved_account_id = data.uved_account_id
                            this.payment_date = data.payment_date

                            // this.warehouse_id = data.warehouse_id
                            // this.delivery_date = data.delivery_date ?? this.$moment().format('YYYY-MM-DD HH:mm:ss')
                            // this.delivery_address = data.delivery_address
                            // this.comment = data.comment
                            // this.based_on_id = data.id
                            // this.items = data.items.map((item) => {
                            //     item.id = null;
                            //     return item;
                            // })
                            // this.sum = data.sum;
                            //
                            // this.buying = data
                        })
                    }
                }
            },

            immediate: true
        },

        account_payment_types: {
            handler: function (value) {
                if (this.account_payment_types.length > 0) {
                    this.account_payment_type = this.account_payment_types.find((item) => item.slug === 'outgoing').id
                }
            }
        },

        organization: {
            handler: function (value) {
                this.fetchOrganizationAccounts(value).then((data) => {
                    this.organization_accounts = data
                })
            }
        }
    },

    created() {
        this.init();
    },

    methods: {
        init(){
            this.initAccountPaymentTypes();
            this.initOrganizations();
        },

        closeDialogAdd() {
            this.$router.push({
                name: 'account_payment',
            })
        },

        async fetchItem() {
            let _this = this;
            let params = {}

            this.progress = 0
            this.loading = true

            if (this.language) {
                params.language = this.language
            }

            await this.$http
                .get(`admin/accounting/payment/${this.uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res && res.body && res.body.data) {
                        this.handleResponse(res.body.data)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('account_payment.fetching-error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async saveItem() {
            let _this = this;

            this.progress = 0
            this.loading = true

            let formData = {};
            formData.organization_account_id = this.organization_account;
            formData.account_payment_type_id = this.account_payment_type;
            formData.sum = this.sum + '';
            formData.description = this.description;
            formData.number = this.number;
            formData.uved_id = this.uved.id;

            if (this.uuid === 'create') {
                // Add
                formData.based_on_id = this.based_on_id;

                await this.$http
                    .post('admin/accounting/payment', formData, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('account_payment.added'))
                        this.$router.push({
                            name: 'account_payment'
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('account_payment.add_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                await this.$http
                    .put(`admin/accounting/payment/${this.uuid}`, formData, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('account_payment.updat_success'))

                        this.$router.push({
                            name: 'account_payment'
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('account_payment.update_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },

        handleResponse(res) {
            this.organization = res.organization.uuid
            this.organization_account = res.organization_account_id
            this.account_payment_type = res.account_payment_type_id
            this.sum = res.sum
            this.description = res.description
            this.number = res.number
            this.payment_date = res.payment_date
            this.uved = res.uved
            this.passedAt = res.passed_at
        },

        async pass() {
            let _this = this;

            this.progress = 0
            this.loading = true

            await this.$http
                .post(`admin/accounting/payment/${this.uuid}/pass`)
                .then(res => {
                    this.$toastr.success(this.$t('buyer_order.passed'))
                    this.passedAt = res.body.data.passed_at
                })
                .catch(err => {
                    this.$toastr.error(this.$t('buyer_order.pass_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        revoke() {
            let _this = this;

            this.progress = 0
            this.loading = true

            this.$http
                .post(`admin/accounting/payment/${this.uuid}/revoke`)
                .then(res => {
                    this.$toastr.success(this.$t('buyer_order.revoked'))
                    this.passedAt = null
                })
                .catch(err => {
                    this.$toastr.error(this.$t('buyer_order.revoke_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
    }
}
</script>
