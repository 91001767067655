var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageLayout',[_c('TabsNav',{attrs:{"strict":"","items":[
            {
                title: _vm.$t('all'),
                route: {
                    name: 'messages'
                }
            },
            {
                title: _vm.$t('uveds'),
                route: {
                    name: 'messages',
                    query: {
                        fltr: {
                            messageable_type: {
                                eq: 'uved'
                            }
                        }
                    }
                }
            },
            {
                title: _vm.$t('deals'),
                route: {
                    name: 'messages',
                    query: {
                        fltr: {
                            messageable_type: {
                                eq: 'deal'
                            }
                        }
                    }
                }
            },
            {
                title: _vm.$t('tasks'),
                route: {
                    name: 'messages',
                    query: {
                        fltr: {
                            messageable_type: {
                                eq: 'task'
                            }
                        }
                    }
                }
            }
        ]},on:{"input":_vm.getItems}}),_c('div',{staticClass:"w-100 d-flex flex-wrap justify-space-between mb-3"},[_c('h1',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('messages')))]),_c('div',{staticClass:"d-flex align-center w-100",class:!(_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly) ? 'text-right' : 'text-right'},[_c('div',{staticClass:"buttons-block w-100"},[_c('Btn',{style:(_vm.countUnreadNotifications > 0),on:{"click":_vm.readMessages}},[_vm._v(" "+_vm._s(_vm.$t('mark_as_read'))+" "),_c('v-icon',{staticClass:"bold",staticStyle:{"font-weight":"500"},attrs:{"right":"","small":""}},[_vm._v(" mdi-check-all ")])],1)],1)])]),(_vm.items.length <= 0)?_c('div',{staticClass:"mx-auto"},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-img',{attrs:{"src":"img/no/storage.png"}})],1)])],1):_c('div',[_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.messages),function(item,index){return [_c('v-list-item',{key:item.uuid,attrs:{"to":item.route}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"lighten-1",class:(item.is_readed ? 'grey' : 'primary'),attrs:{"dark":""}},[_vm._v(" mdi-card-text ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.title)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.comment.value)}})],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(item.created_at)}})],1)],1)]})],2),((_vm.nextLink === true && _vm.loading === false))?_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"elevation-0",attrs:{"color":"primary","outlined":""},on:{"click":_vm.nextItems}},[_vm._v(" "+_vm._s(_vm.$t('download_more'))+" ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }