export default {
    methods: {
        fetchInvoice(uuid) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/accounting/invoice/${uuid}`)
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('accounting_invoice.fetching-error'))
                        reject(err)
                    })
            })
        }
    }
}
