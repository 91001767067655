<template>
    <v-container
        fluid
        @drop.prevent="dragover = false"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragend.prevent="dragover = false"
    >
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <PageLayout class="pr-0">
                <template>
                    <v-row>
                        <v-col cols="12">
                            <v-row class="flex-nowrap">
                                <v-col cols="12">
                                    <div class="d-flex flex-row">
                                        <h1>{{ $t('document_number', {'number':number}) }}</h1>
                                        <v-icon :disabled="userBlock"  :color="favorite ? 'primary' : ''" size="20" class="mb-2 ml-2" @click="favoriteUpdate()"> {{ favorite ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
                                        <v-icon :disabled="userBlock || loading"  @click="approvalSheet" size="20" class="mb-2 ml-2" :title="$t('process_sheet')">mdi-clipboard-list-outline</v-icon>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-text v-if="documentStatuses.length > 0 && document_flow_status.id">
                                    <v-row class="border_bottom_6 px-3 pb-3" :style="'border-color: '+document_flow_status.color+' !important;'">
                                        <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="author && author.name">
                                            <v-list subheader two-line class="background_none py-0 margin_top_6">
                                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                        <img v-if="author.photo !== '/img/avatar.png'" :src="author.photo" :alt="author.name">
                                                        <v-icon v-else color="primary" size="22">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="author.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$t('director')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="recipients && recipients[0] !== null && recipients[0] !== undefined && recipients[0].name !== null">
                                            <v-list :disabled="userBlock" subheader two-line class="background_none py-0 margin_top_6">
                                                <v-list-item class="px-0 py-0 my-0 min_height_auto" @click="dialogAddParticipant = is_author">
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                        <img v-if="recipients[0].photo !== '/img/avatar.png'" :src="recipients[0].photo" :alt="recipients[0].name">
                                                        <v-icon v-else class="settings_icon_client" color="primary" size="36">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="recipients[0].name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$t('recipients')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>

                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="created_at">
                                            <v-list class="background_none py-0">
                                                <v-list-item class="mx-0 px-0">
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="$t('date_created')"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$moment(created_at).format('DD.MM.YYYY HH:mm')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>

                                        <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="documentStatuses.length > 0 && document_flow_status.id !== null">
                                            <v-list class="background_none py-0">
                                                <v-list-item class="mx-0 px-0">
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="documentStatuses.find(item => item.id === document_flow_status.id).name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$t('status')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>

                                    </v-row>

                                    <v-row>
                                        <!-- documents -->
                                        <v-col class="task-documents" v-if="documentList.length > 0">
                                            <div class="task-document bordered" v-for="file in documentList" :key="file.uuid + '_' + Date.now()">
                                                <div class="task-document__desc approval-sheet__item">
                                                    <div class="task-document__icon" @click="downloadFileMessage(file.document.url, file.document.name)">
                                                        <v-img
                                                            max-width="45"
                                                            max-height="45"
                                                            :alt="`${file.document.name}`"
                                                            :src="file.document.url"
                                                            v-if="mimeTypeImage(file.document.type)"
                                                        ></v-img>
                                                        <v-icon
                                                            x-large
                                                            size="10"
                                                            color="primary"
                                                            v-else
                                                        >
                                                            mdi-file-document-outline
                                                        </v-icon>
                                                    </div>
                                                    <div class="task-document__text" >
                                                        <div class="task-document__name cursor-pointer" @click="openFile(file.document)" v-text="file.document.file_name"></div>
                                                        <div class="task-document__author" v-text="file.document_flow_type.name"></div>
                                                        <div class="task-document__size" v-text="(file.document.size / 1000).toFixed(1) + ' KB'"></div>
                                                    </div>
                                                </div>
                                                <div class="task-document__approval-actions approval__actions" v-if="!file.answered && is_recipient">
                                                    <v-btn :disabled="userBlock" class="ma-2" color="primary" @click="document_flow_id=file.id;addStatusApprovalFile(1)">{{ $t('accept') }}</v-btn>
                                                    <v-btn :disabled="userBlock" class="ma-2 mr-0" @click="document_flow_id=file.id;dialogStatusApprovalFile = true">{{ $t('reject') }}</v-btn>
                                                </div>

                                                <div class="task-document__approval approval">
                                                    <div class="task-document__approval-info approval__info" v-if="file.answered">
                                                        <div>
                                                            <v-icon color="green">mdi-check-circle-outline</v-icon>
                                                            {{ $t('accepted_document', {'count': file.accepted}) }}
                                                        </div>
                                                        <div>
                                                            <v-icon color="red">mdi-close-circle-outline</v-icon>
                                                            {{ $t('rejected_document', {'count': file.rejected}) }}
                                                        </div>
                                                        <div v-if="false">
                                                            <v-icon color="grey">mdi-alert-circle-outline</v-icon>
                                                            {{ $t('did_not_make_decision', {'count': (file.not_accepted)}) }}
                                                        </div>
                                                    </div>

                                                    <div class="task-document__approval-users approval__users" v-if="file.aligners && file.aligners.length">
                                                        <v-list-item
                                                            class="mx-0 px-0"
                                                            v-for="aligner in file.aligners"
                                                            :key="aligner.author.name + aligner.author.id"
                                                        >
                                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                <img v-if="aligner.author.photo !== '/img/avatar.png'" :src="aligner.author.photo" :alt="aligner.author.name">
                                                                <v-icon v-else color="primary" size="22">
                                                                    ${{ 'settingsIcon' }}
                                                                </v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content class="py-0 title_subtitle">
                                                                <v-list-item-title v-text="aligner.author.name"></v-list-item-title>
                                                                <v-list-item-subtitle class="chat_text mr-1" v-if="(aligner.reason && !aligner.confirm)" v-text="$t('rejected_text', {reason:aligner.reason})"></v-list-item-subtitle>
                                                                <v-list-item-subtitle v-else-if="(aligner.confirm)" v-text="$t('accepted')"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="false && recipientsDecisionItems && recipientsDecisionItems.length > 0">
                                        <v-col cols="12">
                                            <div class="approval">
                                                <div class="approval__info">
                                                    <div>
                                                        <v-icon color="green">mdi-check-circle-outline</v-icon>
                                                        {{ $t('confirmed', {'count': accepted}) }}
                                                    </div>
                                                    <div>
                                                        <v-icon color="red">mdi-close-circle-outline</v-icon>
                                                        {{ $t('rejected', {'count': rejected}) }}
                                                    </div>
                                                    <div>
                                                        <v-icon color="grey">mdi-alert-circle-outline</v-icon>
                                                        {{ $t('did_not_make_decision', {'count': not_accepted}) }}
                                                    </div>
                                                </div>
                                                <div class="approval__users">
                                                    <v-list class="my-0 py-0">
                                                        <v-list-item
                                                            class="mx-0 px-0"
                                                            v-for="recipient in recipientsDecisionItems"
                                                            :key="recipient.name + recipient.id"
                                                        >
                                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                <img v-if="recipient.photo !== '/img/avatar.png'" :src="recipient.photo" :alt="recipient.name">
                                                                <v-icon v-else color="primary" size="22">
                                                                    ${{ 'settingsIcon' }}
                                                                </v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content class="py-0 title_subtitle">
                                                                <v-list-item-title v-text="recipient.name"></v-list-item-title>
                                                                <v-list-item-subtitle v-if="(recipient.reason && !recipient.confirm)" v-text="$t('rejected_text',{reason: recipient.reason})"></v-list-item-subtitle>
                                                                <v-list-item-subtitle v-else-if="(recipient.confirm)" v-text="$t('accepted')"></v-list-item-subtitle>
                                                                <v-list-item-subtitle v-else v-text="$t('decision_not_adopted')"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>



                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="12">
                            <Messages entity="document_flow_author" :entity_uuid="id" socket-name="DocumentFlowChat" />
                        </v-col>
                    </v-row>


                </template>

                <template v-slot:side>



                    <v-col cols="12" class="pa-0">
                        <v-card class="side-block" style="margin-top: 15px">
                            <div>
                                <div class="d-flex">
                                    <div class="pa-4 participants mr-auto">{{ $t('participants') }}</div>
                                    <div class="pa-4">
                                        <v-icon
                                            :disabled="(author.id !== $auth.user().id) || userBlock"
                                            size="18"
                                            color="secondary"
                                            class="add_icon"
                                            @click="dialogAddParticipants=true"
                                        >
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                            <v-card-text>
                                <v-row>
                                    <v-col class="sub_participants" v-if="!$vuetify.breakpoint.xlOnly">{{ $t('author') }}</v-col>
                                    <v-col cols="12" sm="12" class="py-1" v-if="author && author.name">
                                        <v-list class="my-0 py-0">
                                            <template>
                                                <div v-if="$vuetify.breakpoint.xlOnly" class="my-0 sub_participants">{{ $t('author') }}</div>
                                                <v-list-item class="px-0">
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                        <img v-if="author.photo !== '/img/avatar.png'" :src="author.photo" :alt="author.name">
                                                        <v-icon v-else color="primary" size="22">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>

                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="author.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="formatPhoneNumber(author.phone)"></v-list-item-subtitle>
                                                    </v-list-item-content>

                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-col>

                                    <template v-if="recipients && recipients[0] !== null && recipients[0] !== undefined && recipients[0].name !== null">
                                        <v-col class="sub_participants">{{ $t('recipients') }}</v-col>
                                        <v-col cols="12" class="py-1"
                                               v-for="recipients in recipients"
                                               :key="'recipients_'+recipients.id"
                                        >
                                            <v-list class="my-0 py-0">
                                                <template>
                                                     <v-list-item class="px-0">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="recipients.photo !== '/img/avatar.png'" :src="recipients.photo" :alt="recipients.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{ 'settingsIcon' }}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                         <v-list-item-content class="py-0 title_subtitle">
                                                             <v-list-item-title v-text="recipients.name"></v-list-item-title>
                                                             <v-list-item-subtitle v-if="(recipients.reason && !recipients.confirm)" v-text="$t('rejected_text',{reason: recipients.reason})"></v-list-item-subtitle>
                                                             <v-list-item-subtitle v-else-if="(recipients.confirm)" v-text="$t('accepted')"></v-list-item-subtitle>
                                                             <v-list-item-subtitle v-else v-text="$t('decision_not_adopted')"></v-list-item-subtitle>
                                                         </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </v-col>
                                    </template>




                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" class="pa-0">
                        <v-card class="side-block" style="margin-top: 15px">
                            <div>
                                <div class="d-flex">
                                    <div class="pa-4 participants mr-auto">{{ $t('files') }}{{ accompanying_document_urls.length > 0 ? ': ' + accompanying_document_urls.length : '' }}</div>
                                    <div class="pa-4">
                                        <v-icon
                                            :disabled="userBlock || !is_author"
                                            size="18"
                                            color="secondary"
                                            class="add_icon"
                                            @click="document.getElementById('accompanying_documents').click()"
                                        >
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <v-card-text class="mt-0 pt-0" v-if="accompanying_document_urls.length > 0">
                                    <v-list>
                                        <v-list-item outlined class="file_item px-0" v-for="file in accompanying_document_urls" :key="file.id + '_' + Date.now()">
                                            <v-list-item-icon>
                                                <v-img
                                                    max-width="45"
                                                    :alt="`${file.name}`"
                                                    :src="file.url"
                                                    max-height="30"
                                                ></v-img>
                                                <v-icon
                                                    x-large
                                                    size="10"
                                                    color="primary"
                                                    v-if="!mimeTypeImage(file.type)">mdi-file-document-outline
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content class="file_text">
                                                <ContextMenuFile :file="file" @add-document-form ="addDocumentForm"  @open-file="openFileContext"></ContextMenuFile>
                                                <v-list-item-subtitle v-text="(file.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                                <v-list-item-subtitle v-text="file.author.name"></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-icon class="d-flex align-self-center mt-3 px-0 mx-0">
                                                <v-icon color="primary" class="mr-2" :title="$t('download')" @click="downloadFileMessage(file.url,file.name)">mdi-cloud-upload</v-icon>
                                                <v-icon vcolor="primary" v-if="file.uuid && (is_author || can(['administrator', 'general_manager']))" :title="$t('delete')" @click="deleteFileServer(file)">mdi-delete-outline</v-icon>
                                                <v-icon color="primary" v-if="!file.uuid && (is_author || can(['administrator', 'general_manager']))" :title="$t('delete')" @click="deleteAccompanyingDocumentFile(file)">mdi-delete-outline</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                            </div>
                        </v-card>
                    </v-col>


                    <v-col class="py-0" cols="12">
                        <!--accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                        <v-file-input
                            hide-input prepend-icon=""

                            :label="$t('accompanying_documents')"
                            v-model="accompanying_documents"
                            id="accompanying_documents"
                            multiple
                            @change="accompanyingDocumentsPreviewFiles"
                        >
                        </v-file-input>
                    </v-col>

                    <v-col class="py-0" cols="12">
                        <!--accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                        <v-file-input hide-input prepend-icon=""
                                      v-model="files"
                                      id="file"
                                      multiple
                                      @change="previewFiles"
                        >
                        </v-file-input>
                    </v-col>
                </template>
            </PageLayout>
        </ValidationObserver>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="700"
            v-model="dialogAddParticipants"
            v-if="dialogAddParticipants"
        >
            <ValidationObserver ref="observerAddParticipants" v-slot="{ invalid, validated, passes, validate }">
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('edit_participants') }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialogAddParticipants = false" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col class="py-0" cols="12">
                            <ValidationProvider ref="authorEdit" rules="required|min:1" v-slot="{ errors, valid }">
                                <UserSelection
                                    v-model="authorEdit"
                                    id="authorEdit"
                                    :label="$t('author')"
                                    :error="!valid"
                                    :valid="valid"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    clearable
                                />
                            </ValidationProvider>
                        </v-col>

                        <v-col class="py-0" cols="12">
                            <ValidationProvider ref="recipientsEdit" rules="min:1" v-slot="{ errors, valid }">
                                <UserSelection
                                    v-model="recipientsEdit"
                                    multiple
                                    id="recipientsEdit"
                                    :label="$t('recipients')"
                                    :error="!valid"
                                    :valid="valid"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    clearable
                                />
                            </ValidationProvider>

                        </v-col>




                    </v-row>

                </v-card-text>
                <v-card-actions class="pt-5">
                    <v-spacer></v-spacer>
                    <v-btn :disabled="invalid || loading"  :loading="loading" text color="primary" @click="editParticipants()">
                        {{ $t('edit') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            </ValidationObserver>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="700"
            v-model="dialogAddParticipant"
            v-if="dialogAddParticipant"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('edit_participants') }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialogAddParticipant = false" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col class="py-0 pt-5" cols="12">
                            <ValidationProvider ref="recipientsEdit" rules="required|min:1" v-slot="{ errors, valid }">
                                <UserSelection
                                    v-model="recipientsEdit"
                                    multiple
                                    id="recipientsEdit2"
                                    :label="$t('recipients')"
                                    :error="!valid"
                                    :valid="valid"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    clearable
                                />
                            </ValidationProvider>
                        </v-col>

                    </v-row>

                </v-card-text>
                <v-card-actions class="pt-5">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="editParticipant()">
                        {{ $t('edit') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_file"
            scrollable
            persistent
            transition="dialog-bottom-transition"
            max-width="500px"
            class="dialog_body"

        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('send_files', {'count': document_urls.length}) }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialog_file = false;document_urls=[];documents=[]">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text style="height: 300px;" class="pa-0">
                    <v-list subheader>
                        <v-list-item v-for="document in document_urls" :key="document.id">
                            <v-list-item-icon>
                                <v-img
                                    max-width="90"
                                    :alt="`${document.name}`"
                                    :src="document.url"
                                    max-height="60"
                                ></v-img>
                                <v-icon
                                    x-large
                                    v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title v-text="document.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon class="d-flex align-self-center mt-2">
                                <v-btn
                                    icon
                                    @click="deleteFile(document)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>

                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>


                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="SentMessageFile">
                        {{ $t('sent') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="accompanying_documents_dialog"
            scrollable
            persistent
            transition="dialog-bottom-transition"
            max-width="500px"
            class="dialog_body"

        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('send_files', {'count': accompanying_documents_preview.length}) }}</span>
                    </div>
                    <div>
                        <v-icon @click="accompanying_documents_dialog = false;accompanying_documents_preview=[];accompanying_documents=[]">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text style="height: 300px;" class="pa-0">
                    <v-list subheader>
                        <v-list-item>
                            <v-row>
                                <v-col cols="12">
                                    <ValidationProvider ref="document_flow_type" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="document_flow_type"
                                            :value="document_flow_type"
                                            v-model="document_flow_type"
                                            item-text="name"
                                            item-value="id"
                                            :error="!valid"
                                            :error-messages="errors"
                                            :label="$t('document_flow_type')"
                                            :disabled="loading"
                                            :items="documentFlowTypeItems"
                                            @input="parent_id = $event"
                                            clearable
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list>
                    <v-list subheader>
                        <v-list-item v-for="document in accompanying_documents_preview" :key="document.id">
                            <v-list-item-icon>
                                <v-img
                                    max-width="90"
                                    :alt="`${document.name}`"
                                    :src="document.url"
                                    max-height="60"
                                ></v-img>
                                <v-icon
                                    x-large
                                    v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title v-text="document.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon class="d-flex align-self-center mt-2">
                                <v-btn
                                    icon
                                    @click="deleteAccompanyingDocuments(document)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>

                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="saveTaskFile">
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogStatusApprovalFile"
            class="dialog_body"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0"
                >{{ $t('reason_for_rejecting_file') }}
                </v-toolbar>
                <v-card-text class="pa-2 pb-0">
                    <v-col cols="12">
                        <ValidationProvider ref="reject_text" rules="required|min:10|max:62000"
                                            v-slot="{ errors, valid }">
                            <v-textarea
                                :label="$t('write_reason_for_the_rejection')"
                                v-model="reject_text"
                                :error-messages="errors"
                                :disabled="loading"
                                rows="8"
                                color="primary"
                                auto-grow
                                clearable outlined

                                full-width
                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                            >
                            </v-textarea>
                        </ValidationProvider>
                    </v-col>
                </v-card-text>
                <v-card-actions class="justify-end pt-0">
                    <v-btn
                        text
                        @click="dialogStatusApprovalFile = false"
                    >{{ $t('close') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="red"
                        @click="addStatusApprovalFile(0)"
                    >{{ $t('reject') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_image_view"
            scrollable
            fullscreen
            v-if="!!image_view.comment"
        >
            <v-card>
                <v-card-title class="px-2 mx-0">
                    <span class="pl-5 text-truncate">{{ image_view.comment.file_name }}</span>
                    <v-spacer></v-spacer>
                    <v-tooltip>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on"
                                   @click="downloadFileMessage(image_view.comment.value, image_view.comment.file_name)"
                                   icon>
                                <v-icon>mdi-arrow-collapse-up mdi-flip-v</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('download') }}</span>
                    </v-tooltip>
                    <v-tooltip>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on"
                                   @click="ImageViewClose()"
                                   icon>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('close') }}</span>
                    </v-tooltip>
                </v-card-title>

                <v-card-text style="height: 300px;">

                    <v-container fluid>
                        <v-row dense>
                            <v-col>
                                <v-card>
                                    <v-img
                                        :alt="`${image_view.comment.file_name}`"
                                        :src="image_view.comment.value"


                                    >

                                    </v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>


                </v-card-text>


            </v-card>
        </v-dialog>

        <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen"/>
        <DocumentFormDialog
            :dialogForm="dialogForm"
            :document_flow_urls_form="document_flow_urls_form"
            :closeForm = "true"
            @dialog-form-close = "dialogFormClose"
        ></DocumentFormDialog>


    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import UserSelection from "@/components/Form/UserSelection.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import OpenFile from '@/components/OpenFile.vue';
import Messages from "@/components/Messages.vue";
import ContextMenuFile from "@/components/ContextMenuFile.vue";
import DocumentFormDialog from '@/components/DocumentFormDialog.vue';

export default {
    name: 'DocumentShow',
    components: {
        ValidationProvider,
        ValidationObserver,
        PageLayout,
        UserSelection,
        SelectInput,
        OpenFile,
        Messages,
        ContextMenuFile,
        DocumentFormDialog
    },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: false,
            document_flow_urls_form: [],
            recipients: [],
            documentStatuses: [],
            document_flow_status: {},
            authorEdit:{},
            recipientsEdit:[],
            dialogOpenFile:false,
            fileOpen:{},
            recipientsDecisionItems:[],
            is_author: false,
            not_accepted: 0,
            accepted: 0,
            rejected: 0,
            document_flow_type: null,
            documentFlowTypeItems: [],
            is_recipient: false,
            author: {},
            progress: 0,
            loading: false,
            number: null,
            text: null,
            document,
            documents: [],
            files: [],
            document_urls: [],
            dialog_file: false,
            document_flow_uuid: null,
            chatMessages: [],
            chatMessagesDate: [],
            toolbar_reply: false,
            reply_msg: {},
            reply_name: null,
            reply_uuid: null,
            message_menu: [
                {title: this.$t('delete'), click: "delete"},
                {title: this.$t('reply'), click: "reply"},
            ],
            created_at: null,
            accompanying_documents: [],
            accompanying_document_urls: [],
            documentList: [],
            accompanying_documents_preview: [],
            accompanying_documents_dialog: false,
            dialogStatusApprovalFile: false,
            document_flow_id: null,
            reject_text: null,
            loadingMessages: false,
            dialogAddParticipant: false,
            dialogAddParticipants: false,
            favorite: false,
            dialog_image_view: false,
            image_view: {},
            showSuggestions: false,
            suggestions: [],
            menuX: 0,
            menuY: 0,
            inputAfterAt: "",
            activeIndex: 0,
            heightSuggestions: 150,
        }
    },
    computed: {
        ...mapGetters([ 'userBlock', 'language', 'listLanguages', 'lang', 'timezone']),
        languages() {
            return this.listLanguages
        },
        tinymceInit() {
            return this.tinymceInitDefault()
        },
        filteredSuggestions() {
            // Фильтрация предложений на основе текста после символа "@"
            let suggestionItems = this.suggestions.filter((suggestion) =>
                suggestion.toLowerCase().includes(this.inputAfterAt.toLowerCase())
            );
            if (suggestionItems.length >= 3) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 150;
                this.menuY = textareaRect.top - 5;
            } else if (suggestionItems.length === 2) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 100;
                this.menuY = textareaRect.top - 4.5;
            } else if (suggestionItems.length === 1) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 50;
                this.menuY = textareaRect.top - 4.9;
            } else if (suggestionItems.length === 0) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 0;
                this.menuY = textareaRect.top - 5.1;
            }
            return suggestionItems;
        },
    },
    async mounted() {
        await this.getDocumentStatuses();
        await this.checkCreate()
        await this.getAdminLogins();
        await this.grtDocumentFlowTypes()

    },
    methods: {
        async grtDocumentFlowTypes() {

            this.loading = true
            let params = {}
            await this.$http
                .get("admin/document_flow_type", {
                    params: params,
                })
                .then(res => {
                    this.documentFlowTypeItems = res.body.data
                })
                .catch(err => {
                    this.documentFlowTypeItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getDocumentStatuses() {
            var _this = this;
            this.loading = true;

            let params = {};
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/document_flow_status`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.documentStatuses = res.body.data
                })
                .catch(err => {
                    this.documentStatuses = []
                    this.$toastr.error(this.$t('failed_to_get_document_flow_statuses'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async translation(text, comment_uuid = '') {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            formData.append('text', text)
            if (comment_uuid !== '') {
                formData.append('comment_uuid', comment_uuid)
            }
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.$route.params.id) {
                formData.append('document_flow_author_uuid', this.$route.params.id)
            }

            // Save
            await this.$http
                .post(`admin/translate_text`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res.body.data.comment) {
                        let message_date = this.chatMessagesDate[this.$moment(res.body.data.comment.created_at).format('YYYY-MM-DD')];
                        let uuidToFind = res.body.data.comment.uuid;
                        let foundObject = message_date.find(obj => obj.uuid === uuidToFind);
                        if (typeof foundObject != "undefined") {
                            foundObject.comment = res.body.data.comment.comment;
                            foundObject.uuid = res.body.data.comment.uuid;
                        }
                    }

                    this.$toastr.success(this.$t('translated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('translation_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        getTranslation(translations) {
            if (!Array.isArray(translations) || translations.length === 0) {
                translations = [];
                return null;
            }
            let translationObject = translations.find(obj => obj && Object.prototype.hasOwnProperty.call(obj, this.language));
            return translationObject ? translationObject[this.language] : null;
        },
        async handleInput() {

            const lastChar = this.text.slice(-1);
            if (lastChar === "@") {
                this.showSuggestions = true;
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.menuX = textareaRect.left;
                // this.menuY = textareaRect.bottom - 220;
                this.activeIndex = 0;

            } else if (lastChar === " ") {
                // Если введен пробел, закрываем меню
                this.showSuggestions = false;
                // Сбрасываем текст после "@" при введении пробела
                this.inputAfterAt = "";
                this.activeIndex = 0;
            } else if (this.showSuggestions) {
                // Если меню открыто и введенный символ не пробел, обновляем текст после "@"
                const atIndex = this.text.lastIndexOf("@");
                this.inputAfterAt = this.text.substring(atIndex + 1);
            }
        },
        selectSuggestion(suggestion) {
            // Заменяем текст в textarea выбранным предложением
            if (this.inputAfterAt.length > 0) {
                this.text = this.text.slice(0, -this.inputAfterAt.length) + suggestion + " ";
            } else {
                this.text = this.text + suggestion + " "
            }
            this.showSuggestions = false;
            // Сбрасываем текст после "@" после выбора предложения
            this.inputAfterAt = "";
            this.activeIndex = 0;
            this.$nextTick(() => {
                this.$refs.textarea.focus();
            });
        },
        handleArrowDown() {
            if (this.activeIndex >= 0 && this.activeIndex < this.filteredSuggestions.length) {
                this.$refs.listItem[this.activeIndex].$el.focus();
                this.activeIndex += 1;
            }
        },
        handleArrowUp() {
            if (this.activeIndex !== 0) {
                this.activeIndex -= 1;
                this.$refs.listItem[this.activeIndex].$el.focus();
            }
        },
        async getAdminLogins() {
            this.loading = true
            let params = {}
            params.active = this.active
            params.perPage = 1000
            params.filter = 'login'
            await this.$http
                .get("admin/admin", {
                    params: params,
                })
                .then(res => {
                    this.suggestions = res.body.data
                })
                .catch(err => {
                    this.suggestions = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async favoriteUpdate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.$route.params.id) {
                formData.append('document_flow_author_uuid', this.$route.params.id)
            }
            formData.append('type', 'document_flow_author')

            await this.$http
                .put(`admin/favorite/${this.$route.params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('favorite_has_been_updated'))
                    if (this.favorite) {
                        this.favorite = false;
                    } else {
                        this.favorite = true
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('favorite_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async editParticipant() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.$route.params.id) {
                formData.append('document_flow_author_uuid', this.$route.params.id)
            }


            if (this.recipientsEdit && this.recipientsEdit.length > 0) {
                for (let i in this.recipientsEdit) {
                    if (this.recipientsEdit[i].id !== undefined && this.recipientsEdit[i].id > 0) {
                        formData.append(`recipients[${i}]`, this.recipientsEdit[i].id)
                    }
                }
            }

            // Add
            await this.$http
                .put('admin/document_flow_author_participant', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.dialogAddParticipant = false;
                    this.recipients = this.recipientsEdit

                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async editParticipants() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.$route.params.id) {
                formData.append('document_flow_author_uuid', this.$route.params.id)
            }

            if (this.authorEdit.id) {
                formData.append('author_id', this.authorEdit.id)
            }


            if (this.recipientsEdit && this.recipientsEdit.length > 0) {
                for (let i in this.recipientsEdit) {
                    if (this.recipientsEdit[i].id !== undefined && this.recipientsEdit[i].id > 0) {
                        formData.append(`recipients[${i}]`, this.recipientsEdit[i].id)
                    }
                }
            }


            // Add
            await this.$http
                .put('admin/document_flow_author_participant', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.dialogAddParticipants = false;
                    this.recipients = this.recipientsEdit
                    this.author = this.authorEdit

                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async checkCreate() {
            if (this.$route.params.id) {
                await this.getDocumentFlow()
            }
        },
        handleClick(click, msg, date) {
            switch (click) {
                case 'delete':
                    this.deleteMessages(msg, date)
                    break;
                case 'reply' :
                    this.replyOpen(msg)
                    break;
            }
        },
        replyOpen(msg) {
            this.reply_uuid = msg.uuid
            this.reply_msg = msg.comment;
            this.reply_name = msg.name;
            this.toolbar_reply = true
        },
        replyClose() {
            this.scrollCard()
            this.reply_uuid = null
            this.reply_name = null
            this.toolbar_reply = false
            this.reply_msg = {}
        },
        scrollCard() {
            this.$nextTick(() => {
                // Прокрутка до нижней части после обновления списка
                if (this.$refs.scrollContainer !== undefined) {
                    const scrollContainer = this.$refs.scrollContainer.$el;
                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                }
            });
        },
        ImageViewClose() {
            this.dialog_image_view = false;
            this.image_view = {};
        },
        ImageView(msg) {
            this.dialog_image_view = true;
            this.image_view = msg;
        },
        async getDocumentFlow() {
            var _this = this;
            this.progress = 0
            this.loadingTask = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/document_flow_author/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.document_flow_uuid = res.body.data.uuid
                    this.number = res.body.data.id
                    this.author = res.body.data.author
                    this.authorEdit = res.body.data.author
                    this.recipients = res.body.data.recipients
                    this.recipientsEdit = res.body.data.recipients
                    this.created_at = res.body.data.created_at
                    this.documentList = res.body.data.files
                    this.document_flow_status = res.body.data.document_flow_status
                    this.recipientsDecisionItems = res.body.data.recipients
                    this.not_accepted = res.body.data.not_accepted
                    this.accepted = res.body.data.accepted
                    this.rejected = res.body.data.rejected
                    this.is_author = res.body.data.is_author
                    this.accompanying_document_urls = res.body.data.documents
                    this.is_recipient = res.body.data.is_recipient
                    this.favorite = res.body.data.favorite




                })
                .catch(err => {
                    this.$router.push({name: 'error-404'});
                    this.$toastr.error(this.$t('failed_to_get_task'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loadingTask = false
                })
        },
        downloadFileMessage(url, file_name) {
            fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },
        dropFile(event) {
            this.files = Array.from(event.dataTransfer.files);
            this.previewFiles();
        },
        previewFiles() {
            let i = this.document_urls.length
            let data = {}
            this.files.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.document_urls.push(data)
                i++
            })
            this.dialog_file = true
        },
        deleteFile(item) {
            let index = this.document_urls.indexOf(item)
            this.document_urls.splice(index, 1)
            if (this.document_urls.length === 0) {
                this.dialog_file = false;
                this.document_urls = [];
                this.documents = []
            }
        },
        deleteAccompanyingDocumentFile(item) {
            let index = this.accompanying_document_urls.indexOf(item)
            this.accompanying_document_urls.splice(index, 1)
        },
        deleteAccompanyingDocuments(item) {
            let index = this.accompanying_documents_preview.indexOf(item)
            this.accompanying_documents_preview.splice(index, 1)
            if (this.accompanying_documents_preview.length === 0) {
                this.accompanying_documents_dialog = false;
                this.accompanying_documents_preview = [];
                this.accompanying_documents = []
            }
        },
        async saveTaskFile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }

            formData.append('document_flow_type', this.document_flow_type)



            if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                let is_image = false;
                for (let i in this.accompanying_documents_preview) {
                    is_image = this.mimeTypeImage(this.accompanying_documents_preview[i].type);
                    formData.append(`type_mime[${i}]`, this.accompanying_documents_preview[i].type)
                    formData.append(`size[${i}]`, this.accompanying_documents_preview[i].size)
                    formData.append(`file_name[${i}]`, this.accompanying_documents_preview[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)

                    this.accompanying_documents_preview[i].blob = await this.convertBlobToBase64(this.accompanying_documents_preview[i].blob)
                    if (this.accompanying_documents_preview[i].blob) {
                        let blob = this.dataURL64toBlob(this.accompanying_documents_preview[i].blob)
                        if (blob) {
                            formData.append(`upfiles[${i}]`, blob, this.accompanying_documents_preview[i].type)
                        }
                    }

                    // formData.append(`upfiles[${i}]`, this.accompanying_documents_preview[i].blob)
                }
            }

            await this.$http
                .put(`admin/document_flow/file/${this.document_flow_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))

                    if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                        for (let i in this.accompanying_documents_preview) {
                            let author = {
                                "author": {
                                    "name": this.$auth.user().name
                                }
                            };
                            Object.assign(this.accompanying_documents_preview[i], author);
                            this.accompanying_document_urls.push(this.accompanying_documents_preview[i])
                        }
                        this.accompanying_documents_dialog = false;
                        this.accompanying_documents_preview = [];
                        this.accompanying_documents = []
                    }
                    this.getDocumentFlow();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async deleteFileServer(item) {
            if (confirm(this.$t('want_to_delete_file'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/document_flow/file/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('file_has_been_deleted'))
                        this.deleteAccompanyingDocumentFile(item)
                        this.getDocumentFlow();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        TaskMessagesWebsocket() {
            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .listen(`.Event.DocumentFlowChat.${this.document_flow_uuid}`, (event) => {
                    //Если пришло добавление собщение
                    if (event && event.id && event.id > 0) {
                        if (event.comment && event.comment.type) {
                            // if(event.comment.type === 'text') {

                            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                                const newMessage = {
                                    [this.$moment().format('YYYY-MM-DD')]:
                                        [
                                            event
                                        ]
                                }
                                this.chatMessagesDate = {...this.chatMessagesDate, ...newMessage};
                            } else {
                                let message_date = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')];
                                let uuidToFind = event.uuid;
                                let foundObject = message_date.find(obj => obj.uuid === uuidToFind);
                                if (typeof foundObject != "undefined") {
                                    foundObject.id = event.id;
                                    foundObject.comment = event.comment;
                                    foundObject.delivered = event.delivered;
                                    foundObject.uuid = event.uuid;
                                    foundObject.created_at = event.created_at;
                                } else {
                                    message_date.push(event);
                                }
                            }
                        }

                    } else if (event && event.type && event.type === 'delete') {
                        //Если пришло удаление собщение
                        if (event.id_array && event.id_array > 0 && event.date) {
                            //if(event.admin_id !== this.$auth.user().id){
                            this.chatMessagesDate[event.date].splice(event.id_array, 1);
                            // }

                        }
                    }
                    this.scrollCard();

                });
        },
        handleEnterKey(event) {
            if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
                this.SentMessage();
            } else {
                event.preventDefault();
                if (!event.shiftKey) {
                    this.text += '\n';
                }
            }
        },
        async SentMessage() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (typeof this.text === 'undefined' || this.text === null || this.text === '') {
                return;
            }

            let comment_uuid = this.$uuid.v4();
            formData.append('comment_uuid', comment_uuid)
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.reply_uuid) {
                formData.append('reply_uuid', this.reply_uuid)
            }
            if (this.text) {
                formData.append('message', this.text)
            }
            formData.append('document_flow_author_uuid', this.document_flow_uuid)
            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            // Add
            await this.$http
                .post('admin/document_flow_message', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    let replyMessage = {}
                    if (this.reply_uuid) {
                        if (this.reply_msg.type === 'text') {
                            replyMessage = {
                                "type": this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid": this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                            }
                        } else {
                            replyMessage = {
                                "type": this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid": this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                                "size": this.reply_msg.size,
                                "type_mime": this.reply_msg.type_mime,
                                "file_type": this.reply_msg.file_type,
                                "file_name": this.reply_msg.file_name,
                            }
                        }

                    }

                    if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {

                        const newMessage = {
                            [this.$moment().format('YYYY-MM-DD')]:
                                [
                                    {
                                        "id": Number(new Date().getTime()) + 1,
                                        "uuid": comment_uuid,
                                        "me": true,
                                        "comment": {"type": "text", "value": this.text},
                                        "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                        "delivered": false,
                                        "name": this.$auth.user().name,
                                        "phone": this.$auth.user().phone,
                                        "photo": this.$auth.user().photo,
                                        "reply": replyMessage
                                    }
                                ]
                        }
                        this.chatMessagesDate = {...this.chatMessagesDate, ...newMessage};
                    } else {
                        this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].push({
                            "id": Number(new Date().getTime()) + this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1,
                            "uuid": comment_uuid,
                            "me": true,
                            "comment": {"type": "text", "value": this.text},
                            "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                            "delivered": false,
                            "name": this.$auth.user().name,
                            "phone": this.$auth.user().phone,
                            "photo": this.$auth.user().photo,
                            "reply": replyMessage
                        });
                    }
                    this.text = ''
                    this.count_messages++;
                    this.replyClose();

                })
                .catch(err => {
                    this.$toastr.error(this.$t('message_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async getMessages() {
            this.loadingMessages = true;
            let params = {};
            params.document_flow_uuid = this.document_flow_uuid;
            await this.$http
                .get("admin/document_flow_message", {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data) {
                        this.chatMessagesDate = res.body.data
                    } else {
                        this.chatMessagesDate = []
                    }
                    this.scrollCard();
                })
                .catch(err => {
                    this.chatMessagesDate = []
                    this.$toastr.error(this.$t('failed_to_get_list_messages'))
                })
                .finally(end => {
                    this.loadingMessages = false
                })
        },
        async SentMessageFile() {
            var _this = this;
            let formData, blob;
            this.dialog_file = false;
            this.documents = []
            let is_image = false;


            let id_array = 0;
            for (const file of this.document_urls) {

                let replyMessage = {}
                if (this.reply_uuid) {
                    if (this.reply_msg.type === 'text') {
                        replyMessage = {
                            "type": this.reply_msg.type,
                            "name": this.reply_name,
                            "reply_uuid": this.reply_msg.uuid,
                            "value": this.reply_msg.value,
                        }
                    } else {
                        replyMessage = {
                            "type": this.reply_msg.type,
                            "name": this.reply_name,
                            "reply_uuid": this.reply_msg.uuid,
                            "value": this.reply_msg.value,
                            "size": this.reply_msg.size,
                            "type_mime": this.reply_msg.type_mime,
                            "file_type": this.reply_msg.file_type,
                            "file_name": this.reply_msg.file_name,
                        }
                    }

                }
                let comment_uuid = this.$uuid.v4();
                is_image = this.mimeTypeImage(file.type);
                if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                    id_array = 1;
                    const newMessage = {
                        [this.$moment().format('YYYY-MM-DD')]:
                            [
                                {
                                    "id": Number(new Date().getTime()) + 1,
                                    "uuid": comment_uuid,
                                    "me": true,
                                    "comment": is_image ? {"type": "image", "value": file.url} : {"type": "file", "value": file.url, "file_name": file.name, "size": file.size},
                                    "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                    "delivered": false,
                                    "name": this.$auth.user().name,
                                    "phone": this.$auth.user().phone,
                                    "photo": this.$auth.user().photo,
                                    "reply": replyMessage
                                }
                            ]
                    }
                    this.chatMessagesDate = {...this.chatMessagesDate, ...newMessage};
                } else {
                    id_array = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1;
                    this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].push({
                        "id": Number(new Date().getTime()) + (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length) + 1,
                        "uuid": comment_uuid,
                        "me": true,
                        "comment": is_image ? {"type": "image", "value": file.url} : {"type": "file", "value": file.url, "file_name": file.name, "size": file.size},
                        "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                        "delivered": false,
                        "name": this.$auth.user().name,
                        "phone": this.$auth.user().phone,
                        "photo": this.$auth.user().photo,
                        "reply": replyMessage
                    });
                }

                formData = new FormData();
                if (this.reply_uuid) {
                    formData.append('reply_uuid', this.reply_uuid)
                }
                formData.append('comment_uuid', comment_uuid)
                formData.append('id_array', id_array)
                formData.append('document_flow_author_uuid', this.document_flow_uuid)
                formData.append('type_mime', file.type)
                formData.append('size', file.size)
                formData.append('file_name', file.name)
                formData.append('is_image', is_image ? 1 : null)
                file.blob = await this.convertBlobToBase64(file.blob)
                if (file.blob) {
                    blob = this.dataURL64toBlob(file.blob)
                    if (blob) {
                        formData.append('file', blob, file.type)
                    }
                }

                // Add
                this.$http
                    .post('admin/document_flow_message/file', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(res => {
                        var index = this.chatMessages.length + 1
                        if (typeof this.chatMessages[index] != "undefined") {
                            this.chatMessages[index].delivered = true;
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('page_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        var index = this.chatMessages.length + 1
                        if (typeof this.chatMessages[index] != "undefined") {
                            this.chatMessages[index].delivered = true;
                        }
                    })


            }
            this.replyClose()
            this.document_urls = [];
            this.count_messages++;
        },
        async deleteMessages(msg, date) {
            this.loading = true;
            let params = {};
            let idx = this.chatMessagesDate[date].indexOf(msg);
         //   params.document_flow_uuid = this.document_flow_uuid;
            params.message_uuid = msg.uuid;
            params.date = date;
            if (idx !== -1) {
                params.id_array = idx;
            }

            await this.$http
                .delete(`admin/document_flow_message/${this.document_flow_uuid}`, {
                    params: params,
                })
                .then(res => {

                    //let index = this.chatMessagesDate[date].findIndex(el => el.uuid === "4fb4bc6b-9a77-40fb-99aa-865c73a4b004");
                    if (idx !== -1) {
                        this.chatMessagesDate[date].splice(idx, 1);
                    }
                    this.$toastr.success(this.$t('message_has_been_deleted'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('message_has_not_been_deleted'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        accompanyingDocumentsPreviewFiles() {
            let i = 0;
            let data = {}
            this.accompanying_documents.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.accompanying_documents_preview.push(data)
                i++
            })
            this.accompanying_documents_dialog = true
        },
        async addStatusApprovalFile(confirm) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)

            }
            formData.append('confirm', confirm)
            if (this.reject_text) {
                formData.append('reject_text', this.reject_text)
            }

            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            await this.$http
                .post(`admin/document_flow_author/status/file/${this.document_flow_id}/approval`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.getDocumentFlow();
                    this.document_flow_id = null
                    this.dialogStatusApprovalFile = false;
                    this.reject_text = null;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async approvalSheet(){
            var _this = this;
            this.loading = true


            this.loading = true
            let params = {}
            if (this.language) {
                params.language =  this.language
            }
            if (this.$route.params.id) {
                params.document_flow_author_uuid =  this.$route.params.id
            }

            await this.$http
                .get(`admin/approval_sheet/document_flow`, {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.url){
                        this.openFile(res.body.data)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('document_flow_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })



        },
    }
}
</script>

<style scoped>
.v-card--link:before {
    background: none;
}

>>> .heightTable td {
    height: 50px !important;
}

.select_border_none >>> .v-input__slot::before {
    border-style: none !important;
}
</style>
<style lang="scss">
.approval-sheet__item {
    @media screen and(max-width: 992px) {
        &.v-list-item {
            flex-direction: column;
            min-height: auto;
            padding-left: 0;
            padding-right: 0;
        }

        .approval-sheet__desc {
            width: 100%;
        }

        .approval-sheet__btns {
            width: 100%;
            flex-direction: row;

            .v-btn {
                flex-basis: auto;
            }
        }
    }
}

.task-document {
    display: flex;
    flex-direction: column;
    margin-bottom: $grid-gutter / 2;
    border: 1px solid #eeeeee;
    flex-wrap: wrap;

    &.bordered {
        border-radius: 8px;
        padding: $grid-gutter / 2;;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.task-document__desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: $grid-gutter / 2;
}

.task-document__icon {
    width: 45px;
    height: 45px;
    margin-right: $grid-gutter / 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task-document__text {
    flex-grow: 1;
}

.task-document__approval {
    margin-top: $grid-gutter / 2;
    padding-top: $grid-gutter / 2;
    border-top: 1px solid #ccc;
}

.approval {
    width: 100%;

    &:empty {
        display: none;
    }
}

.approval__actions {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: $grid-gutter / 2;
    flex-direction: row-reverse;

    & > * {
        flex-grow: 1;
    }
}

.approval__info {
    display: flex;
    flex-direction: column;
    margin-bottom: $grid-gutter / 2;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .task-document {
        flex-direction: row;
    }

    .task-document__approval-info {
        flex-direction: row;
        gap: $grid-gutter / 2;
    }

    .approval-actions {
        flex-direction: column;

        & > * {
            width: 100%;
        }
    }

    .task-document__desc {
        margin-bottom: 0;
    }
}
</style>
