export default {
    data() {
        return {
            accountTypes: [],
        }
    },

    created() {
        this.fetchAccountTypes();
    },

    methods: {
        fetchAccountTypes(params = {}) {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/account_type', params)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },

    mounted() {
        this.fetchAccountTypes()
            .then((types) => {
                this.accountTypes = types;
            });
    }
}
