export default {
    methods: {
        fetchNomenclature(params) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get('admin/nomenclature', {
                        params
                    })
                    .then(res => {
                        resolve(res.body)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('nomenclature.fetch_error'))
                        reject(err)
                    });
            });
        },

        fetchNomenclaturePrice(nomenclatureUuid) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/nomenclature/${nomenclatureUuid}/price`)
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('nomenclature.price_fetch_error'))
                        reject(err)
                    });
            });
        },

        fetchNomenclatureStockForWarehouse(nomenclatureUuid, warehouseId) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/nomenclature/${nomenclatureUuid}/stock`, {
                        params: {
                            warehouse_id: warehouseId
                        }
                    })
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('nomenclature.price_fetch_error'))
                        reject(err)
                    });
            });
        }
    }
}
