<script>
export default {
    name: "FileList",

    props: {
        entityType: {
            type: String,
            required: true
        },

        entityUuid: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            files: [],

            pagination: {
                page: 1,
                total_pages: 1
            },

            uploadingFiles: [],
            uploadingFilesPreview: [],
            uploadingFilesPreviewDialog: false
        }
    },

    watch: {
        entityUuid: {
            handler: function (value) {
                this.loading = true;

                this.init().then(() => {
                    this.loading = false;
                });
            },
            immediate: true
        }
    },

    methods: {
        init() {
            return new Promise((resolve, reject) => {
                let filesPromise = this.fetchFiles({
                    page: this.pagination.page
                }).then((data) => {
                    this.files = data.data;
                    this.pagination.total_pages = data.meta.last_page;
                });

                Promise.all([filesPromise]).then(() => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        fetchFiles({ page }) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/${this.entityType}/${this.entityUuid}/file`, {
                        params: {
                            page
                        }
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        onDownloadFileBtnClick(file) {
            let a = document.createElement('a');
            a.href = file.url;
            a.download = file.name;
            a.click();
        },

        deleteAccompanyingDocumentFile(item) {
            let index = this.accompanying_document_urls.indexOf(item)
            this.accompanying_document_urls.splice(index, 1)
        },

        deleteAccompanyingDocuments(item) {
            let index = this.accompanying_documents_preview.indexOf(item)
            this.accompanying_documents_preview.splice(index, 1)
            if (this.accompanying_documents_preview.length === 0) {
                this.accompanying_documents_dialog = false;
                this.accompanying_documents_preview = [];
                this.accompanying_documents = []
            }
        },

        async onDeleteFileServerBtnClick(item) {
            if (confirm(this.$t('want_to_delete_file'))) {
                const _this = this
                this.loading = true;

                this.deleteFile(item)
                    .then(() => {
                        _this.$emit('file-deleted', item);
                    })
                    .finally(() => {
                        _this.init()
                            .then(() => {
                            })
                            .finally(() => {
                                _this.loading = false;
                            });
                    });
            }
        },

        deleteFile(file) {
            return new Promise((resolve, reject) => {
                this.$http.delete(`admin/${this.entityType}/${this.entityUuid}/file/${file.uuid}`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        onPageChange() {
            this.loading = true;

            this.fetchFiles({
                page: this.pagination.page
            }).then((data) => {
                this.files = data.data;
                this.pagination.total_pages = data.meta.last_page;
                this.loading = false;
            });
        }
    }
}
</script>

<template>
    <div>
        <v-row v-if="loading">
            <v-col>
                <v-progress-linear
                    color="secondary"
                    indeterminate
                ></v-progress-linear>
            </v-col>
        </v-row>
        <template v-else-if="files.length">
            <v-list>
                <v-list-item outlined class="file_item px-0" v-for="file in files" :key="file.id + '_' + Date.now()">
                    <v-list-item-icon>
                        <v-img
                            max-width="45"
                            :alt="`${file.original_name}`"
                            :src="file.url"
                            max-height="30"
                            v-if="mimeTypeImage(file.mime_type)"
                        ></v-img>
                        <v-icon
                            x-large
                            size="10"
                            color="primary"
                            v-else
                        >mdi-file-document-outline
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="file_text">
                        <v-list-item-title class="cursor-pointer" v-text="file.original_name" @click="openFile(file)" @contextmenu.prevent="onDownloadFileBtnClick(file)"></v-list-item-title>
                        <v-list-item-subtitle v-text="(file.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                        <v-list-item-subtitle v-text="`${file.admin.name}`"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon class="d-flex align-self-center mt-3 px-0 mx-0">
                        <v-icon color="primary" class="mr-2" :title="$t('download')" @click="onDownloadFileBtnClick(file)">mdi-cloud-upload</v-icon>
                        <v-icon vcolor="primary" v-if="file.uuid && (can(['administrator', 'general_manager']))" :title="$t('delete')" @click="onDeleteFileServerBtnClick(file)">mdi-delete-outline</v-icon>
                        <v-icon color="primary" v-if="!file.uuid && (can(['administrator', 'general_manager']))" :title="$t('delete')" @click="deleteAccompanyingDocumentFile(file)">mdi-delete-outline</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>

            <v-pagination
                dens
                v-model="pagination.page"
                :length="pagination.total_pages"
                @input="onPageChange"
            ></v-pagination>

        </template>
        <template v-else>
            <v-row>
                <v-col>
                    <v-alert
                        dense
                        text
                        type="success"
                        class="ma-0"
                    >
                        {{ $t('no_files') }}
                    </v-alert>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<style scoped lang="scss">

</style>
