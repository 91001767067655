<template>
    <PageLayout
        class="nomenclature-detail"
        @drop.prevent="dragover = false"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragend.prevent="dragover = false"
    >
        <template>
            <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-container fluid>
                    <v-form @submit.prevent="onSubmitForm">
                        <v-row>
                            <v-col :cols="uuid === 'create' ? 12 : 8">
                                <v-card>
                                    <v-card-title>
                                        {{ $t('nomenclature.information') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <ValidationProvider ref="type" tag="div" rules="required|min:1" v-slot="{ errors, valid }">
                                                    <SelectInput
                                                        :label="$t('columns.nomenclature.nomenclature_type_id')"
                                                        id="type"
                                                        :items="types.map((item) => ({ text: ' . '.repeat(item.depth ?? 0) + ' ' + item.name, value: item.id }))"
                                                        item-text="text"
                                                        item-value="value"
                                                        v-model="type"
                                                        :error="!valid"
                                                    ></SelectInput>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="6">
                                                <ValidationProvider ref="name" tag="div" rules="required|min:1" v-slot="{ errors, valid }">
                                                    <TextInput
                                                        id="name"
                                                        :label="$t('columns.nomenclature.name')"
                                                        v-model="name"
                                                        :error="!valid"
                                                    >
                                                    </TextInput>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="6">
                                                <ValidationProvider ref="article" tag="div" rules="required|min:1" v-slot="{ errors, valid }">
                                                    <TextInput
                                                        id="article"
                                                        :label="$t('columns.nomenclature.article')"
                                                        v-model="article"
                                                        :error="!valid"
                                                    >
                                                    </TextInput>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12">
                                                <SelectInput
                                                    :label="$t('nomenclature.group')"
                                                    id="group"
                                                    :items="groups.map((item) => ({ text: ' . '.repeat(item.depth - 1 ?? 0) + ' ' + item.name, value: item.id }))"
                                                    item-text="text"
                                                    item-value="value"
                                                    v-model="group"
                                                ></SelectInput>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4" v-if="uuid !== 'create'">
                                <v-card height="100%">
                                    <v-card-title>
                                        {{ $t('nomenclature.image') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col >
                                                <NomenclatureImages v-if="renderImages" entity-type="nomenclature" :entity-uuid="uuid" @files-uploaded="onFileUploaded"/>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card color="white">
                                    <v-card-title>
                                        {{ $t('nomenclature.description') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <Textarea id="description" :label="$t('columns.nomenclature.description')" />
                                            </v-col>

                                            <v-col cols="12">
                                                <SelectInput
                                                    :label="$t('unit.self')"
                                                    id="unit"
                                                    :items="units.map((item) => ({ text: item.name, value: item.id }))"
                                                    item-text="text"
                                                    item-value="value"
                                                    v-model="unit"
                                                ></SelectInput>
                                            </v-col>

                                            <v-col cols="12">
                                                <SelectInput
                                                    :label="$t('nomenclature.vat')"
                                                    id="vat"
                                                    :items="vats.map((item) => ({ text: item.value + ' %', value: item.id }))"
                                                    item-text="text"
                                                    item-value="value"
                                                    v-model="vat"
                                                ></SelectInput>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="12" v-if="saved">
                                <ResponsiveTabs>
                                    <ResponsiveTab :title="$t('nomenclature.prices')">
                                        <NomenclaturePrices :nomenclature-uuid="uuid"></NomenclaturePrices>
                                    </ResponsiveTab>
<!--                                    <ResponsiveTab :title="$t('nomenclature.stocks')">-->
<!--                                        stock-->
<!--                                    </ResponsiveTab>-->
                                    <ResponsiveTab :title="$t('nomenclature.barcodes')">
                                        <NomenclatureBarcode :nomenclature-uuid="uuid"></NomenclatureBarcode>
                                    </ResponsiveTab>
<!--                                    <ResponsiveTab :title="$t('nomenclature.history')">-->
<!--                                        history-->
<!--                                    </ResponsiveTab>-->
                                    <ResponsiveTab :title="$t('nomenclature.files')">
                                        <v-row>
                                            <v-col>
                                                <FileUploader
                                                    :entity-uuid="uuid"
                                                    entity-type="nomenclature"
                                                    @files-uploaded="onFileUploaded"
                                                ></FileUploader>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <FileList v-if="renderFiles" entity-type="nomenclature" :entity-uuid="uuid" @file-deleted="onFileDeleted"/>
                                            </v-col>
                                        </v-row>
                                    </ResponsiveTab>
                                </ResponsiveTabs>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col class="d-flex justify-end">
                                <Btn color="secondary" type="submit">{{ $t('nomenclature.save') }}</Btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </ValidationObserver>
        </template>
    </PageLayout>
</template>
<script>
import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import TextInput from "@/components/Form/TextInput.vue";

import ResponsiveTabs from "@/components/Leentech/ResponsiveTabs.vue";
import ResponsiveTab from "@/components/Leentech/ResponsiveTab.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import Textarea from "@/components/Form/Textarea.vue";
import Btn from "@/components/Form/Btn.vue";
import NomenclaturePrices from "@/components/Warehouse/NomenclaturePrices.vue";
import NomenclatureBarcode from "@/components/Warehouse/NomenclatureBarcodes.vue";
import NomenclatureImages from "@/components/Warehouse/NomenclatureImages.vue";
import FileList from "@/components/Leentech/FileList.vue";
import FileUploader from "@/components/Leentech/FileUploader.vue";
import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";

export default {
    name: 'ProductShow',

    components: {
        FileUploader,
        NomenclatureImages,
        FileList,
        NomenclatureBarcode,
        NomenclaturePrices,
        Btn,
        Textarea,
        SelectInput,
        TextInput,
        ValidationProvider,
        ValidationObserver,
        ResponsiveTabs,
        ResponsiveTab
    },

    props: {
        uuid: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            name: '',
            unit: null,
            type: null,
            vat: null,
            group: null,
            article: null,

            renderImages: true,
            renderFiles: true,

            types: [],
            groups: [],
            units: [],
            vats: []
        }
    },

    computed: {
        ...mapGetters(['userBlock', 'language', 'listLanguages', 'lang', 'timezone']),

        saved() {
            return this.uuid !== 'create';
        },
    },

    watch: {
        uuid: {
            handler() {
                this.init();
            },
            immediate: true
        }
    },

    mounted() {

    },

    methods: {
        async init() {
            if(this.uuid !== 'create') {
                this.fetchItem();
            }

            this.fetchUnits();
            this.fetchGroups();
            this.fetchTypes();
            this.fetchVat();
        },

        fetchItem() {
            this.$http.get(`admin/nomenclature/${this.uuid}`)
                .then(({ body }) => {
                    this.name = body.data.name;
                    this.unit = body.data.unit_id;
                    this.type = body.data.nomenclature_type_id;
                    this.vat = body.data.vat_id;
                    this.group = body.data.nomenclature_group_id;
                    this.article = body.data.article;
                })
                .catch(error => {
                    this.$tostar.error(this.$t('nomenclature.fetching_error' + error));
                });
        },

        fetchUnits() {
            this.$http.get('admin/unit')
                .then(({ body }) => {
                    this.units = body.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        fetchTypes() {
            this.$http.get('admin/nomenclature_type')
                .then(({ body }) => {
                    this.types = body.data;
                })
                .catch(error => {
                    this.$toastr.error(this.$t('messages.error'));
                });
        },

        fetchGroups() {
            this.$http.get('admin/nomenclature_group')
                .then(({ body }) => {
                    let groups = body.data;

                    groups = groups.map((item) => {
                        item.parent_id = item.parent_id ?? 0;
                        return item;
                    });

                    groups = buildTree(groups, 0, 'id', 'parent_id', 'children');
                    groups = buildListArrayFromTreeArray(groups);

                    this.groups = groups;
                })
                .catch(error => {
                    this.$toastr.error(this.$t('nomenclature_group.error_fetching'));
                    this.$toastr.error(error);
                });
        },

        fetchVat() {
            this.$http.get('admin/vat')
                .then(({ body }) => {
                    this.vats= body.data;
                })
                .catch(error => {
                    this.$toastr.error(this.$t('messages.error'));
                });
        },

        saveItem() {
            let data = {
                name: this.name,
                article: this.article,
                unit_id: this.unit,
                nomenclature_type_id: this.type,
                nomenclature_group_id: this.group,
                vat_id: this.vat,
            };

            return new Promise((resolve, reject) => {
                if(this.uuid === 'create') {
                    this.$http.post('admin/nomenclature', data)
                        .then(({body}) => {
                            resolve(body.data);
                        })
                        .catch(error => {
                            this.$toastr.error(this.$t('nomenclature.saving_error'));
                            reject(error)
                        });
                } else {
                    this.$http.put(`admin/nomenclature/${this.uuid}`, data)
                        .then(({body}) => {
                            resolve(body.data);
                        })
                        .catch(error => {
                            this.$toastr.error(this.$t('nomenclature.saving_error'));
                            reject(error)
                        });
                }
            });
        },

        onSubmitForm() {
            this.saveItem().then((data) => {
                this.$toastr.success(this.$t('nomenclature.saved'));
                this.$router.push({ name: 'nomenclature' });
            }).catch((error) => {
                this.$toastr.error(this.$t('nomenclature.error_saving') + error);
            });
        },

        rerenderImages() {
            this.renderImages = false;

            this.$nextTick(() => {
                this.renderImages = true;
            });
        },

        rerenderFiles() {
            this.renderFiles = false;

            this.$nextTick(() => {
                this.renderFiles = true;
            });
        },

        onFileDeleted() {
            this.rerenderImages()
            this.rerenderFiles()
        },

        onFileUploaded() {
            this.rerenderImages()
            this.rerenderFiles()
        }
    }
}
</script>
