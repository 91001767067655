export default {
    data() {
        return {
            warehouseShelves: [],
        }
    },

    methods: {
        initWarehouseShelves() {
            this.fetchWarehouseShelves()
                .then((items) => {
                    this.warehouseShelves = items;
                });
        },

        fetchWarehouseShelves({
            warehouse_zone_id = null
        } = {}) {
            let params = {
                fltr: {},
            }

            if(warehouse_zone_id) {
                params.fltr.warehouse_zone_id = {
                    eq: warehouse_zone_id + ''
                };
            }

            return new Promise((resolve, reject) => {
                this.$http.get('admin/warehouse/warehouse_shelf', {
                    params
                })
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },
}
