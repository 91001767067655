<script>
export default {
    name: "ActivityLogItem",

    props: {
        item: {
            type: Object,
            required: true
        },

        columns: {
            type: Array,
            required: true
        },

        userBlock: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        activeColumns() {
            return this.columns.filter(column => {
                return   column.prop !== 'uuid'

            });
        }
    },

    methods: {
        deleteDocument(item) {
            this.$emit('deleteItem', item);
        }
    }
}
</script>

<template>
    <div class="item">
        
        <div class="item__data" v-if="activeColumns.length > 0">
            <div class="text-info" v-for="column in activeColumns" :key="column.name">
                <div class="text-info__label">
                    <div>{{ column.label }}</div>
                </div>
                <div class="text-info__value">
                    <slot :name="column.prop" :item="item">
                        <div>{{ item[column.prop] }}</div>
                    </slot>
                </div>
            </div>
        </div>

        <div class="item__footer">
            <div class="item__deadline"></div>
            <div class="item__recipients"></div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
.item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-bottom: 0;
    padding-top: 5px;
    border-radius: 0px;
}

.item__header {
    padding-top: $spacer * 4;
    padding-left: $spacer * 4;
    padding-right: $spacer * 4;
    margin-bottom: $spacer * 4;

    display: flex;
    justify-content: space-between;
}

.item__description {
    padding-left: $spacer * 4;
    padding-right: $spacer * 4;
    margin-bottom: $spacer * 4;
}

.item__status {
    padding: 2px 8px;
    border-radius: 0px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}

.item__id {
    margin-bottom: $spacer * 2;
}

.item__name {
    margin-bottom: $spacer * 2;
}

.item__data {
    padding-left: $spacer * 4;
    padding-right: $spacer * 4;
    padding-bottom: $spacer * 4;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
}

.item__footer {
    border-top: 1px solid #E7E7E7;
    padding: $spacer * 4;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.item__deadline {
    flex-grow: 1;
}

.item__recipients {
    max-width: 50%;
}

.text-info {
    margin-bottom: $spacer * 2;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        width: 50%;
    }
}
</style>
