<template>
    <v-container fluid>
        <v-dialog
            persistent

            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveSubStatus">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-row class="flex-nowrap">
                                        <v-col cols="1" style="max-width: 80px">
                                            <v-btn :to="{name: 'sub_status'}" class="back-btn" link large>
                                                <v-icon>mdi-less-than</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-subheader class="headline">{{ heading }}</v-subheader>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="py-0" cols="12" md="6">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span
                                                :class="exist_translations[language] ? '' : 'red--text '">{{ language }}</span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="status" rules="required" v-slot="{ errors, valid }">
                                        <v-select v-model="status" :disabled="loading" :items="statusItems"
                                                  :error="!valid" :error-messages="errors"
                                                  prepend-icon="mdi-playlist-check"
                                                  color="primary" item-text="name" item-value="id"
                                                  :label="$t('status')" clearable></v-select>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="sub_status_name" name="sub_status_name" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="sub_status_name" type="text" :error="!valid" :error-messages="errors" :disabled="loading"
                                                      prepend-icon="mdi-playlist-check" :label="$t('sub_status_name')"
                                                      color="primary" clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="code" name="code" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="code" type="text" :error="!valid" :error-messages="errors" :disabled="loading"
                                                      prepend-icon="mdi-playlist-check" :label="$t('code')"
                                                      color="primary" clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="12">
                                    <v-switch v-model="finish"
                                              :disabled="loading"
                                              :label="$t('completed_process')"
                                              class="input_switch py-0"
                                              color="primary" hide-details></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 py-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"

    export default {
        name: 'SubStatusForm',
        components: {
            ValidationProvider,
            ValidationObserver
        },
        inject: ['forceRerender'],
        data() {
            return {
                dialogForm: true,
                heading: null,
                progress: 0,
                tab: 0,
                language: null,
                loading: false,
                errors: {},
                sub_status_name: null,
                exist_translations: {},
                all_translations: true,
                code: null,
                status: null,
                statusItems: [],
                finish: false,
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.language = this.languages[this.tab]
            this.getStatuses()
            this.checkCreate()
        },
        methods: {
            checkCreate() {
                if (this.$route.name === "sub_status.create") {
                    this.heading = this.$t('sub_status_creation')
                } else {
                    this.heading = this.$t('sub_status_editing')
                    if (this.$route.params.id) {
                        this.getSubStatus()
                    }
                }
            },
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
            closeDialogAdd(){
                if(window.history.length > 2){
                    this.$router.back()
                }
                else{
                    this.$router.push({
                        name: 'sub_status',
                    })
                }
            },
            async getStatuses() {
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/status`, {
                        params: params,
                    })
                    .then(res => {
                        this.statusItems = res.body.data
                    })
                    .catch(err => {
                        this.statusItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_statuses'))
                    });
            },
            async getSubStatus() {
                var _this = this
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/sub_status/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.sub_status_name = res.body.data.name
                        this.status = res.body.data.status.id
                        this.code = res.body.data.code
                        this.finish = res.body.data.finish
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_sub_status'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveSubStatus() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }
                if (this.sub_status_name) {
                    formData.append('sub_status_name', this.sub_status_name)
                }
                if (this.status) {
                    formData.append('status', this.status)
                }
                if (this.code) {
                    formData.append('code', this.code)
                }
                if (this.finish) {
                    formData.append('finish', 1)
                }
                if (this.$route.params.id) {
                    // Save
                    await this.$http
                        .put(`admin/sub_status/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('sub_status_has_been_updated'))
                            if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('sub_status_has_not_been_updated'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/sub_status', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('sub_status_has_been_added'))
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                                this.$router.push({
                                    name: 'sub_status.edit',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                                //this.forceRerender()
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('sub_status_has_not_been_added'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
<style scoped>
</style>
