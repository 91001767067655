export default {
    data() {
        return {
            warehouseZones: [],
        }
    },

    methods: {
        initWarehouseZones() {
            this.fetchWarehouseZones()
                .then((items) => {
                    this.warehouseZones = items;
                });
        },

        fetchWarehouseZones({
            warehouse_id = null
        } = {}) {
            let params = {
                filter: {},
            }

            if(warehouse_id) {
                params.filter.warehouse_id = warehouse_id;
            }

            return new Promise((resolve, reject) => {
                this.$http.get('admin/warehouse/warehouse_zone', {
                    params
                })
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },
}
