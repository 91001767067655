import Vue from "vue";

export default {
    namespaced: true,
    state: {
        messages: []
    },
    mutations: {
        setMessages(state, messages) {
            state.messages = messages;
        }
    },
    actions: {
        fetchMessages({ commit }, comment) {
            return new Promise((resolve, reject) => {
                Vue.http.get('admin/message', {
                    params: {
                        perPage: 12,
                        fltr: {
                            my: {
                                eq: '1'
                            },
                            exclude_bot: {
                                eq: '1'
                            }
                        },
                        sort: 'read'
                    }
                })
                    .then(({body}) => {
                        commit('setMessages', body.data);
                        resolve(body);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                        throw error;
                    });
            });
        },

    },
    getters: {
        messages: state => state.messages,
        unreadMessages: state => state.messages.filter(message => !message.is_readed)
    }
}
