export default {
    data() {
        return {
            account_payment_types: [],
        }
    },

    methods: {
        initAccountPaymentTypes() {
            this.fetchAccountPaymentTypes()
                .then((account_payment_types) => {
                    this.account_payment_types = account_payment_types;
                });
        },

        fetchAccountPaymentTypes(params = {}) {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/account_payment_type', params)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
}
