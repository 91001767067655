<script>
import Btn from "@/components/Form/Btn.vue";
import TextInput from "@/components/Form/TextInput.vue";

export default {
    name: "NomenclaturePrices",
    components: {Btn, TextInput},

    props: {
        nomenclatureUuid: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: false,

            prices: {},
            priceTypes: [],
        }
    },

    watch: {
        nomenclatureUuid: {
            handler: function (value) {
                this.loading = true;

                this.init().then(() => {
                    this.loading = false;
                });
            },
            immediate: true
        }
    },

    mounted() {

    },

    methods: {
        init() {
            return new Promise((resolve, reject) => {
                let pricesPromise = this.fetchPrices().then((data) => {
                    this.prices = data;
                });

                Promise.all([pricesPromise]).then(() => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });

        },

        fetchPrices() {
            return new Promise((resolve, reject) => {
                this.$http.get(`admin/nomenclature/${this.nomenclatureUuid}/price`)
                    .then(({ body }) => {
                        resolve(body.data);
                    })
                    .catch(e => {
                        reject(e);
                    });
            })
        },

        priceAdd(priceTypeId, price) {
            return new Promise((resolve, reject) => {
                this.$http.post(`admin/nomenclature/${this.nomenclatureUuid}/price`, {
                    value: price,
                    price_type_id: priceTypeId
                })
                    .then(response => {
                        this.$toast.success(this.$t('columns.nomenclature_price.price_added'));
                        resolve(response.body.data);
                    })
                    .catch(e => {
                        this.$toast.error(this.$t('columns.nomenclature_price.price_not_added'));
                        reject(e);
                    });
            })
        },

        onPriceAdd(priceTypeId, price) {
            this.priceAdd(priceTypeId, price).then((data) => {
                this.fetchPrices().then((data) => {
                    this.prices = data;
                });
            });
        }
    }
}
</script>

<template>
    <div>
        <v-row v-if="loading">
            <v-col>
                <v-progress-linear
                    indeterminate
                ></v-progress-linear>
            </v-col>
        </v-row>
        <v-row v-else-if="prices.length" v-for="(priceType, i) in prices" :key="i">
            <v-col cols="12" class="pb-0">
                <span>{{ priceType.name }}</span>
            </v-col>
            <v-col class="flex-grow-1">
                <TextInput
                    hide-label
                    :id="`price ${priceType.id }`"
                    v-model="priceType.value"
                    :label="priceType.name"
                ></TextInput>
            </v-col>

            <v-col class="flex-grow-0">
                <Btn @click="onPriceAdd(priceType.id, priceType.value)" color="secondary">{{ $t('columns.nomenclature_price.set_price') }}</Btn>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-row >
                <v-col>
                    <v-alert
                        dense
                        text
                        type="error"
                    >
                        {{ $t('columns.nomenclature_price.no_prices') }}
                    </v-alert>
                </v-col>
            </v-row>
        </v-row>
    </div>
</template>

<style scoped lang="scss">

</style>
