<template>
    <v-list-group
        :value="false"
        :sub-group="subGroup"
        no-action
    >
        <template v-slot:activator>
            {{ group.name }}
            <v-btn
                class="ml-2"
                icon
                x-small
                :to="{
                    name: 'nomenclature_group.edit',
                    params: {
                        uuid: group.uuid
                    }
                }"
            >
                <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn
                class="ml-2"
                icon
                x-small
                :to="{
                    name: 'nomenclature',
                    query: {
                        filter: {
                            nomenclature_group_id: {
                                eq: group.id
                            }
                        }
                    }
                }"
            >
                <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
        </template>

        <template v-for="item in group.children">
            <NomenclatureGroupListItem v-if="!item.children" :group="item" @deleteItem="$emit('deleteItem', $event)" @clickItem="$emit('clickItem', $event)" />
            <NomenclatureGroupListGroup v-else :group="item" @deleteItem="$emit('deleteItem', $event)" @clickItem="$emit('clickItem', $event)" :sub-group="true"/>
        </template>
    </v-list-group>
</template>
<script>
import NomenclatureGroupListItem from "@/components/NomenclatureGroupListItem.vue";
import NomenclatureGroupListGroup from "@/components/NomenclatureGroupListGroup.vue";

export default {
    name: 'NomenclatureGroupListGroup',
    components: {NomenclatureGroupListItem, NomenclatureGroupListGroup},
    props: {
        group: {
            type: Object,
            required: true
        },

        subGroup: {
            type: Boolean,
            default: false
        }
    },
}
</script>
