<template>
    <v-list-item
        @click="onItemClick(group)"
        :to="{
                name: 'nomenclature',
                query: {
                    filter: {
                        nomenclature_group_id: {
                            eq: group.id
                        }
                    }
                }
            }">
        <v-list-item-title>
            {{ group.name }}
            <v-btn
                class="ml-2"
                icon
                x-small
                :to="{
                    name: 'nomenclature_group.edit',
                    params: {
                        uuid: group.uuid
                    }
                }"
            >
                <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn
                class="ml-2"
                icon
                x-small
                :to="{
                    name: 'nomenclature',
                    query: {
                        filter: {
                            nomenclature_group_id: {
                                eq: group.id
                            }
                        }
                    }
                }"
            >
                <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
            <v-btn
                class="ml-2"
                icon
                x-small
                @click="onDeleteBtnClick(group)"
            >
                <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
        </v-list-item-title>
    </v-list-item>
</template>
<script>
export default {
    name: 'NomenclatureGroupListItem',
    props: {
        group: {
            type: Object,
            required: true
        }
    },

    methods: {
        onDeleteBtnClick(item) {
            this.$emit('deleteItem', item)
        },

        onItemClick(item) {
            this.$emit('clickItem', item)
        },
    }
}
</script>
