<script>
import TabsNav from "@/components/Leentech/TabsNav.vue";
import NavMixin from "@/plugins/mixins/Nav";

export default {
    name: "WarehouseHeader",

    mixins: [
        NavMixin
    ],

    components: {TabsNav}
}
</script>

<template>
    <div>
        <TabsNav v-if="breadcrumbs()[0]['items']" strict :items="breadcrumbs()[0]['items']"/>
        <TabsNav v-if="breadcrumbs()[1]['items']" strict :items="breadcrumbs()[1]['items']"/>
    </div>
</template>

<style scoped lang="scss">

</style>
