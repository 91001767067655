<template>
    <PageLayout>
        <template v-slot:default>
            <TabsNav :items="breadcrumbs()[0]['items']"/>
            <div class="d-flex justify-space-between mb-10">
                <div class="d-flex align-center flex-grow-1">
                    <h1>
                        <template v-if="currentFilter">
                            {{ currentFilter.name }}
                        </template>
                        <template v-else>
                            {{ $t('menu_basket') }}
                        </template>
                    </h1>
                    <TableSettings
                        :fields="tableColumns"
                        :entityName="entityName"
                        className="ml-2"
                        @column-select="onColumnSelect"
                    />
                </div>
            </div>

            <div class="mobile-filter mb-5" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    :disabled="userBlock"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>


            </div>



            <template v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in baskets"
                    :item="item"
                    :key="item.id"
                    :columns="tableColumns"
                    :userBlock="userBlock"

                    @deleteItem="deleteBasket(item)"
                >



                    <template v-slot:id="{ item }">
                        {{ item.id }}
                    </template>


                    <template v-slot:document="{ item }">
                        <v-list>
                            <v-list-item class="ml-0 pl-0 ">
                                <v-list-item-content class="file_text mt-0 pt-0">
                                    <v-list-item-title class="cursor-pointer" v-text="item.document.name" @click="openFile(item.document)"
                                                       @auxclick.prevent="windowOpenFile(item.document.url);"
                                                       @contextmenu.prevent="downloadFileByURL(item.document)"></v-list-item-title>
                                    <v-list-item-subtitle v-text="(item.document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon class="d-flex align-self-center mt-0 pt-0 px-0 mx-0">
                                    <v-icon color="primary" small>file-text-download</v-icon>
                                    <v-btn icon :title="$t('download')" @click="downloadFileByURL(item.document)">
                                        <v-icon color="primary" small>icon-file-text-download</v-icon>
                                    </v-btn>
                                    <v-btn icon :title="$t('delete')" @click="deleteBasket(item)">
                                        <v-icon color="primary" small>icon-bin</v-icon>
                                    </v-btn>
                                    <v-btn icon :title="$t('restored_document')" @click="recoverBasket(item)">
                                        <v-icon color="primary" small>icon-file-text-refresh</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>

                    </template>

                    <template v-slot:admin_id="{ item }">
                        <div>
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.admin.photo === '/img/avatar.png'}">
                                        <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>

                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>


                </ListItem>
            </template>
            <template v-else>
                <ResizableTable
                    class="main-data-table elevation-0 uveds-table"
                    :rows="baskets"
                    :columns="tableColumns"
                    :sort-by="options.sortBy[0]"
                    :sort-dir="options.sortDesc[0]"

                    @sort="sortItems"
                    @columnMoved="onColumnsMoved"
                    @columnResized="onColumnResized"
                >

                    <template v-slot:id="{ item }">
                        {{ item.id }}
                    </template>


                    <template v-slot:document="{ item }">
                       <v-list>
                            <v-list-item outlined class="file_item">
                                <v-list-item-content class="file_text">
                                    <v-list-item-title class="cursor-pointer" v-text="item.document.name" @click="openFile(item.document)"
                                                       @auxclick.prevent="windowOpenFile(item.document.url);"
                                                       @contextmenu.prevent="downloadFileByURL(item.document)"></v-list-item-title>
                                    <v-list-item-subtitle v-text="(item.document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon class="d-flex align-self-center mt-3 px-0 mx-0">
                                    <v-icon color="primary" small>file-text-download</v-icon>
                                    <v-btn icon :title="$t('download')" @click="downloadFileByURL(item.document)">
                                        <v-icon color="primary" small>icon-file-text-download</v-icon>
                                    </v-btn>
                                    <v-btn icon :title="$t('delete')" @click="deleteBasket(item)">
                                        <v-icon color="primary" small>icon-bin</v-icon>
                                    </v-btn>
                                    <v-btn icon :title="$t('restored_document')" @click="recoverBasket(item)">
                                        <v-icon color="primary" small>icon-file-text-refresh</v-icon>
                                    </v-btn>

                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>

                    </template>

                    <template v-slot:admin_id="{ item }">
                        <div>
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.admin.photo === '/img/avatar.png'}">
                                        <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>

                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>


                </ResizableTable>
            </template>

            <div class="main-pagination d-flex flex-row justify-end">
                <SelectInput
                    class="main-pagination__per-page"
                    id="pagination-variants"
                    :value="options.itemsPerPage"
                    :items="perPageItems"
                    :label="$t('items_per_page')"
                    :disabled="loading"
                    @input="onPerPageChange($event)"
                    hide-label
                ></SelectInput>
                <v-pagination v-model="page" :total-visible="totalVisiblePag" class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>

            <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen"/>
        </template>


    </PageLayout>
</template>

<script>
import { mapGetters } from "vuex"
import FilterView from "@/plugins/mixins/FilterView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Btn from "@/components/Form/Btn.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import ResourceView from "@/plugins/mixins/ResourceView";
import NavMixin from "@/plugins/mixins/Nav";
import ListItem from "@/components/Basket/BasketItem.vue";
import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import OpenFile from '@/components/OpenFile.vue';

export default {
    name: "Baskets",
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    components: {

        ListItem,
        PageLayout,
        SelectInput,
        TabsNav,
        OpenFile
    },
    data() {
        return {
            entityName: 'basket',
            invalid: false,
            options: {
                ...FilterView.data().options,
                sortBy: ['id']
            },
            fab: false,
            baskets: [],
            sortBy: "created_at",
            sortDir: false,
            page: 1,
            pageCount: 0,
            totalVisiblePag: 7,
            loading: false,
            Additional: [
                {
                    'title': this.$t('my_baskets'),
                    'count': '-',
                    'action': 'all',
                    'can': 1,
                    'url': {
                        path: '/document',
                    }
                },
                {
                    'title': this.$t('inbox'),
                    'count': '-',
                    'action': 'inbox',
                    'can': 1,
                    'url': {
                        path: '/document',
                        query: {
                            filter: {
                                inbox: {
                                    custom: '1'
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('outbox'),
                    'count': '-',
                    'action': 'outbox',
                    'can': 1,
                    'url': {
                        path: '/document',
                        query: {
                            filter: {
                                outbox: {
                                    custom: '1'
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('favorite'),
                    'count': '-',
                    'action': 'favorite',
                    'can': 1,
                    'url': {
                        path: '/document',
                        query: {
                            filter: {
                                favorite: {
                                    custom: '1'
                                }
                            }
                        }
                    }
                },
            ],
            group1: true,
            userFiltersGroup: true,
            filter_show: false,
            adminItems: [],
            system_filters: [],
            documentStatuses: [],
            dialogOpenFile: false,
            fileOpen: {},
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'userBlock']),

    },
    mounted() {
        this.init();
    },

    methods: {
        async init() {
            await this.getItems();
            await this.getFilters();
        },
        sortItems(column) {
            if (this.$route.query.sortBy === column) {
                let sortDir = this.$route.query.sortDir === 'true';

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        sortDir: sortDir ? 'false' : 'true'
                    }
                });
            } else {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        sortBy: column,
                        sortDir: false
                    }
                });
            }
            // Здесь вы можете отсортировать элементы items
            // в соответствии с выбранным столбцом и порядком сортировки (asc/desc)
        },
        async deleteBasket(item) {
            if (confirm(this.$t('delete_basket'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/basket/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('basket_has_been_deleted'))
                        this.getBaskets()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('basket_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        getItems() {
            this.getBaskets()
        },
        async getBaskets(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }

            params.sortDir = 'asc';
            if(sortDesc) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }

            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }

            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }


            await this.$http
                .get("admin/basket", {
                    params: params,
                })
                .then(res => {
                    this.filterData = res.body.filter
                    this.baskets = res.body.data
                    this.page = res.body.meta.current_page
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.baskets = []
                    this.$toastr.error(this.$t('failed_to_get_list_baskets'))
                })
                .finally(end => {
                    this.loading = false
                })
        },


        async recoverBasket(item) {
            if (confirm(this.$t('do_you_really_want_to_restore_document'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .put(`admin/basket/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('document_has_been_restored'))
                        this.getBaskets()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('document_has_not_been_restored'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },



    }
}
</script>
