<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveItem">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('warehouse_shelf.editing') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`warehouse_id`" rules="required|min:1" v-slot="{ errors, valid }">

                                        <SelectInput
                                            :id="`warehouse`"
                                            :label="$t('warehouse.self')"
                                            v-model="warehouse_id"
                                            :error="!valid"
                                            :items="warehouses"
                                            item-text="name"
                                            item-value="id"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" v-if="warehouse_id">
                                    <ValidationProvider :ref="`zone_id`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            :id="`zone`"
                                            :label="$t('columns.warehouse_shelf.zone_id')"
                                            v-model="zone_id"
                                            :error="!valid"
                                            :items="warehouseZones"
                                            item-text="name"
                                            item-value="id"
                                        >
                                        </SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`name`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            :id="`name`"
                                            :label="$t('columns.warehouse_shelf.name')"
                                            v-model="name"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`sort`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            :id="`name`"
                                            :label="$t('columns.warehouse_shelf.sort')"
                                            v-model="sort"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="buttons-block">
                            <Btn type="submit" :disabled="invalid || loading" :loading="loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                {{ $t('save') }}
                            </Btn>
                            <Btn
                                :disabled="loading"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </Btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import Btn from "@/components/Form/Btn.vue";
import TextInput from "@/components/Form/TextInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import WithWarehouses from "@/plugins/mixins/WithWarehouses";
import WithWarehouseZones from "@/plugins/mixins/WithWarehouseZones";

export default {
    name: 'WarehouseShelfForm',

    components: {
        Btn,
        ValidationProvider,
        ValidationObserver,
        TextInput,
        SelectInput
    },

    inject: ['forceRerender'],

    props: {
        uuid: {
            type: String,
            required: true
        }
    },

    mixins: [
        WithWarehouses,
        WithWarehouseZones
    ],

    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,

            warehouse_id: null,
            zone_id: null,
            name: null,
            sort: 500
        }
    },

    computed: {
        ...mapGetters(['listLanguages', 'lang']),

        languages() {
            return this.listLanguages
        },
    },

    watch: {
        uuid: {
            handler: function (value) {
                if (value === 'create') {
                    this.heading = this.$t('warehouse_shelf.add')
                } else {
                    this.heading = this.$t('warehouse_shelf.edit')
                    this.fetchItem()
                }
            },

            immediate: true
        },

        warehouse_id: {
            handler: function (value) {
                if(value) {
                    this.fetchWarehouseZones({
                        warehouse_id: value
                    }).then((items) => {
                        this.warehouseZones = items
                    })
                }
            }
        },
    },

    async mounted() {
        this.language = this.languages[this.tab]
        this.initWarehouses();
    },

    methods: {
        async checkCreate() {

        },

        closeDialogAdd(){
            this.$router.push({
                name: 'warehouse_shelf',
            })
        },

        async fetchItem() {
            let _this = this;
            let params = {}

            this.progress = 0
            this.loading = true

            if (this.language) {
                params.language = this.language
            }

            await this.$http
                .get(`admin/warehouse/warehouse_shelf/${this.uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res && res.body && res.body.data) {
                        this.handleResponse(res.body.data)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('warehouse.fetching-error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async saveItem() {
            let _this = this;

            this.progress = 0
            this.loading = true

            let formData = new FormData()
            formData.append(`name`, this.name);
            formData.append(`warehouse_zone_id`, this.zone_id);
            formData.append(`sort`, this.sort);

            if (this.uuid === 'create') {
                await this.$http
                    .post('admin/warehouse/warehouse_shelf', formData, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('warehouse_shelf.added'))
                        this.$router.push({
                            name: 'warehouse_shelf'
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('warehouse.add_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                await this.$http
                    .put(`admin/warehouse/warehouse_shelf/${this.uuid}`, formData, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('warehouse_shelf.update_success'));
                        this.$router.push({
                            name: 'warehouse_shelf'
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('warehouse_shelf.update_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },

        handleResponse(res) {
            this.name = res.name
            this.sort = res.sort + ''
            this.warehouse_id = res.warehouse_zone.warehouse.id
            this.zone_id = res.warehouse_zone.id
        },

        onMapClick(e,a,b,c) {
            console.log(e,a,b,c);
        },

        onMarkerDrugEnd(e) {
            let coords = e.originalEvent.target.geometry.getCoordinates();
            this.data.location = {
                lat: coords[0].toFixed(4) + '',
                lon: coords[1].toFixed(4) + ''
            };
        }
    }
}
</script>
