<template>
    <v-menu
        v-model="isMenuOpen"
        :position-x="x"
        :position-y="y"
        absolute
        :close-on-click="false"
        :close-on-content-click="false"
    >
        <v-list dense>
                <v-list-item
                    v-for="(item, index) in items" :key="index"
                    @click="handleMenuClick(item)"
                >
                    <v-list-item-icon  class="mr-2">
                        <v-icon v-text="item.icon" small color="primary"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>{{ item.title }}</v-list-item-content>
                </v-list-item>

        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "ContextMenuDocument",
    data() {
        return {
            x: 0,
            y: 0,
            isMenuOpen: false,
            file: {},
            no_click: false,
            items: [
                {icon: "icon-file-text",title: this.$t('open'), action: 'open'},
                {icon: "icon-file-text-upload",title: this.$t('download'), action: 'download'},
                {icon: "icon-file-text-share", title: this.$t('sent'), action: 'sent'},
            ],
        };
    },
    methods: {
        openMenu(event, file) {
            event.preventDefault();
            this.file = file;
            this.x = event.clientX;
            this.y = event.clientY;
            this.isMenuOpen = true;
            const clickedElement = event.target;
            this.no_click = false;
            if (clickedElement.classList.contains('open-menu')) {
                this.no_click = true;
            }
        },
        closeMenu() {
            this.file = {};
            this.isMenuOpen = false;
        },
        handleMenuClick(item) {
            this.$emit("menu-item-click", item, this.file);
            this.closeMenu();
        },
        handleOutsideClick(event) {
            if(!this.no_click){
                if(event){
                    if (!event.target.closest(".v-menu__content")) {
                        this.closeMenu();
                    }
                }

            }
            this.no_click = false;
        }
    },
    mounted() {
        document.addEventListener("click", this.handleOutsideClick);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleOutsideClick);
    },


};
</script>
