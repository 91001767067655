import {mapState} from "vuex";

export default {
    computed: {
        ...mapState({
            vats: state => state.vats.vats,
        })
    },

    methods: {},

    mounted() {
        this.$store.dispatch('fetchVats');
    }
}
