var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item',{attrs:{"to":{
            name: 'nomenclature',
            query: {
                filter: {
                    nomenclature_group_id: {
                        eq: _vm.group.id
                    }
                }
            }
        }},on:{"click":function($event){return _vm.onItemClick(_vm.group)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.group.name)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","x-small":"","to":{
                name: 'nomenclature_group.edit',
                params: {
                    uuid: _vm.group.uuid
                }
            }}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","x-small":"","to":{
                name: 'nomenclature',
                query: {
                    filter: {
                        nomenclature_group_id: {
                            eq: _vm.group.id
                        }
                    }
                }
            }}},[_c('v-icon',[_vm._v("mdi-filter-outline")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.onDeleteBtnClick(_vm.group)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }