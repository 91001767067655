export default {
    methods: {
        back(fallback) {
            if(history && history.length > 1) {
                this.$router.go(-1);
            } else {
                this.$router.push({
                    name: fallback
                })
            }
        },
    }
}
