var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-group',{attrs:{"value":false,"sub-group":_vm.subGroup,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_vm._v(" "+_vm._s(_vm.group.name)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","x-small":"","to":{
                name: 'nomenclature_group.edit',
                params: {
                    uuid: _vm.group.uuid
                }
            }}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","x-small":"","to":{
                name: 'nomenclature',
                query: {
                    filter: {
                        nomenclature_group_id: {
                            eq: _vm.group.id
                        }
                    }
                }
            }}},[_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]},proxy:true}])},[_vm._l((_vm.group.children),function(item){return [(!item.children)?_c('NomenclatureGroupListItem',{attrs:{"group":item},on:{"deleteItem":function($event){return _vm.$emit('deleteItem', $event)},"clickItem":function($event){return _vm.$emit('clickItem', $event)}}}):_c('NomenclatureGroupListGroup',{attrs:{"group":item,"sub-group":true},on:{"deleteItem":function($event){return _vm.$emit('deleteItem', $event)},"clickItem":function($event){return _vm.$emit('clickItem', $event)}}})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }