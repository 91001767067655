export default {
    data() {
        return {
            organization_accounts: [],
        }
    },

    methods: {
        fetchOrganizationAccounts(organizationUuid, params = {}) {
            return new Promise((resolve, reject) => {
                this.$http.get(`admin/organization/${organizationUuid}/account`, params)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
}
