<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>{{ localFile.file_name }}</v-toolbar-title>
                <v-btn icon @click="downloadFile(localFile)" :title="$t('download')">
                    <v-icon>mdi-download-outline</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog" :title="$t('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="iframe-container py-0 px-0">
                <div v-if="isLoading" class="loading-overlay">
                    <v-progress-circular indeterminate size="80" width="7" color="primary"></v-progress-circular>
                </div>
                <iframe
                    v-show="!isLoading"
                    :src="iframeSrc"
                    ref="myIframe"
                    @load="setLoading(false)"
                    @error="setLoading(false)"
                    frameborder="0"
                    width="100%"
                    height="100%"
                ></iframe>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "OpenFile",
    props: {
        dialogOpenFile: {
            type: Boolean,
            required: true
        },
        file: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            localFile: {},
            iframeSrc: '',
            isLoading: true,
            officeMimeTypes: [
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
                'application/msword', // .doc
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                'application/vnd.ms-excel', // .xls
                'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
                'application/vnd.ms-powerpoint' // .ppt
            ]
        };
    },
    watch: {
        dialogOpenFile(newVal) {
            this.dialog = newVal;
        },
        dialog(newVal) {
            this.$emit('update:dialogOpenFile', newVal);
            if (newVal) {
                this.loadFile();
            } else {
                this.clearIframe();
            }
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        clearIframe() {
            this.localFile = {};
            this.iframeSrc = '';
            this.isLoading = true;
        },
        setLoading(value) {
            this.isLoading = value;
        },
        loadFile() {
            this.localFile = this.file;
            this.isLoading = true;
            if (this.officeMimeTypes.includes(this.file.type_mime)) {
                this.iframeSrc = `${process.env.VUE_APP_GOOGLE_DOC_VIEWER}${this.localFile.url}`;
            } else {
                this.iframeSrc = this.localFile.url;
            }
        },
        downloadFile(data) {
            fetch(data.url)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    if(data.file_name && data.file_name !== ''){
                        a.download = data.file_name;
                    }
                    else{
                        a.download = data.name;
                    }
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'));
                })
                .catch(() => {
                    this.$toastr.error(this.$t('file_download_error'));
                });
        }
    }
};
</script>

<style scoped>
.iframe-container {
    height: calc(100vh - 64px);
}
.loading-overlay {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
</style>
