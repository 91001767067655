<script>
import debounce from "lodash/debounce";
import TreeMenu from "@/components/Leentech/TreeMenu.vue";

export default {
    name: "EntitySelectionPopup",

    components: {
        TreeMenu
    },

    props: {
        items: {
            required: true,
            type: Array
        },

        filters: {
            required: true,
            type: Array
        },

        searchValue: {
            type: String,
            default: ''
        },

        focusItemIndex: {
            type: Number,
            default: null
        },

        value: {
            required: false,
        },

        multiple: {
            type: Boolean,
            default: false
        },

        loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selected: [],
            innerValue: null,
            innerFilters: [],

            loaded: false,
            lastFilter: null
        }
    },

    watch: {
        searchValue: debounce(function (value) {
            if (value) {
                this.$emit('search', value);
            } else {
                this.$emit('search');
            }
        }, 500),

        value: {
            handler: function (value) {
                this.innerValue = value;
                this.$emit('search');
            },
            deep: true,
            immediate: true
        },

        filters: {
            handler: function (value) {
                this.innerFilters = value;
            },
            deep: true,
            immediate: true
        },

        focusItemIndex: function (value) {
            if (value !== null) {
                this.$nextTick(() => {
                    const item = this.$refs['users-list'].querySelectorAll('.entity-select__item').item(value);

                    if(item) {
                        item.focus()
                    }
                });
            }
        },
    },

    methods: {
        updateValue: function (value) {
            this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        clear() {
            this.updateValue([])
            this.items = [];
        },

        input(value) {
            this.updateValue(value);
        },

        onParentClick(item, path) {
            let tree = this.innerFilters;

            while (path.length > 0) {
                let index = path.shift();

                if(path.length === 0){
                    tree.forEach((itm, i) => {
                        if(i === index) {
                            tree[index].active = 'active' in tree[index] ? !tree[index].active : true;
                        } else {
                            tree[i].active = false;
                        }
                    });

                    if ('click' in item) {
                        item.click(item);
                    }
                }

                tree = tree[index].children;
            }

            this.$set(this, 'innerFilters', [...this.innerFilters]);
        },

        selectItem(item) {
            if (this.multiple) {
                let result = [];

                if (this.innerValue && this.innerValue.length > 0) {
                    result = this.innerValue;
                }

                if (!result.find((itm) => { return item.id === itm.id})) {
                    result.push(item);
                }

                this.updateValue(result);
            } else {
                this.updateValue(item);
            }
        },

        onItemListScroll() {
            if (this.$refs['users-list'].scrollTop + this.$refs['users-list'].clientHeight >= this.$refs['users-list'].scrollHeight) {
                this.$emit('scroll-end');
            }
        }
    },

    mounted() {

    }
}
</script>

<template>
    <div class="entity-select__popup">
        <v-overlay
            :value="loading"
            absolute
        />
        <div class="entity-select__users" ref="users-list" @scroll="onItemListScroll">
            <template v-if="items && items.length > 0" @click="selectItem(item)">
                <div v-for="(item, index) in items" :key="item.id" class="entity-select__item" :class="{'active': focusItemIndex === index}" @click="selectItem(item)">
                    <slot name="item" :item="item"></slot>
                </div>
            </template>
            <template v-else>
                <div class="entity-select__nothing">
                    <img src="@/assets/nothing-found.svg" alt="Nothing found" class="entity-select__nothing-found">
                    <span>{{ $t('nothing_found') }}</span>
                </div>
            </template>
        </div>
        <div class="entity-select__filters">
            <TreeMenu
                :items="innerFilters"
                @item-clicked="onParentClick"
                @item-parent-clicked="onParentClick"
            ></TreeMenu>
        </div>
    </div>
</template>

<style lang="scss">
.entity-select__popup {
    display: flex;
    z-index: 10;
    background-color: #fff;
    height: 300px;
    width: 100%;
    max-width: 460px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    position: relative;
}

.entity-select__users {
    overflow: auto;
    width: 55%;
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer * 1.5;
}

.entity-select__filters {
    overflow: auto;
    background-color: var(--body);
    width: 45%;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
            width: 100%;

            span {
                margin: 0;
                padding: 6px $grid-gutter / 2;
                display: block;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            &.visible {
                //& > span {
                //    background-color: rgba(0, 0, 0, 0.2);
                //}

                & > ul {
                    display: block;
                }
            }
        }

        ul {
            li {
                span {
                    padding: 6px $grid-gutter;
                }
            }

            display: none;


        }
    }
}

.entity-select__nothing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.entity-select__item {
    padding: $spacer * 1.5 $spacer * 3;
    cursor: pointer;

    &:hover, &:focus, &.active {
        background-color: rgba(0,0,0,0.1);
    }
}
</style>
