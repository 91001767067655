<template>
    <div class="notifications-popup" v-if="value">
        <div class="notifications-popup__overlay" @click="$emit('close')"></div>
        <div class="notifications__wrap">
            <v-card class="notifications">
                <v-card-text>
                    <v-list class="notifications-list">
                        <v-list-item
                            v-for="(item, index) in items"
                            class="notification-item pl-0 pr-0"
                            :class="{'notification-item--new': !item.read }"
                            :key="index"
                            @click="notificationClick(item)"
                        >
                            <v-list-item-avatar size="8"></v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="item.title"></v-list-item-title>
                                <v-list-item-subtitle v-html="item.message"></v-list-item-subtitle>
                                <div>
                                    {{ item.date }}
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <Btn
                        @click="$emit('close')"
                        class="w-100"
                        :to="allItemsLink"
                        :loading="loading"
                        :disabled="loading"
                    >
                        {{ allItemsLabel }}
                    </Btn>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
import Btn from "@/components/Form/Btn.vue";

export default {
    name: 'NotificationPopup',
    components: { Btn },

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default() {
                return []
            }
        },
        allItemsLink: {
            type: [Object, String],
            default: ''
        },

        allItemsLabel: {
            type: String,
            default: 'All notifications'
        }
    },

    data() {
        return {
            loading: false,
            nextLink: true
        };
    },

    methods: {
        notificationClick(item) {
            this.$emit('item-click', item);
        },
    }
};
</script>

<style lang="scss">

.notifications-popup {

}

.notifications__wrap {
    padding: $grid-gutter / 2;
    position: absolute;
    right: 0;
    top: var(--header-height-mobile);
    bottom: 0;
    width: 100%;
    max-width: 480px;
    display: flex;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        top: var(--header-height);
    }
}

.notifications-popup__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 999;
}

.notifications {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;

    .v-card__text {
        padding: $grid-gutter $grid-gutter $grid-gutter /2 $grid-gutter !important;
        flex-grow: 1;
        overflow-y: auto;
    }

    .v-card__actions {
        padding: $grid-gutter / 2 $grid-gutter $grid-gutter $grid-gutter;
    }
}



.notifications-list {
    &.v-list {
        padding: 0;
    }
}

.notification-item {
    margin-bottom: $grid-gutter;
    opacity: .6;

    &--new {
        opacity: 1;

        .v-list-item__avatar {
            background-color: var(--primary);
        }

        //.v-list-item__title {
        //    &:before {
        //        display: inline-block;
        //        margin-right: $grid-gutter / 4;
        //        margin-bottom: 2px;
        //        content: "";
        //        width: 6px;
        //        height: 6px;
        //        border-radius: 100%;
        //        background-color: var(--primary);
        //    }
        //}
    }

    &:last-child {
        margin-bottom: 0;
    }

    .v-list-item__avatar {
        margin-top: 0;
        margin-bottom: 0;
        border: 1px solid var(--primary);
    }

    .v-list-item__subtitle {
        color: var(--text-color-light) !important;
    }

    .v-list-item__content {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }

    .v-list-item__subtitle {
        padding-top: $grid-gutter / 4;
        padding-bottom: $grid-gutter / 4;

        overflow: auto;
        text-overflow: unset;
        white-space: normal;
    }
}
</style>
