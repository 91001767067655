<script>
import NavMixin from '@/plugins/mixins/Nav'
import { isSameRouteWithQueryParams } from '@/plugins/functions'

export default {
    name: "Side",

    mixins: [
        NavMixin
    ],

    props: {
        title: {
            type: String,
            default: ''
        }
    },

    methods: {
        isSameRouteWithQueryParams(a, b) {
            return isSameRouteWithQueryParams(a, b)
        }
    }
}
</script>

<template>
    <v-list v-if="$vuetify.breakpoint.mdAndUp" class="filter_left">
        <v-list-group :ripple="false" :value="true" class="px-0">
            <template v-slot:activator class="px-0">
                <v-list-item-title :ripple="false" class="main_filter">{{ title ? title : $t(breadcrumbs()[0].title) }}</v-list-item-title>
            </template>
            <v-list-item :ripple="false" v-for="(item, i) in breadcrumbs()[0].items" :key="i" :to="item.route" :class="{'active-item': isSameRouteWithQueryParams($route, item.route)}">
                <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
            </v-list-item>
        </v-list-group>
    </v-list>
</template>

<style scoped lang="scss">

</style>
