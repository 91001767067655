<script>
import TextInput from "@/components/Form/TextInput";
import NomenclatureSelectionPopup from "@/components/Warehouse/NomenclatureSelectionPopup.vue";

export default {
    name: "NomenclatureSelection",
    components: {
        NomenclatureSelectionPopup,
        TextInput
    },

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        value: {
            required: false,
        },

        multiple: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            searchValue: null,
            innerValue: null,
            isPopupShown: false
        }
    },

    watch: {
        departments: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        roles: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        positions: {
            handler: function (value) {
                this.filters = this.buildFilters();
            },
            deep: true
        },

        value: {
            handler: function (value) {
                this.innerValue = value;
            },
            deep: true
        }
    },

    methods: {
        showPopup: function () {
            this.isPopupShown = true;
        },

        hidePopup: function () {
            this.isPopupShown = false;
        },

        updateValue: function (value) {
            this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        clear() {
            this.updateValue([])
            this.items = [];
        },

        input(value) {
            this.updateValue(value);
        },

        deleteUser(id) {
            if (this.multiple) {
                let result = [];

                if (this.value.length > 0) {
                    result = this.value;
                }

                result = result.filter((item) => {
                    return item.id !== id;
                });

                this.updateValue(result);
            } else {
                this.updateValue(null)
            }
        },

        onUserSelect(value) {
            this.updateValue(value)
            this.searchValue = '';

            if(!this.multiple) {
                this.hidePopup();
            }
        },
    },

    mounted() {
        this.innerValue = this.value;
    }
}
</script>

<template>
    <div class="user-select">
        <VMenu
            offset-y
            offset-overflow
            allow-overflow
            :close-on-content-click="false"
            v-model="isPopupShown"
            @input="showPopup"
            max-width="420"
        >
            <template v-slot:activator="{ on, attrs }">
                <TextInput
                    v-model="searchValue"
                    class="user-select__input"
                    :id="$attrs.id"
                    :label="$attrs.label"
                    :autocomplete="false"
                    v-on="on"
                    v-bind="$attrs"
                >
                    <template v-slot:prepend-inner v-if="innerValue">
                        <div class="user-select__selected" v-if="innerValue">
                            <template v-if="multiple">
                                <div v-for="item in innerValue" :key="item.id" class="user-select__selected-item">
                                    <v-chip
                                        v-if="multiple"
                                        class="input_chip"
                                        :color="'green lighten-5'"
                                        :title="item.name"
                                        close
                                        @click:close="deleteUser(item.id)"
                                    >
                                        <span v-html="item.name"></span>
                                    </v-chip>
                                </div>
                            </template>
                            <template v-else>
                                <div class="user-select__selected-item">
                                    <v-chip
                                        class="input_chip"
                                        :title="innerValue.active ? $t('active') : $t('no_active')"
                                        close
                                        @click:close="deleteUser(innerValue.id)"
                                    >
                                        <span v-html="innerValue.name"></span>
                                    </v-chip>
                                </div>
                            </template>
                        </div>
                    </template>
                </TextInput>
            </template>

            <template>
                <NomenclatureSelectionPopup
                    id="test_user_popup"
                    v-model="innerValue"
                    @input="onUserSelect"
                    :search-value="searchValue"
                    :multiple="multiple"
                />
            </template>
        </VMenu>
    </div>
</template>
