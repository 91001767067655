<script>
import TextInput from '@/components/Form/TextInput.vue';
import Btn from "@/components/Form/Btn.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";

export default {
    name: "DiscussChatEditMessage",

    components: {
        ValidationProvider,
        ValidationObserver,
        Btn,
        TextInput
    },

    model: {
        prop: 'editMessage',
        event: 'input'
    },

    props: {
        entity_type: {
            type: String,
            required: true
        },
        entity_uuid: {
            type: String,
            required: true
        },
        editMessage: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    message: null
                }
            }
        }
    },

    data() {
        return {
            message: null
        }
    },

    watch: {
        editMessage: {
            handler(value) {
                if (value) {
                    this.message = value.comment.value
                }
            },
            immediate: true
        }
    },

    methods: {
        save() {
            this.$http.post('admin/' + this.entity_type + '/' + this.entity_uuid + '/message/' + this.editMessage.uuid, {
                message: this.message
            }).then(response => {
                this.$emit('input', null);
                this.$toastr.success(this.$t('message_saved'));
            }).catch(error => {
                this.$toastr.error(this.$t('message_not_saved'));
            });
        }
    }
}
</script>

<template>
    <v-dialog v-if="editMessage" :value="editMessage">
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <v-card>
                <v-card-title>{{ $t('editing_message') }}</v-card-title>
                <v-card-text>
                    <strong>{{ $t('original_message') }}</strong>
                    <blockquote>{{ editMessage.comment.value }}</blockquote>
                </v-card-text>
                <v-card-text>
                    <ValidationProvider ref="message" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                        <TextInput
                            id="editing-message"
                            hide-label
                            :label="$t('editing_message')"
                            v-model="message"
                        ></TextInput>
                    </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <Btn @click="$emit('input', null)" color="default">{{ $t('cancel') }}</Btn>
                    <Btn @click="save" :disabled="invalid">{{ $t('save') }}</Btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>
