<template>
    <div>
        <v-card>
            <v-card-text class="px-0 mx-0">
                <v-row class="px-0 mx-0">
                    <v-col v-if="showMore" cols="12" class="d-flex justify-center">
                        <Btn plain @click="loadMore">
                            {{ $t('more_messages') }}
                        </Btn>
                    </v-col>

                    <v-col cols="12" class="px-0 mx-0" v-if="emailMessagesDate.length !== 0">
                        <v-card ref="scrollContainer" id="book" flat max-height="800" class="h_scroll px-0 mx-0">
                            <v-card-text class="py-0 my-0 px-0 mx-0">
                                <div v-for="(emailMessages, date) in emailMessagesDate" :key="date">
                                    <div class="date_day_week">{{ getDateDayWeek(date) }}</div>

                                    <v-col v-for="(msg, index) in emailMessages" :key="msg.id" ref="comment"
                                           class="py-0 my-0" :data-message-id="msg.id"
                                           :data-message-date="date"
                                           :class="{'email-message--last': index === messages.length - 1}">
                                        <v-card :id="'div_'+msg.uuid" flat class="py-1 mt-7 px-3 mx-0">
                                            <v-card class="d-flex" flat v-if="msg.admin">
                                                <v-avatar size="36" class="border_blue px-0 mx-0">
                                                    <img
                                                        v-if="msg.admin.photo !== '/img/avatar.png' && msg.admin.photo !== '' && msg.admin.photo !== null"
                                                        :src="msg.admin.photo" :alt="msg.admin.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-avatar>

                                                <div class="title_subtitle mr-auto px-2">
                                                    <div>
                                                        <span class="v-list-item__title">{{ msg.admin.name }}</span>
                                                        <v-menu offset-y>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon
                                                                       v-bind="attrs"
                                                                       v-on="on"
                                                                       x-small
                                                                >
                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <v-list-item @click="replyOpen(msg)">
                                                                    <v-list-item-title>{{
                                                                            $t('reply')
                                                                        }}
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </div>
                                                    <div class="v-list-item__subtitle">{{
                                                            formatPhoneNumber(msg.admin.phone)
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="chat_time chat-message__info">
                                                    <div>
                                                        {{ $moment(msg.created_at).format('HH:mm') }}
                                                    </div>
                                                </div>
                                            </v-card>


                                            <v-row class="chat_text_row">
                                                <v-col cols="12" class="px-2 pb-1 ">
                                                    <div class="chat_text">
                                                        <span @click="openEmail(msg)" class="cursor-pointer">{{ msg.subject }}</span>
                                                        <div v-if="msg.inbox">
                                                            {{ msg.from }}
                                                            <v-icon small color="primary"

                                                                    class="mx-0 px-0 pr-1 my-0 py-0"
                                                            >
                                                                mdi-arrow-right-thin
                                                            </v-icon>
                                                            {{ msg.to }}
                                                        </div>
                                                        <div v-if="msg.outbox">
                                                            {{ msg.to }}
                                                            <v-icon small color="red"

                                                                    class="mx-0 px-0 pr-1 my-0 py-0"
                                                            >
                                                                mdi-arrow-left-thin
                                                            </v-icon>
                                                            {{ msg.from }}
                                                        </div>
                                                    </div>

                                                </v-col>
                                            </v-row>

                                        </v-card>
                                    </v-col>

                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialogOpenEmail"
            scrollable
            persistent
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" extended extension-height="10" elevation="0">
                    <div class="ma-auto ml-0 text-container">
                        <div>{{ mail_read.subject }}</div>
                    </div>
                    <div>
                        <v-icon @click="dialogOpenEmailClose()" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-title>
                    {{$t('whom')}} {{ mail_read.to }} <br/>
                    {{$t('from_whom')}} {{ mail_read.from }}
                </v-card-title>
                <v-card-text>
                    <div v-if="mail_read.html_body != ''" v-html="mail_read.html_body"></div>
                    <div v-else-if="mail_read.text_body != ''" v-text="mail_read.text_body"></div>
                    <v-list subheader v-if="mail_read && mail_read.attachments && mail_read.attachments.length > 0">
                        <v-list-item
                            v-for="file in mail_read.attachments"
                            :key="file.id + '_' + Date.now()"
                            outlined
                        >
                            <v-list-item-content class="file_text" @click="downloadFile(file)">
                                <v-list-item-title class="cursor-pointer" v-text="file.name"></v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ (file.size / 1000).toFixed(1) + ' KB' }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="primary lighten-1" @click="downloadFile(file)">mdi-cloud-upload
                                    </v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <btn
                        text
                        color="primary"
                        @click="dialogOpenEmailClose"
                    >
                        {{ $t('close') }}
                    </btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Btn from "@/components/Form/Btn.vue";
import moment from "moment";

export default {
    name: "EmailMessages",
    components: {
        Btn,
    },
    props: {
        entity: {
            type: String,
            required: true
        },
        entity_uuid: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            messages: [],
            loading: false,
            dialog_file: false,
            page: 1,
            lastPage: null,
            total: 0,
            toolbar_reply: false,
            text: '',
            readerTimer: null,
            mail_read: {},
            dialogOpenEmail: false,
        }
    },

    computed: {
        emailMessagesDate() {
            return this.messages.reduce((acc, message) => {
                const date = moment(message.created_at).format("YYYY-MM-DD");
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(message);
                return acc;
            }, {});
        },
        showMore: {
            get() {
                return this.page < this.lastPage;
            }
        },
    },

    async mounted() {
        await this.fetchEmailMessages()
        this.scrollCard();
    },
    methods: {
        loadMore() {
            this.fetchEmailMessages(this.page + 1)
        },
        async fetchEmailMessages(page = 1) {
            this.loading = true;

            let params = {};
            if (page) {
                params.page = page;
            }
            params.entity = this.entity;
            params.entity_uuid = this.entity_uuid;

            await this.$http
                .get(`admin/email_reader/mail`, {
                    params
                })
                .then(res => {
                    let messages = res.body.data.reverse();

                    this.messages = [...messages, ...this.messages];
                    this.page = res.body.meta.current_page;
                    this.lastPage = res.body.meta.last_page;
                    this.total = res.body.meta.total;
                })
                .catch(err => {
                    console.log(err);
                    this.$toastr.error(this.$t('failed_to_get_list_messages'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        scrollCard() {
            this.$nextTick(() => {
                if (this.$refs.scrollContainer !== undefined) {
                    const container = this.$refs.scrollContainer.$el;
                    const firstUnread = this.$refs.scrollContainer.$el.querySelector(".email-message--last");

                    if (firstUnread) {
                        const containerRect = container.getBoundingClientRect();
                        const elementRect = firstUnread.getBoundingClientRect();
                        const offset = elementRect.top - containerRect.top + container.scrollTop;

                        container.scrollTo({
                            top: offset,
                            behavior: "smooth",
                        });
                    } else {
                        container.scrollTop = container.scrollHeight;
                    }
                }
            });
        },
        replyOpen(item) {
            console.log(item);
        },
        async openEmail(item) {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/email_reader/mail/${item.uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.mail_read = res.body.data
                    this.dialogOpenEmail = true
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_base'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        dialogOpenEmailClose() {
            this.dialogOpenEmail = false;
            setTimeout(() => {
                this.mail_read = {};
            }, 300);
        },
        downloadFile(data) {
            fetch(data.url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = data.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },
    },
}
</script>



<style scoped lang="scss">
.text-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
