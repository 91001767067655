<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('chart_of_account.title') }}</h1>
                </div>
            </div>
            <v-row>
                <v-col>
                    <ResizableTable
                        class="main-data-table elevation-0 uveds-table"
                        :rows="items"
                        :columns="tableColumns"
                        :sort-by="sortBy"
                        :sort-dir="sortDir"
                    >
                        <template v-slot:type="{ item }">
                            {{ item.type.name }}
                        </template>
                    </ResizableTable>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side/>
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import NavMixin from "@/plugins/mixins/Nav";
import FilterView from "@/plugins/mixins/FilterView";
import Side from "@/components/Warehouse/Side.vue";
import WithChartOfAccount from "@/plugins/mixins/WithChartOfAccount";

export default {
    name: "ChartOfAccount",

    components: {
        Side,
        PageLayout
    },

    mixins: [
        NavMixin,
        FilterView,
        WithChartOfAccount
    ],

    data() {
        return {
            invalid: false,
            items: [],
            sortBy: "id",
            sortDir: false,
            page: 1,
            pageCount: 0,
            options: {
                ...FilterView.data().options,
                itemsPerPage: 10,
                sortBy: [
                    "id"
                ],
            },
            loading: false,
            tableColumns: [
                {
                    active: true,
                    label: this.$t("id"),
                    prop: 'id',
                    type: 'integer'
                },
                {
                    prop: 'name',
                    type: 'string',
                    active: true,
                    label: this.$t("columns.chart_of_account.name"),
                },
                {
                    prop: 'type',
                    type: 'string',
                    active: true,
                    label: this.$t("columns.chart_of_account.type"),
                },
                {
                    prop: 'account_code',
                    type: 'string',
                    active: true,
                    label: this.$t("columns.chart_of_account.account_code"),
                },
            ]
        }
    },

    computed: {},

    mounted() {},

    methods: {
        init() {
            this.getItems();
        },

        getItems() {
            this.fetchChatOfAccounts().then((body) => {
                this.items = body.data;
            });
        },
    }
}
</script>
