<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >

            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ heading }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary">
                                            mdi-close
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>

                        <v-row class="mt-5 mb-2">
                            <v-col class="py-0" cols="12">


                                <ValidationProvider ref="phone" rules="required|phone" v-slot="{ errors, valid }">
                                    <TextInput
                                        id="phone"
                                        :label="$t('phone')"
                                        v-model="phoneRaw"
                                        v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                        :disabled="loading"
                                        :error-messages="errors"
                                    ></TextInput>
                                </ValidationProvider>

                            </v-col>


                            <v-col class="py-0" cols="12" v-if="request_code">
                                <ValidationProvider ref="password" rules="required"
                                                    v-slot="{ errors, valid }">

                                    <TextInput
                                        id="password"
                                        :label="$t('code')"
                                        v-model="code"
                                        :disabled="loading"
                                        :error-messages="errors"
                                        :error="!valid"
                                        rows="3"
                                        :type="passwordHidden ? 'password' : 'text'"
                                    >
                                        <template v-slot:append>
                                            <v-icon size="22" color="primary" class="input_icon"
                                                    @click="passwordHidden = !passwordHidden">
                                                {{ passwordHidden ? 'mdi-eye-off' : 'mdi-eye' }}
                                            </v-icon>
                                        </template>
                                    </TextInput>
                                </ValidationProvider>
                            </v-col>
                        </v-row>


                    </v-card-text>
                    <v-card-actions class="px-4 py-7">

                        <v-btn v-if="!request_code" @click="login()" :disabled="invalid || loading" :loading="loading"
                               color="primary" class="infinity_button">
                            {{ $t('get_code') }}
                        </v-btn>

                        <v-btn v-if="request_code" @click="complete_login" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                            {{ $t('connect') }}
                        </v-btn>
                        <v-btn
                            :disabled="loading"
                            class="ma-1 button_cancel"
                            plain
                            :to="{name: 'telegram_connect'}"
                        >
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>

                    </v-card-actions>

                </v-card>
            </ValidationObserver>

        </v-dialog>
    </v-container>
</template>
<script>

import {mask} from 'vue-the-mask'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import TextInput from "@/components/Form/TextInput.vue";

export default {
    name: 'TelegramConnectForm',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        TextInput,
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            sortBy: "sort",
            sortDir: false,

            phoneRaw: null,
            code: null,
            passwordHidden: true,
            request_code: false,


        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang', 'phoneMask']),
        languages() {
            return this.listLanguages
        },
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()

    },
    methods: {
        checkCreate() {
            if (this.$route.name === "telegram_connect.create") {
                this.heading = this.$t('telegram_connect_creation')
            } else {
                this.heading = this.$t('telegram_connect_editing')
                if (this.$route.params.id) {
                    this.getTelegramConnect()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        closeDialogAdd() {
            if (window.history.length > 2) {
                this.$router.back()
            } else {
                this.$router.push({
                    name: 'telegram_connect',
                })
            }
        },
        async getTelegramConnect() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/telegram_app/connector/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {

                    this.phone = res.body.data.phone;


                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_telegram_connect'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveTelegramConnect() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if (this.phone) {
                formData.append('phone', this.phone)
            }

            await this.$http
                .post('admin/telegram_app/connector', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('telegram_connect_has_been_added'))
                    this.closeDialogAdd()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('telegram_connect_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async login() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if (this.phone) {
                formData.append('phone', this.phone)
            }

            await this.$http
                .post('admin/telegram_app/login', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('confirmation_code_has_been_sent_to_telegram'))
                    this.request_code = true
                })
                .catch(err => {
                    this.$toastr.error(this.$t('connection_error_with_telegram'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async complete_login() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if (this.code) {
                formData.append('code', this.code)
            }

            await this.$http
                .post('admin/telegram_app/complete_login', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('telegram_connect_has_been_added'))
                    this.saveTelegramConnect();
                })
                .catch(err => {
                    this.request_code = false;
                    this.code = null;
                    this.$toastr.error(this.$t('telegram_connect_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },


    }
}
</script>
