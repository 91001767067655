<template>
    <v-container fluid>
        <DocumentFormDialog
            :dialogForm.sync="dialogForm"
        ></DocumentFormDialog>
    </v-container>
</template>
<script>


import DocumentFormDialog from '@/components/DocumentFormDialog.vue';

export default {
    name: 'DocumentForm',
    components: {
        DocumentFormDialog
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: true,
        }
    },

}
</script>

<style scoped>


</style>
