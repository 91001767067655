<script>
import Btn from "@/components/Form/Btn.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import TextInput from "@/components/Form/TextInput.vue";

export default {
    name: "NomenclatureBarcode",
    components: {SelectInput, Btn, TextInput},

    props: {
        nomenclatureUuid: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            barcodes: [],
            barcodeTypes: [],

            barcodeType: null,
            barcode: null,
        }
    },

    mounted() {
        this.loading = true;

        this.init().then(() => {
            this.loading = false;
        });
    },

    methods: {
        init() {
            this.loading = true;

            let barcodesPromise = this.fetchBarcodes()
                .then((data) => {
                    this.barcodes = data;
                });

            let barcodeTypesPromise = this.fetchBarcodeTypes().then((data) => {
                this.barcodeTypes = data.map(item => {
                    return {
                        value: item.id,
                        text: item.name
                    }
                });
            });

            return new Promise((resolve, reject) => {
                Promise.all([barcodesPromise, barcodeTypesPromise])
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });

        },

        fetchBarcodes() {
            return new Promise((resolve, reject) => {
                this.$http.get(`admin/nomenclature/${this.nomenclatureUuid}/barcode`)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch(e => {
                        reject(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            })
        },

        fetchBarcodeTypes() {
            return new Promise((resolve, reject) => {
                this.$http.get(`admin/barcode_type`)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch(e => {
                        reject(e);
                    })
                    .finally(() => {
                });
            })
        },

        addBarcode() {
            return new Promise((resolve, reject) => {
                this.$http.post(`admin/nomenclature/${this.nomenclatureUuid}/barcode`, {
                    value: this.barcode,
                    barcode_type_id: this.barcodeType
                })
                    .then(response => {
                        this.$toastr.success(this.$t('columns.nomenclature_barcode.barcode_added'));
                        resolve(response.body.data);
                    })
                    .catch(e => {
                        this.$toastr.error(this.$t('columns.nomenclature_barcode.barcode_not_added'));
                        reject(e);
                    });
            })
        },

        deleteBarcode(bc) {
            return new Promise((resolve, reject) => {
                this.$http.delete(`admin/nomenclature/${this.nomenclatureUuid}/barcode/${bc.uuid}`)
                    .then(response => {
                        this.$toastr.success(this.$t('columns.nomenclature_barcode.barcode_deleted'));
                        resolve(response.body.data);
                    })
                    .catch(e => {
                        this.$toastr.error(this.$t('columns.nomenclature_barcode.barcode_not_deleted'));
                        reject(e);
                    });
            })
        },

        onAddBarcodeBtnClick() {
            this.addBarcode()
                .then((data) => {
                    this.$toastr.success(this.$t('columns.nomenclature_barcode.barcode_added'));
                    this.fetchBarcodes()
                        .then((data) => {
                            this.barcodes = data;
                        })
                        .catch(e => {
                            this.$toastr.error(this.$t('columns.nomenclature_barcode.barcode_not_added'));
                        });
                });
        },

        onDeleteBarcodeBtnClick(bc) {
            this.deleteBarcode(bc)
                .then((data) => {
                    this.$toastr.success(this.$t('columns.nomenclature_barcode.barcode_deleted'));
                    this.fetchBarcodes()
                        .then((data) => {
                            this.barcodes = data;
                        })
                        .catch(e => {
                            this.$toastr.error(this.$t('columns.nomenclature_barcode.barcode_not_deleted'));
                        });
                });
        }
    }
}
</script>

<template>
    <div>
        <v-row v-if="loading">
            <v-col>
                <v-progress-linear
                    color="secondary"
                    indeterminate
                ></v-progress-linear>
            </v-col>
        </v-row>
        <template v-else-if="barcodes.length">
            <v-row>
                <v-col>
                    <table width="100%">
                        <tr class="barcode-table__header">
                            <td>
                                {{$t('columns.nomenclature_barcode.barcode_type')}}
                            </td>
                            <td>
                                {{$t('columns.nomenclature_barcode.barcode')}}
                            </td>
                            <td class="barcode-table__delete-wrap">
                                {{$t('columns.nomenclature_barcode.delete')}}
                            </td>
                        </tr>
                        <tr v-for="(bc, i) in barcodes" :key="bc.id" class="barcode">
                            <td>
                                {{bc.barcode_type.name}}
                            </td>
                            <td>
                                {{bc.value}}
                            </td>
                            <td class="barcode__delete-wrap">
                                <Btn @click="onDeleteBarcodeBtnClick(bc)" color="secondary" icon small>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </Btn>
                            </td>
                        </tr>
                    </table>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-divider />
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-row >
                <v-col>
                    <v-alert
                        dense
                        text
                        type="success"
                    >
                        {{$t('columns.nomenclature_barcode.no_barcodes')}}
                    </v-alert>
                </v-col>
            </v-row>
        </template>

        <v-row class="barcode-add-form">
            <v-col class="barcode-add-form__col">
                <SelectInput v-model="barcodeType" id="barcode-type" :label="$t('columns.nomenclature_barcode.barcode_type')" :items="barcodeTypes" hide-label></SelectInput>
            </v-col>
            <v-col class="barcode-add-form__col">
                <TextInput v-model="barcode" id="barcode" :label="$t('columns.nomenclature_barcode.barcode')" hide-label></TextInput>
            </v-col>
            <v-col class="barcode-add-form__submit-wrap">
                <Btn @click="onAddBarcodeBtnClick" color="secondary">{{ $t('columns.nomenclature_barcode.add') }}</Btn>
            </v-col>
        </v-row>

    </div>
</template>

<style lang="scss">
.barcode-table__header {
    font-weight: bold;
}

.barcode-table__delete-wrap {
    text-align: right;
}

.barcode__delete-wrap {
    display: flex;
    justify-content: flex-end;

    text-align: right;
    flex-grow: 0;
}

.barcode-add-form__col {
    min-width: 100%;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .barcode-add-form__col {
        flex-grow: 1;
        min-width: auto;
    }

    .barcode-add-form__submit-wrap {
        flex-grow: 0;
    }
}

.barcode {
    .col {
        display: flex;
        flex-grow: 1;
        align-items: center;

        &.barcode__delete-wrap {
            flex-grow: 0;
        }
    }
}
</style>
