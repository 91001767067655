<template>
    <div>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="internalDialogFolder">


                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('my_folders') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="closeDialogFolder()" :title="$t('close')" color="primary">mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-5">
                            <v-col class="py-0" cols="12" sm="12">

                                <el-tree :ref="treeRef"
                                         :default-expand-all="defaultExpandAll"
                                         node-key="id"
                                         :data="folderItems"
                                         :props="defaultProps"
                                         :filter-node-method="filterNode"
                                         class="folder-tree elevation-0 p-0 m-0"
                                         :empty-text="$t('loading_please_wait')"
                                         :expand-on-click-node="false"
                                         icon-class="mdi mdi-chevron-right"
                                         :show-checkbox="showCheckbox"
                                         :check-strictly="true"
                                         @check-change="handleCheckChange"
                                >
                                <span slot-scope="{ node, data }" class="custom-tree-node container_tree align-center">
                                        <div class="d-flex text-info">
                                            <div @click="checkChange(data)" class="pa-2 mr-auto text-info__value">
                                                {{ data.name }}
                                            </div>
                                        </div>
                                    </span>
                                </el-tree>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 pt-10 pb-7">
                        <v-btn :disabled="loading"
                               :loading="loading"
                               @click="saveSharedFolder()"
                               :block="$vuetify.breakpoint.xsOnly"
                               color="primary"
                               class="infinity_button">
                            {{ $t('OK') }}
                        </v-btn>
                        <v-btn :disabled="loading"
                               class="ma-1 button_cancel"
                               plain
                               @click="closeDialogFolder()">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly"
                                           v-model="progress"
                                           :active="loading"
                                           class="mx-2">
                        </v-progress-linear>
                    </v-card-actions>
                </v-card>

        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "TreeFolder",
    props: {
        dialogFolder: {
            type: Boolean,
            default: false
        },
        showCheckbox: {
            type: Boolean,
            default: true
        },
        defaultExpandAll: {
            type: Boolean,
            default: true
        },
        treeRef: {
            type: String,
            default: "tree"
        }
    },
    data() {
        return {
            internalDialogFolder: this.dialogFolder,
            loading: false,
            progress: 0,
            folderItems: [],
            defaultProps: {
                children: 'children',
                label: 'name',
                isLeaf: 'leaf'
            },
            folder_id: null,



        };
    },
    watch: {
        dialogFolder(val) {
            this.internalDialogFolder = val;
        },
    },
    mounted() {
        this.getFolders(false)

    },
    methods: {
        async getFolders(shared) {
            this.loading = true;
            let params = {};
            params.filter = 'all';

            if (this.folder) {
                params.folder = this.folder;
            }
            if (shared) {
                params.shared = 1;
            }
            await this.$http
                .get("admin/folder", {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.length > 0) {
                        if (shared) {
                            this.folderSharedItems = res.body.data;
                            this.showSharedTree = this.folderSharedItems.length > 0;
                        } else {
                            this.folderItems = res.body.data;
                            this.showTree = true;
                        }
                    } else {
                        this.folderItems = [];
                        this.folderSharedItems = [];
                    }
                })
                .catch(err => {
                    this.folderItems = [];
                    this.folderSharedItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_folders'));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        handleCheckChange(data, checked, indeterminate) {
            if (checked) {
                this.folder_id = data.id;
                this.$refs[this.treeRef].setCheckedKeys([data.id]);
            }
        },
        checkChange(data) {
            this.$refs[this.treeRef].setCheckedKeys([data.id]);
        },
        closeDialogFolder() {
            this.$refs[this.treeRef].setCheckedKeys([]);
            this.internalDialogFolder = false;
            this.folder_id = null
            this.$emit('update:dialogFolder', false);
        },
        async saveSharedFolder() {
            this.$emit('getFolder', this.folder_id);
            this.closeDialogFolder();
        },
    }
};
</script>

<style>
.custom-tree-node {
    width: 100%;
    min-height: 25px;
}

.folder-tree .el-tree-node__content{
    min-height: 50px;
    margin-top: 5px;
}
</style>

