export default {
    state: {
        vats: null,
        isFetching: false
    },
    mutations: {
        setVats(state, vats) {
            state.vats = vats;
        },
        setFetching(state, isFetching) {
            state.isFetching = isFetching;
        }
    },
    actions: {
        fetchVats({ state, commit }, params = {}) {
            return new Promise((resolve, reject) => {
                if (state.vats !== null) {
                    return resolve(state.vats);
                }

                if (state.isFetching) {
                    const interval = setInterval(() => {
                        if (state.vats !== null) {
                            clearInterval(interval);
                            resolve(state.vats);
                        }
                    }, 100);
                    return;
                }

                commit('setFetching', true);

                this._vm.$http.get('admin/vat', params)
                    .then(({ body }) => {
                        commit('setVats', body.data);
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setFetching', false);
                    });
            });
        }
    }
};
