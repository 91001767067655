
<template>
    <div class="item" :class="item.step_stop === false ? 'no_color' : 'border_card'" >

        <div class="item__header">
            <div class="item__status small-text no_color_white"
                 :style="{ backgroundColor: item.step_stop !== false ? item.deal_status.color : '#b3b3b3' }"
               >
                {{ item.deal_status.name }}
            </div>

            <div class="item__created_at text-color-light date_info_top">
                <span v-if="item.documents.length > 0">
                    {{item.documents.length}}
                    <v-icon small color="primary" class="pl-1 pb-1">icon-file-text</v-icon>
                </span>
                <span v-if="item.deal_documents.length > 0">
                    {{item.deal_documents.length }}
                    <v-icon small color="primary" class="pl-1 pb-1">icon-file-text-check</v-icon>
                </span>
                <span class="cursor_pointer" @click="showDetailsApplication(item)">
                    <v-icon color="primary" class="pl-1 pb-1">icon-receipt-slip-1</v-icon>
                </span>
            </div>
        </div>

        <div class="item__description">
            <div class="item__id text-color-light small-text">
                <v-btn text plain :to="'/deal/' + item.deal_uuid + '/show'" class="cursor-pointer button_link">
                    {{ item.number_deal }}
                </v-btn>
            </div>
            <div v-if="item.type_transport" class="item__note text-color-light"> {{ item.type_transport }}</div>
            <div v-if="item.loading_type && item.loading_type.name" class="item__note text-color-light">
                {{ item.loading_type.name }}
            </div>
        </div>
        <div class="item__data">
            <div class="d-flex" style="width: 100%">
                <div class="text-info mr-auto" v-if="item.uved_name">
                    <div class="text-info__label">
                        <div>{{ $t('uved.self') }}</div>
                    </div>
                    <div class="text-info__value">
                        <v-btn text plain :to="'/uved/' + item.uved.uuid + '/show'"
                               class="cursor-pointer tracking_link button_link">
                            {{ item.uved_name }}
                        </v-btn>
                    </div>
                </div>
                <div class="text-info">
                    <div class="text-info__label">
                        <div>{{ $t('container_number') }}</div>
                    </div>
                    <div class="text-info__value">
                        <div>{{ item.container_number ? item.container_number : $t('no_data') }}</div>
                    </div>
                </div>

            </div>
            <div class="d-flex" style="width: 100%">
                <div class="text-info mr-auto">
                    <div class="text-info__label">
                        <div>{{ $t('date_operation') }}</div>
                    </div>
                    <div class="text-info__value">
                        <div>{{ item.date_operation ? $moment(item.date_operation).format('DD.MM.YYYY') : '' }}</div>
                    </div>
                </div>
                <div class="text-info" v-if="!item.arrival_date_at_destination">
                    <div class="text-info__label">
                        <div>{{ $t('in_operation_for') }}</div>
                    </div>
                    <div class="text-info__value">
                        <div>{{ $t('for_day', {"day": item.day}) }}</div>
                    </div>
                </div>
                <div class="text-info" v-else>
                    <div class="text-info__label">
                        <div>{{ $t('arrived') }}</div>
                    </div>
                    <div class="text-info__value">
                        {{ $moment(item.arrival_date_at_destination).format('DD.MM.YYYY') }}
                    </div>
                </div>
            </div>
            <div class="d-flex" style="width: 100%">
                <div class="text-info mr-auto">
                    <div class="text-info__label">
                        <div>{{ $t('current_station') }}</div>
                    </div>

                    <div class="text-info__value" v-if="item.finish === true || item.step_stop === false">
                        {{ item.station ? item.station : item.departure }}
                    </div>
                    <div  v-else class="text-info__value">
                        <div class="cursor-pointer" @click="showDialogTracking(item)">
                            {{ item.station ? item.station : item.departure }}
                            <v-icon small color="primary" class="ml-1">icon-shipment-add</v-icon>
                        </div>
                    </div>
                </div>
                <div class="text-info " v-if="item.sub_status_name ">
                    <div class="text-info__label">
                        <div>{{ $t('delivery_status') }}</div>
                    </div>
                    <div class="text-info__value">
                        {{ item.sub_status_name ? item.sub_status_name : '' }}
                    </div>
                </div>
            </div>
        </div>


        <div class="item__footer cursor_pointer" @click="showDetailsApplication(item)">
            <v-timeline class="px-0 mx-0" align-top
                        dense>
                    <v-timeline-item v-for="(status_item, i) in filteredStatuses" :key="'timeline_'+i"
                         :fill-dot="parseInt(item.deal_status_sort) === parseInt(status_item.sort)"
                         small
                         :color=" item.step_stop !== false ? status_item.color : '#b3b3b3'"
                         class="timeline_status"
                    >
                        {{status_item.name}}
                    </v-timeline-item>
            </v-timeline>

        </div>
        <div class="item__footer">
            <div v-if="item.responsible && item.responsible.length > 0">
                <div v-for="(responsible, i) in item.responsible.slice(0, 3)" :key="'responsible_'+i" class="my-5">
                    <User :item="responsible"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import User from "@/components/User.vue";

export default {
    name: "TrackingListItem",
    components: {User},

    props: {
        item: {
            type: Object,
            required: true
        },

        columns: {
            type: Array,
            required: false
        },

        userBlock: {
            type: Boolean,
            required: false
        }
    },

    computed: {
        filteredStatuses() {
            const dealStatuses = this.item.deal_statuses || [];
            const currentSort = parseInt(this.item.deal_status_sort);

            // Найти индекс текущего элемента
            const currentIndex = dealStatuses.findIndex(
                (status) => parseInt(status.sort) === currentSort
            );

            let result = [];

            // Добавляем предыдущую запись, если она есть
            if (currentIndex > 0) {
                result.push(dealStatuses[currentIndex - 1]);
            }

            // Добавляем текущую запись
            if (currentIndex !== -1) {
                result.push(dealStatuses[currentIndex]);
            }

            // Добавляем следующую запись, если она есть
            if (currentIndex < dealStatuses.length - 1) {
                result.push(dealStatuses[currentIndex + 1]);
            }

            // Если сверху нет записи, добавляем одну с конца массива
            if (currentIndex === 0 && dealStatuses.length > 1) {
                result.push(dealStatuses[currentIndex + 2]);
            }

            // Если снизу нет записи, добавляем одну с начала массива
            if (currentIndex === dealStatuses.length - 1 && dealStatuses.length > 1) {
                result.unshift(dealStatuses[currentIndex - 2]);
            }

            return result.filter(Boolean); // Убираем null, если они есть
        },
    },


    methods: {
        showDialogTracking(item) {
            this.$emit('showDialogTracking', item);
        },
        showDetailsApplication(item) {
            this.$emit('showDetailsApplication', item);
        }
    }
}
</script>

<style scoped>
.date_info_top {
    position: absolute;
    right: 24px;
    top: 10px;
    text-align: right;
}
.no_color .deal_bold, .no_color i, .no_color span, .no_color a, .no_color div{
    color: #b3b3b3 ;
}
.no_color span i{
    color: #b3b3b3  !important;
}
.no_color_white{
    color: #FFFFFF !important;
}


</style>

