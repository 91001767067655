<template>
    <v-container fluid>
        <PageLayout>

            <template v-slot:default>
                <TabsNav />
                <div class="d-flex justify-space-between mb-10">
                    <div class="d-flex align-center flex-grow-1">
                        <h1>
                            <template>
                                {{ filter_show ? $t('search_station') : $t('stations') }}
                            </template>
                        </h1>
                        <TableSettings
                            :fields="tableColumns"
                            :entityName="entityName"
                            className="ml-2"
                            @column-select="onColumnSelect"
                        />
                    </div>
                    <div class="text-right">
                        <Btn large  :to="{name: 'station.create'}" color="secondary">
                            {{ $t('create') }}
                        </Btn>
                    </div>
                </div>

                <div class="mobile-filter mb-5" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon
                        class="mobile-filter__btn mr-2"
                        color="secondary"
                        @click="filter_show === true ? filter_show = false : filter_show = true"
                        :title="$t['filter_add']"
                    >mdi-filter-plus</v-icon>

                    <FilterMobileLinks
                        :links="[
                        ...Additional.map((item) => {
                            return {
                                ...item
                            }
                        }),

                        ...adminItems.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...system_filters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...userFilters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                    />
                </div>

                <FilterComponent
                    class="mb-10"
                    v-if="filter_show"
                    :filterData="filterData"
                    :currentFilter="currentFilter"
                    :value="filterValues"
                    :loading="loading"
                    :invalid="invalid"
                    entity="document"
                    @input="filterValues = $event"
                    @submit="submitForm"
                    @filterSaved="filterSaved"
                    @filterDeleted="filterDeleted"
                />


                    <ResizableTable
                        class="main-data-table elevation-0 uveds-table"
                        :rows="stationItems"
                        :columns="tableColumns"
                        :sort-by="sortBy"
                        :sort-dir="sortDir"

                        @sort="sortItems"
                        @columnMoved="onColumnsMoved"
                        @columnResized="onColumnResized"
                    >

                        <template v-slot:geocoder_name="{ item, index }">
                            <span class="cursor-pointer" @click="editStation(item)">
                                    {{ item.geocoder_name }}
                                </span>
                            <v-icon @click="deleteStation(item)" small>mdi-delete-outline</v-icon>
                        </template>

                </ResizableTable>

                <div class="main-pagination d-flex flex-row justify-end">
                    <SelectInput
                        class="main-pagination__per-page"
                        id="pagination-variants"
                        :value="options.itemsPerPage"
                        :items="perPageItems"
                        :label="$t('items_per_page')"
                        :disabled="loading"
                        @input="onPerPageChange($event)"
                        hide-label
                    ></SelectInput>
                    <v-pagination v-model="page" :total-visible="totalVisiblePag" class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
                </div>
            </template>
            <template v-slot:side>
                <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5"  color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>

                <v-list  class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                    <v-list-group :ripple="false" v-model="group1" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                        </template>
                        <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" :to="item.url" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}">
                            <v-list-item-title v-if="item.can" v-text="item.title" class="cursor-pointer"></v-list-item-title>
                            <v-list-item-icon v-if="item.can" v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>

            </template>
        </PageLayout>
    </v-container>
</template>
<script>


import {mapGetters} from "vuex"
import PageLayout from "@/components/Leentech/PageLayout.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";


import FilterView from "@/plugins/mixins/FilterView";
import ResourceView from "@/plugins/mixins/ResourceView";
import NavMixin from "@/plugins/mixins/Nav";

import Btn from "@/components/Form/Btn.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import FilterMobileLinks from "@/components/FilterMobileLinks.vue";

export default {
    name: "Stations",
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    components: {
        TabsNav,
        PageLayout,
        Btn,
        SelectInput,
        FilterMobileLinks
    },
    data() {
        return {
            entityName: 'station',
            invalid: false,
            adminItems:[],
            system_filters:[],
            page: 1,
            pageCount: 0,
            totalVisiblePag: 7,
            options: {
                ...FilterView.data().options,
                sortBy: ['id']
            },
            Additional: [
                {
                    'title': this.$t('all_tracking'),
                    'count': '-',
                    'action': 'all',
                    'can': 1,
                    'url': {
                        path: '/tracking',
                    }
                },
                {
                    'title': this.$t('my_tracking'),
                    'count': '-',
                    'action': 'my_tracking',
                    'can': 1,
                    'url': {
                        path: '/tracking',
                        query: {
                            filter: {
                                my_tracking: {
                                    custom: '1'
                                }
                            }
                        }
                    }
                }
            ],
            group1: true,
            group2: true,

            code: null,
            station_name: null,
            sortBy: "name",
            sortDir: true,
            loading: false,
            perPage: 5,
            totalStations: 0,
            stationItems: [],
            country: null,
            countryItems: [],
            headers: [
                {
                    text: this.$t('name'),
                    align: "left",
                    value: "name"
                },
                {
                    text: this.$t('code'),
                    align: "left",
                    value: "code"
                },
                {
                    text: this.$t('country'),
                    align: "left",
                    value: "country_id"
                },
            ],
            filter_show: false,
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },
    async mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage;
        this.init();

    },

    methods: {
        async init() {
            await this.getCountries()
            await this.getFilters();
            await this.getStations()
        },
        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table3' : 'rowMobiClass';
        },
        editStation(item) {
            this.$router.push({
                name: 'station.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getStations() {
            var _this = this
            this.progress = 0
            this.loading = true
            const {
                sortBy,
                sortDir: sortDesc,
                page,
                itemsPerPage
            } = this.options
            let params = {}

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }

            if (sortDesc !== undefined) {
                params.sortDir = sortDesc ? 'asc' : 'desc'
            }

            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }


            if (this.filterValues) {
                params.fltr = this.filterValues;
            }


            await this.$http
                .get("admin/station", {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.filterData = res.body.filter
                    this.stationItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.$toastr.success(this.$t('failed_to_get_list_stations'))
                    this.stationItems = []
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async deleteStation(item) {
            if (confirm(this.$t('delete_station'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/station/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('station_has_been_deleted'))
                        this.getStations()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('station_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getCountries() {
            var _this = this
            let params = {}
            params.list = 'all'
            await this.$http.get(`admin/country`, {
                    params: params,
                })
                .then(res => {
                    this.countryItems = res.body.data
                    this.Additional = res.body.data.map((item) => {
                        return {
                            ...item,
                            title: item.name,
                            count: '-',
                            action: 'station',
                            can: 1,
                            url: {
                                path: '/station',
                                query: {
                                    filter: {
                                        country_id: {
                                            eq: item.id + ''
                                        }
                                    }
                                }

                            }
                        }
                    })
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_countries'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
        },

    }
}
</script>
