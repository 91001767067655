<template>
    <PageLayout>
        <TabsNav
            strict
            :items="[
                {
                    title: $t('all'),
                    route: {
                        name: 'messages'
                    }
                },
                {
                    title: $t('uveds'),
                    route: {
                        name: 'messages',
                        query: {
                            fltr: {
                                messageable_type: {
                                    eq: 'uved'
                                }
                            }
                        }
                    }
                },
                {
                    title: $t('deals'),
                    route: {
                        name: 'messages',
                        query: {
                            fltr: {
                                messageable_type: {
                                    eq: 'deal'
                                }
                            }
                        }
                    }
                },
                {
                    title: $t('tasks'),
                    route: {
                        name: 'messages',
                        query: {
                            fltr: {
                                messageable_type: {
                                    eq: 'task'
                                }
                            }
                        }
                    }
                }
            ]" @input="getItems"
        />
        <div class="w-100 d-flex flex-wrap justify-space-between mb-3">
            <h1 class="mb-3">{{ $t('messages') }}</h1>
            <div
                class="d-flex align-center w-100"
                :class="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 'text-right' : 'text-right'"
            >
                <div class="buttons-block w-100">
                    <Btn @click="readMessages" :style="countUnreadNotifications > 0">
                        {{ $t('mark_as_read') }}
                        <v-icon right small style="font-weight: 500;" class="bold">
                            mdi-check-all
                        </v-icon>
                    </Btn>
                </div>
            </div>
        </div>

        <div
            v-if="items.length <= 0"
            class="mx-auto"
        >
            <v-list-item-content>
                <div class="text-overline mb-4">
                    <v-img src="img/no/storage.png"></v-img>
                </div>
            </v-list-item-content>
        </div>
        <div v-else>
            <v-list three-line>
                <template v-for="(item, index) in messages">
                    <v-list-item
                        :key="item.uuid"
                        :to="item.route"
                    >
                        <v-list-item-avatar>
                            <v-icon
                                :class="(item.is_readed ? 'grey' : 'primary')"
                                class=" lighten-1"
                                dark
                            >
                                mdi-card-text
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-html="item.title"></v-list-item-title>
                            <v-list-item-subtitle v-html="item.comment.value"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-list-item-action-text v-text="item.created_at"></v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>

            <v-card-actions class="justify-center" v-if="(nextLink === true && loading === false)">
                <v-btn
                    color="primary"
                    outlined
                    class="elevation-0 "
                    @click="nextItems"
                >
                    {{ $t('download_more') }}
                </v-btn>
            </v-card-actions>
        </div>
    </PageLayout>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import {getMessaging, getToken} from "firebase/messaging";
import Btn from "@/components/Form/Btn.vue";
import ResourceView from "@/plugins/mixins/ResourceView";
import FilterView from "@/plugins/mixins/FilterView";
import TabsNav from "@/components/Leentech/TabsNav.vue";

export default {
    name: 'Messages',
    components: {Btn, TabsNav},

    props: {
        type: {
            type: String,
            default: 'uved'
        }
    },

    inject: [
        'forceRerenderSidebar'
    ],

    data() {
        return {
            page: 1,
            total: 0,
            pageCount: 0,
            items: [],

            loading: false,
        }
    },

    computed: {
        ...mapGetters(['listLanguages', 'lang']),

        nextLink() {
            return this.pageCount !== this.page
        },

        messages() {
            let items = [];
            this.items.forEach((item) => {
                let routeName = '';

                switch (item.messageable_type) {
                    case 'uved':
                        routeName = 'uved.show';
                        break;
                    case 'task':
                        routeName = 'task.show';
                        break;
                    case 'deal':
                        routeName = 'deal.show';
                        break;
                }

                items.push({
                    ...item,
                    route: {
                        name: routeName,
                        params: {
                            id: item.messageable_uuid
                        }
                    }
                });
            })

            return items;
        }
    },

    watch: {
        '$route.query': {
            handler: function (val) {
                this.init()
            },
            immediate: true
        }
    },

    methods: {
        ...mapActions('messages', {
            fetchGlobalMessages: 'fetchMessages'
        }),

        ...mapActions({
            fetchNotificationGroups: 'fetchNotificationGroups'
        }),

        init() {
            this.getItems();
        },

        getItems() {
            this.fetchMessages().then(body => {
                this.items = body.data
                this.page = body.meta.current_page
                this.totalNotifications = body.meta.total
                this.pageCount = body.meta.last_page
            });
        },

        fetchMessages(page = 1) {
            this.loading = true

            let params = {
                fltr: {
                    my: {
                        eq: '1'
                    },
                    exclude_bot: {
                        eq: '1'
                    }
                },
            }

            if(this.$route.query.fltr) {
                params.fltr = {
                    ...params.fltr,
                    ...this.$route.query.fltr
                }
            }

            if (page !== undefined) {
                params.page = page;
            }

            return new Promise((resolve, reject) => {
                this.$http
                    .get("admin/message", {
                        params: params,
                    })
                    .then(({body}) => {
                        resolve(body);
                    })
                    .catch(err => {
                        this.notificationItems = []
                        this.totalNotifications = 0
                        this.$toastr.error(this.$t('failed_to_get_notifications'))

                    })
                    .finally(end => {
                        this.loading = false
                    })
            });
        },

        readMessages() {
            this.$http
                .post("admin/message/read")
                .then(({body}) => {
                    this.getItems();
                    this.fetchGlobalMessages();
                    this.fetchNotificationGroups();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_mark_as_read'))
                })
        },

        nextItems() {
            this.page++
            this.fetchMessages(this.page).then(body => {
                this.items = [...this.items, ...body.data]
                this.page = body.meta.current_page
                this.totalNotifications = body.meta.total
                this.pageCount = body.meta.last_page
            });
        }
    }
}
</script>
<style scoped>
.topic_background {
    border: 1px solid #f2f4f5;
}

.topic_title {
    font-weight: 400;
    font-size: 19px;
    color: #000
}
</style>
