<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveItem">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ $t('organization_account.edit') }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <ValidationProvider :ref="`bank`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="country"
                                            :label="$t('columns.organization_account.bank_id')"
                                            v-model="bank"
                                            :items="banks.map((item) => ({'text': item.name, 'value': item.id}))"
                                            :error="!valid"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider :ref="`currency`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="currency"
                                            :label="$t('columns.organization_account.currency_id')"
                                            v-model="currency"
                                            :items="currencies.map((item) => ({'text': item.name, 'value': item.id}))"
                                            :error="!valid"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider :ref="`account_type`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="currency"
                                            :label="$t('columns.organization_account.account_type_id')"
                                            v-model="account_type"
                                            :items="accountTypes.map((item) => ({'text': item.name, 'value': item.id}))"
                                            :error="!valid"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`is_active`" rules="" v-slot="{ errors, valid }">
                                        <v-checkbox
                                            id="is_active"
                                            :label="$t('columns.organization_account.is_active')"
                                            v-model="is_active"
                                            :error="!valid"
                                        >
                                        </v-checkbox>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`number`" rules="required|min:1" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="sort"
                                            :label="$t('columns.organization_account.number')"
                                            v-model="number"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider :ref="`description`" rules="" v-slot="{ errors, valid }">
                                        <TextInput
                                            id="description"
                                            :label="$t('columns.organization_account.description')"
                                            v-model="description"
                                            :error="!valid"
                                        >
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>

                            </v-row>
                        </v-card-text>
                        <v-card-actions class="buttons-block">
                            <Btn type="submit" :disabled="invalid || loading" :loading="loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                {{ $t('save') }}
                            </Btn>
                            <Btn
                                :disabled="loading"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </Btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import Btn from "@/components/Form/Btn.vue";
    import TextInput from "@/components/Form/TextInput.vue";
    import SelectInput from "@/components/Form/SelectInput.vue";
    import WithBanks from "@/plugins/mixins/WithBanks";
    import WithCurrencies from "@/plugins/mixins/WithCurrencies";
    import WithAccountTypes from "@/plugins/mixins/WithAccountTypes";

    export default {
        name: 'VatForm',
        components: {
            SelectInput,
            Btn,
            ValidationProvider,
            ValidationObserver,
            TextInput
        },

        mixins: [
            WithBanks,
            WithCurrencies,
            WithAccountTypes
        ],

        props: {
            uuid: {
                type: String,
                required: true
            },
            accountUuid: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                dialogForm: true,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,

                countries: [],

                number: null,
                bank: null,
                currency: null,
                account_type: null,
                is_active: true,
                description: null,
                opened_at: null,
                closed_at: null
            }
        },

        computed: {
            ...mapGetters(['listLanguages', 'lang']),

            languages() {
                return this.listLanguages
            },
        },

        watch: {
            accountUuid: {
                handler: function (value) {
                    this.init();

                    if (value !== 'create') {
                        this.fetchItem();
                    }
                },

                immediate: true
            }
        },

        async mounted() {
            this.language = this.languages[this.tab]
        },

        methods: {
            init() {
                this.fetchCountries();
            },

            fetchCountries() {
                let _this = this;
                this.$http.get('admin/country')
                    .then(res => {
                        if (res && res.body && res.body.data) {
                            this.countries = res.body.data
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('columns.organization_account.fetching-error'))

                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
            },

            closeDialogAdd(){
                this.$router.push({
                    name: 'organization_accounte',
                })
            },

            async fetchItem() {
                let _this = this;
                let params = {}

                this.progress = 0
                this.loading = true

                if (this.language) {
                    params.language = this.language
                }

                await this.$http
                    .get(`admin/organization/${this.uuid}/account/${this.accountUuid}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if (res && res.body && res.body.data) {
                            this.handleResponse(res.body.data)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$toastr.error(this.$t('columns.organization_account.fetching-error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },

            async saveItem() {
                let _this = this;

                this.progress = 0
                this.loading = true

                let formData = {};

                formData['bank_id'] = this.bank
                formData['currency_id'] = this.currency
                formData['account_type_id'] = this.account_type
                formData['is_active'] = this.is_active
                formData['account_number'] = this.number
                formData['description'] = this.description

                if (this.accountUuid === 'create') {
                    // Add
                    await this.$http
                        .post(`admin/organization/${this.uuid}/account`, formData, {
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('columns.organization_account.added'))
                            this.$router.push({
                                name: 'organization_account'
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('columns.organization_account.add_error'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    await this.$http
                        .put(`admin/organization/${this.uuid}/account/${this.accountUuid}`, formData, {
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('columns.organization_account.update_success'))

                            this.$router.push({
                                name: 'organization_account'
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('columns.organization_account.update_error'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },

            handleResponse(res) {
                this.bank = res.bank_id
                this.currency = res.currency_id
                this.account_type = res.account_type_id
                this.is_active = res.is_active
                this.number = res.account_number
                this.description = res.description
            }
        }
    }
</script>
