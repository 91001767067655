<template>
    <v-container class="auth" fluid fill-height justify-center align-center>
        <v-row justify="center" class="auth__row">
            <v-col cols="12" sm="8" md="4" lg="3" class="auth__left">
                <v-icon class="auth__logo">icon-logo-footer</v-icon>
                <div class="auth__form">
                    <h1>{{ $t('login') }}</h1>
                    <v-form @submit.prevent="submit">
                        <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                            <v-row>
                                <v-col cols="12">
                                    <ValidationProvider ref="email" rules="required|email|min:5|max:50" v-slot="{ errors, valid }">
                                        <InputText
                                            id="email"
                                            v-model="email"
                                            type="email"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            :label="$t('email')"
                                            dark
                                        >
                                            <template v-slot:prepend-inner>
                                                <v-icon size="20" color="#898989">
                                                    icon-envelope
                                                </v-icon>
                                            </template>
                                        </InputText>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                    <ValidationProvider ref="password" rules="required|min:8|max:30" v-slot="{ errors, valid }">
                                        <InputText
                                            id="password"
                                            v-model="password"
                                            :error-messages="errors"

                                            :type="passwordHidden ? 'password' : 'text'"
                                            :disabled="loading"
                                            :label="$t('password')"
                                            dark
                                        >
                                            <template v-slot:prepend-inner>
                                                <v-icon size="20" color="#898989">
                                                    icon-lock
                                                </v-icon>
                                            </template>
                                            <template v-slot:append>
                                                <span @click="togglePassword">
                                                    <v-icon size="20" color="secondary">
                                                        {{ passwordHidden ? 'icon-view-on' : 'icon-view-off' }}
                                                    </v-icon>
                                                </span>
                                            </template>
                                        </InputText>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <Btn class="w-100" type="submit" :loading="loading" :disabled="loading || invalid || !validated">{{ $t('sign_in') }}</Btn>
                                </v-col>
                            </v-row>
                        </ValidationObserver>
                    </v-form>
                </div>
                <v-menu open-on-hover offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon>
                            <v-icon>mdi-web</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="py-0" dense>
                        <v-list-item @click="setLang('kk', true)">
                            <v-list-item-title>{{ $t('kazakh') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="setLang('ru', true)">
                            <v-list-item-title>{{ $t('russian') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="setLang('en', true)">
                            <v-list-item-title>{{ $t('english') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="setLang('zh', true)">
                            <v-list-item-title>{{ $t('chinese') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="8" lg="9" class="auth__right auth__bg"></v-col>
        </v-row>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from "vee-validate"
import {mask} from 'vue-the-mask'
import InputText from '@/components/Form/TextInput.vue'
import Btn from '@/components/Form/Btn.vue'

export default {
    name: "Login",
    directives: {
        mask
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        InputText,
        Btn
    },
    data() {
        return {
            email: null,
            password: null,
            remember: null,
            passwordHidden: true,
            loading: false,
            show: false,
        };
    },
    computed: {
        ...mapGetters(['lang']),
    },
    mounted() {
        this.show = true
    },
    methods: {
        togglePassword(){
            this.passwordHidden = !this.passwordHidden;
        },
        setLang(langSelect) {
            let langLocale = localStorage.getItem('lang')
            document.documentElement.lang = langSelect ? langSelect : (langLocale ? langLocale : process.env.VUE_APP_I18N_LOCALE)
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
        },
        async setTheme(themeSelect) {
            let localTheme = localStorage.getItem('themeDark')
            localTheme = localTheme === 'true' ? true : (localTheme === 'false' ? false : null)
            const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
            this.$vuetify.theme.dark = (themeSelect !== undefined ? themeSelect : (localTheme !== null ? localTheme : mediaTheme))
            localStorage.setItem('themeDark', this.$vuetify.theme.dark)
            document.documentElement.setAttribute('class', (this.$vuetify.theme.dark ? 'dark' : 'light'))
        },
        clear() {
            this.email = this.password = null;
            requestAnimationFrame(() => {
                this.$refs.observ.reset();
            });
        },
        async submit() {
            var _this = this
            this.loading = true;
            await this.$auth
                .login({
                    body: {
                        email: this.email,
                        password: this.password,
                    },
                    fetchUser: true
                })
                .then(res => {
                    if (this.$auth.check() && this.$auth.user() && this.$auth.user().settings) {
                        this.$store.dispatch('setSettings', this.$auth.user().settings)
                    }
                    this.$toastr.success(this.$t('you_have_successfully_logged_in'));
                })
                .catch(err => {
                    if (err && err.status !== 503) {
                        this.$toastr.error(this.$t('you_entered_the_wrong_data'));
                    }
                    if (err && err.body && err.body.message) {
                        if (err && err.status === 503) {
                            this.$toastr.error(this.$t('the_service_is_temporarily_unavailable_please_try_again_later'), this.$t('maintenance_mode'));
                        } else {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    }
                });
            this.loading = false;
        }
    }
}
</script>

<style lang="scss">
.auth__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: $grid-gutter * 2;
}

.auth__logo {
    &.v-icon {
        font-size: 40px;
        color: var(--primary);
    }
}

.auth__row {
    height: 100vh;
    align-items: center;
    background-color: #fff;
}

.auth__bg {
    height: 100%;
    background-image: radial-gradient(167.42% 189.57% at 29.91% 16.01%, #FFFFFF 0%, #B1DAF9 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    position: relative;
    display: none;

    &:before {
        content: '';
        background: url('/img/auth-bg.png') no-repeat center center;
        background-size: contain;
        position: absolute;
        height: 80%;
        width: 80%;
        margin: 10%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 0;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        display: block;
    }
}

.auth__form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
</style>
