<script>
export default {
    name: "Btn",
    props: {
        color: {
            type: String,
            default: 'primary'
        },
        type: {
            type: String,
            default: 'button'
        }
    },
    methods: {
        handleClick() {
            this.$emit('click');
        }
    }
}
</script>

<template>
    <v-btn :type="type" v-bind="$attrs" v-on="$listeners" class="inf-button" :color="color">
        <slot name="prepend"></slot>
        <slot></slot>
        <slot name="append"></slot>
    </v-btn>
</template>

<style lang="scss">
.inf-button {
    box-shadow: none;

    &:active {
        box-shadow: none;
    }

    &.v-btn:not(.v-btn--round).v-size--large {
        font-size: 16px;
        letter-spacing: normal;
    }
}

.v-btn {
    @each $name, $size in $btn-font-sizes-mobile {
        &.v-size--#{$name} {
            font-size: $size
        }
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        @each $name, $size in $btn-font-sizes {
            &.v-size--#{$name} {
                font-size: $size
            }
        }
    }
}

</style>
