<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('accounting.report.title') }}</h1>
                </div>
            </div>
            <TabsNav :items="breadcrumbs()[1].items" />
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import WarehouseHeader from "@/components/Warehouse/WarehouseHeader.vue";
import Side from "@/components/Warehouse/Side.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import NavMixin from "@/plugins/mixins/Nav";

export default {
    name: "Sale",

    components: {
        TabsNav,
        Side,
        PageLayout
    },

    mixins: [NavMixin],

    data() {
        return {}
    },

    computed: {},

    mounted() {
        this.init();
    },

    methods: {
        init() {

        }
    }
}
</script>
