export default {
    data() {
        return {
            id: null,
            passedAt: null
        };
    },

    computed: {
        saved() {
            return this.id !== null
        },

        passed() {
            return this.passedAt !== null
        }
    },

    methods: {
        deleteDocument() {
            return new Promise((resolve, reject) => {
                this.$http
                    .delete(`admin/${this.documentUrl}/${this.uuid}`)
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('incoming.delete_error'))
                        reject(err)
                    })
                    .finally(end => {

                    })
            })
        },

        pass() {
            return new Promise((resolve, reject) => {
                this.$http
                    .post(`admin/${this.documentUrl}/${this.uuid}/pass`)
                    .then(res => {
                        resolve(res.body)
                    })
                    .catch(err => {
                        reject(err)
                    });
            });
        },

        revoke() {
            return new Promise((resolve, reject) => {
                this.$http
                    .post(`admin/${this.documentUrl}/${this.uuid}/revoke`)
                    .then(res => {
                        resolve(res.body)
                    })
                    .catch(err => {
                        reject(err)
                    });
            });
        },

        onRevokeClick() {
            this.loading = true;

            this.revoke().then((data) => {
                this.$toastr.success(this.$t('document.revoked_successfully'));
                this.passedAt = null;
            }).catch((error) => {
                this.$toastr.error(this.$t('document.error_revoking'));
                this.$toastr.error(error);
            }).finally(() => {
                this.loading = false;
            })
        },

        onPassClick() {
            this.loading = true;

            this.pass().then((data) => {
                this.$toastr.success(this.$t('document.passed_successfully'));
                this.passedAt = data.passed_at
            }).catch((error) => {
                this.$toastr.error(this.$t('document.error_passing'));
                this.$toastr.error(error);
            }).finally(() => {
                this.loading = false;
            })
        },
    }
}
