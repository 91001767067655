<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveEmailConnect">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat >
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>

                            </v-row>

                            <v-row class="mt-5 mb-2">
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="username" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <TextInput
                                            id="username"
                                            v-model="username"
                                            :value="username"
                                            :error="!valid"
                                            :error-messages="errors"
                                            :label="$t('email')"
                                            :disabled="loading"
                                            @blur="findDomain"
                                        >

                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="password" rules="required"
                                                        v-slot="{ errors, valid }">

                                        <TextInput
                                        id="password"
                                        :label="$t('password')"
                                        v-model="password"
                                        :disabled="loading"
                                        :error-messages="errors"
                                        :error="!valid"
                                        rows="3"
                                        :type="passwordHidden ? 'password' : 'text'"
                                        >
                                        <template v-slot:append>
                                            <v-icon size="22" color="primary" class="input_icon" @click="passwordHidden = !passwordHidden">
                                                {{ passwordHidden ? 'mdi-eye-off' : 'mdi-eye' }}
                                            </v-icon>
                                        </template>
                                        </TextInput>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" class="cursor-pointer">
                                    <v-btn small text color="primary" @click="manual_settings_open()">
                                        {{$t('manual_settings')}}
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row class="py-0" v-show="manual_settings">
                                <v-col cols="12">
                                    <v-card class="my-0 py-0" style="background-color: #F9F9F9">
                                        <v-card-title>
                                            {{$t('imap_outgoing_mail_server')}}
                                        </v-card-title>
                                        <v-card-text class="py-0">
                                            <v-row>
                                            <v-col class="py-0" cols="12">
                                                <ValidationProvider ref="host" rules="required"
                                                                    v-slot="{ errors, valid }">
                                                    <TextInput
                                                        id="host"
                                                        v-model="host"
                                                        :value="host"
                                                        :error="!valid"
                                                        :error-messages="errors"
                                                        :label="$t('server')"
                                                        :disabled="loading"
                                                    >

                                                    </TextInput>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col class="py-0" cols="12" sm="6">
                                                <ValidationProvider ref="port" rules="required"
                                                                    v-slot="{ errors, valid }">
                                                    <TextInput
                                                        id="port"
                                                        v-model="port"
                                                        :value="port"
                                                        :error="!valid"
                                                        :error-messages="errors"
                                                        :label="$t('port')"
                                                        :disabled="loading"
                                                    >

                                                    </TextInput>
                                                </ValidationProvider>
                                            </v-col><v-col class="py-0" cols="12" sm="6">
                                                <ValidationProvider ref="encryption" rules="required"
                                                                    v-slot="{ errors, valid }">
                                                    <TextInput
                                                        id="encryption"
                                                        v-model="encryption"
                                                        :value="encryption"
                                                        :error="!valid"
                                                        :error-messages="errors"
                                                        :label="$t('encryption')"
                                                        :disabled="loading"
                                                    >

                                                    </TextInput>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-checkbox
                                                    v-model="validate_cert"
                                                    :label="$t('use_tls_ssl_recommended_for_security')"
                                                ></v-checkbox>
                                            </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="pb-0" v-show="manual_settings">
                                <v-col cols="12">
                                    <v-card class="pb-0" style="background-color: #F9F9F9">
                                        <v-card-title>
                                            {{$t('smtp_outgoing_mail_server')}}
                                        </v-card-title>
                                        <v-card-text class="pb-0">
                                            <v-row>
                                                <v-col class="py-0" cols="12">
                                                    <ValidationProvider ref="smtp_host" rules="required"
                                                                        v-slot="{ errors, valid }">
                                                        <TextInput
                                                            id="smtp_host"
                                                            v-model="smtp_host"
                                                            :value="smtp_host"
                                                            :error="!valid"
                                                            :error-messages="errors"
                                                            :label="$t('server')"
                                                            :disabled="loading"
                                                        >

                                                        </TextInput>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col class="py-0" cols="12" sm="6">
                                                    <ValidationProvider ref="smtp_port" rules="required"
                                                                        v-slot="{ errors, valid }">
                                                        <TextInput
                                                            id="smtp_port"
                                                            v-model="smtp_port"
                                                            :value="smtp_port"
                                                            :error="!valid"
                                                            :error-messages="errors"
                                                            :label="$t('port')"
                                                            :disabled="loading"
                                                        >

                                                        </TextInput>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col class="py-0" cols="12" sm="6">
                                                    <ValidationProvider ref="smtp_encryption" rules="required"
                                                                        v-slot="{ errors, valid }">
                                                        <TextInput
                                                            id="smtp_encryption"
                                                            v-model="smtp_encryption"
                                                            :value="smtp_encryption"
                                                            :error="!valid"
                                                            :error-messages="errors"
                                                            :label="$t('encryption')"
                                                            :disabled="loading"
                                                        >

                                                        </TextInput>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-checkbox
                                                        v-model="smtp_validate_cert"
                                                        :label="$t('use_tls_ssl_recommended_for_security')"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 py-7">

                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                :to="{name: 'email_connect'}"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import TextInput from "@/components/Form/TextInput.vue";

    export default {
        name: 'EmailConnectForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            TextInput,
        },
        inject: ['forceRerender'],
        data() {
            return {
                dialogForm: true,
                heading: null,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,
                sortBy: "sort",
                sortDir: false,

                username: null,
                password: null,
                passwordHidden: true,

                host: null,
                port: null,
                encryption: null,
                validate_cert: true,
                protocol: 'imap',

                smtp_host: null,
                smtp_port: null,
                smtp_encryption: null,
                smtp_validate_cert: true,
                smtp_protocol: 'smtp',

                EmailConnectorGuideItems: [],
                found_connector: {},
                manual_settings : false
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.language = this.languages[this.tab]
            this.checkCreate()
            this.getEmailConnectorGuide()
        },
        methods: {
            checkCreate() {
                if (this.$route.name === "email_connect.create") {
                    this.heading = this.$t('email_connect_creation')
                } else {
                    this.heading = this.$t('email_connect_editing')
                    if (this.$route.params.id) {
                        this.getEmailConnect()
                    }
                }
            },
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
            closeDialogAdd(){
                if(window.history.length > 2){
                    this.$router.back()
                }
                else{
                    this.$router.push({
                        name: 'email_connect',
                    })
                }
            },
            async getEmailConnect() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/email_reader/connector/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.username = res.body.data.username;
                        this.password = res.body.data.password;


                        this.host = res.body.data.host;
                        this.port = res.body.data.port;
                        this.encryption = res.body.data.encryption;
                        this.validate_cert = res.body.data.validate_cert;
                        this.protocol = res.body.data.protocol;
                        this.smtp_host = res.body.data.smtp_host;
                        this.smtp_port = res.body.data.smtp_port;
                        this.smtp_encryption = res.body.data.smtp_encryption;
                        this.smtp_validate_cert = res.body.data.smtp_validate_cert;
                        this.smtp_protocol = res.body.data.smtp_protocol;

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_email_connect'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveEmailConnect() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }

                if (this.host) {
                    formData.append('host', this.host)
                }
                if (this.port) {
                    formData.append('port', this.port)
                }
                if (this.encryption) {
                    formData.append('encryption', this.encryption)
                }
                if (this.validate_cert) {
                    formData.append('validate_cert', 1)
                }
                else {
                    formData.append('validate_cert', 0)
                }
                if (this.protocol) {
                    formData.append('protocol', this.protocol)
                }

                if (this.username) {
                    formData.append('username', this.username)
                }
                if (this.password) {
                    formData.append('password', this.password)
                }


                if (this.smtp_host) {
                    formData.append('smtp_host', this.smtp_host)
                }
                if (this.smtp_port) {
                    formData.append('smtp_port', this.smtp_port)
                }
                if (this.smtp_encryption) {
                    formData.append('smtp_encryption', this.smtp_encryption)
                }
                if (this.smtp_validate_cert) {
                    formData.append('smtp_validate_cert', 1)
                }
                else {
                    formData.append('smtp_validate_cert', 0)
                }
                if (this.smtp_protocol) {
                    formData.append('smtp_protocol', this.smtp_protocol)
                }

                if (this.$route.params.id) {
                    await this.$http
                        .put(`admin/email_reader/connector/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('email_connect_has_been_updated'))
                            this.closeDialogAdd()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('email_connect_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/email_reader/connector', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('email_connect_has_been_added'))
                            this.closeDialogAdd()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('email_connect_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            async getEmailConnectorGuide(){

                this.loading = true
                let params = {}

                await this.$http
                    .get("admin/email_reader/connector_guide", {
                        params: params,
                    })
                    .then(res => {
                        this.EmailConnectorGuideItems = res.body.data
                    })
                    .catch(err => {
                        this.EmailConnectorGuideItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            findDomain() {
                // Извлекаем домен из введенного email
                const domain = this.username.split('@')[1].split('.')[0];
                this.found_connector = this.EmailConnectorGuideItems.find((obj) => obj.domain === domain);
                if (this.found_connector && this.found_connector.domain) {
                    this.host = this.found_connector.host;
                    this.port = this.found_connector.port;
                    this.encryption = this.found_connector.encryption;
                    this.validate_cert = this.found_connector.validate_cert;
                    this.protocol = this.found_connector.protocol;
                    this.smtp_host = this.found_connector.smtp_host;
                    this.smtp_port = this.found_connector.smtp_port;
                    this.smtp_encryption = this.found_connector.smtp_encryption;
                    this.smtp_validate_cert = this.found_connector.smtp_validate_cert;
                    this.smtp_protocol = this.found_connector.smtp_protocol;
                }
                else {
                    this.host = null;
                    this.port = null;
                    this.encryption = null;
                    this.validate_cert = null;
                    this.protocol = null;
                    this.smtp_host = null;
                    this.smtp_port = null;
                    this.smtp_encryption = null;
                    this.smtp_validate_cert = null;
                    this.smtp_protocol = null;
                }
            },
            manual_settings_open(){
                if (this.manual_settings === true) {
                    this.manual_settings = false;
                }
                else {
                    this.manual_settings = true;
                }
            }


        }
    }
</script>
