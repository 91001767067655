<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('realization.plural') }}</h1>
                </div>
            </div>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Side from "@/components/Warehouse/Side.vue";

export default {
    name: "Sale",

    components: {
        Side,
        PageLayout
    },
    mixins: [],

    data() {
        return {}
    },

    computed: {},

    mounted() {
        this.init();
    },

    methods: {
        init() {

        }
    }
}
</script>
