<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="1200px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >


                <v-card flat>
                    <v-card-text class="py-0  px-10">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ activity_log.event }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                {{$t('author')}}: <b>{{activity_log.causer.name}}</b>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                {{$t('date')}}:  <b>{{activity_log.created_at}}</b>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5 mb-5">
                            <v-col class="py-0" cols="12" sm="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5 " >
                            <v-col v-if="Object.entries(old).length > 0" class="py-0" cols="12" :sm="Object.entries(attributes).length === 0 ? 12 : 6">
                                <v-card class="mx-auto" :class="(Object.entries(old).length > 0 && Object.entries(attributes).length > 0) ?  'shadow' : ''">
                                    <v-card-title :class="(Object.entries(old).length === 0 || Object.entries(attributes).length === 0) ?  'pt-0' : ''"><b>{{$t('it_was')}}</b></v-card-title>
                                    <v-card-text>
                                        <v-row  v-for="(value, key) in old" :key="key + '_old'"
                                                :class="{'background_grey': changed_keys.includes(key)}"
                                        >
                                            <v-col cols="12" sm="6">{{key}}</v-col>
                                            <v-col cols="12" sm="6">{{value}}</v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col  v-if="Object.entries(attributes).length > 0 > 0" class="py-0" cols="12"  :sm="Object.entries(old).length === 0 ? 12 : 6">
                                <v-card class="mx-auto" :class="(Object.entries(old).length > 0 && Object.entries(attributes).length > 0) ?  'shadow' : ''">
                                    <v-card-title :class="(Object.entries(old).length === 0 || Object.entries(attributes).length === 0) ?  'pt-0' : ''"><b>{{$t('become')}}</b></v-card-title>
                                    <v-card-text>
                                        <v-row  v-for="(value, key) in attributes" :key="key + '_attribute'"
                                                :class="{'background_yellow': changed_keys.includes(key)}"
                                        >
                                            <v-col cols="12" sm="6">{{key}}</v-col>
                                            <v-col cols="12" sm="6">{{value}}</v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>



                    </v-card-text>
                    <v-card-actions class="px-4 py-7" >
                        <v-btn v-if="false"
                            :disabled="loading"
                            class="ma-1 button_cancel"
                            plain
                            @click="closeDialogAdd"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>

                    </v-card-actions>
                </v-card>

        </v-dialog>
    </v-container>
</template>
<script>


import {mapGetters} from "vuex";

export default {
    name: 'ActivityLogForm',
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            activity_log: {},
            old: {},
            attributes: {},
            changed_keys: {},
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "activity_log.create") {
                this.heading = this.$t('activity_log_creation')
            } else {
                this.heading = this.$t('activity_log_editing')
                if (this.$route.params.id) {
                    this.getActivityLog()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        closeDialogAdd(){
            if(window.history.length > 2){
                this.$router.back()
            }
            else{
                this.$router.push({
                    name: 'activity_log',
                })
            }
        },
        async getActivityLog() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/activity_log/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.activity_log = res.body.data
                    this.old = res.body.old
                    this.attributes = res.body.attributes
                    this.changed_keys = res.body.changed_keys
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_activity_log'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

    }
}
</script>

<style scoped>
.background_yellow {
    background-color: #fef3c7;
}
.background_grey {
    background-color: #F5F5F5;
}
.shadow.v-card:not(.v-sheet--outlined) {
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
</style>
