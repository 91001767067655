<script>
import FileUploader from "@/components/Leentech/FileUploader.vue";

export default {
    name: "NomenclatureImages",
    components: {FileUploader},

    props: {
        entityType: {
            type: String,
            required: true
        },

        entityUuid: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            files: [],
        }
    },

    watch: {
        entityUuid: {
            handler: function (value) {
                this.loading = true;

                this.init().then(() => {
                    this.loading = false;
                });
            },
            immediate: true
        }
    },

    methods: {
        init() {
            return new Promise((resolve, reject) => {
                this.loading = true;

                let filesPromise = this.fetchFiles(this.entityType, this.entityUuid)
                    .then((data) => {
                        this.files = data.data;
                    });

                Promise.all([filesPromise]).then(() => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    this.loading = false;
                });
            });
        },

        onDownloadFileBtnClick(file) {
            let a = document.createElement('a');
            a.href = file.url;
            a.download = file.name;
            a.click();
        },

        deleteAccompanyingDocumentFile(item) {
            let index = this.accompanying_document_urls.indexOf(item)
            this.accompanying_document_urls.splice(index, 1)
        },

        deleteAccompanyingDocuments(item) {
            let index = this.accompanying_documents_preview.indexOf(item)
            this.accompanying_documents_preview.splice(index, 1)
            if (this.accompanying_documents_preview.length === 0) {
                this.accompanying_documents_dialog = false;
                this.accompanying_documents_preview = [];
                this.accompanying_documents = []
            }
        },

        async onDeleteFileServerBtnClick(item) {
            if (confirm(this.$t('want_to_delete_file'))) {
                const _this = this
                this.loading = true;

                this.deleteFile(item).finally(() => {
                    _this.init().finally(() => {
                        _this.loading = false;
                    });
                });
            }
        },

        onFilesUploaded() {
            this.init();
        },

        onUploadingStart() {
            this.loading = true;
        },

        deleteFile(file) {
            return new Promise((resolve, reject) => {
                this.$http.delete(`admin/${this.entityType}/${this.entityUuid}/file/${file.uuid}`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fetchFiles(entityType, entityUuid) {
            return new Promise((resolve, reject) => {
                this.$http.get(`admin/${entityType}/${entityUuid}/file`, {
                    params: {
                        fltr: {
                            is_image: {
                                eq: 'true'
                            }
                        },
                        perPage: 'all'
                    }
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    }
}
</script>

<template>
    <div>
        <v-row v-if="loading">
            <v-col>
                <v-progress-linear
                    color="secondary"
                    indeterminate
                ></v-progress-linear>
            </v-col>
        </v-row>
        <template v-else-if="files.length">
            <v-row>
                <v-col
                    cols="3"
                    v-for="file in files"
                    :key="file.id"
                >
                    <a :href="file.url" target="_blank">
                        <v-img
                            :alt="`${file.original_name}`"
                            :src="file.url"
                            aspect-ratio="1"
                        ></v-img>
                    </a>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-row>
                <v-col>
                    <v-alert
                        dense
                        text
                        type="success"
                        class="ma-0"
                    >
                        {{ $t('no_files') }}
                    </v-alert>

                </v-col>
            </v-row>
        </template>

        <v-row>
            <v-col>
                <FileUploader
                    :entityType="entityType"
                    :entityUuid="entityUuid"
                    accept="image/*"
                    @files-uploading-start="onUploadingStart"
                    @files-uploaded="onFilesUploaded"
                ></FileUploader>
            </v-col>
        </v-row>
    </div>
</template>

<style scoped lang="scss">

</style>
