<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-card flat class="background_color_transparent">
                    <v-card-text>
                        <Btn class="mb-8" color="secondary" :to="{ name: 'organization.edit', params: { uuid: 'create' } }">{{$t('columns.organization.add')}}</Btn>
                        <v-data-table
                            :headers="headers"
                            :items="currencyItems"
                            :options.sync="options"
                            :page.sync="page"
                            :items-per-page="perPage"
                            :server-items-length="totalCurrencies"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDir"
                            :loading="loading"
                            :locale="lang"
                            :loading-text="$t('loading_please_wait')"
                            hide-default-footer
                            :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                            class="sortable-table elevation-0"
                        >
                            <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                <thead>
                                    <tr>
                                        <th v-for="h in headers" :key="h.value" class="table_header">
                                            <span>{{ h.text }}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>

                            <template v-slot:item.id="{ item }">
                                <router-link :to="{name: 'organization.edit', params: { uuid: item.uuid }}">{{ item.id }}</router-link>
                            </template>

                            <template v-slot:item.uuid="{ item }">
                                <router-link :to="{name: 'organization.edit', params: { uuid: item.uuid }}">{{ item.uuid }}</router-link>
                            </template>

                            <template v-slot:item.value="{ item }">
                                {{ item.value }}%
                            </template>
                            <template v-slot:item.account="{ item }">
                                <router-link :to="{name: 'organization_account', params: { uuid: item.uuid }}">Счета</router-link>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions class="pt-0 justify-end">
                        <v-pagination
                            v-model="page"
                            class="pagination_table"
                            :total-visible="totalVisiblePage"
                            :length="pageCount"
                            :disabled="loading"
                        ></v-pagination>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from "vuex";
import SettingMenu from "../components/SettingMenu";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "Currencies",
    components: {
        Btn,
        // ValidationProvider,
        // ValidationObserver,
        SettingMenu
    },
    data() {
        return {
            sortBy: "name",
            sortDir: true,
            loading: false,
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePage: 5,
            totalCurrencies: 0,
            organization: null,
            currencyItems: [],
            headers: [
                {
                    text: this.$t('columns.organization.id'),
                    value: "id"
                },
                {
                    text: this.$t('columns.organization.uuid'),
                    value: "uuid"
                },
                {
                    text: this.$t('columns.organization.name'),
                    value: "name"
                },
                {
                    text: this.$t('columns.organization.created_at'),
                    value: "created_at"
                },
                {
                    text: this.$t('columns.organization.updated_at'),
                    value: "updated_at"
                },
                {
                    text: this.$t('columns.organization.deleted_at'),
                    value: "deleted_at"
                },
                {
                    text: this.$t('columns.organization.account'),
                    value: "account"
                }
            ],
            filter_show: false
        };
    },

    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },

    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
    },

    watch: {
        options: {
            handler() {
                this.fetchItems()
            },
            deep: false
        }
    },

    methods: {
        handleFilterShow(action) {
            this.filter_show = action;
        },

        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table4' : 'rowMobiClass';
        },

        editCurrency(item) {
            this.$router.push({
                name: 'organization.edit',
                params: {
                    id: item.id
                }
            })
        },

        async fetchItems(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;

            let params = {};

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            await this.$http
                .get("admin/organization", {
                    params: params,
                })
                .then(res => {
                    this.currencyItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalCurrencies = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.currencyItems = []
                    this.totalCurrencies = 0
                    this.$toastr.error(this.$t('failed_to_get_list_currencies'))
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async deleteCurrency(item) {
            if (confirm(this.$t('organization.delete_confirm'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/organization/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('currency_has_been_deleted'))
                        this.fetchItems()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('currency_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
    }
}
</script>
